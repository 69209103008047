import { Link } from "@material-ui/core";
import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { useHtmlClassService } from "../../_core/MetronicLayout";

export function Footer() {
  const today = new Date().getFullYear();
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses("footer", true),
      footerContainerClasses: uiService.getClasses("footer_container", true),
    };
  }, [uiService]);

  return (
    <div
      className={`footer bg-white py-4 d-flex flex-lg-column  ${layoutProps.footerClasses}`}
      id="kt_footer"
    >
      <div
        className={`${layoutProps.footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        <div className="text-dark order-2 order-md-1">
          <span className="text-muted font-weight-bold mr-2">
            {today.toString() + " "}
          </span>
          &copy;
          <a
            href="https://my-bellaz.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-dark-75 text-hover-primary"
          >
            Iforsys Private Limited
          </a>
        </div>
        <div className="nav nav-dark font-weight-bolder">
          {/* <NavLink className="nav-link ml-0 pl-0" to="/contact">
            Contact
          </NavLink> */}
          <NavLink className="nav-link ml-0 pl-0" to="/faqs">
            FAQs
          </NavLink>
          <NavLink className="nav-link ml-0 pl-0" to="/privacy">
            Privacy
          </NavLink>
        </div>
      </div>
    </div>
  );
}
