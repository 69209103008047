import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import filterFactory from "react-bootstrap-table2-filter";

import { sortCaret } from "../../_metronic/_helpers";

import { StatusColumnFormatter } from "../../_metronic/_helpers/tableColumnFormatter";

import paginationFactory from "react-bootstrap-table2-paginator";
import { getSegmentList } from "../../store/admin/segment/segmentSlice";
import { Link } from "react-router-dom";

const LoadImage = (cell, row) => {
  return (
    <img src={`${process.env.REACT_APP_MEDIA_URL}/${cell}`} width="100px"></img>
  );
};

export const SegmentsTable = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.segments.segments);
  const [dataLoading, setDataLoading] = useState(true);


  useEffect(() => {
    dispatch(getSegmentList()).then(() => {
      setDataLoading(false);
    });
  }, []);

  const LinkFormatter = (cell, row) => {
    return <Link to={`segment/${row.uuid}`}>{cell}</Link>;
  };

  const columns = [
    // {
    //   dataField: "id",
    //   text: "#",
    // },
    {
      dataField: "name",
      text: "Segment Name",
      sort: true,
      sortCaret: sortCaret,
      formatter: LinkFormatter,
      // filter: textFilter(),
    },
    {
      dataField: "image",
      text: "Image",
      formatter: LoadImage,
    },
    {
      dataField: "isActive",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      formatter: StatusColumnFormatter,
    },
  ];

  const { SearchBar, ClearSearchButton } = Search;

  return (
    <ToolkitProvider search keyField="_id" data={data} columns={columns}>
      {(props) => (
        <>
          <div className="form-group row align-items-center ">
            <div className="col-lg-4">
              <h4 className="mb-0 float-right">Search</h4>
            </div>
            <div className="col-lg-8">
              <SearchBar {...props.searchProps} />
              <ClearSearchButton
                {...props.searchProps}
                className="ml-4 btn btn-secondary-light"
              />
            </div>
          </div>

          <BootstrapTable
            {...props.baseProps}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            wrapperClasses="table-responsive"
            bordered={false}
            bootstrap4
            pagination={paginationFactory()}
            filter={filterFactory()}
            noDataIndication={
              <div className={"d-flex justify-content-center py-10"}>
                <h3>{dataLoading ? "Loading Data..." : "No records found"}</h3>
              </div>
            }
          />
        </>
      )}
    </ToolkitProvider>
  );
};
