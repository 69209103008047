import React from "react";

import { StaffTable } from "./table";
import { CardBasic } from "../../components/CardBasic";
import { useSelector } from "react-redux";

export function StaffPage() {
  const { role, user } = useSelector((state) => state.authPersist);
  return (
    <CardBasic
      title={"Staff List"}
      btnType={"button"}
      headerBtnText={role === "business" ? "Add Staff" : null}
      headerBtnLink={role == "business" ? "/staff/create" : null}
    >
      <StaffTable />
    </CardBasic>
  );
}
