import axios from "axios";

const URL = process.env.REACT_APP_API_URL + "/business";
const URL2 = process.env.REACT_APP_API_URL + "/userBranch";
const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};


export const getLoyaltyPointsList = () => {
  return axios.get(`${URL}/loyaltyPoints/list`);
};

