import { createSlice } from "@reduxjs/toolkit";
import * as requestFromServer from "./branchInvoiceApi";

const initialState = {
  branchInvoice:[]
};

export const branchInvoiceSlice = createSlice({
  name: "branchInvoice",
  initialState,
  reducers: {
    updateList(state, action) {
      state.branchInvoice = action.payload;
    },
  },
});

export const loadBranchInvoice = (data) => {
  return async (dispatch) => {
    return requestFromServer
      .getBranchInvoice(data)
      .then((response) => {
        dispatch(branchInvoiceSlice.actions.updateList(response.data.error));
      })
      .catch((err) => {
        alert(err.message);
      });
  };
};
