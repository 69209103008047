import axios from "axios";

const URL = process.env.REACT_APP_API_URL + "/business";
const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const getHolidayList = () => {
  return axios.get(`${URL}/staff/holidays`);
};

export const createHoliday = (data) => {
  return axios.post(`${URL}/staff/holiday/create`, data, {
    headers,
  });
};

export const deleteStuffHoliday = (data) => {
  console.log(data);
  const req = {
    uuid: data,
  };
  return axios.delete(`${URL}/staff/holiday/delete`, {
    headers: {
      'Content-Type': 'application/json',
    },
    data: { "holidayUuid": data }
  });
};