import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import ReactSelect from "react-select";

import { Input, Select } from "../../_metronic/_partials/controls";

import { CardBasic } from "../../components/CardBasic";

import { branchHoliday } from "../../store/business/holiday/holidayApi";

import { MultiSelect } from "../../_metronic/_partials/controls/forms/MultiSelect";
import { DatePickerField } from "../../_metronic/_partials/controls";
import TextField from '@material-ui/core/TextField';
import { sendFailure, sendSuccess } from "../../store/toast/toastSlice";
import { loadBranchList } from "../../store/business/branches/branchSlice";

export const HolidayCreate = () => {
  const data = useSelector((state) => state.branch.branches);
  const history = useHistory();
  const dispatch = useDispatch();
  const { segments } = useSelector((state) => state.data);
  console.log(segments);
  console.log(data);
  const validationSchema = Yup.object().shape({
    branchUuid: Yup.string().required("Type is required"),
    startDate: Yup.date().required("This field is required"),
    endDate: Yup.date()
      .min(Yup.ref("startDate"), "End Date must be greater than Start Date")
      .required("This field is required"),
  });

  useEffect(() => {
    dispatch(loadBranchList()).then(() => {
    });
  }, []);

  return (
    <CardBasic
      title={"Add Holiday"}
      btnType={"submit"}
      formId={"createBranchForm"}
      headerBtnText={"Save Holiday"}
    >
      <Formik
        initialValues={{
          branchUuid: "",
          startDate: new Date(),
          endDate: new Date(),
        }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          console.log(values);
          branchHoliday(values)
            .then(() => {
              dispatch(
                sendSuccess(
                  "The branch holiday has been created successfully"
                )
              );
              history.replace("/holidays");
            })
            .catch((err) => {
              //console.log(err.response.data.message);
              dispatch(sendFailure(err.response.data.message));
              actions.setStatus("Error!");
            });
        }}
      >
        {({ values, errors }) => (
          <Form className="form form-label-right" id="createBranchForm">
            <div className="form-group row">
              <div className="col-lg-4">
                <Select name="branchUuid" label="Branch Name *" placeholder="Select Branch" >
                  <option value={'Select Branch'} key={'Select Branch'}>
                    {'Select Branch'}
                  </option>
                  {data.map((category, index) => (
                      <option value={category.uuid} key={category.uuid}>
                        {category.name}
                      </option>
                  ))}
                </Select>
              </div>
              <div className="col-lg-4">
                <div>
                  <label>Holiday Start Date *</label>
                </div>
                <div>
                  <DatePickerField
                    minDate={new Date()}
                    name="startDate"
                    className="form-control"
                  ></DatePickerField>
                </div>
              </div>
              <div className="col-lg-4">
                <div>
                  <label>Holiday End Date *</label>
                </div>
                <div>
                  <DatePickerField
                    name="endDate"
                    minDate={new Date()}
                    className="form-control"
                  ></DatePickerField>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </CardBasic>
  );
};
