import { createSlice } from "@reduxjs/toolkit";

import * as requestFromServer from "./servicesApi";

const initialState = {
  services: [],
};

export const serviceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    updateList(state, action) {
      state.services = action.payload;
    },
    created(state, action) {
      state.services.push(action.payload);
    }
  }
});

export const loadServiceList = () => {
  return async (dispatch) => {
    return requestFromServer
      .getServiceList()
      .then((response) => {
        console.log(response.data);
        dispatch(serviceSlice.actions.updateList(response.data.data));
      })
      .catch((err) => {
        alert(err.message);
      });
  }
}

export const createService = (data) => {
  return async (dispatch) => {
    return requestFromServer
      .createService(data)
      .then((response) => {
        
        dispatch(serviceSlice.actions.created(response.data.data));
      })
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };
};