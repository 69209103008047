import axios from "axios";

// const URL = process.env.REACT_APP_API_URL + "/admin/service";
const URL = process.env.REACT_APP_API_URL + "/data";
console.log(process.env.REACT_APP_API_URL)

const headers = {
  "Content-Type": "multipart/form-data",
  Accept: "application/json",
};

export const getCountryCodeList = () => {
  return axios.get(`${URL}/countryCodes/list`);
};


export const getCityList = ()=>{
  return axios.get(`${URL}/city/list`)
}