import React from "react";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import SVG from "react-inlinesvg";

const data = [
  {
    name: "Find a Nearby Salons",
    img: "/media/BellazMain/workCards/icon1.png",
    text:"Find nearby Salons, Spas, Clinics based on Name, Area, Ratings or by Categories",
  },
  {
    name: "Choose a service",
    img: "/media/BellazMain/workCards/icon2.png",
    text:"Search from our wide variety of services and packages",
  },
  {
    name: "Book Appointment",
    img: "/media/BellazMain/workCards/icon3.png",
    text:"Book at appointment at fingertip",
  },
  {
    name: "Enjoy",
    img: "/media/BellazMain/workCards/icon3.png",
    text:"Enjoy your experience",
  },
];

function BellazWork() {
  return (
    <div className="bellazWork align-items-center">
      <div className="conatiner text-center bellazWork-inner d-flex align-items-center flex-column position-relative">
        <img
          className="img-fluid position-absolute d-none d-lg-flex flower-main"
          src={toAbsoluteUrl("/media/BellazMain/svg icons/flower 2.png")}
        />
        <img
          className="img-fluid position-absolute d-none d-lg-flex flower-logo"
          src={toAbsoluteUrl("/media/BellazMain/svg icons/flower-1.png")}
        />
        <div className="container align-items-center justify-content-center d-flex flex-column w-100 text-center bellazCard-top">
          <SVG
            className="location-logo img-fluid mb-4"
            src={toAbsoluteUrl("/media/BellazMain/svg icons/location.svg")}
          ></SVG>

          <h2 className="mb-2 text-uppercase">salons at your finger tips</h2>
          <h1 className="ml-5 display-4">How it works?</h1>
        </div>
        <div className="row bellazWork-card">
          {data.map((_data) => (
            <div className="col-lg-3 d-block mx-auto col-sm-3 col-12">
              <img
                src={toAbsoluteUrl(_data.img)}
                alt=""
                className="mb-8 w-50"
              />
              <p className="h4" style={{ letterSpacing: "2px", height: '40px' }}>
                {_data.name}
              </p>
              <span>{_data.text}</span>
            </div>
          ))}
        </div>
        {/* <div
          href=""
          class="btn btn-primary d-flex justify-content-center mt-5 mb-5 text-uppercase text-white pt-4 pb-4 pl-10 pr-10 rounded-pill"
        >
          BOOK AN APPOINTMENT NOW
        </div> */}
      </div>
    </div>
  );
}

export default BellazWork;
