import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, Select } from "../../../_metronic/_partials/controls";

import { CardBasic } from "../../../components/CardBasic";
import { loadBranchList } from "../../../store/business/branches/branchSlice";
import { createStaff } from "../../../store/business/staff/staffSlice";
import { loadShiftList } from "../../../store/business/shifts/shitftsSlice";
import dayjs from "dayjs";
import { getType, updateType } from "../../../store/business/expense/type/typeApi";
import { sendFailure, sendSuccess } from "../../../store/toast/toastSlice";

export const ExpenseTypeView = () => {
  const [staffData, setStaffData] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();

  const { typeUuid } = useParams();
  const req = {
    expenseUuid: typeUuid,
  };
  // console.log(req);

  useEffect(() => {
    getType(req)
      .then((response) => {
        // console.log(response);
        const data = response.data.data;
        // console.log(data);
        setStaffData(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  // Validation schema
  const ValidationSchema = Yup.object().shape({
    typeName: Yup.string().required("This field is required")
  });

  return (
    <CardBasic
      title={"Edit Expense Type"}
      btnType={"submit"}
      formId={"updateForm"}
      headerBtnText={"Save Expense Type"}
    >
      <Formik
        initialValues={{
          typeName: staffData.typeName ?? "",
          isActive: staffData.isActive ?? true
        }}
        enableReinitialize
        validationSchema={ValidationSchema}
        onSubmit={(values, actions) => {
          //console.log(values);
          const newValue = {...values, typeName: values.typeName.trim().toLowerCase(), ...req }
          //console.log(newValue);
          updateType(newValue)
            .then(() => {
              dispatch(
                sendSuccess(
                  "The Type information has been updated successfully"
                )
              );
              history.replace("/expense/type");
            })
            .catch((err) => {
              //console.log(err.response.data.message);
              dispatch(sendFailure(err.response.data.message));
              actions.setStatus(err.message);
              //console.log(err.message);
            });
        }}
      >
        {({ values, errors, setFieldValue }) => (
          <Form className="form form-label-right" id="updateForm">
           <div className="form-group row">
              <div className="col-lg-8">
                <Field
                  name="typeName"
                  component={Input}
                  placeholder="Type Name"
                  label="Type Name *"
                />
              </div>
              <div className="col-lg-4">
                <Select name="isActive" label="Status *">
                  <option value={true}>Active</option>
                  <option value={false}>Inactive</option>
                </Select>
              </div>
            </div>
            {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
          </Form>
        )}
      </Formik>
    </CardBasic>
  );
};
