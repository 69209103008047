import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Overview } from '../pages/Overview'
import { Sales } from '../pages/Sales'
import { Staff } from '../pages/Staff'
import { Customer } from '../pages/Customer'
import Select from '@material-ui/core/Select';
import { getCountryCodeList } from '../store/countryCode/countryCodeSlice';
import Logout from '../app/modules/Auth/pages/Logout';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#FCFCFC',
  },
  appBar: {
    boxShadow: 'none ',
    backgroundColor: '#fff',
    borderBottom: '1px solid #cdcdcd',
    color: '#339187',
  },
  tabs: {
    color: '#339187',
  },
  tab: {
    color: '#339187',
    fontSize: '15px'
    // indicatorColor: '#000',
    // '&:focus': {
    //   borderBottom: '2px solid #00534a',
    // }
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },

}));


export const Dashboard = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [pageValue, setPageValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [dateFilter, setDateFilter] = React.useState(3);
  const { countryCode } = useSelector((state) => state.countryCode);
  const { user, role } = useSelector((state) => state.authPersist);
  const businessName = useSelector(state => state.authPersist.user.business_name);
  console.log(user);
  console.log(businessName);
  if(new Date(user.subscription.expiry) < new Date() ) {
    console.log("exp")
    Logout();
  }
  const currencySymbol = countryCode.filter((el) => el.countryCode === user.countryCode);
 // console.log(currencySymbol);
  const last15Day = new Date();
  last15Day.setDate(last15Day.getDate() - 15);

  const [date, setDate] = React.useState({from: new Date(), to: last15Day});

  useEffect(() => {
    dispatch(getCountryCodeList());
  }, []);


  //console.log(date);

  const handleDateChange = (e) => {
    setDateFilter(e);
    const last7Day = new Date();
    last7Day.setDate(last7Day.getDate() - 7);
    //console.log(last7Day);
  
    const last15Day = new Date();
    last15Day.setDate(last15Day.getDate() - 15);
    //console.log(last15Day);
  
    const last30Day = new Date();
    last30Day.setDate(last30Day.getDate() - 30);
    //console.log(last30Day);
  
    const last1Year = new Date();
    last1Year.setDate(last1Year.getDate() - 365);
    //console.log(last1Year);

    const last2Year = new Date();
    last2Year.setDate(last2Year.getDate() - 730);

    if(e === 1) {
      setDate({from: new Date(), to: new Date()});
    } else if (e === 2) {
      setDate({from: new Date(), to: last7Day});
    }
    else if (e === 3) {
      setDate({from: new Date(), to: last15Day});
    }
    else if (e === 4) {
      setDate({from: new Date(), to: last30Day});
    } else if (e === 5) {
      setDate({from: new Date(), to: last1Year});
    } else {
      setDate({from: new Date(), to: last2Year});
    }
  }
  //console.log(date);
  function handleChange(event, newValue) {
    console.log(newValue);
    setPageValue(newValue);
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default" textColor="inherit" className={classes.appBar} >
        <Tabs value={pageValue} onChange={handleChange} className={classes.tabs} indicatorColor='primary'>
          <Tab label="Overview" className={classes.tab} />
          <Tab label="Sales"  className={classes.tab} />
          <Tab label="Customer" className={classes.tab} />
          <Tab label="Staff " className={classes.tab} />
        </Tabs>
      </AppBar>
      <div className="container">
        <div className="row" style={{ justifyContent: "right" }}>
          <div className="p-5">
            <Select id="fruits" value={dateFilter} onChange={(e) => handleDateChange(e.target.value)} style={{ width: "150px", textAlign: "center", border: "1px solid #339187" }} >
              <option value={1} style={{ textAlign: "center", padding: "5px", cursor: 'pointer' }} >Today</option>
              <option value={2} style={{ textAlign: "center", padding: "5px", cursor: 'pointer' }} >Last 7 Days</option>
              <option value={3} style={{ textAlign: "center", padding: "5px", cursor: 'pointer' }} >Last 15 Days</option>
              <option value={4} style={{ textAlign: "center", padding: "5px", cursor: 'pointer' }} >Last 30 Days</option>
              <option value={5} style={{ textAlign: "center", padding: "5px", cursor: 'pointer' }} >Last 1 Year</option>
              <option value={6} style={{ textAlign: "center", padding: "5px", cursor: 'pointer' }} >Last 2 Year</option>
            </Select>
          </div>
        </div>
        {pageValue === 0 && <Overview dateData={date} date={dateFilter} currencySymbol={currencySymbol} />}
        {pageValue === 1 && <Sales dateData={date} date={dateFilter} currencySymbol={currencySymbol} />}
        {pageValue === 2 && <Customer dateData={date} date={dateFilter} currencySymbol={currencySymbol} />}
        {pageValue === 3&& <Staff dateData={date} date={dateFilter} currencySymbol={currencySymbol} />}
      </div>
    </div>
  );
}