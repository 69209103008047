import { createSlice } from "@reduxjs/toolkit";

import * as requestFromServer from "./otpApi";

const initialState = {
  otpSent: false,
  otpResent: false,
  otpVerified: false,
};

const otpSlice = createSlice({
  name: "otp",
  initialState,
  reducers: {
    sendOtp(state, action) {
      state.otpSent = true;
    },
    resendOtp(state, action) {
      state.otpResent = true;
    },
  },
});

export const sendOtpAction = (countryCode, phone) => {
  return async (dispatch) => {
    return requestFromServer
      .sendOtp(countryCode, phone)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
};

export const resendOtpAction = (countryCode, phone, medium) => {
  return async (dispatch) => {
    return requestFromServer
      .resendOtp(countryCode, phone, medium)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
};

export const otpActions = otpSlice.actions;

export default otpSlice.reducer;
