import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, Select } from "../../_metronic/_partials/controls";
import { CardBasic } from "../../components/CardBasic";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getChair, updateChair } from "../../store/business/chairs/chairApi";
import { loadBranchList } from "../../store/business/branches/branchSlice";
import { sendFailure, sendSuccess } from "../../store/toast/toastSlice";

export const ChairView = () => {
  const [chairData, setChairData] = useState({});
  const history = useHistory();
  const dispatch = useDispatch();
  const { branches } = useSelector((state) => state.branch);
  const { chairUuid } = useParams();
  const req = {
    uuid: chairUuid,
  };
  const ValidationSchema = Yup.object().shape({
    branchUuid: Yup.string().required("This field is required"),
    chairName: Yup.string().required("This field is required"),
  });

  useEffect(() => {
    if (branches.length === 0) {
      dispatch(loadBranchList()).then(() => { });
    }
  }, [branches]);

  useEffect(() => {
    getChair(req)
      .then((response) => {
        const data = response.data.data;
        // console.log(data);
        setChairData(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return (
    <CardBasic
      title={"Edit Chair"}
      btnType={"submit"}
      formId={"updateForm"}
      headerBtnText={"Update Chair"}
    >
      <Formik
        initialValues={{
          branchUuid: chairData.branchUuid ?? "",
          chairName: chairData.chairName ?? "",
          isActive: chairData.isActive ?? true,
        }}
        enableReinitialize
        validationSchema={ValidationSchema}
        onSubmit={(values, actions) => {
          if (values.isActive === 'true' || values.isActive === true) {
            values.isActive = true;
          } else {
            values.isActive = false;
          }
          const newValue = { ...values, ...req }
          console.log(newValue);
          updateChair(newValue, chairUuid)
            .then(() => {
              dispatch(
                sendSuccess(
                  "The chair information has been updated successfully"
                )
              );
              history.replace("/chairs");
            })
            .catch((err) => {
              dispatch(sendFailure(err.message));
              actions.setStatus(err.message);
              console.log(err.message);
            });
          actions.setFormStatus("submitted");
        }}
      >
        {({ values, errors }) => (
          <Form className="form form-label-right" id="updateForm">
            <div className="form-group row">
              <div className="col-6">
                <Field
                  name="chairName"
                  component={Input}
                  placeholder="Chair Name"
                  label="Chair Name *"
                />
              </div>
              <div className="col-lg-6">
                <Select name="isActive" label="Is Active">
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </Select>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-12">
                <Select name="branchUuid" label="Branch *">
                  {branches.map((branch) => (
                    <option value={branch.uuid} key={branch.uuid}>
                      {branch.name} - {branch.city}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
            {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
          </Form>
        )}
      </Formik>
    </CardBasic>
  );
};
