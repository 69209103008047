import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { DatePickerField } from "../../_metronic/_partials/controls";
import { CardBasic } from "../../components/CardBasic";
import { TimePickerField } from "../../components/pickers/TimePickerField";
import dayjs from "dayjs";
import {
  getSchedule,
  updateSchedule,
} from "../../store/business/schedule/scheduleApi";
import { loadStaffList } from "../../store/business/staff/staffSlice";
import { MultiSelect } from "../../_metronic/_partials/controls/forms/MultiSelect";
import { loadScheduleList } from "../../store/business/schedule/scheduleSlice";
import { sendFailure, sendSuccess } from "../../store/toast/toastSlice";
import { loadBranchStaffList } from "../../store/branch/branchStaffSlice";
import { loadBranchScheduleList } from "../../store/branch/branch_schedule/branchScheduleSlice";
import {
  getBranchSchedule,
  updateBranchSchedule,
} from "../../store/branch/branch_schedule/branchScheduleApi";

export const ScheduleView = () => {
  const [scheduleData, setScheduleData] = useState({});
  const [dataLoading, setDataLoading] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const data = useSelector((state) => state.branchStaff.branchStaff);
  const schedule = useSelector((state) => state.schedule.schedule);

  // console.log(schedule);
  useEffect(() => {
    dispatch(loadBranchStaffList()).then(() => {
      setDataLoading(false);
    });
    dispatch(loadBranchScheduleList()).then(() => {
      setDataLoading(false);
    });
  }, []);

  const { uuid } = useParams();
  const req = {
    uuid,
  };

  const ValidationSchema = Yup.object().shape({
    staff: Yup.string().required("This field is required"),
    start_date: Yup.date().required("This field is required"),
    end_date: Yup.date().min(
      Yup.ref("start_date"),
      "End Date must be greater than Start Date"
    ),
    start_time: Yup.date().required("This field is required"),
    end_time: Yup.date().min(
      Yup.ref("start_time"),
      "End Time must be greater than Start Time"
    ),
    break_start_time: Yup.date()
      .min(
        Yup.ref("start_time"),
        "Break start time must be between start time and end time"
      )
      .max(
        Yup.ref("break_end_time"),
        "Break start time must be between start time and end time"
      )
      .required("This field is required"),

    break_end_time: Yup.date()
      .min(
        Yup.ref("break_start_time"),
        "Break end time must be between start time and end time"
      )
      .max(
        Yup.ref("end_time"),
        "Break end time must be between start time and end time"
      )
      .required("This field is required"),
  });

  useEffect(() => {
    getBranchSchedule(req)
      .then((response) => {
        const data = response.data.data;
        // console.log(data);
        setScheduleData(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return (
    <CardBasic
      title={"Edit Schedule"}
      btnType={"submit"}
      formId={"createScheduleForm"}
      headerBtnText={"Update Schedule"}
    >
      <Formik
        initialValues={{
          staff: scheduleData.staff
            ? scheduleData.staff.map((key, index) => ({
                value: key.uuid,
                label: key.name,
              }))
            : "",
          start_date: scheduleData.start_date
            ? dayjs(scheduleData.start_date, "DD-MM")
            : "",
          end_date: scheduleData.end_date
            ? dayjs(scheduleData.end_date, "DD-MM")
            : "",
          start_time: scheduleData.start_time
            ? dayjs(scheduleData.start_time, "HH:MM")
            : "",
          end_time: scheduleData.end_time
            ? dayjs(scheduleData.end_time, "HH:MM")
            : "",
          break_start_time: scheduleData.break_start_time
            ? dayjs(scheduleData.break_start_time, "HH:MM")
            : "",
          break_end_time: scheduleData.break_end_time
            ? dayjs(scheduleData.break_end_time, "HH:MM")
            : "",
        }}
        enableReinitialize
        validationSchema={ValidationSchema}
        onSubmit={(values, actions) => {
          console.log(values);
          updateBranchSchedule(values, uuid)
            .then(() => {
              dispatch(
                sendSuccess("The schedule has been updated successfully")
              );
              history.replace("/schedule");
            })
            .catch((err) => {
              dispatch(sendFailure(err.message));
              actions.setStatus(err.message);
              console.log(err.message);
            });
        }}
      >
        {({ values, errors }) => (
          <Form className="form form-label-right" id="createScheduleForm">
            <div className="form-group row">
              <div className="col-lg-12">
                <MultiSelect
                  label="Staff *"
                  name="staff"
                  options={data.map((staff, index) => ({
                    value: staff.uuid,
                    label: staff.name,
                  }))}
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-6">
                <div>
                  <label> Applicable From *</label>
                </div>
                <div>
                  <DatePickerField name="start_date" className="form-control" />
                </div>
              </div>
              <div className="col-lg-6">
                <div>
                  <label> Applicable Till *</label>
                </div>
                <div>
                  <DatePickerField
                    name="end_date"
                    className="form-control"
                    minDate={new Date()}
                  />
                </div>
              </div>
            </div>

            <div className="form-group row">
              <div className="col-lg-6">
                <div>
                  <label> Shift Start Time *</label>
                </div>
                <div>
                  <TimePickerField name="start_time" className="form-control" />
                </div>
              </div>
              <div className="col-lg-6">
                <div>
                  <label> Shift End Time *</label>
                </div>
                <div>
                  <TimePickerField name="end_time" className="form-control" />
                </div>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-6">
                <div>
                  <label> Break Start Time *</label>
                </div>
                <div>
                  <TimePickerField
                    name="break_start_time"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div>
                  <label> Break End Time *</label>
                </div>
                <div>
                  <TimePickerField
                    name="break_end_time"
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </CardBasic>
  );
};
