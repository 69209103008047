import { createSlice } from "@reduxjs/toolkit";

import * as requestFromServer from "./branchStaffApi";

const initialState = {
  branchStaff: [],
};

export const branchStaffSlice = createSlice({
  name: "branchStaff",
  initialState,
  reducers: {
    updateList(state, action) {
      state.branchStaff = action.payload;
    },
    created(state, action) {
      state.branchStaff.push(action.payload);
    },
  },
});

export const loadBranchStaffList = () => {
  return async (dispatch) => {
    return requestFromServer
      .getBranchStaffList()
      .then((response) => {
        console.log(response.data);
        dispatch(branchStaffSlice.actions.updateList(response.data.data));
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
        //alert(err.message);
      });
  };
};
