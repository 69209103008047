import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import filterFactory from "react-bootstrap-table2-filter";

import { sortCaret } from "../../_metronic/_helpers";

import {
  StatusColumnFormatter,
  ActionColumnSimple,
} from "../../_metronic/_helpers/tableColumnFormatter";

import paginationFactory from "react-bootstrap-table2-paginator";
import { loadStaffList } from "../../store/business/staff/staffSlice";
import { loadBranchStaffList } from "../../store/branch/branchStaffSlice";

export const CustomerStatusTitles = ["Suspended", "Active", "Pending", ""];

export const StaffTable = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.staff.staff);

  const staffList = useSelector((state) => state.branchStaff.branchStaff);
  const { role, user } = useSelector((state) => state.authPersist);
  const [dataLoading, setDataLoading] = useState(true);
  const LinkFormatter = (cell, row) => {
    if (role == "business") {
      return <Link to={`staff/${row.uuid}`}>{cell}</Link>;
    } else {
      return cell;
    }
  };
  const branchFormatter = (cell, row) => {
    return user.name;
  };

  useEffect(() => {
    if (role === "business") {
      dispatch(loadStaffList()).then(() => {
        setDataLoading(false);
      });
    } else {
      dispatch(loadBranchStaffList()).then(() => {
        setDataLoading(false);
      });
    }
  }, []);

  const columns = [
    {
      dataField: "",
      text: "#",
      formatter: (row, cell, index) => {
        return index + 1;
      },
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      formatter: LinkFormatter,
      sortCaret: sortCaret,
      // filter: textFilter(),
    },
    {
      dataField: "designation",
      text: "Designation",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "phone",
      text: "Phone",
    },
    {
      dataField: "branch[0].name",
      text: "Branch",
      sort: true,
      sortCaret: sortCaret,
      formatter: role == "business" ? "" : branchFormatter,
    },
    {
      dataField: "isActive",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      formatter: StatusColumnFormatter,
    },
    // {
    //   dataField: "",
    //   text: "Actions",
    //   formatter: ActionColumnSimple,
    // },
  ];

  const { SearchBar, ClearSearchButton } = Search;

  return (
    <ToolkitProvider
      search
      keyField="name"
      data={role == "business" ? data : staffList}
      columns={columns}
    >
      {(props) => (
        <>
          <div className="container">
            <div class="row">
              <div className="col-sm-4">
                {/* <h4 className="mt-4" >Search</h4> */}
              </div>
              <div className="col-sm-8">
                <SearchBar {...props.searchProps} />
                <ClearSearchButton
                  {...props.searchProps}
                  className="ml-4 btn btn-secondary-light"
                />
              </div>
            </div>
          </div>

          <BootstrapTable
            {...props.baseProps}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            wrapperClasses="table-responsive"
            bordered={false}
            bootstrap4
            pagination={paginationFactory()}
            filter={filterFactory()}
            noDataIndication={
              <div className={"d-flex justify-content-center py-10"}>
                <h3>{dataLoading ? "Loading Data..." : "No records found"}</h3>
              </div>
            }
          />
        </>
      )}
    </ToolkitProvider>
  );
};
