import React from "react";
import { useParams } from "react-router-dom";
import { CardBasic } from "../../components/CardBasic";
import { PaymentSummaryTable } from "./table";


export function PaymentPage() {
  const { date } = useParams();
  console.log(date);
  return (
    <CardBasic title={"Sales Summary"}>
      <PaymentSummaryTable date={date} />
    </CardBasic>
  );
}
