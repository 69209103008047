import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

function TermsCondition({ content }) {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <span className="navi-text ml-1" onClick={handleClickOpen}>
        {content}
      </span>
      <Dialog
        className=" w-100 d-flex justify-content-lg-end justify-content-md-center justify-content-sm-center align-content-center p-10 m-0"
        maxWidth={"xs"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="w-100 p-4">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <DialogTitle id="alert-dialog-title">Terms & Condition</DialogTitle>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </div>

          <DialogContent>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce ac
            viverra eros. Quisque vitae metus in diam ultricies lacinia id in
            justo. Etiam ornare blandit lorem nec placerat. Vestibulum ante
            ipsum primis in faucibus orci luctus et ultrices posuere cubilia
            curae; Aliquam faucibus laoreet rhoncus. Maecenas aliquam leo at
            elit pharetra, ac viverra est pharetra. Suspendisse potenti. Proin
            et dignissim metus. Orci varius natoque penatibus et magnis dis
            parturient montes, nascetur ridiculus mus. Sed tempus faucibus erat
            eu malesuada. Fusce vel porttitor massa. Vestibulum nec tellus in
            massa vulputate euismod quis at lorem. Phasellus vulputate dui et
            finibus consequat. Donec et aliquam lectus, sit amet ultrices
            ligula. Phasellus vitae odio finibus, vulputate justo et, pulvinar
            nunc. Sed vel pharetra massa, nec sodales tortor. In ut eleifend
            dui, nec malesuada lacus. Ut augue ante, cursus sit amet odio ut,
            congue mollis libero. In fermentum, nisl vitae blandit cursus, leo
            sem feugiat metus, a congue lorem nisi ut mi. Ut posuere ipsum id
            nibh viverra dapibus.
          </DialogContent>
          <div className="w-100 d-flex justify-content-end align-items-end">
            <div
              type="button"
              onClick={handleClose}
              className="btn btn-primary"
            >
              Okay
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default TermsCondition;
