import React from "react";
import { Spinner } from "react-bootstrap";

export const SpinnerBasic = () => {
  return (
    <div className="text-center m-20">
      <Spinner animation="border" style={{ width: "40px", height: "40px" }} />
    </div>
  );
};
