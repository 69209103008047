import { createSlice } from "@reduxjs/toolkit";
import { sendFailure, sendSuccess } from "../../toast/toastSlice";

import * as requestFromServer from "./branchServiceApi";

const initialState = {
  branchServices: [],
};

export const branchServiceSlice = createSlice({
  name: "branchServices",
  initialState,
  reducers: {
    updateList(state, action) {
      state.branchServices = action.payload;
    },
    created(state, action) {
      state.branchServices.push(action.payload);
    },
  },
});

export const loadBranchServiceList = () => {
  return async (dispatch) => {
    return requestFromServer
      .getBranchServiceList()
      .then((response) => {
        console.log(response.data);
        dispatch(branchServiceSlice.actions.updateList(response.data.data));
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
        //alert(err.message);
      });
  };
};
