import { createSlice } from "@reduxjs/toolkit";

import * as requestFromServer from "./subscriptionPlanApi";

const initialState = {
  subscriptionPlan: [],
  transactions: [],
};

export const subscriptionPlanSlice = createSlice({
  name: "subscriptionPlan",
  initialState,
  reducers: {
    updateList(state, action) {
      state.subscriptionPlan = action.payload;
    },
    created(state, action) {
      state.subscriptionPlan.push(action.payload);
    },
    updateTransactions(state, action) {
      state.transactions = action.payload;
    },
  },
});
export const createSubscriptionPlan = (data) => {
  const uuid = { uuid: data };
  return async (dispatch) => {
    console.log(data);
    return requestFromServer
      .createSubscriptionOrder(uuid)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        throw new Error(error);
      });
  };
};
export const verifySubscriptionPlanData = (data) => {
  return async (dispatch) => {
    return requestFromServer
      .verifySubscriptionPlan(data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };
};

export const loadSubscriptionTransactions = () => {
  return async (dispatch) => {
    return requestFromServer
      .getSubscriptionTransactions()
      .then((res) => {
        dispatch(subscriptionPlanSlice.actions.updateTransactions(res.data.data));
        console.log(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
      });
  };
};

export const applyCoupone = (data) => {
  return async (dispatch) => {
    return requestFromServer
      .applyCouponeCode(data)
      .then((response) => {
        console.log(response.data);
        // dispatch(bookingSlice.actions.updateBooking(response.data.data.newBooking));
        return response.data.data;
      })
      .catch((err) => {
        //console.log(err.response);
        throw new Error(err.response.data.message);
      });
  };
};

export const orderCreate = (data) => {
  return async (dispatch) => {
    return requestFromServer
      .createSubscriptionOrder(data)
      .then((response) => {
        console.log(response.data);
        // dispatch(bookingSlice.actions.updateBooking(response.data.data.newBooking));
        return response.data.data;
      })
      .catch((err) => {
        //console.log(err.response);
        throw new Error(err.response.data.message);
      });
  };
};