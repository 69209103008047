import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import SVG from "react-inlinesvg";
import { useDispatch } from "react-redux";
import { sendFailure, sendSuccess } from "../../store/toast/toastSlice";
import { bookingStatusUpdate, bookingCancle } from "../../store/business/bookings/bookingApi";
import { updateBookingStatus } from "../../store/business/bookings/bookingSlice";

export default function BookingStatusDialog({
  content,
  svg,
  uuid,
  status,
  present = null,
}) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  //console.log(status);
  const Confirm = () => {
    if (status === 0) {
      bookingCancle(uuid)
        .then((response) => {
          //console.log(response);
          dispatch(updateBookingStatus(response.data.data.result)).then(() => {
            dispatch(sendSuccess(content.successMessage));
          });
          setOpen(false);
        })
        .catch((err) => {
          dispatch(sendFailure(content.errorMessage));
          console.log(err.message);
        });
    } else {
      bookingStatusUpdate(uuid, status, present)
        .then((response) => {
          console.log(response);
          // TODO : change Booking Data into Result
          dispatch(updateBookingStatus(response.data.data.result)).then(() => {
            dispatch(sendSuccess(content.successMessage));
          });
          setOpen(false);
        })
        .catch((err) => {
          dispatch(sendFailure(content.errorMessage));
          console.log(err.message);
        });
    }
  };

  return (
    <div>
      {content ? (
        <span className="navi-text" onClick={handleClickOpen}>
          {content.navigation}
        </span>
      ) : (
        <SVG src={svg} onClick={handleClickOpen} />
      )}

      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{content.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content.text ?? "Are you sure you want to confirm this action?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button onClick={Confirm} className="btn btn-light-success">
            Yes, Continue.
          </button>
          <button onClick={handleClose} className="btn btn-danger">
            No, Go back.
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
