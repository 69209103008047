import React from "react";
import { BranchesTable } from "./table";
import { CardBasic } from "../../components/CardBasic";

export function BranchesPage() {
  return (
    <CardBasic
      title={"Branch List"}
      btnType={"button"}
      headerBtnText={"Add Branch"}
      headerBtnLink={"/branch/create"}
    >
      <BranchesTable />
    </CardBasic>
  );
}