import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardHeaderToolbar,
} from "../../src/_metronic/_partials/controls/Card";

export const CardSecondary = (props) => {
  console.log(props);

  const headerTitle = props.title;
  const headerBtnText = props.headerBtnText;
  const headerBtnLink = props.headerBtnLink;
  const headerBtn2Text = props.headerBtn2Text;
  const headerBtn2Link = props.headerBtn2Link;
  const btnType = props.btnType;
  const btn2Type = props.btn2Type;
  const formId = props.formId;

  return (
    <Card className="mt-4 mt-xl-0">
      {headerTitle && (
        <CardHeader title={headerTitle}>
          {headerBtnText && (
            <CardHeaderToolbar>
              {btn2Type === "button" && (
                <Link
                  to={headerBtn2Link ?? ""}
                  className="btn btn-light-success"
                >
                  {headerBtn2Text}
                </Link>
              )}
              {btn2Type === "submit" && (
                <button
                  type={btn2Type}
                  className="btn btn-light-success"
                  form={formId}
                >
                  {headerBtn2Text}
                </button>
              )}
              {btn2Type === "other" && (
                <button
                  type={"button"}
                  className="btn btn-light-success"
                >
                  {headerBtn2Text}
                </button>
              )}

              <div className="mx-3">
                {btnType === "button" && (
                  <Link
                    to={headerBtnLink ?? ""}
                    className="btn btn-light-success"
                  >
                    {headerBtnText}
                  </Link>
                )}
                {btnType === "submit" && (
                  <button
                    type={btnType}
                    className="btn btn-light-success"
                    form={formId}
                  >
                    {headerBtnText}
                  </button>
                )}
              </div>
            </CardHeaderToolbar>
          )}
        </CardHeader>
      )}
      <CardBody>{props.children}</CardBody>
      {btnType === "submit" && (
        <CardFooter>
          <button
            type={btnType}
            className="btn btn-light-success float-right"
            form={formId}
          >
            {headerBtnText}
          </button>
        </CardFooter>
      )}
    </Card>
  );
};
