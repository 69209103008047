import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from '@material-ui/core/TextField';
import SVG from "react-inlinesvg";
import { useDispatch } from "react-redux";
import { sendFailure, sendSuccess } from "../../store/toast/toastSlice";

import { giveCustomerRating } from '../../store/business/addCustomer/customerApi';
import { updateCustomerRating } from "../../store/business/bookings/bookingSlice";

const Star = ({ starId, marked }) => {
    return (
        <span
            star-id={starId}
            role="button"
            style={{ color: "#ff9933", cursor: "pointer",fontSize:'36px',margin:'0px 10px' }}
        >
            {marked ? "\u2605" : "\u2606"}
        </span>
    );
};

export default function RatingDialog({
    content,
    svg,
    uuid,
    totalStars
}) {
    const [open, setOpen] = useState(false);
    const [starsSelected, selectStar] = useState(0);
    const dispatch = useDispatch();
    const [values, setValues] = React.useState("");
    console.log(uuid);
    const handleChange = event => {
        setValues(event.target.value);
    };
    console.log(values);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // Manages on Hover selection of a star
    const [selection, setSelection] = React.useState(0);

    // Manages rating selection
    const [rating, setRating] = React.useState(0);

    const hoverOver = event => {
        let starId = 0;
        if (event && event.target && event.target.getAttribute("star-id")) {
            starId = event.target.getAttribute("star-id");
        }
        setSelection(starId);
    };
    console.log(rating);

    const Confirm = () => {
        giveCustomerRating(uuid, rating, values)
            .then((response) => {
                console.log(response);
                const bookings = [];
                const CustomerRating = [ {bookingUuid: uuid,
                rating: rating} ];
                bookings.push(CustomerRating)
                console.log(bookings);
                dispatch(updateCustomerRating( uuid, CustomerRating )).then(() => {
                    dispatch(sendSuccess(content.successMessage));
                  });
                setOpen(false);
                selectStar(0);
            })
            .catch((err) => {
                dispatch(sendFailure(content.errorMessage));
                console.log(err);
            });
    };

    return (
        <div>
            {content ? (
                <span className="navi-text" onClick={handleClickOpen}>
                    {content.navigation}
                </span>
            ) : (
                <SVG src={svg} onClick={handleClickOpen} />
            )}

            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {/* <DialogTitle id="alert-dialog-title">{content.title}</DialogTitle> */}
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{textAlign:'center', fontSize: '20px', fontWeight: "bold"}}>
                        {"Rate the customer"}
                    </DialogContentText>
                    <div
                    style={{display:' flex', justifyContent: 'center',}}
                        onMouseOver={hoverOver}
                        onMouseOut={() => hoverOver(null)}
                        onClick={event => setRating(event.target.getAttribute("star-id"))}
                    >
                        {Array.from({ length: 5 }, (v, i) => (
                            <Star starId={i + 1} marked={selection ? selection > i : rating > i} />
                        ))}
                    </div>
                    {/* <form noValidate autoComplete="off">
                        <TextField
                            name="remark"
                            id="standard-name"
                            label="Remark"
                            //className={classes.textField}
                            value={values}
                            onChange={handleChange}
                            margin="normal"
                        />
                    </form> */}
                </DialogContent>
                <DialogActions>
                    <button onClick={Confirm} className="btn btn-light-success">
                        Submit rating.
                    </button>
                    <button onClick={handleClose} className="btn btn-danger">
                        No, Go back.
                    </button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
