import React from "react";

import { CardBasic } from "../../components/CardBasic";
import { TransactionTable } from "./table";

export function TransactionPage() {
  return (
    <CardBasic title={"Transaction Summary"}>
      <TransactionTable />
    </CardBasic>
  );
}
