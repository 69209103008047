import { createSlice } from "@reduxjs/toolkit";

import * as requestFromServer from "./poAPi";

const initialState = {
  po: [],
};

export const expensePoSlice = createSlice({
  name: 'expensePo',
  initialState,
  reducers: {
    updateList(state, action) {
      state.po = action.payload;
    },
    created(state, action) {
      state.po.push(action.payload);
    },
    updateStatus(state, action) {
      const poIndex = state.po.findIndex(
        (po) => po.uuid === action.payload
      );
      console.log(poIndex);
      if (poIndex > -1) {
        state.po.splice(poIndex, 1);
      }
    },
    created(state, action) {
      state.po.push(action.payload);
    },
  }
});

export const loadPoList = () => {
  return async (dispatch) => {
    return requestFromServer
      .getPoList()
      .then((response) => {
        console.log(response.data);
        dispatch(expensePoSlice.actions.updateList(response.data.data));
      })
      .catch((err) => {
        alert(err.message);
      });
  }
}

export const createPo = (data) => {
  console.log(data);
  return async (dispatch) => {
    return requestFromServer
      .createPo(data)
      .then((response) => {
        console.log(response);
        dispatch(expensePoSlice.actions.created(response.data.data));
      })
      .catch((error) => {
        throw new Error(error.response.data.error);
      });
  };
};

export const updatePoList = (data) => {
  console.log(data);
  return async (dispatch) => {
        dispatch(expensePoSlice.actions.updateStatus(data));
  };
};