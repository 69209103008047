import React from "react";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import BookOnlineIcon from '@material-ui/icons/Book';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import DescriptionIcon from '@material-ui/icons/Description';
import UpdateIcon from '@material-ui/icons/Update';
import BusinessIcon from '@material-ui/icons/Business';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

function CancellationAndRefund() {
  return (
    <div className="p-0 m-0" style={{ background: ' #f1f3f5' }}>
      <div
        className="container-fluid bg-dark d-flex flex-column justify-content-center align-items-center"
        style={{ height: "220px", borderRadius: "10px" }}
      >
        <h2 className="display-4 text-white mb-5"><DescriptionIcon className="mr-5 display-4 mb-2" />Cancellation and Refund</h2>
      </div>
      <div
        className="container bg-white p-12"
        style={{ width: "90%", marginTop: "-40px", borderRadius: "10px" }}
      >
        <div className="mb-8">
          {/* <h2 className="mb-4 display-5"><BusinessIcon className="display-4 pr-2 mb-2" />Welcome to BELLAZ’s PRIVACY POLICY</h2> */}
          <p className="h5" style={{ color: "black" }}>We at Bellaz’s work towards attaining 'Customer Delight', - a step further to achieving 'Customer Satisfaction', and thus, we have put forward a very simple and customer-focused cancellation and refund policy.</p>
        </div>
        <div className="mb-8">
          <h2 className="mb-4 display-5">Case 1: When booking is not confirmed: </h2>
          <p className="h5" style={{ color: "black" }}>In this case, the deducted amount (if any) will be refunded back to the customers through the same mode of payment within 21 (Twenty-One ) Business Days (time taken for refund is subject to the policies of the third party payment gateway).</p>
        </div>
        <div className="mb-8">
          <h2 className="mb-4 display-5">Case 2: When customers order  is not confirmed:</h2>
          <p className="h5" style={{ color: "black" }}>In this case, the deducted amount (if any) will be refunded back to the customers through the same mode of payment within 21 (Twenty-One ) Business Days (time taken for refund is subject to the policies of the third party payment gateway).</p>
        </div>
        <div className="mb-8">
          <h2 className="mb-4 display-5">Case 3: When customers cancel their appointment:</h2>
          <p className="h5" style={{ color: "black" }}>If a customer cancels his/her appointment in advance (24 hours before the appointment), then we will be providing full refund. Refer to Case 1 above for refund timeline.</p>
        </div>
        <div className="mb-8">
          <h2 className="mb-4 display-5">Case 4: When customers cancel their order:</h2>
          <p className="h5" style={{ color: "black" }}>If a customer cancels his/her order in advance (24 hours before the appointment), then we will be providing full refund. Refer to Case 2 above for refund timeline.</p>
        </div>
        <div className="mb-8">
          <h2 className="mb-4 display-5">CASE 5: When receives wrong order or want to exchange or has received a defective product :</h2>
          <p className="h5" style={{ color: "black" }}>In this case, the deducted amount (if any) will be refunded back to the customers through the same mode of payment within 21 (Twenty-One ) Business Days (time taken for refund is subject to the policies of the third-party payment gateway).</p>
          <p className="h5" style={{ color: "black" }}>In case, the customer wants to make an exchange then he/she may write to <a href="mailto:support@my-bellaz.com" target="_blank">support@my-bellaz.com</a>  with subject – “REQUEST FOR EXHANGE ”along with their order information and attach the picture of the product.</p>
          <p className="h5" style={{ color: "black" }}>In case, the customer wants to make an exchange then he/she may write to <a href="mailto:support@my-bellaz.com" target="_blank">support@my-bellaz.com</a>  with subject – “DEFECTIVE ORDER  ”along with their order information and attach the picture of the product. You either receive a refund within 21 (Twenty-One ) Business Days (time taken for refund is subject to the policies of the third-party payment gateway) or exchanged product as the case maybe.</p>
        </div>
        <div className="mb-8">
          <h2 className="mb-4 display-5">Case 6: In the event of no-show:</h2>
          <p className="h5" style={{ color: "black" }}>When a customer doesn't cancel the booking, neither visits the service provider at the scheduled time, then the refund will be dependent on the discretion & policies of our partner in this case.</p>
        </div>
      </div>
    </div>
  );
}

export default CancellationAndRefund;
