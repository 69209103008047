import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Select, DatePickerField } from "../../../_metronic/_partials/controls";
import { useHistory } from "react-router";
import { CardBasic } from "../../../components/CardBasic";
import { createHoliday } from "../../../store/business/staff/holiday/holidaySlice";
import { useDispatch, useSelector } from "react-redux";
import { loadStaffList } from "../../../store/business/staff/staffSlice";
import { sendFailure, sendSuccess } from "../../../store/toast/toastSlice";

export const StaffHolidayCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [dataLoading, setDataLoading] = useState(true);

  const staff = useSelector((state) => {
    return state.staff.staff.filter((singleStaff) => {
      return singleStaff.isActive === true;
    });
  });

  useEffect(() => {
    if (staff.length === 0) {
      dispatch(loadStaffList()).then(() => {
        setDataLoading(false);
      });
    }
  }, [staff]);

  const ValidationSchema = Yup.object().shape({
    staff_uuid: Yup.string().required("This field is required"),
    start_date: Yup.date().required("This field is required"),
    end_date: Yup.date()
      .min(Yup.ref("start_date"), "End Date must be greater than Start Date")
      .required("This field is required"),
  });

  return (
    <CardBasic
      title={"Add Staff Leave"}
      btnType={"submit"}
      formId={"createForm"}
      headerBtnText={"Save Staff Leave"}
    >
      <Formik
        initialValues={{
          staff_uuid: staff.length > 0 ? staff[0].uuid : "",
          start_date: new Date(),
          end_date: new Date(),
        }}
        enableReinitialize
        validationSchema={ValidationSchema}
        onSubmit={(values, actions) => {
          console.log(values);
          // saveCustomer(values);
          dispatch(createHoliday(values))
            .then(() => {
              dispatch(
                sendSuccess(
                  "The leave application has been created successfully"
                )
              );
              history.replace("/staff/leaves");
            })
            .catch((err) => {
              dispatch(sendFailure(err.message));
              actions.setStatus("Error!");
              console.log(err.message);
            });
        }}
      >
        {({ values, errors, setFieldValue }) => (
          <Form className="form form-label-right" id="createForm">
            <div className="form-group row">
              {/* Staff Name */}
              <div className="col-lg-12">
                <Select name="staff_uuid" label="Staff *">
                  {staff.map((staff, index) => (
                    <option value={staff.uuid} key={index}>
                      {staff.name}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-6">
                <div>
                  <label>Holiday Start Date *</label>
                </div>
                <div>
                  <DatePickerField
                    minDate={new Date()}
                    name="start_date"
                    className="form-control"
                  ></DatePickerField>
                </div>
              </div>
              <div className="col-lg-6">
                <div>
                  <label>Holiday End Date *</label>
                </div>
                <div>
                  <DatePickerField
                    name="end_date"
                    minDate={new Date() + 1}
                    className="form-control"
                  ></DatePickerField>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </CardBasic>
  );
};
