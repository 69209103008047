import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import { useDispatch, useSelector } from "react-redux";
import DialogContent from "@material-ui/core/DialogContent";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Select, Input } from "../../_metronic/_partials/controls";
import { sendFailure, sendSuccess } from "../../store/toast/toastSlice";

import ClearIcon from "@material-ui/icons/Clear";
import { createCollectPayment } from "../../store/business/bookings/bookingApi";
import { collectPayment } from "../../store/business/bookings/bookingSlice";

export default function CollectPaymentDialog({ content, svg, bookingDetails }) {
  const [open, setOpen] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState({});
  const [paymentMode, setPaymentMode] = useState("");
  const dispatch = useDispatch();
  const { role, user } = useSelector((state) => state.authPersist);
  const { countryCode } = useSelector((state) => state.countryCode);
  const currencySymbol = countryCode.filter(
    (el) => el.countryCode === user.countryCode
  );
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  //console.log(paymentMode);
  const collectPaymentSchema = Yup.object().shape({
    paymentMode: Yup.string(),
    amount: Yup.number()
      .max(
        bookingDetails.total - bookingDetails.paidAmount,
        "Number should be less than Pending amount"
      )
      .min(1, "Number should be greater than one")
      .required("This field is required"),
    bookingUuid: Yup.string().required("This field is required"),
    paymentNote: Yup.string(),
  });

  const submitHandler = (values) => {
    if (paymentMode !== "") {
      const newValues = { ...values, paymentMode: paymentMode };
      console.log(newValues);
      dispatch(collectPayment(newValues))
        .then((response) => {
          console.log(response);
          dispatch(sendSuccess("Payment has been collected successfully"));
          handleClose();
        })
        .catch((err) => {
          dispatch(sendFailure());
          console.log(err.message);
        });
    } else {
      dispatch(sendFailure("Please Select any Payment Mode"));
    }
  };

  return (
    <div>
      {content ? (
        <span className="navi-text" onClick={handleClickOpen}>
          {content}
        </span>
      ) : (
        <SVG src={svg} onClick={handleClickOpen} />
      )}

      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div
          id="alert-dialog-title"
          className="d-flex justify-content-end align-items-center bg-primary text-white mb-5 w-100 pt-4 pb-4"
        >
          <div
            className="d-flex justify-content-between flex-row align-items-center pr-4 pl-5"
            style={{ width: "70%" }}
          >
            <h2>{content}</h2>
            <ClearIcon
              className="display-4"
              onClick={handleClose}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
        <DialogContent>
          <div className="d-flex flex-column align-items-center">
            <div className="d-flex flex-column w-75 justify-content-center align-items-start mb-4">
              <div className="d-flex w-100 flex-row align-items-start justify-content-start m-0">
                <h4 className="w-75">Booking Amount </h4>
                <h4 className="w-25 align-self-start d-flex flex-row justify-content-center">
                  {/* <SVG
                    className="mr-1"
                    style={{ width: "9%" }}
                    src={toAbsoluteUrl(
                      "/media/svg/icons/custom/Indian-Rupee-symbol.svg"
                    )}
                  ></SVG> */}
                  <span style={{ marginRight: "5px" }}>
                    {currencySymbol[0] ? currencySymbol[0].currencySymbol : "₹"}
                  </span>
                  {bookingDetails.subTotal}
                </h4>
              </div>
              <div className="d-flex w-100 flex-row align-items-start justify-content-start m-0">
                <h4 className="w-75">Taxes</h4>
                <h4 className="w-25 align-self-start d-flex flex-row justify-content-center">
                  {/* <SVG
                    className="mr-1"
                    style={{ width: "9%" }}
                    src={toAbsoluteUrl(
                      "/media/svg/icons/custom/Indian-Rupee-symbol.svg"
                    )}
                  ></SVG> */}
                  <span style={{ marginRight: "5px" }}>
                    {currencySymbol[0] ? currencySymbol[0].currencySymbol : "₹"}
                  </span>
                  {bookingDetails.tax}
                </h4>
              </div>

              <div className="d-flex w-100 flex-row align-items-start justify-content-start m-0">
                <h4 className="w-75">Discount</h4>
                <h4 className="w-25 align-self-start d-flex flex-row justify-content-center">
                  {/* <SVG
                    className="mr-1"
                    style={{ width: "9%" }}
                    src={toAbsoluteUrl(
                      "/media/svg/icons/custom/Indian-Rupee-symbol.svg"
                    )}
                  ></SVG> */}
                  <span style={{ marginRight: "5px" }}>
                    {currencySymbol[0] ? currencySymbol[0].currencySymbol : "₹"}
                  </span>
                  {bookingDetails.discount}
                </h4>
              </div>
              <div
                className="d-flex w-100 flex-row pt-2 pb-2 m-0"
                style={{
                  borderBottom: "1px solid black",
                  borderTop: "1px solid black",
                }}
              >
                <h4
                  className="w-75 m-0 font-weight-bolder"
                  style={{ color: "#d4b55b" }}
                >
                  Bill Total{" "}
                </h4>
                <h4
                  className="w-25 align-self-start d-flex flex-row justify-content-center m-0 font-weight-bolder"
                  style={{ color: "#d4b55b" }}
                >
                  {/* <SVG
                    className="mr-1"
                    style={{ width: "9%" }}
                    src={toAbsoluteUrl("/media/svg/icons/custom/RupeGold.svg")}
                  ></SVG> */}
                  <span style={{ marginRight: "5px" }}>
                    {currencySymbol[0] ? currencySymbol[0].currencySymbol : "₹"}
                  </span>
                  {bookingDetails.total}
                </h4>
              </div>

              <div className="d-flex w-100 flex-row align-items-start justify-content-start m-0 mt-4">
                <h4 className="w-75">Paid Amount</h4>
                <h4 className="w-25 align-self-start d-flex flex-row justify-content-center">
                  {/* <SVG
                    className="mr-1"
                    style={{ width: "9%" }}
                    src={toAbsoluteUrl(
                      "/media/svg/icons/custom/Indian-Rupee-symbol.svg"
                    )}
                  ></SVG> */}
                  <span style={{ marginRight: "5px" }}>
                    {currencySymbol[0] ? currencySymbol[0].currencySymbol : "₹"}
                  </span>
                  {bookingDetails.paidAmount}
                </h4>
              </div>
              <div
                className="d-flex w-100 flex-row align-items-start justify-content-start m-0"
                style={{
                  borderBottom: "1px solid black",
                }}
              >
                <h4 className="w-75">Pending Amount</h4>
                <h4 className="w-25 align-self-start d-flex flex-row justify-content-center">
                  {/* <SVG
                    className="mr-1"
                    style={{ width: "9%" }}
                    src={toAbsoluteUrl(
                      "/media/svg/icons/custom/Indian-Rupee-symbol.svg"
                    )}
                  ></SVG> */}
                  <span style={{ marginRight: "5px" }}>
                    {currencySymbol[0] ? currencySymbol[0].currencySymbol : "₹"}
                  </span>
                  {bookingDetails.total - bookingDetails.paidAmount}
                </h4>
              </div>
            </div>
            <Formik
              initialValues={{
                //paymentMode: "Cash",
                amount: "",
                bookingUuid: bookingDetails.uuid,
              }}
              validationSchema={collectPaymentSchema}
              onSubmit={(values, errors) => {
                submitHandler(values);
                //console.log(errors);
                // dispatch(collectPayment(values))
                //   .then((response) => {
                //     console.log(response);
                //     dispatch(
                //       sendSuccess("Payment has been collected successfully")
                //     );
                //     handleClose();
                //   })
                //   .catch((err) => {
                //     dispatch(sendFailure());
                //     console.log(err.message);
                //   });
              }}
            >
              {({ values, errors, touched }) => {
                console.log(values);
                return (
                  <Form className="form form-label-right w-75">
                    <div className="d-flex justify-content-center flex-column align-items-center">
                      <div className="d-flex w-100 flex-row align-items-center justify-content-between">
                        <h4 style={{ margin: "0" }}>Amount Collected</h4>
                        <div className="w-25">
                          <Field
                            name="amount"
                            type="number"
                            component={Input}
                          />
                        </div>
                      </div>
                      {paymentMode === "" || paymentMode === "Cash" ? null : (
                        <div className="d-flex w-100 flex-row align-items-center justify-content-between mt-5">
                          <h4 style={{ margin: "0" }}>Payment Note</h4>
                          <div className="w-50">
                            <Field
                              name="paymentNote"
                              type="text"
                              component={Input}
                            />
                          </div>
                        </div>
                      )}
                      <div className="d-flex flex-row row w-100 justify-content-center align-items-center p-0 mt-5 mb-5">
                        <h4>Mode of Payment</h4>
                        {/* <div className="w-25">
                        <Select name="paymentMode">
                          <option value="Card">Card</option>
                          <option value="Upi">UPI</option>
                          <option value="Wallet">Wallet</option>
                          <option value="Cash">Cash</option>
                          <option value="Bank Transfer">Bank Transfer</option>
                        </Select>
                      </div> */}
                      </div>
                      <div className="d-flex flex-row row w-100 justify-content-between align-items-center p-0 m-0">
                        <input
                          class="btn btn-light-success font-weight-bold"
                          type="button"
                          value="Card"
                          onClick={() => {
                            setPaymentMode("Card");
                          }}
                        />
                        <input
                          class="btn btn-light-success font-weight-bold"
                          type="button"
                          value="UPI"
                          onClick={() => {
                            setPaymentMode("UPI");
                          }}
                        />
                        <input
                          class="btn btn-light-success font-weight-bold"
                          type="button"
                          value="Wallet"
                          onClick={() => {
                            setPaymentMode("Wallet");
                          }}
                        />
                        <input
                          class="btn btn-light-success font-weight-bold"
                          type="button"
                          value="Cash"
                          onClick={() => {
                            setPaymentMode("Cash");
                          }}
                        />
                        <input
                          class="btn btn-light-success font-weight-bold"
                          type="button"
                          value="Bank Transfer"
                          onClick={() => {
                            setPaymentMode("Bank Transfer");
                          }}
                        />
                      </div>
                      <button
                        className="btn bg-primary mb-5 mt-4 font-weight-bold h6 text-white"
                        type="submit"
                      >
                        Save & Close
                      </button>
                    </div>
                    {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
                  </Form>
                );
              }}
            </Formik>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
