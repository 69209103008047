import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import { Input, Select } from "../../_metronic/_partials/controls";

import { CardBasic } from "../../components/CardBasic";
import { loadServiceList } from "../../store/business/services/servicesSlice";
import { createPackage } from "../../store/business/packages/packageSlice";
import {
  getPackage,
  updatePackage,
} from "../../store/business/packages/packageApi";
import { getCountryCodeList } from "../../store/countryCode/countryCodeSlice";
import { sendFailure, sendSuccess } from "../../store/toast/toastSlice";

export const PackageView = () => {
  const [packageData, setPackageData] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();

  const { uuid } = useParams();
  const req = {
    uuid: uuid,
  };

  console.log(uuid);

  const [formStatus, setFormStatus] = useState("");
  const [dataLoading, setDataLoading] = useState(true);
  const [init, setInitVal] = useState(0);

  const { serviceCategories } = useSelector((state) => state.data);
  const { services } = useSelector((state) => state.service);
  const { role, user } = useSelector((state) => state.authPersist);
  const { countryCode } = useSelector((state) => state.countryCode);
  const currencySymbol = countryCode.filter((el) => el.countryCode === user.countryCode);
  const [categoryServices, setCategoryServices] = useState([]);

  useEffect(() => {
    dispatch(getCountryCodeList());
  }, []);

  const loadCategoryServices = (categoryUuid, index) => {
    categoryServices[index] = services.filter((service) => {
      return service.category_uuid === categoryUuid;
    });
    setCategoryServices(categoryServices);
    console.log(categoryServices);
  };

  useEffect(() => {
    if (services.length === 0) {
      dispatch(loadServiceList()).then(() => {
        setDataLoading(false);
      });
    }
  }, [services]);

  useEffect(() => {
    getPackage(req)
      .then((response) => {
        const data = response.data.data;
        console.log(data);
        setPackageData(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  useEffect(() => {
    if (
      init === 0 &&
      serviceCategories.length > 0 &&
      services.length > 0 &&
      packageData.services &&
      packageData.services.length > 0
    ) {
      packageData.services.forEach((service, index) => {
        loadCategoryServices(service.categoryUuid, index);
      });

      setInitVal(1);
    }
  }, [serviceCategories, services, packageData]);

  const emptyServiceRow = {
    categoryUuid: "",
    serviceUuid: "",
  };

  const PackageCreateSchema = Yup.object().shape({
    name: Yup.string().required("This field is required"),
    maxServices: Yup.number()
      .positive()
      .required("This field is required"),
    price: Yup.number()
      .positive()
      .required("This field is required"),
    time: Yup.number().required("This field is required"),
    isActive: Yup.string().required("This field is required"),
    description: Yup.string()
      .max(200, "Maximum length should be 200 characters.")
      .required("This field is required"),
    services: Yup.array(
      Yup.object({
        categoryUuid: Yup.string().required(),
        serviceUuid: Yup.string().required(),
        // price: Yup.number().required(),
      })
    ).min(1, "Add at least 1 service"),
    // description: Yup.string(),
  });

  return (
    <CardBasic
      title={"Edit Package"}
      btnType={"submit"}
      formId={"createPackageForm"}
      headerBtnText={"Update Package"}
    >
      <Formik
        initialValues={{
          name: packageData.name ?? "",
          maxServices: packageData.maxServices ?? "",
          price: packageData.price ?? "",
          time: packageData.time ?? "",
          isActive: packageData.isActive ?? "",
          services: packageData.services ?? [emptyServiceRow],
          description: packageData.description ?? "",
          categoryServices: categoryServices.length > 0 ? "1" : "",
          applicable_discount: packageData.applicable_discount ?? false,
        }}
        enableReinitialize
        validationSchema={PackageCreateSchema}
        onSubmit={(values, actions) => {
          console.log(values);
          updatePackage(values, uuid)
            .then(() => {
              dispatch(
                sendSuccess(
                  "The package information has been updated successfully"
                )
              );
              history.replace("/packages");
            })
            .catch((err) => {
              dispatch(sendFailure(err.message));
              actions.setStatus(err.message);
              console.log(err.message);
            });
        }}
      >
        {({ values, errors, setFieldValue }) => (
          <Form className="form form-label-right" id="createPackageForm">
            <div className="form-group row">
              <div className="col-lg-12">
                <Field
                  name="name"
                  component={Input}
                  placeholder="Package Name"
                  label="Package Name *"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-4">
                <Field
                  name="price"
                  type="number"
                  min="1"
                  component={Input}
                  placeholder="0.00"
                  label={`Price (${currencySymbol[0] ? currencySymbol[0].currencySymbol : '₹'}) *`}
                />
              </div>
              <div className="col-lg-4">
                <Field
                  name="time"
                  type="number"
                  min="5"
                  step="5"
                  component={Input}
                  placeholder="15"
                  label="Time (Minutes) *"
                />
              </div>
              <div className="col-lg-4">
                <Select name="isActive" label="Status *">
                  <option value={true}>Active</option>
                  <option value={false}>Inactive</option>
                </Select>
              </div>
            </div>

            <div className="form-group row mb-0">
              <div className="col-6">
                <label>Category</label>
              </div>
              <div className="col-6">
                <label>Service</label>
              </div>
              {/* <div className="col-lg-4">
                <label>Price (₹)</label>
              </div> */}
            </div>
            <hr />
            <FieldArray name="services">
              {({ push, remove }) => (
                <>
                  {values.services.map((_, index) => (
                    <div className="form-group row" key={index}>
                      <div className="col-6">
                        <Select
                          name={`services.[${index}].categoryUuid`}
                          onChange={(e) => {
                            setFieldValue(
                              `services.[${index}].categoryUuid`,
                              e.target.value
                            );

                            const categoryServices = loadCategoryServices(
                              e.target.value,
                              index
                            );
                          }}
                        >
                          <option value="" key="default" disabled>
                            {"Select Category"}
                          </option>

                          {serviceCategories.map((category, index) => (
                            <option value={category.uuid} key={category.uuid}>
                              {category.name}
                            </option>
                          ))}
                        </Select>
                      </div>
                      <div className="col-6 d-flex">
                        <Select name={`services.[${index}].serviceUuid`}>
                          <option value="" key="default" disabled>
                            {"Select Service"}
                          </option>
                          {categoryServices[index] && (
                            <>
                              <option value="0" key="0">
                                {"Any Service"}
                              </option>
                              {categoryServices[index].map((service, index) => (
                                <option value={service.uuid} key={service.uuid}>
                                  {service.name}
                                </option>
                              ))}
                            </>
                          )}
                        </Select>
                        <span>
                          <a
                            className="btn font-weight-bold btn-danger btn-icon ml-2"
                            onClick={() => index > 0 && remove(index)}
                          >
                            <i className="flaticon2-cross icon-1x"></i>
                          </a>
                        </span>
                      </div>
                      {/* <div className="col-md-4 d-flex">
                        <Field
                          name={`services.[${index}].price`}
                          type="number"
                          component={Input}
                          disabled
                        />
                        <span>
                          <a
                            className="btn font-weight-bold btn-danger btn-icon ml-2"
                            onClick={() => index > 0 && remove(index)}
                          >
                            <i className="flaticon2-cross icon-1x"></i>
                          </a>
                        </span>
                      </div> */}
                    </div>
                  ))}
                  {errors.services && errors.services[0] && (
                    <div className="invalid-feedback d-block mb-3">
                      Add at least 1 service
                    </div>
                  )}
                  {errors.services && (
                    <div className="invalid-feedback d-block mb-3">
                      Service row can not be empty. Add service or delete the
                      row.
                    </div>
                  )}

                  <div className="form-group row">
                    <div className="col-md-5">
                      <button
                        type="button"
                        className="btn font-weight-bold btn-primary"
                        onClick={() => push(emptyServiceRow)}
                      >
                        <i className="flaticon2-add-1 icon-1x"></i>Add Service
                      </button>
                    </div>
                  </div>
                </>
              )}
            </FieldArray>
            <div className="form-group row">
              <div className="col-lg-12">
                <Field
                  name="maxServices"
                  type="number"
                  min="1"
                  step="1"
                  component={Input}
                  placeholder="1"
                  label="Maximum Allowed Services *"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-12">
                <Field
                  name="description"
                  component={Input}
                  label="Package Description *"
                />
              </div>
            </div>
            <div className="form-group row">
            <div className="col-lg-12">
              <Field
                name="short_description"
                component={Input}
                label="Short Description *"
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-lg-12 checkbox-inline">
              <label className="checkbox">
                <Field type="checkbox" name="applicable_discount" />
                <span></span>Applicable for discount offers
              </label>
            </div>
          </div>
            {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
          </Form>
        )}
      </Formik>
    </CardBasic>
  );
};
