import { createSlice } from "@reduxjs/toolkit";

import * as requestFromServer from "./couponApi";

const initialState = {
  coupons: [],
};

export const couponsSlice = createSlice({
  name: "coupons",
  initialState,
  reducers: {
    updateList(state, action) {
      state.coupons = action.payload;
    },
    created(state, action) {
      state.coupons.push(action.payload);
    },
    updateCouponList(state, action) {
      // state.branch.image.push(action.payload);
      // const key = state.branch.image;
      // console.log(key);
      const CoponIndex = state.coupons.findIndex(
        (el) => el.uuid === action.payload
      );
      console.log(CoponIndex);
      if (CoponIndex > -1) {
        state.coupons.splice(CoponIndex, 1);
      }
    },
  },
});

export const loadCouponsList = () => {
  return async (dispatch) => {
    return requestFromServer
      .getCouponList()
      .then((res) => {
        console.log(res.data.data);
        dispatch(couponsSlice.actions.updateList(res.data.data));
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
      });
  };
};

export const createCoupon = (data) => {
  return async (dispatch) => {
    console.log(data);
    return requestFromServer
      .createCoupon(data)
      .then((response) => {
        console.log(response);
        dispatch(couponsSlice.actions.created(response.data.data));
      })
      .catch((error) => {
        throw new Error(error.response.data.error);
      });
  };
};

export const updateCouponeDeleteData = (uuid) => {
  console.log(uuid);
  return async (dispatch) => {
    dispatch(couponsSlice.actions.updateCouponList(uuid));
  };
};