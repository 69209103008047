import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, Select } from "../../../_metronic/_partials/controls";

import { CardBasic } from "../../../components/CardBasic";
import { loadBranchList } from "../../../store/business/branches/branchSlice";
import { createCategory } from "../../../store/business/expense/Category/categorySlice";
import { loadShiftList } from "../../../store/business/shifts/shitftsSlice";
import dayjs from "dayjs";
import { sendFailure, sendSuccess } from "../../../store/toast/toastSlice";
import { number } from "prop-types";

export const ExpenseCategoryCreate = () => {
  const [formStatus, setFormStatus] = useState("");
  const [dataLoading, setDataLoading] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();

  // Validation schema
  const ValidationSchema = Yup.object().shape({
    categoryName: Yup.string().required("This field is required"),
  });

  return (
    <CardBasic
      title={"Add Category"}
      btnType={"submit"}
      formId={"createCategoryForm"}
      headerBtnText={"Save Category"}
    >
      <Formik
        initialValues={{
          categoryName: "",
        }}
        enableReinitialize
        validationSchema={ValidationSchema}
        onSubmit={(values, actions) => {
          //console.log(values);
          const newValue = {...values, categoryName: values.categoryName.trim().toLowerCase()}
          //console.log(newValue);
          dispatch(createCategory(newValue))
            .then(() => {
              dispatch(sendSuccess("The category has been created succesfully"));
              history.replace("/expense/category");
            })
            .catch((err) => {
              dispatch(sendFailure(err.message));
              actions.setStatus("Error! Duplicate Category Name.");
              console.log(err.message);
            });
        }}
      >
        {({ values, errors, setFieldValue, setFieldError }) => (
          <Form className="form form-label-right" id="createCategoryForm">
            <div className="form-group row">
              <div className="col-lg-12">
                <Field
                  name="categoryName"
                  component={Input}
                  placeholder="Category Name"
                  label="Category Name *"
                />
              </div>
            </div>
            {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
          </Form>
        )}
      </Formik>
    </CardBasic>
  );
};
