import { createSlice } from "@reduxjs/toolkit";

import * as requestFromServer from "./casgdrawerapi";

const initialState = {
  cashDrawerList: [],
  casDrawerTotal: 0,
};

export const cashdrawerSlice = createSlice({
  name: "cashdrawer",
  initialState,
  reducers: {
    updateList(state, action) {
      console.log(action.payload);
      state.cashDrawerList = action.payload.CashDrawerTransactionData;
      state.casDrawerTotal = action.payload.CasDrawerTotal;
    }
  },
});

export const loadCasgDrawerList = (from, to) => {
  return async (dispatch) => {
    return requestFromServer
      .getCashDrawerList(from, to)
      .then((response) => {
        console.log(response.data.data);
        dispatch(cashdrawerSlice.actions.updateList(response.data.data));
      })
      .catch((err) => {
        alert(err.message);
      });
  };
};