import React, { useEffect, useState } from "react";
import { Formik, Form, Field, useFormikContext } from "formik";
import * as Yup from "yup";
import { useHistory, useParams } from "react-router-dom";
import { Input, Select } from "../../_metronic/_partials/controls";
import { useDispatch } from "react-redux";

import { CardBasic } from "../../components/CardBasic";
import {
  getCategory,
  updateCategory,
} from "../../store/admin/category/categoryApi";
import { sendFailure, sendSuccess } from "../../store/toast/toastSlice";

export const ServiceCategoryView = () => {
  const [formStatus, setFormStatus] = useState("");
  const [customAlert, setCustomAlert] = useState({});
  const [categoryData, setCategoryData] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const { categoryUuid } = useParams();

  const req = {
    uuid: categoryUuid,
  };

  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required"),
    isActive: Yup.boolean().required("This field is required"),
  });

  useEffect(() => {
    getCategory(req)
      .then((response) => {
        const data = response.data.data;
        setCategoryData(data);
        throw new Error("Error");
      })
      .catch((err) => {
        // setCustomAlert({
        //   status: err.message,
        //   class: "danger",
        // });
        console.log(err.message);
      });
  }, []);

  return (
    <CardBasic
      title={"Edit Service Category"}
      btnType={"submit"}
      formId={"updateForm"}
      headerBtnText={"Update Category"}
    >
      <Formik
        initialValues={{
          name: categoryData.name ?? "",
          image: "",
          isActive: categoryData.isActive ?? false,
        }}
        enableReinitialize
        validationSchema={ValidationSchema}
        onSubmit={(values, actions) => {
          console.log(values);

          updateCategory(values, categoryUuid)
            .then((response) => {
              dispatch(
                sendSuccess("The segments has been created successfully")
              );
              // console.log(response.data);
              history.replace("/service/categories");
            })
            .catch((err) => {
              dispatch(sendFailure(err.message));
              actions.setStatus("Error! Duplicate Segment Name.");
              console.log(err.message);
            });
          setFormStatus("submitted");
        }}
      >
        {(formProps) => (
          <Form className="form form-label-right" id="updateForm">
            {formProps.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formProps.status}
                </div>
              </div>
            )}
            {/* {customAlert.status && (
              <div
                className={`mb-10 alert alert-custom alert-light-${customAlert.class} alert-dismissible`}
              >
                <div className="alert-text font-weight-bold">
                  {customAlert.status}
                </div>
              </div>
            )} */}
            {categoryData.image && (
              <div className="form-group row col">
                <img
                  src={`${process.env.REACT_APP_MEDIA_URL}/${categoryData.image}`}
                  width="300px"
                ></img>
              </div>
            )}
            <div className="form-group row">
              <div className="col-lg-6">
                <Field
                  name="name"
                  component={Input}
                  placeholder="Category Name"
                  label="Category Name *"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-6">
                <label>Category Image (300 X 300 px)</label>
                <input
                  type="file"
                  name="image"
                  className="form-control"
                  onChange={(e) =>
                    formProps.setFieldValue("image", e.target.files[0])
                  }
                />
                {formProps.errors.image && (
                  <div className="invalid-feedback d-flex">
                    {formProps.errors.image}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-4">
                <Select name="isActive" label="Status *">
                  <option value={true}>Active</option>
                  <option value={false}>Inactive</option>
                </Select>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </CardBasic>
  );
};
