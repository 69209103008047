import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import dayjs from "dayjs";
import SVG from "react-inlinesvg";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useDispatch, useSelector } from "react-redux";
import { sendFailure, sendSuccess } from "../../store/toast/toastSlice";
import {
  bookingStatusUpdate,
  bookingCancle,
} from "../../store/business/bookings/bookingApi";
import InvoiceMain from "../invoice/component/InvoiceMain";
import { updateBookingStatus } from "../../store/business/bookings/bookingSlice";
import { loadInvoiceList } from "../../store/business/invoice/invoiceSlice";
import { loadBranchInvoice } from "../../store/branch/branchInvoiceSlice";
import { Invoice } from "../invoice/Invoice";
import { Hidden } from "@material-ui/core";

export default function InvoiceDialog({ id, content, svg }) {
  console.log("id",id)
  const [open, setOpen] = useState(false);
  const { user, role } = useSelector((state) => state.authPersist);
  const { countryCode } = useSelector((state) => state.countryCode);
  const currencySymbol = countryCode.filter((el) => el.countryCode === user.countryCode);
  //console.log(currencySymbol);
  const dispatch = useDispatch();
  const bookingUuid = {
    booking_uuid: id,
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (role === "business") {
      dispatch(loadInvoiceList(bookingUuid))
        .then((response) => {
          const data = response.data.data;
        })
        .catch((err) => {
          console.log(err.message);
        });
    } else if (role === "branch") {
      dispatch(loadBranchInvoice(bookingUuid))
        .then((response) => {
          const data = response.data.data;
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }, []);
  const invoice = useSelector((state) => {
    if (role === "business") {
      return state.invoice.invoice;
    } else if (role === "branch") {
      return state.branchInvoice.branchInvoice;
    }
  });
  console.log("invoice",invoice);
  // const staff_name = "";
  // for(let i=0; i<invoice.Staff.length; i++){
  //   staff_name = invoice.Staff[i].name
  // }
  // console.log("staff_name",staff_name)
  const downloadHandler = () => {
   return  <NavLink className="navi-text" target="_blank" to={`/invoice/${id}`}>
                  View Invoice
                </NavLink>
    // return (
    //   <PDFDownloadLink
    //     document={<InvoiceMain invoice={invoice} user={user} />}
    //     fileName="invoice.pdf"
    //   >
    //     {({ blob, url, loading, error }) =>
    //       loading ? "Loading document..." : "Download now!"
    //     }
    //   </PDFDownloadLink>
    // );
  };
  
  return (
    <div>
      {content ? (
        <span className="navi-text" onClick={handleClickOpen}>
          {content.navigation}
        </span>
      ) : (
        <SVG src={svg} onClick={handleClickOpen} />
      )}

      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Invoice {invoice?.booking_details?.invoiceNo ? `: #${invoice?.booking_details?.invoiceNo}` : null}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="text-center">
              <h4>{user.name}</h4>
              <p className="m-0">
                {user.address_line1}
                {user.address_line2 != "" && `,  ${user.address_line2}`}
                {`, ${user.city} - ${user.zipcode}`}
              </p>
              <p className="m-0">{`${user.phone}, ${user.email}`}</p>
              {user.website != "" && <p className="m-0">{user.website}</p>}
              {user.taxNo != "" && <p className="m-0">TAX NO: {user.taxNo}</p>}
            </div>
            <div className="d-flex justify-content-between mt-2">
              <div>
                <p className="m-0">Customer Name : {invoice?.booking_details?.customer?.name}</p>
                <p className="m-0">Booking Id: {invoice?.booking_details?.bookingId}</p>
                <p className="m-0">Staff Name: {invoice ? invoice?.booking_details?.Staff?.length>0 ? invoice?.booking_details?.Staff[invoice?.booking_details?.Staff?.length-1]?.name : "" : ""}</p>
              </div>
              <div>
                <p className="m-0">
                  Date : {dayjs(invoice?.booking_details?.date).format("DD-MM-YYYY")}
                </p>
                <p className="m-0">
                  Time: {dayjs(invoice?.booking_details?.dateTime).format("hh:mm A")}
                </p>
              </div>
            </div>
            <div>
              <table
                class="table"
                style={{ borderBottom: "1px solid #F2EFF1" }}
              >
                <thead>
                  <tr>
                    <th scope="col">PARTICULARS</th>
                    <th scope="col">TYPE</th>
                    <th scope="col">AMOUNT ({currencySymbol[0] ? currencySymbol[0].currencySymbol : '₹'})</th>
                  </tr>
                </thead>
                <tbody>
                  {invoice?.booking_details?.service?.map((service) => {
                    return (
                      <tr key={service.uuid}>
                        <td> {service.name}</td>
                        <td>Service</td>
                        <td>{service.price}</td>
                      </tr>
                    );
                  })}
                  {invoice?.booking_details?.package?.map((service) => {
                    return (
                      <tr key={service.uuid}>
                        <td> {service.name}</td>
                        <td>Package</td>
                        <td>{service.price}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div style={{ textAlign: "end" }}>
              <p className="m-0"> SUB TOTAL ({currencySymbol[0] ? currencySymbol[0].currencySymbol : '₹'}) : {invoice?.booking_details?.baseSubTotal}</p>
              {invoice?.booking_details?.tax > 0 && <p className="m-0">TAX ({currencySymbol[0] ? currencySymbol[0].currencySymbol : '₹'}) : {invoice?.booking_details?.tax}</p>}
              {invoice?.booking_details?.discount > 0 && (
                <p className="m-0">Discount ({currencySymbol[0] ? currencySymbol[0].currencySymbol : '₹'}) - ({invoice?.booking_details?.coupon ? invoice?.booking_details?.coupon[0]?.couponCode : null}) : {invoice?.booking_details?.discount}</p>
              )}
              <p className="m-0">TOTAL ({currencySymbol[0] ? currencySymbol[0].currencySymbol : '₹'}) : {invoice?.booking_details?.total}</p>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        {/* <NavLink className="btn btn-light-success"  target="_blank" to={`/invoice/${id}`}>
                  Download Invoice
                </NavLink> */}
                <Hidden smDown>
                  {console.log("laptop")}
                <Invoice id={id} invoice={invoice?.booking_details} />
                </Hidden>
                <Hidden mdUp>
                  {console.log("mobile")}
                  <a href={invoice.invoiceLink ? invoice.invoiceLink : "#"} target="_blank" className="btn btn-light-success">Download Invoice</a>
                </Hidden>
                
          <button onClick={handleClose} className="btn btn-danger">
            Close
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
