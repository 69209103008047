import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input } from "../../_metronic/_partials/controls";
import { useHistory } from "react-router";
import { CardBasic } from "../../components/CardBasic";
import { CardSecondary } from "../../components/CardSecondary";

import { useDispatch } from "react-redux";
import { updateBranch } from "../../store/business/branches/branchApi";
import { ProfileUpdateData } from "../../store/business/authSlice";
import { sendFailure, sendSuccess } from "../../store/toast/toastSlice";
import ChangePasswordDialog from "../../components/dialogs/ChangePasswordDialog";

export function BusinessProfile(props) {
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const dispatch = useDispatch();
  const business = props.business;
  const history = useHistory();
  console.log("business", business);

  const BranchCreateSchema = Yup.object().shape({
    business_name: Yup.string()
      .min(5, "Minimum 5 characters")
      .required("This field is required"),
    owner_name: Yup.string()
      .min(5, "Minimum 5 characters")
      .required("This field is required"),
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(4, "Enter 4 digit phone number")
      .max(20, "Enter 20 digit phone number")
      .required("This field is required"),
    email: Yup.string()
      .email("Enter valid email address")
      .required("This field is required"),
    // password: Yup.string().min(
    //   8,
    //   "Password is too short - should be 8 chars minimum."
    // ),
    // c_password: Yup.string().oneOf(
    //   [Yup.ref("password"), null],
    //   "Passwords must match"
    // ),
    // type: Yup.string().required("Type is required"),
    // address1: Yup.string().required("This field is required"),
    // address2: Yup.string(),
    // city: Yup.string().required("This field is required"),
    // zipcode: Yup.string().required("This field is required"),
    // website: Yup.string().url(),
    // gmaps: Yup.string().url(),
    // status: Yup.string().required("This field is required"),
  });
  return (
    <CardSecondary
      title={"BUSINESS PROFILE"}
      btnType={"submit"}
      btn2Type={"other"}
      formId={"updateProfileForm"}
      headerBtnText={"Update"}
      headerBtn2Text={
        <ChangePasswordDialog
          content="Change Password"
          business={business}
          role="business"
        />
      }
    >
      <Formik
        initialValues={{
          business_name: business.business_name,
          owner_name: business.owner_name,
          phone: business.phone,
          email: business.email,
          isOpted: business.isOpted == 1 ? true : false,
          // password: "",
          // c_password: "",
        }}
        enableReinitialize
        validationSchema={BranchCreateSchema}
        onSubmit={(values, actions) => {
          console.log(values);
          //saveCustomer(values);
          dispatch(ProfileUpdateData(values))
            .then((res) => {
              console.log(res);
              dispatch(sendSuccess("The branch profile updated successfully"));
            })
            .catch((err) => {
              console.log(err);
              dispatch(sendFailure(err.message));
              actions.setStatus(err.message);
              console.log(err.message);
            });
          actions.setFormStatus("submitted");
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <Form className="form form-label-right" id="updateProfileForm">
              <div className="form-group row">
                <div className="col-12">
                  <Field
                    name="business_name"
                    component={Input}
                    placeholder="Business Name"
                    label="Business Name *"
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-12">
                  <Field
                    name="owner_name"
                    component={Input}
                    placeholder="Owner Name"
                    label="Owner Name *"
                  />
                </div>
              </div>
              {/* Contact Number */}
              <div className="form-group row">
                <div className="col-lg-6">
                  <Field
                    name="phone"
                    disabled={true}
                    component={Input}
                    placeholder="9899898893"
                    pattern="[1-9]{1}[0-9]{9}"
                    label="Registered Phone *"
                  />
                </div>
                <div className="col-lg-6">
                  <Field
                    name="email"
                    type="email"
                    disabled={true}
                    component={Input}
                    placeholder="example@domain.com"
                    label="Email Address *"
                  />
                </div>
              </div>
              {/* <div className="form-group row">
                <div className="col-lg-12 d-flex checkbox-inline checkbox">
                  
                 
                  <Field
                    type="checkbox"
                    // component={Input}
                    name="isOpted"
                    checked={values.isOpted == "1" ? true : false}
                   
                  />
                  <span className="mx-2">Allow Bellaz Promotion </span>
                  
                </div>
              </div> */}
               <div className="form-group row">
              <div className="col-lg-12 checkbox-inline">
                <label className="checkbox">
                  <Field type="checkbox" name="isOpted" />
                  <span></span>Allow Bellaz Promotion
                </label>
              </div>
            </div>
              {/* New Password */}
              {/* <div className="form-group row">
            <div className="col-lg-6">
              <Field
                name="password"
                type="password"
                component={Input}
                placeholder=""
                label="New Password"
              />
            </div>
            <div className="col-lg-6">
              <Field
                name="c_password"
                type="password"
                component={Input}
                placeholder=""
                label="Confirm Password"
              />
            </div>
          </div> */}

              {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
            </Form>
          );
        }}
      </Formik>
    </CardSecondary>
  );
}
