import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import { Input, Select } from "../../_metronic/_partials/controls";

import { CardBasic } from "../../components/CardBasic";
import { useDispatch, useSelector } from "react-redux";
import { createBranch } from "../../store/business/branches/branchSlice";
import { useHistory } from "react-router-dom";
import { MultiSelect } from "../../_metronic/_partials/controls/forms/MultiSelect";
import { TimePickerField } from "../../components/pickers/TimePickerField";
import { sendFailure, sendSuccess } from "../../store/toast/toastSlice";
import PlacesAutocomplete from "../../components/googleMaps/PlacesAutocomplete";

export const BranchCreate = () => {
  // Validation schema
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const history = useHistory();
  const dispatch = useDispatch();

  const { segments } = useSelector((state) => state.data);
  console.log(segments);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(5, "Minimum 5 characters")
      .required("This field is required"),
    business_segment: Yup.array().required("This field is required"),
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(4, "Enter 4 digit phone number")
      .max(20, "Enter 20 digit phone number")
      .required("This field is required"),
    email: Yup.string()
      .email("Enter valid email address")
      .required("This field is required"),
    password: Yup.string()
      .required("Please Enter your password")
      .min(8, "Password is too short - should be 8 chars minimum."),
    c_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Please enter your password."),
    gender: Yup.string().required("Type is required"),
    otherContact: Yup.string(),
    address_line1: Yup.string().required("This field is required"),
    address_line2: Yup.string(),
    city: Yup.string().required("This field is required"),
    zipcode: Yup.string().required("This field is required"),
    website: Yup.string().url(),
    gmap: Yup.string(),
    latitude: Yup.number().required("This field is required"),
    longitude: Yup.number(),
    isActive: Yup.string().required("This field is required"),
    description: Yup.string().required("This field is required").max(1500, "Maximum length should be 1500 characters."),
    startTime: Yup.date().required("This field is required"),
    endTime: Yup.date()
      .min(Yup.ref("startTime"), "End Time must be greater than Start Time")
      .required("This field is required"),
    taxSlab: Yup.number()
      .test(
        "Is positive?",
        "Tax Slab must be greater than 0",
        (value) => value >= 0
      )
      .integer()
      .max(100, "max Tax Slab is 100")
      .required("This field is required"),
    taxNo: Yup.string(),
    holiday: Yup.string(),
    logo: Yup.string().required("This field is required"),
  });

  return (
    <CardBasic
      title={"Add Branch"}
      btnType={"submit"}
      formId={"createBranchForm"}
      headerBtnText={"Save Branch"}
    >
      <Formik
        initialValues={{
          name: "",
          business_segment: [],
          phone: "",
          email: "",
          password: "",
          c_password: "",
          gender: "unisex",
          // otherContact: "",
          address_line1: "",
          address_line2: "",
          city: "",
          zipcode: "",
          website: "",
          gmap: "",
          isActive: true,
          image: "",
          logo: "",
          description: "",
          startTime: "",
          endTime: "",
          taxSlab: 0,
          taxNo: "",
          holiday: "",
          latitude: "",
          longitude: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          //console.log(values);
          dispatch(createBranch(values))
            .then(() => {
              dispatch(sendSuccess("The branch has been created successfully"));
              history.replace("/branches");
            })
            .catch((err) => {
              //console.log(err);
              dispatch(sendFailure(err.message));
              actions.setStatus("Error! Duplicate Segment Name.");
              console.log(err.message);
            });
          actions.setFormStatus("submitted");
        }}
      >
        {(formProps) => (
          <Form className="form form-label-right" id="createBranchForm">
            <div className="form-group row">
              {/* Branch Name */}
              <div className="col-lg-8 mb-4 mb-lg-0">
                <Field
                  name="name"
                  component={Input}
                  placeholder="Branch Name"
                  label="Branch Name *"
                />
              </div>
              <div className="col-lg-4">
                <MultiSelect
                  label="Segment *"
                  name="business_segment"
                  options={segments.map((segment, index) => ({
                    value: segment.uuid,
                    label: segment.name,
                  }))}
                />
              </div>
            </div>
            {/* Contact Number */}
            <div className="form-group row">
              <div className="col-lg-4 mb-4 mb-lg-0">
                <Field
                  name="phone"
                  component={Input}
                  placeholder="9899898893"
                  label="Contact Number *"
                  //maxLength="10"
                />
              </div>
              <div className="col-lg-4 mb-4 mb-lg-0">
                <Field
                  name="email"
                  type="email"
                  component={Input}
                  placeholder="example@domain.com"
                  label="Email Address *"
                />
              </div>
              <div className="col-lg-4">
                <Select name="gender" label="Type *">
                  <option value="unisex">Unisex</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </Select>
              </div>
            </div>
            {/* password */}
            <div className="form-group row">
              <div className="col-lg-4 mb-4 mb-lg-0">
                <Field
                  name="password"
                  type="password"
                  component={Input}
                  placeholder=""
                  label="Password *"
                  //maxLength="10"
                />
              </div>
              <div className="col-lg-4 mb-4 mb-lg-0">
                <Field
                  name="c_password"
                  type="password"
                  component={Input}
                  placeholder=""
                  label="Confirm Password *"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col">
                <Field
                  name="otherContact"
                  component={Input}
                  label="Additional Contact Numbers"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-4 mb-4 mb-lg-0">
                <Field
                  name="address_line1"
                  component={Input}
                  label="Address Line 1 *"
                />
              </div>
              <div className="col-lg-4 mb-4 mb-lg-0">
                <Field
                  name="address_line2"
                  component={Input}
                  label="Address Line 2"
                />
              </div>
              <div className="col-lg-4">
                <Field name="city" component={Input} label="City *" />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-4 mb-4 mb-lg-0">
                <Field name="zipcode" component={Input} label="Zipcode *" />
              </div>
              <div className="col-lg-4 mb-4 mb-lg-0">
                <Select name="isActive" label="Active *">
                  <option value={true}>Active</option>
                  <option value={false}>Inactive</option>
                </Select>
              </div>
              <div className="col-lg-4">
                <label>Branch Image</label>
                <input
                  type="file"
                  name="image"
                  className="form-control"
                  multiple="multiple"
                  onChange={(e) => {
                    console.log(e.target.files);
                    let file = e.target.files[0];
                    console.log(file.type);
                    if (
                      file.type == "image/jpeg" ||
                      file.type == "image/jpg" ||
                      file.type == "image/png"
                    ) {
                      if (file.size <= 1000000) {
                        formProps.setFieldValue("image", e.target.files[0]);
                      } else {
                        formProps.setFieldError(
                          "image",
                          "Image size should be less than 1 MB"
                        );
                      }
                    } else {
                      formProps.setFieldError(
                        "image",
                        "Please enter a valid file format"
                      );
                    }
                  }}
                />
                {formProps.errors.image && (
                  <div className="invalid-feedback d-flex">
                    {formProps.errors.image}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group row">
              {/* <div className="col-lg-8 mb-4 mb-lg-0">
                <Field name="gmap" component={Input} label="Google Maps Link" />
              </div> */}
              <div className="col-lg-8 mb-4 mb-lg-0">
                <Field
                  name="website"
                  component={Input}
                  label="Website"
                  placeholder="https://example.com"
                />
              </div>
              <div className="col-lg-4">
                <label>Branch Logo *</label>
                <input
                  type="file"
                  name="logo"
                  className="form-control"
                  onChange={(e) => {
                    let file = e.target.files[0];
                    //console.log(file.type);
                    if (
                      file.type == "image/jpeg" ||
                      file.type == "image/jpg" ||
                      file.type == "image/png"
                    ) {
                      if (file.size <= 1000000) {
                        formProps.setFieldValue("logo", e.target.files[0]);
                      } else {
                        formProps.setFieldError(
                          "logo",
                          "logo size should be less than 1 MB"
                        );
                      }
                    } else {
                      formProps.setFieldError(
                        "logo",
                        "Please enter a valid file format"
                      );
                    }
                  }}
                />
                {formProps.errors.logo && (
                  <div className="invalid-feedback d-flex">
                    {formProps.errors.logo}
                  </div>
                )}
              </div>
            </div>
            {/* <div className="form-group row">
              <div className="col-lg-12">
                <Field
                  name="website"
                  component={Input}
                  label="Website"
                  placeholder="https://example.com"
                />
              </div>
            </div> */}
            <div className="form-group row">
              {/* Branch Name */}
              <div className="col-lg-4 mb-4 mb-lg-0">
                <TimePickerField
                  name="startTime"
                  label="Start Time *"
                  placeholder="Start Time"
                />
              </div>
              <div className="col-lg-4 mb-4 mb-lg-0">
                <TimePickerField
                  name="endTime"
                  label="End Time *"
                  placeholder="End Time"
                />
              </div>
              <div className="col-lg-4">
                <Select name="holiday" label="Weekly Off">
                  <option value="">No Off</option>
                  <option value="0">Sunday</option>
                  <option value="1">Monday</option>
                  <option value="2">Tuesday</option>
                  <option value="3">Wednesday</option>
                  <option value="4">Thursday</option>
                  <option value="5">Friday</option>
                  <option value="6">Saturday</option>
                </Select>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-12">
                <label>Branch Description *</label>
                <Field
                  name="description"
                  as="textarea"
                  rows="5"
                  className={`form-control ${formProps.errors.description &&
                    "is-invalid"}`}
                />
                {formProps.errors.description && (
                  <div className="invalid-feedback d-flex">
                    {formProps.errors.description}
                  </div>
                )}
              </div>
            </div>

            <div className="form-group row">
              <div className="col-lg-12">
                <PlacesAutocomplete setValue={formProps.setFieldValue} label='Set Branch Location *' />
                {formProps.errors.latitude && (
                  <div className="invalid-feedback d-flex">
                    {formProps.errors.latitude}
                  </div>
                )}
              </div>
            </div>
            {/* Tax */}
            <div className="form-group row">
              <div className="col-lg-6 mb-4 mb-lg-0">
                <Field
                  name="taxSlab"
                  type="number"
                  component={Input}
                  placeholder="Tax Slab %"
                  label="Tax Slab (%) *"
                  max="100"
                />
              </div>
              <div className="col-lg-6">
                <Field
                  name="taxNo"
                  type="text"
                  component={Input}
                  placeholder="Tax Number"
                  label="Tax Number"
                  style={{ "text-transform": "uppercase" }}
                />
              </div>
            </div>
            {/* <pre>{JSON.stringify(formProps.values, null, 4)}</pre> */}
          </Form>
        )}
      </Formik>
    </CardBasic>
  );
};
