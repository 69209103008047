import { createSlice } from "@reduxjs/toolkit";

import * as requestFromServer from "./raAPi";

const initialState = {
  ra: [],
};

export const expenseRaSlice = createSlice({
  name: 'expenseRa',
  initialState,
  reducers: {
    updateList(state, action) {
      state.ra = action.payload;
    },
    created(state, action) {
      state.ra.push(action.payload);
    },
    updateStatus(state, action) {
      const raIndex = state.ra.findIndex(
        (ra) => ra.uuid === action.payload
      );
      console.log(raIndex);
      if (raIndex > -1) {
        state.ra.splice(raIndex, 1);
      }
    },
    created(state, action) {
      state.ra.push(action.payload);
    },
  }
});

export const loadRaList = () => {
  return async (dispatch) => {
    return requestFromServer
      .getRaList()
      .then((response) => {
        console.log(response.data);
        dispatch(expenseRaSlice.actions.updateList(response.data.data));
      })
      .catch((err) => {
        alert(err.message);
      });
  }
}

export const createRa = (data) => {
  console.log(data);
  return async (dispatch) => {
    return requestFromServer
      .createRa(data)
      .then((response) => {
        console.log(response);
        dispatch(expenseRaSlice.actions.created(response.data.data));
      })
      .catch((error) => {
        throw new Error(error.response.data.error);
      });
  };
};

export const updateRaList = (data) => {
  console.log(data);
  return async (dispatch) => {
        dispatch(expenseRaSlice.actions.updateStatus(data));
  };
};