import React from "react";
import { ServicesTable } from "./table";
import { CardBasic } from "../../components/CardBasic";

export function ServicesPage() {
  return (
    <CardBasic
      title={"Services List"}
      btnType={"button"}
      headerBtnText={"Add Service"}
      headerBtnLink={"/service/create"}
    >
      <ServicesTable />
    </CardBasic>
  );
}
