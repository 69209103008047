import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Select, DatePickerField, Input } from "../../../_metronic/_partials/controls";
import { useHistory } from "react-router";
import { CardBasic } from "../../../components/CardBasic";
import { createExpenseType } from "../../../store/business/expense/type/typeSlice";
import { useDispatch, useSelector } from "react-redux";
import { loadCategoryList } from "../../../store/business/expense/Category/categorySlice";
import { loadStaffList } from "../../../store/business/staff/staffSlice";
import { sendFailure, sendSuccess } from "../../../store/toast/toastSlice";

export const ExpenseCategoryTypeCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [dataLoading, setDataLoading] = useState(true);

  const category = useSelector((state) => state.category.category);

  useEffect(() => {
    if (category.length === 0) {
      dispatch(loadCategoryList()).then(() => {
        setDataLoading(false);
      });
    }
  }, [category]);
  console.log(category);

  const ValidationSchema = Yup.object().shape({
    typeName: Yup.string().required("This field is required"),
    categoryUuid: Yup.string().required("Type is required"),
  });

  return (
    <CardBasic
      title={"Add Expense Type"}
      btnType={"submit"}
      formId={"createForm"}
      headerBtnText={"Save Expense Type"}
    >
      <Formik
        initialValues={{
          typeName: "",
          categoryUuid: ""
        }}
        enableReinitialize
        validationSchema={ValidationSchema}
        onSubmit={(values, actions) => {
          //console.log(values);
          const newValue = {...values, typeName: values.typeName.trim().toLowerCase()}
         //console.log(newValue);
          dispatch(createExpenseType(newValue))
            .then(() => {
              console.log("success");
              dispatch(
                sendSuccess(
                  "The Expense Type has been created successfully."
                )
              );
              history.replace("/expense/type");
            })
            .catch((err) => {
              dispatch(sendFailure(err.message));
              actions.setStatus("Error!");
              console.log(err.message);
            });
        }}
      >
        {({ values, errors, setFieldValue }) => (
          <Form className="form form-label-right" id="createForm">
             <div className="form-group row">
             <div className="col-lg-6">
                <Select name="categoryUuid" label="Category Name *" placeholder="Select Category" >
                  <option value={'Select Category'} key={'Select Category'}>
                    {'Select Category'}
                  </option>
                  {category.map((category, index) => (
                      <option value={category.uuid} key={category.uuid}>
                        {category.categoryName.toUpperCase()}
                      </option>
                  ))}
                </Select>
              </div>
              <div className="col-lg-6">
                <Field
                  name="typeName"
                  component={Input}
                  placeholder="Type Name"
                  label="Type Name *"
                />
              </div>
            </div>
             {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
          </Form>
        )}
      </Formik>
    </CardBasic>
  );
};
