import React from "react";

import { SegmentsTable } from "./table";
import { CardBasic } from "../../components/CardBasic";

export function SegmentsPage() {
  return (
    <CardBasic
      title={"Business Segment List"}
      btnType={"button"}
      headerBtnText={"Add Business Segment"}
      headerBtnLink={"/segment/create"}
    >
      <SegmentsTable />
    </CardBasic>
  );
}
