import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SpinnerBasic } from "../components/spinners/SpinnerBasic";
import { BarChart } from "../components/widgets/BarChart";
import { getBusinessCustomerReport } from "../store/business/reports/reportApi";
import { TilesWidget11 } from "../_metronic/_partials/widgets";
import { BaseTablesWidget11 } from '../_metronic/_partials/widgets';

export const Customer = (props) => {
  const { role, user } = useSelector((state) => state.authPersist);
  const [businessReportData, setBusinessReportData] = useState({});
  const [dataLoading, setDataLoading] = useState(true);

  console.log(businessReportData);
  //console.log(role);

  useEffect(() => {
    setDataLoading(true);
    if (role == "business") {
      getBusinessCustomerReport(props.dateData).then((response) => {
        console.log(response);
        setBusinessReportData(response.data.data);
        setDataLoading(false);
      });
    }
  }, [props.dateData]);

  return (
    <>
      {role === "business" && (
        <>
          {dataLoading ? (
            <div style={{ height: '600px', paddingTop: '200px' }}>
              <SpinnerBasic />
            </div>
          ) : (
            <>
              <div className="row">
                {/* Bookings Report */}
                <div className="col-xl-6">
                  <div className="row">
                    <div className="col-12">
                      <TilesWidget11
                        className="gutter-b"
                        baseColor="primary"
                        widgetHeight="150px"
                        count={businessReportData.ownOldCustomer}
                        title="Own Old Customer"
                        svgUrl="/media/svg/icons/General/User.svg"
                        linkUrl="#"
                      />
                    </div>
                    <div className="col-sm-6">
                      <TilesWidget11
                        className="gutter-b"
                        baseColor="primary"
                        widgetHeight="150px"
                        count={businessReportData.totalCustomersHomeBranch}
                        title="Customers Home Branch"
                        svgUrl="/media/svg/icons/General/User.svg"
                        linkUrl="#"
                      />
                    </div>
                    <div className="col-sm-6">
                      <TilesWidget11
                        className="gutter-b"
                        baseColor="primary"
                        widgetHeight="150px"
                        count={businessReportData.totalNewCustomers}
                        title="Total New Customers"
                        svgUrl="/media/svg/icons/General/User.svg"
                        linkUrl="#"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xl-6">
                  <BaseTablesWidget11
                    className="gutter-b card-stretch"
                    title="customet"
                    // chartColor="danger"
                    data={businessReportData.topcustomers ? businessReportData.topcustomers : []}
                  />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};