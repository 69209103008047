import React from "react";

import { TypeTable } from "./table";
import { CardBasic } from "../../../components/CardBasic";
import { useSelector } from "react-redux";

export function ExpenseTypePage() {
  const { role } = useSelector((state) => state.authPersist);

  return (
    <CardBasic
      title={"Expense Type List"}
      btnType={"button"}
      headerBtnText={role === "business" ? "Add Expense Type" : null}
      headerBtnLink={role === "business" ? "/expense/type/create" : null}
    >
      <TypeTable />
    </CardBasic>
  );
}
