import { createSlice } from "@reduxjs/toolkit";

import * as requestFromServer from "./packageApi";

const initialState = {
  packages: [],
};

export const packageSlice = createSlice({
  name: "package",
  initialState,
  reducers: {
    updateList(state, action) {
      state.packages = action.payload;
    },
    created(state, action) {
      state.packages.push(action.payload);
    },
  },
});

export const loadPackageList = () => {
  return async (dispatch) => {
    return requestFromServer
      .getPackageList()
      .then((response) => {
        console.log(response.data);
        dispatch(packageSlice.actions.updateList(response.data.data));
      })
      .catch((err) => {
        alert(err.message);
      });
  };
};

export const createPackage = (data) => {
  return async (dispatch) => {
    return requestFromServer
      .createPackage(data)
      .then((response) => {
        dispatch(packageSlice.actions.created(response.data.data));
      })
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };
};
