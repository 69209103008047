import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, Select } from "../../_metronic/_partials/controls";

import { CardBasic } from "../../components/CardBasic";
import { loadBranchList } from "../../store/business/branches/branchSlice";
import { createStaff } from "../../store/business/staff/staffSlice";
import { loadShiftList } from "../../store/business/shifts/shitftsSlice";
import { getCountryCodeList } from "../../store/countryCode/countryCodeSlice";
import dayjs from "dayjs";
import { sendFailure, sendSuccess } from "../../store/toast/toastSlice";
import { number } from "prop-types";

export const StaffCreate = () => {
  const [formStatus, setFormStatus] = useState("");
  const [dataLoading, setDataLoading] = useState(true);
  const { role, user } = useSelector((state) => state.authPersist);
  const { countryCode } = useSelector((state) => state.countryCode);
  const currencySymbol = countryCode.filter((el) => el.countryCode === user.countryCode);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getCountryCodeList());
  }, []);
  
  //console.log(user);
  const branches = useSelector((state) => {
    return state.branch.branches.filter((data) => {
      return data.isActive === true;
    });
  });
  const { shifts } = useSelector((state) => state.shift);

  useEffect(() => {
    if (branches.length === 0) {
      dispatch(loadBranchList()).then(() => {
        setDataLoading(false);
      });
    }

    if (shifts.length === 0) {
      dispatch(loadShiftList()).then(() => {
        setDataLoading(false);
      });
    }
  }, [branches, shifts]);

  // Validation schema
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const ValidationSchema = Yup.object().shape({
    name: Yup.string()
      .min(5, "Minimum 5 characters")
      .required("This field is required"),
    branchUuid: Yup.string().required("This field is required"),
    designation: Yup.string().required("This field is required"),
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(4, "Enter 4 digit phone number")
      .max(20, "Enter 20 digit phone number")
      .required("This field is required"),
    email: Yup.string()
      .email("Enter valid email address")
      .required("This field is required"),
    salary: Yup.number(),
    role: Yup.string().required("Type is required"),
    shift: Yup.string(),
    commission: Yup.number().max(100),
    isActive: Yup.bool().required("This field is required"),
    canLogin: Yup.bool().required("This field is required"),
    holiday: Yup.string(),
  });

  return (
    <CardBasic
      title={"Add Staff"}
      btnType={"submit"}
      formId={"createStaffForm"}
      headerBtnText={"Save Staff"}
    >
      <Formik
        initialValues={{
          name: "",
          branchUuid: branches.length > 0 ? branches[0].uuid : "",
          designation: "",
          email: "",
          phone: "",
          salary: 0,
          role: "staff",
          shift: shifts.length > 0 ? shifts[0].uuid : "",
          commission: 5,
          isActive: true,
          holiday: 0,
          image: "",
          canLogin: false,
        }}
        enableReinitialize
        validationSchema={ValidationSchema}
        onSubmit={(values, actions) => {
          console.log(values);
          dispatch(createStaff(values))
            .then(() => {
              dispatch(sendSuccess("The staff has been created succesfully"));
              history.replace("/staff");
            })
            .catch((err) => {
              dispatch(sendFailure(err.message));
              actions.setStatus("Error! Duplicate Staff Name.");
              console.log(err.message);
            });
        }}
      >
        {({ values, errors, setFieldValue, setFieldError }) => (
          <Form className="form form-label-right" id="createStaffForm">
            <div className="form-group row">
              {/* Staff Name */}
              <div className="col-lg-6">
                <Field
                  name="name"
                  component={Input}
                  placeholder="Staff Name"
                  label="Staff Name *"
                />
              </div>
              <div className="col-lg-6">
                <Field
                  name="designation"
                  component={Input}
                  placeholder="Designation"
                  label="Designation *"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-12">
                <Select name="branchUuid" label="Branch *">
                  {branches.map((branch, index) => (
                    <option value={branch.uuid} key={index}>
                      {`${branch.name} - ${branch.city}`}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
            {/* Contact Number */}
            <div className="form-group row">
              <div className="col-lg-4">
                <Field
                  name="phone"
                  component={Input}
                  placeholder="9899898893"
                  //maxlength="10"
                  label="Contact Number *"
                />
              </div>
              <div className="col-lg-4">
                <Field
                  name="email"
                  type="email"
                  component={Input}
                  placeholder="example@domain.com"
                  label="Email Address *"
                />
              </div>
              <div className="col-lg-4">
                <Select name="role" label="Role *">
                  <option value="manager">Manager</option>
                  <option value="staff">Staff</option>
                </Select>
              </div>
            </div>
            <div className="form-group row">
              {user.calender_type === 'shift' && <div className="col-lg-4">
                <Select name="shift" label="Shift *">
                  {shifts.map((shift, index) => (
                    <option value={shift.uuid} key={index}>
                      {`${shift.shift_name} - ${dayjs(shift.start_time).format(
                        "hh:mm A"
                      )} to ${dayjs(shift.end_time).format("hh:mm A")}`}
                    </option>
                  ))}
                </Select>
              </div>}
              <div className={user.calender_type === 'shift' ? "col-lg-4" : "col-lg-6"}>
                <Field
                  name="salary"
                  type="number"
                  min="0"
                  component={Input}
                  placeholder="0.00"
                  label={`Salary  (${currencySymbol[0] ? currencySymbol[0].currencySymbol : '₹'})`}
                />
              </div>
              <div className={user.calender_type === 'shift' ? "col-lg-4" : "col-lg-6"}>
                <Field
                  name="commission"
                  type="number"
                  min="0"
                  component={Input}
                  placeholder="0.00"
                  label="Commission (%)"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-4">
                <Select name="isActive" label="Status *">
                  <option value={true}>Active</option>
                  <option value={false}>Inactive</option>
                </Select>
              </div>
              <div className="col-lg-4">
                <label>Staff Image</label>
                <input
                  type="file"
                  name="image"
                  className="form-control"
                  onChange={(e) => {
                    let file = e.target.files[0];
                    console.log(file.type);
                    if (
                      file.type == "image/jpeg" ||
                      file.type == "image/jpg" ||
                      file.type == "image/png"
                    ) {
                      if (file.size <= 500000) {
                        setFieldValue("image", e.target.files[0]);
                      } else {
                        setFieldError(
                          "image",
                          "Image size should be less than 1 MB"
                        );
                      }
                    } else {
                      setFieldError(
                        "image",
                        "Please enter a valid file format"
                      );
                    }
                  }}
                />
                {errors.image && (
                  <div className="invalid-feedback d-flex">{errors.image}</div>
                )}
              </div>
              <div className="col-lg-4">
                <Select name="holiday" label="Holiday *">
                  <option value="0">Sunday</option>
                  <option value="1">Monday</option>
                  <option value="2">Tuesday</option>
                  <option value="3">Wednesday</option>
                  <option value="4">Thursday</option>
                  <option value="5">Friday</option>
                  <option value="6">Saturday</option>
                </Select>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-4">
                <Select name="canLogin" label="Staff Login *">
                  <option value={true}>Allowed</option>
                  <option value={false}>Not Allowed</option>
                </Select>
              </div>
            </div>
            {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
          </Form>
        )}
      </Formik>
    </CardBasic>
  );
};
