import axios from "axios";

const URL = process.env.REACT_APP_API_URL + "/business/booking";
const URL2 = process.env.REACT_APP_API_URL + "/userBranch";
const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const getBookingsList = (data) => {
  const req = {
    bookingStatus: data.bookingStatus,
    from: data.from,
    to: data.to,
  };
  console.log(req);
  return axios.post(`${URL}/list`, req, {
    headers,
  });
};

export const getCustomerDetails = (phone) => {
  return axios.post(`${URL}/customer`, phone);
};

export const getPaymentDetails = (data) => {
  const req = {
    booking_uuid: data,
  };
  return axios.post(`${URL}/read`, req);
};

export const addCustomer = (data) => {
  return axios.post(`${URL}/customer/add`, data, {
    headers,
  });
};

export const createBooking = (data) => {
  return axios.post(`${URL}/create`, data, {
    headers,
  });
};

export const getAvailableTimeSlots = (date, branchUuid) => {
  const req = {
    date: date,
    branchUuid: branchUuid,
  };
  // console.log(req);
  return axios.post(`${URL}/slots/available`, req, {
    headers,
  });
};

export const bookingStatusUpdate = (bookingUuid, status) => {
  // console.log(bookingUuid, status);
  let req = {
    uuid: bookingUuid,
    status: status,
  };
  if ((status = 3)) {
    req.present = true;
  }
  // console.log(req);
  return axios.patch(`${URL2}/booking/status/update`, req, {
    headers,
  });
};

export const bookingCancle = (bookingUuid) => {
  //console.log(bookingUuid);
  let req = {
    bookingUuid: bookingUuid,
  };
  //console.log(req);
  return axios.patch(`${URL2}/booking/cancel`, req, {
    headers,
  });
};

export const getBusinessBookingData = (uuid) => {
  const req = { booking_uuid: uuid };
  return axios.post(`${URL}/read`, req);
};

export const getBranchCouponList = ()=>{
  return axios.get(`${URL2}/coupon/list`);
}

export const postBranchCouponApply = (req)=>{
  return axios.post(`${URL2}/coupon/apply`,req);
}

// BRANCH APIs
export const getBranchBookingsList = (data) => {
  const req = {
    bookingStatus: data.bookingStatus,
    from: data.from,
    to: data.to,
  };
  return axios.post(`${URL2}/booking/list`, req, {
    headers,
  });
};

export const getBranchCustomerDetails = (phone) => {
  return axios.post(`${URL2}/customer/view`, phone);
};

export const addBranchCustomer = (data) => {
  return axios.post(`${URL2}/customer/add`, data, {
    headers,
  });
};

export const createBranchBooking = (data) => {
  return axios.post(`${URL2}/booking/create`, data, {
    headers,
  });
};

export const updateBranchBooking = (data) => {
  return axios.patch(`${URL2}/booking/update`, data, {
    headers,
  });
};

export const getBranchAvailableTimeSlots = (date) => {
  const req = {
    date: date,
  };
  // console.log(req);
  return axios.post(`${URL2}/booking/slots/available`, req, {
    headers,
  });
};

export const getBookingData = (uuid) => {
  const req = { booking_uuid: uuid };
  return axios.post(`${URL2}/booking/view`, req);
};

export const createCollectPayment = (data) => {
  // console.log(data, "api");
  return axios.post(`${URL2}/transaction/create`, data, {
    headers,
  });
};
