import React from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { useHistory } from "react-router";

const HeaderTopSalon = () => {
  const history = useHistory();
  return (
    <div
      className="d-flex flex-column w-100"
      style={{ position: "fixed", zIndex: "100" }}
    >
      <div className="centerContent p-7" style={{ backgroundColor: "#007669" }}>
        <img
          className="bellaz-logo navbar-brand p-0 cursor-pointer"
          src={toAbsoluteUrl("/media/logos/goldenLogo.png")}
          alt="bellaz logo"
          onClick={() => history.push("/")}
        />
      </div>
      <div
        className=""
        style={{ backgroundColor: "#D4B55B", height: "8px" }}
      ></div>
    </div>
  );
};

export default HeaderTopSalon;
