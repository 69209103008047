import React, { useEffect, useState } from "react";

import { CardBasic } from "../../components/CardBasic";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  getBranch,
  updateBranch,
} from "../../store/business/branches/branchApi";
import { loadPackageList } from "../../store/business/packages/packageSlice";
import UploadBrancgImageDialog from "../../components/dialogs/UploadBrancgImageDialog";
import { loadBranch } from '../../store/business/branches/branchSlice';
import BranchImageDeleteDialog from "../../components/dialogs/BranchImageDeleteDialog";

export const AddImage = () => {
  const [branchData, setBranchData] = useState({});
  const dispatch = useDispatch();
  const data = useSelector((state) => state.package.packages);
  const dataBranch = useSelector((state) => state.branch.branch);
  const [dataLoading, setDataLoading] = useState(true);
  const [showDialog, setDialogStatus] = useState(false);
  useEffect(() => {
    dispatch(loadPackageList()).then(() => {
      setDataLoading(false);
    });
  }, []);
  console.log(dataBranch);
  const { branchUuid } = useParams();

  const req = {
    uuid: branchUuid,
  };

  // useEffect(() => {
  //   getBranch(req)
  //     .then((response) => {
  //       console.log(response.data.data.image);
  //       const data = response.data.data;
  //       setBranchData(data);
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //     });
  // }, []);

  useEffect(() => {
    dispatch(loadBranch(req)).then(() => {
      setDataLoading(false);
    });
  }, []);


  const images = dataBranch.image;

  console.log(images);

  if (images === []) {
    return (
      <CardBasic
        title={"Branch Images"}
        btnType={"other"}
        //formId={"updateForm"}
        headerBtnText={<UploadBrancgImageDialog content="Upload Images" branchUuid={branchUuid} role="business" />}
      >
            <div className={"d-flex justify-content-center py-10"}>
              <h3>No image found! Please add image</h3>
            </div>
      </CardBasic>
      
    );
  }
  console.log(images);
  return (
    <CardBasic
      title={"Branch Image"}
      btnType={"other"}
      //formId={"updateForm"}
      headerBtnText={<UploadBrancgImageDialog content="Upload Image" branchUuid={branchUuid} role="business" />}
    >
      {!dataLoading && images
        ?
        
        <div className="row">
        {/* {showDialog && <BranchImageDeleteDialog content="Upload Images" branchUuid={branchUuid} role="deleteImage" />} */}

          {images && images.map((el) => {
            console.log(el);
            return (
              <div className="col-lg-4" style={{ position: "relative", marginBottom: "25px" }}>
                <div >
                  <div>
                    <img src={`${process.env.REACT_APP_MEDIA_URL}/${el}`} width="300px" style={{ width: "100%" }} />
                  </div>
                  <div style={{ position: 'absolute', top: '5px', height: '30px', width: '30px', backgroundColor: ' rgb(241 241 241 / 85%)', right: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      {/* <i className="ki ki-close" type="button" style={{ cursor: "pointer", color: 'red' }} name={el} onClick={() => ActionDeleteImage(el)} /> */}
                      <BranchImageDeleteDialog content={<i className="ki ki-close" type="button" style={{ cursor: "pointer", color: 'red' }} />} name={el} uuid={branchUuid} />
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        :
        !dataLoading && images === null ?
          <div className={"d-flex justify-content-center py-10"}>
            <h3>No image found! Please add image</h3>
          </div> :
          <div className={"d-flex justify-content-center py-10"}>
            <h3>Loading Data...</h3>
          </div>}
    </CardBasic>
    
  );
};
