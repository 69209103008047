import axios from "axios";

const URL = process.env.REACT_APP_API_URL;
const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

// export const getBusinessReport = () => {
//   return axios.get(`${URL}/business/report`);
// };

// export const getSalesReport = () => {
//   return axios.get(`${URL}/business/booking/salesreport`);
// };

// export const getTopPackages = () => {
//   return axios.get(`${URL}/business/report/packages`);
// };

// export const getTopServices = () => {
//   return axios.get(`${URL}/business/report/services`);
// };

// export const getBusinessReportBranch = () => {
//   return axios.get(`${URL}/business/report/branch`);
// };

export const getBusinessReport = (date) => {
  const req = {
    "from": date.to,
    "to": date.from
  };
  return axios.post(`${URL}/business/report`, req);
};

export const getSalesReport = (date) => {
  const req = {
    "from": date.to,
    "to": date.from
  };
  return axios.post(`${URL}/business/booking/salesreport`, req);
};

export const getTopPackages = (date) => {
  const req = {
    "from": date.to,
    "to": date.from
  };
  return axios.post(`${URL}/business/report/packages`, req);
};

export const getTopServices = (date) => {
  const req = {
    "from": date.to,
    "to": date.from
  };
  return axios.post(`${URL}/business/report/services`, req);
};

export const getBusinessReportBranch = (date) => {
  const req = {
    "from": date.to,
    "to": date.from
  };
  return axios.post(`${URL}/business/report/branch`, req);
};

export const getBusinessSalesReport = (date) => {
  console.log(date);
  const req = {
    "from": date.to,
    "to": date.from
  };
  console.log(req);
  return axios.post(`${URL}/business/booking/salesreport`, req);
};

export const getBusinessCustomerReport = (date) => {
  console.log(date);
  const req = {
    "from": date.to,
    "to": date.from
  };
  console.log(req);
  return axios.post(`${URL}/business/report/client`, req);
};

export const getBusinessStuffReport = (date) => {
  console.log(date);
  const req = {
    "from": date.to,
    "to": date.from
  };
  console.log(req);
  return axios.post(`${URL}/business/report/staff`, req);
};