import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import filterFactory from "react-bootstrap-table2-filter";
import { sortCaret } from "../../_metronic/_helpers";

import {
  StatusColumnFormatter,
  TransactionColumnFormatter,
} from "../../_metronic/_helpers/tableColumnFormatter";

import paginationFactory from "react-bootstrap-table2-paginator";

import { loadSubscriptionTransactions } from "../../store/business/subscriptionPlan/subscriptionPlanSlice";
import dayjs from "dayjs";

export const TransactionTable = () => {

  const transactions = useSelector((state) => state.subscriptionPlan.transactions);
  const [dataLoading, setDataLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadSubscriptionTransactions()).then((res) => {
      setDataLoading(false);
    });
  }, []);

  const DateFormatter = (cell, row) => {
    return dayjs(cell).format("DD-MM-YYYY @ hh:mm:ss");
  };

  const columns = [
    {
      dataField: "date",
      text: "Date",
      sort: true,
      sortCaret: sortCaret,
      formatter: DateFormatter,
    },
    {
      dataField: "transactionId",
      text: "Transaction Id",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "Subscription[0].name",
      text: "Plan",
    },
    {
      dataField: "cycle",
      text: "Cycle",
      formatter: (cell, row) => {
        return <span style={{textTransform: 'capitalize'}}>{cell}</span>
      }
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return <span style={{textTransform: 'uppercase'}}>{row.currency} {cell}</span>
      }
    },

    {
      dataField: "status",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      formatter: TransactionColumnFormatter,
    },
  ];

  return (
    <ToolkitProvider
      search
      keyField="id"
      data={transactions}
      columns={columns}
      autoValue
    >
      {(props) => (
        <>
          <BootstrapTable
            {...props.baseProps}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            wrapperClasses="table-responsive"
            bordered={false}
            insertRow
            bootstrap4
            pagination={paginationFactory()}
            filter={filterFactory()}
            noDataIndication={
              <div className={"d-flex justify-content-center py-10"}>
                <h3>{dataLoading ? "Loading Data..." : "No records found"}</h3>
              </div>
            }
          />
        </>
      )}
    </ToolkitProvider>
  );
};
