import React from "react";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import BookOnlineIcon from '@material-ui/icons/Book';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import DescriptionIcon from '@material-ui/icons/Description';
import UpdateIcon from '@material-ui/icons/Update';
import BusinessIcon from '@material-ui/icons/Business';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

function LegalInformation() {
  return (
    <div className="p-0 m-0" style={{ background: ' #f1f3f5' }}>
      <div
        className="container-fluid bg-dark d-flex flex-column justify-content-center align-items-center"
        style={{ height: "220px", borderRadius: "10px" }}
      >
        <h2 className="display-4 text-white mb-5"><DescriptionIcon className="mr-5 display-4 mb-2" />Website Legal Information</h2>
      </div>
      <div
        className="container bg-white p-12"
        style={{ width: "90%", marginTop: "-40px", borderRadius: "10px" }}
      >
        {/* <div className="mb-8">
          <h2 className="mb-4 display-5"><BusinessIcon className="display-4 pr-2 mb-2" />Welcome to BELLAZ’s PRIVACY POLICY</h2>
          <p className="h5" style={{ color: "black" }}>We at Bellaz’s work towards attaining 'Customer Delight', - a step further to achieving 'Customer Satisfaction', and thus, we have put forward a very simple and customer-focused cancellation and refund policy.</p>
        </div> */}
        <div className="mb-8">
          <h2 className="mb-4 display-5">Company registration details</h2>
          <p className="h5" style={{ color: "black" }}> Bellaz  Registered Office: B 5/6, Suphala Co-op. Housing Society, B Building, 1st Floor Behind K.C Gandhi School, Kalyan (West) 421 301 Dist. Thane, Maharashtra. My-bellaz and the logo are registered trademarks.</p>
        </div>
        <div className="mb-8">
          <h2 className="mb-4 display-5">Jurisdiction for use</h2>
          <p className="h5" style={{ color: "black" }}>This website is intended for use only by those who can access it from within India & Middle East. Services  advertised on these pages are only intended for sale to India and Middle East.</p>
        </div>
        <div className="mb-8">
          <h2 className="mb-4 display-5">Governing law</h2>
          <p className="h5" style={{ color: "black" }}>This site is governed by the laws of the country of the India within which you reside and any dispute or action arising out of this site shall be determined in accordance with Indian laws.</p>
        </div>
        <div className="mb-8">
          <h2 className="mb-4 display-5">Contracts</h2>
          <p className="h5" style={{ color: "black" }}>Bellaz (and its third parties and associated companies) will keep a copy of your contract once it is signed and returned. This will be available on request (though there may be a charge for this service).</p>
        </div>
        <div className="mb-8">
          <h2 className="mb-4 display-5">All contracts and applications are written in English.</h2>
        </div>
        <div className="mb-8">
          <h2 className="mb-4 display-5">Contacts</h2>
          <p className="h5" style={{ color: "black" }}>If you believe any information on this site is inaccurate or have any suggestions on how the content of this site could be improved, please contact us</p>
          <p className="h5" style={{ color: "black" }}>If you have questions on a particular product or service, please use the contact details shown for that product or service.</p>
        </div>
        <div className="mb-8">
          <h2 className="mb-4 display-5">Changes</h2>
          <p className="h5" style={{ color: "black" }}>We will update the content of this notice from time to time. Please ensure that you visit this page regularly and refresh your browser to ensure your information is up to date.</p>
        </div>
      </div>
    </div>
  );
}

export default LegalInformation;
