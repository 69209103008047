import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Formik, Form, Field, useFormikContext } from "formik";
import * as Yup from "yup";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Input } from "../../_metronic/_partials/controls";
import { ProfileUpdateData } from "../../store/business/authSlice";
import { branchProfileUpdateData } from "../../store/business/authSlice";
import { sendFailure, sendSuccess } from "../../store/toast/toastSlice";

export default function ChangePasswordDialog({ content, role }) {
  console.log(role);
  const [open, setOpen] = useState(false);
  const [status, setstatus] = useState("");

  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    password: Yup.string().min(
      8,
      "Password is too short - should be 8 chars minimum."
    ).required("This field is required"),
    c_password: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ).required("This field is required"),

    // terms: Yup.bool().oneOf([true],"You must accept the terms and conditions"),
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <span className="navi-text" onClick={handleClickOpen}>
        {content}
      </span>

      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Change Password</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              password: "",
              c_password: "",
              // terms: false,
            }}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              console.log(values);
              if(role === "branch") {
                dispatch(branchProfileUpdateData(values))
                .then((res) => {
                  dispatch(sendSuccess("The branch password has been updated succesfully"));
                  setOpen(false);
                })
                .catch((err) => {
                  dispatch(sendFailure());
                  actions.setStatus(err.message);
                  console.log(err.message);
                });
              actions.setFormStatus("submitted");
              } else {
                dispatch(ProfileUpdateData(values))
                .then((res) => {
                  console.log(res);
                  dispatch(sendSuccess("The business password has been updated succesfully"));
                  setOpen(false);
                })
                .catch((err) => {
                  console.log(err);
                  dispatch(sendFailure());
                  actions.setStatus(err.message);
                  console.log(err.message);
                });
              actions.setFormStatus("submitted");
              }
              
            }}
          >
            {({ values, errors, touched, status }) => (
              <Form className="form form-label-right" id="createBranchForm">
                {status && (
                  <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                    <div className="alert-text font-weight-bold">{status}</div>
                  </div>
                )}
                <div className="form-group row">
                  <div className="col">
                    <Field
                      name="password"
                      component={Input}
                      placeholder=""
                      label="New Password *"
                      type="password"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col">
                    <Field
                      name="c_password"
                      type="password"
                      component={Input}
                      placeholder=""
                      label="Confirm Password *"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col">
                    <button
                      type="submit"
                      className="btn btn-light-success float-right"
                    >
                      Change Password
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}
