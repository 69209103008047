import React from "react";

import { SubscriptionTable } from "./table";
import { CardBasic } from "../../components/CardBasic";

export function CouponsPage() {
  return (
    <CardBasic
      title={"Coupons"}
      btnType={"button"}
      headerBtnText={"Add Coupon"}
      headerBtnLink={"/coupon/create"}
    >
      <SubscriptionTable />
    </CardBasic>
  );
}
