import React, { useState } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Input } from "../../_metronic/_partials/controls";
import { loadBranchCustomer } from "../../store/business/bookings/bookingSlice";

export default function SearchCustomerDialog({ content }) {
  const [open, setOpen] = useState(false);
  const [itemError, setItemError] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = Yup.object().shape({
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(4, "Enter 4 digit phone number")
      .max(20, "Enter 20 digit phone number")
      .required("This field is required"),
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const { role } = useSelector((state) => state.authPersist);
  return (
    <div>
      <span className="navi-text" onClick={handleClickOpen}>
        {content}
      </span>

      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Search Customer</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              phone: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              if (role === "business") {
                console.log(values);
                dispatch(loadBranchCustomer(values))
                  .then((response) => {
                    console.log(response);

                    if (response != "") {
                      setItemError("");
                      setOpen(false);
                    } else {
                      setItemError(
                        "Customer not found. Kindly check the registered phone number."
                      );
                    }
                  })
                  .catch((err) => {
                    actions.setStatus(err.message);
                    console.log(err.message);
                  });
                actions.setFormStatus("submitted");
              } else {
                console.log(values);
                dispatch(loadBranchCustomer(values))
                  .then((response) => {
                    console.log(response);

                    if (response != "") {
                      setItemError("");
                      setOpen(false);
                    } else {
                      setItemError(
                        "Customer not found. Kindly check the registered phone number."
                      );
                    }
                  })
                  .catch((err) => {
                    actions.setStatus(err.message);
                    console.log(err.message);
                  });
              }
            }}
          >
            {({ values, errors, touched }) => (
              <Form className="form form-label-right" id="createBranchForm">
                {itemError && (
                  <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                    <div className="alert-text font-weight-bold">
                      {itemError}
                    </div>
                  </div>
                )}
                <div className="form-group row">
                  <div className="col">
                    <Field
                      name="phone"
                      component={Input}
                      placeholder="9899898893"
                      label="Enter Customer's Contact Number *"
                      //maxLength="10"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col">
                    <button
                      type="submit"
                      className="btn btn-light-success float-right"
                    >
                      Search Customer
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}
