import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SpinnerBasic } from "../components/spinners/SpinnerBasic";
import { getBusinessSalesReport } from "../store/business/reports/reportApi";
import { TilesWidget11 } from "../_metronic/_partials/widgets";

export const Sales = (props) => {
  const { role, user } = useSelector((state) => state.authPersist);
  const [businessReportData, setBusinessReportData] = useState({});
  const [dataLoading, setDataLoading] = useState(true);
  const { countryCode } = useSelector((state) => state.countryCode);
  // console.log(user);
  console.log(countryCode);
  const currencySymbol = countryCode.filter((el) => el.countryCode === user.countryCode);
  console.log(currencySymbol);

  //console.log(role);
  useEffect(() => {
    setDataLoading(true);
    if (role == "business") {
      getBusinessSalesReport(props.dateData).then((response) => {
        console.log(response);
        setBusinessReportData(response.data.data);
        setDataLoading(false);
      });
    }
  }, [props.dateData]);

  return (
    <>
      {role === "business" && (
        <>
          {dataLoading ? (
            <div style={{ height: '600px', paddingTop: '200px' }}>
              <SpinnerBasic />
            </div>
          ) : (
            <>
              <div className="row">
                {/* Bookings Report */}
                <div className="col-xl-4">
                  <div className="row">
                    <div className="col-12">
                      <TilesWidget11
                        className="gutter-b"
                        baseColor="primary"
                        widgetHeight="150px"
                        count={businessReportData.totalSales}
                        title="Total Sales"
                        svgUrl="/media/svg/icons/General/Thunder-move.svg"
                        linkUrl={`/bookings/completed/${props.date}`}
                      />
                    </div>
                    <div className="col-sm-6">
                      <TilesWidget11
                        className="gutter-b"
                        baseColor="primary"
                        widgetHeight="150px"
                        count={businessReportData.activeBookingSales}
                        title="Active Sales"
                        svgUrl="/media/svg/icons/General/Thunder.svg"
                        linkUrl="/booking/present"
                      />
                    </div>
                    <div className="col-sm-6">
                      <TilesWidget11
                        className="gutter-b"
                        baseColor="primary"
                        widgetHeight="150px"
                        count={businessReportData.averageSales.toFixed(2)}
                        title="Avg Sale"
                        svgUrl="/media/svg/icons/General/Thunder.svg"
                        linkUrl={`/bookings/completed/${props.date}`}
                      />
                    </div>
                  </div>
                </div>
                {/* Service & Package Report */}
                <div className="col-xl-4">
                  <div className="row">
                    <div className="col-sm-6">
                      <TilesWidget11
                        className="gutter-b"
                        iconColor="success"
                        widgetHeight="150px"
                        count={`${currencySymbol[0] ? currencySymbol[0].currencySymbol : '₹'}${businessReportData.tax}`}
                        title="Tax"
                        svgUrl="/media/svg/icons/Shopping/Chart-bar1.svg"
                        linkUrl="/booking/tax"
                      />
                    </div>
                    {/* <div className="col-sm-6">
                      <TilesWidget11
                        className="gutter-b"
                        iconColor="success"
                        widgetHeight="150px"
                        count={businessReportData.discount}
                        title="discount"
                        svgUrl="/media/svg/icons/General/Star.svg"
                        linkUrl="/packages"
                      />
                    </div> */}
                    <div className="col-sm-6">
                      <TilesWidget11
                        className="gutter-b"
                        iconColor="success"
                        widgetHeight="150px"
                        count={0}
                        title="Refund"
                        svgUrl="/media/svg/icons/Shopping/Gift.svg"
                        linkUrl="/payment"
                      />
                    </div>
                    <div className="col-sm-6">
                      <TilesWidget11
                        className="gutter-b"
                        iconColor="success"
                        widgetHeight="150px"
                        count={businessReportData.discount}
                        title="Discount"
                        svgUrl="/media/svg/icons/Shopping/Calculator.svg"
                        linkUrl="/payment"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xl-4">
                  <div className="row">
                    <div className="col-12">
                      <TilesWidget11
                        className="gutter-b"
                        baseColor="primary"
                        widgetHeight="150px"
                        count={businessReportData.completedBookings}
                        title="Completed Bookings"
                        //count={`₹${businessReportData.tax}`}
                        //title="Tax"
                        svgUrl="/media/svg/icons/Shopping/Cart3.svg"
                        linkUrl={`/bookings/completed/${props.date}`}
                      />
                    </div>
                    <div className="col-sm-6">
                      <TilesWidget11
                        className="gutter-b"
                        baseColor="primary"
                        widgetHeight="150px"
                        count={businessReportData.pendingBookings}
                        title="Pending"
                        svgUrl="/media/svg/icons/Shopping/Cart3.svg"
                        linkUrl="/bookings/upcoming"
                      />
                    </div>
                    <div className="col-sm-6">
                      <TilesWidget11
                        className="gutter-b"
                        iconColor="success"
                        widgetHeight="150px"
                        count={businessReportData.cancelledBooking}
                        title="Cancelled"
                        svgUrl="/media/svg/icons/Shopping/Cart3.svg"
                        linkUrl={`/bookings/cancelled/${props.date}`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
