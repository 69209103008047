import axios from "axios";

const URL = process.env.REACT_APP_API_URL + "/userBranch/chairs";
const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const getBranchChairList = () => {
  return axios.get(`${URL}/list`);
};
