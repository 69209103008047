import axios from "axios";

const URL = process.env.REACT_APP_API_URL + "/business";
const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const getCategoryList = () => {
  return axios.get(`${URL}/expense/category/list`);
};

export const createCategory = (data) => {
  return axios.post(`${URL}/expense/category/create`, data);
};

export const getCategory = async (req) => {
  return await axios.post(`${URL}/expense/category/view`, req);
};

export const updateCategory = (data) => {
  console.log(data);
  return axios.patch(`${URL}/expense/category/update`, data);
};
