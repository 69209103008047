import React from "react";
import LocalPhoneIcon from "@material-ui/icons/LocalPhone";
import AddLocationAltIcon from "@material-ui/icons/AddLocation";
import EmailIcon from "@material-ui/icons/Email";
import PermPhoneMsgIcon from "@material-ui/icons/PermPhoneMsg";

const data = [
  {
    icon: <AddLocationAltIcon className="pr-2 h2" />,
    heading: "Address",
    content: "129,Lorem ipssum dolor sit amet elit",
  },
  {
    icon: <EmailIcon className="pr-2 h2" />,
    heading: "Email",
    content: "demo@gmail.com",
  },
  {
    icon: <LocalPhoneIcon className="pr-2 h2" />,
    heading: "Contact Number",
    content: "+91 9898989898",
    content2: "+91 9898989898",
  },
];
function Contact() {
  return (
    <div className="container-fluid p-0 m-0">
      <div
        className="container-fluid bg-dark d-flex justify-content-center align-items-center"
        style={{ height: "220px", borderRadius: "10px" }}
      >
        <h2 className="display-4 text-white">
          <PermPhoneMsgIcon className="mr-5 display-4" />
          Contact
        </h2>
      </div>
      <div
        className="container bg-white p-12"
        style={{ width: "90%", marginTop: "-40px", borderRadius: "10px" }}
      >
        <div className="container-fluid row justify-content-between m-0 p-0 mb-10">
          {data.map((s) => {
            return (
              <div
                className="col-lg-3 col-md-3 d-flex justify-content-start align-items-start text-left p-5 flex-column mr-2"
                style={{
                    border: "1px solid lightgray",
                  borderRadius: "12px",
                  boxShadow:" 0 1px 2px rgba(0, 0, 0, 0.075), 0 2px 4px rgba(0, 0, 0, 0.0375)",
                }}
              >
                <h2 className="h4 mb-2">
                  {s.icon}
                  {s.heading}
                </h2>
                <p>{s.content}</p>
                <p>{s.content2}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Contact;
