import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import SVG from "react-inlinesvg";
import { useDispatch } from "react-redux";
import { sendFailure, sendSuccess } from "../../store/toast/toastSlice";
import { deleteHoliday } from "../../store/business/holiday/holidayApi";
import { deleteRa } from '../../store/branch/ra/raAPi';
import { updateRaList } from '../../store/branch/ra/raSlice';
import { updateHolidayBookingList } from '../../store/business/holiday/branchesHolidaySlice';
import { deletePo } from '../../store/branch/po/poAPi';
import { updatePoList } from '../../store/branch/po/poSlice';
import { updateBookingStatus } from "../../store/business/bookings/bookingSlice";

export default function DeleteRaAndPoDialog({
  content,
  uuid,
  role,
  text,
  svg
}) {

  console.log(content.text);
  console.log(uuid);
  console.log(text);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const Confirm = () => {
    if (role === 'ra') {
      console.log("RA");
      deleteRa(uuid)
        .then((response) => {
          console.log(response);
          dispatch(updateRaList(uuid)).then(() => {
            dispatch(sendSuccess(
              "The RA has been deleted successfully"
            ));
          });
          setOpen(false);
        })
        .catch((err) => {
          dispatch(sendFailure(content.errorMessage));
          console.log(err.message);
        });
    } else {
      deletePo(uuid)
        .then((response) => {
          console.log(response);
          dispatch(updatePoList(uuid)).then(() => {
            dispatch(sendSuccess(
              "The PO has been deleted successfully"
            ));
          });
          setOpen(false);
        })
        .catch((err) => {
          dispatch(sendFailure(content.errorMessage));
          console.log(err.message);
        });
    }
  };

  return (
    <div>
      <span className="navi-text" onClick={handleClickOpen}>
        {content}
      </span>

      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{content.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button onClick={Confirm} className="btn btn-light-success">
            Yes, Continue.
          </button>
          <button onClick={handleClose} className="btn btn-danger">
            No, Go back.
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
