import React from "react";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import "./BellazMain.css";

const data = [
  {
    name: "Waxing",
    img: "/media/BellazMain/cardImages/Layer 3.jpg",
    svg: "/media/BellazMain/svg icons/wax.svg",
  },
  {
    name: "Manicure & Pedicure",
    img: "/media/BellazMain/cardImages/Layer 4.jpg",
    svg: "/media/BellazMain/svg icons/pedicure.svg",
  },
  {
    name: "Facial",
    img: "/media/BellazMain/cardImages/Layer 1.jpg",
    svg: "/media/BellazMain/svg icons/facial-mask.svg",
  },
  {
    name: "Spa",
    img: "/media/BellazMain/cardImages/Layer 2.jpg",
    svg: "/media/BellazMain/svg icons/spa.svg",
  },
];

function BellazCards() {
  return (
    <div className="container-fluid d-flex align-items-center p-0 bellazCards mt-5">
      <div className="row vw-100 m-auto">
        {data.map((_data) => (
          <div className="card cardMain col-lg-3 col-md-3 col-sm-6 col-6">
            <img src={toAbsoluteUrl(_data.img)} alt="" />
            <div className="card-content align-items-center d-flex h-100 flex-column justify-content-center">
              <SVG
                className="card-hoverImg img-fluid"
                src={toAbsoluteUrl(_data.svg)}
              ></SVG>
              <p>{_data.name}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default BellazCards;
