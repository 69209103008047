import axios from "axios";

const URL = process.env.REACT_APP_API_URL + "/admin/service";
const token = process.env.REACT_APP_ADMIN_TOKEN;
const Authorization = "Bearer " + token;

const headers = {
  Authorization,
  "Content-Type": "multipart/form-data",
  Accept: "application/json",
};

export const getCategoryList = () => {
  return axios.get(`${URL}/categories`, {
    headers: {
      Authorization,
    },
  });
};

export const getCategory = async (req) => {
  return await axios.post(`${URL}/category/view`, req, {
    headers: {
      Authorization,
    },
  });
};

export const createCategory = (data) => {
  const req = new FormData();

  req.append("name", data.name);
  req.append("image", data.image);

  return axios.post(`${URL}/category/create`, req, {
    headers,
  });
};

export const updateCategory = (data, uuid) => {
  const req = new FormData();

  req.append("uuid", uuid);
  req.append("name", data.name);
  req.append("isActive", data.isActive);
  data.image && req.append("image", data.image);

  return axios.patch(`${URL}/category/update`, req, {
    headers,
  });
};
