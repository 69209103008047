import React from "react";
import BellazLastCarousel from "./BellazLastCarousel";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

function BellazRated() {
    return (
      
      <div className="business container-fluid w-100 d-flex flex-column justify-content-between align-items-center p-0 text-center position-relative">
        <img
            className="img-fluid position-absolute flower8 d-none d-lg-flex"
            src={toAbsoluteUrl("/media/BellazMain/svg icons/flower 2.png")}
          />
          <img
            className="img-fluid position-absolute flower9 d-none d-lg-flex"
            src={toAbsoluteUrl("/media/BellazMain/svg icons/flower 2.png")}
          />
           <img
            className="img-fluid position-absolute flower10 d-none d-lg-flex"
            src={toAbsoluteUrl("/media/BellazMain/svg icons/flower 2.png")}
          />
          <img
            className="img-fluid position-absolute flower11 d-none d-lg-flex"
            src={toAbsoluteUrl("/media/BellazMain/svg icons/flower-1.png")}
          />
        <div className="container business-content rated justify-content-center align-items-center d-flex flex-column">
          <h1 className="text-black display-4 mb-5">Top Rated Salons</h1>
          <h6 className="text-black-50">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt utlabore et dolore magna aliqua.
          </h6>
        </div>
      <BellazLastCarousel/>
      </div>
    )
}

export default BellazRated
