import axios from "axios";

const URL = process.env.REACT_APP_API_URL + "/business";
const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const getPackageList = () => {
  return axios.get(`${URL}/packages`);
};

export const getPackage = async (req) => {
  return await axios.post(`${URL}/package/view`, req);
};

export const createPackage = (data) => {
  
  return axios.post(`${URL}/package/create`, data, {
    headers,
  });
};

export const updatePackage = (data, uuid) => {
  data.uuid = uuid;
  console.log(data);

  return axios.patch(`${URL}/package/update`, data, {
    headers,
  });
};
