import React from "react";

import { CategoryTable } from "./table";
import { CardBasic } from "../../../components/CardBasic";
import { useSelector } from "react-redux";

export function ExpenseCategoryPage() {
  const { role, user } = useSelector((state) => state.authPersist);
  return (
    <CardBasic
      title={"Category List"}
      btnType={"button"}
      headerBtnText={role === "business" ? "Add Category" : null}
      headerBtnLink={role == "business" ? "/expense/category/create" : null}
    >
      <CategoryTable />
    </CardBasic>
  );
}
