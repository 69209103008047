import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray, useFormikContext } from "formik";
import * as Yup from "yup";

import {
  Input,
  Select,
  DatePickerField,
} from "../../_metronic/_partials/controls";

import { CardBasic } from "../../components/CardBasic";
import { useDispatch, useSelector } from "react-redux";
import { loadBranchList } from "../../store/business/branches/branchSlice";
import { loadChairList } from "../../store/business/chairs/chairSlice";
import { loadStaffList } from "../../store/business/staff/staffSlice";
import { loadServiceList } from "../../store/business/services/servicesSlice";
import { loadPackageList } from "../../store/business/packages/packageSlice";
import { useHistory, useParams } from "react-router";
import { getAvailableTimeSlots } from "../../store/business/bookings/bookingApi";
import dayjs from "dayjs";
import { sendFailure, sendSuccess } from "../../store/toast/toastSlice";
import { getBusinessBookingData } from "../../store/business/bookings/bookingApi";
import { updateBranchBookingData } from "../../store/business/bookings/bookingSlice";
import { getCountryCodeList } from "../../store/countryCode/countryCodeSlice";

export const BookingView = () => {
  const { role, user } = useSelector((state) => state.authPersist);
  const { countryCode } = useSelector((state) => state.countryCode);
  const currencySymbol = countryCode.filter((el) => el.countryCode === user.countryCode);
  const { bookingUuid } = useParams();
  //console.log(bookingUuid);
  const [itemError, setItemError] = useState("");
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  const [bookingData, setBookingData] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const [dataLoading, setDataLoading] = useState(true);
  //console.log(bookingData);

  
  useEffect(() => {
    dispatch(getCountryCodeList());
  }, []);

  const branches = useSelector((state) => {
    if (role === "business") {
      return state.branch.branches.filter((data) => {
        return data.isActive === true;
      });
    }
  });

  const staff = useSelector((state) => {
    if (role === "business") {
      return state.staff.staff.filter((singleStaff) => {
        return singleStaff.isActive === true;
      });
    }
  });

  const { currentCustomer } = useSelector((state) => state.booking);
  const { chairs } = useSelector((state) => state.chair);
  //console.log(chairs);

  const branchChairs = useSelector((state) => state.branchChairs.branchChairs);
  //console.log(branchChairs[0]);

  const services = useSelector((state) => {
    if (role === "business") {
      return state.service.services.filter((data) => {
        return data.isActive === true;
      });
    }
  });
  //console.log(services);
  const packages = useSelector((state) => {
    if (role === "business") {
      return state.package.packages.filter((data) => {
        return data.isActive === true;
      });
    }
  });

  let emptyServiceRow = {
    serviceUuid: services.length > 0 ? services[0].uuid : "",
    price: services.length > 0 ? services[0].price : "",
  };

  console.log(packages);
  let emptyPackageRow = {
    packageUuid: packages.length > 0 ? packages[0].uuid : "",
    price: packages.length > 0 ? packages[0].price : "",
  };
  //console.log(emptyPackageRow);

  const getTimeSlots = (date, branchUuid = null) => {
    //console.log("getting time slots");

    if (role == "business") {
      console.log("business time slot");
      getAvailableTimeSlots(date, branchUuid)
        .then((response) => {
          //console.log(response.data.data.timeSlots);
          setAvailableTimeSlots(response.data.data.timeSlots);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };

  useEffect(() => {
    if (branches.length === 0) {
      if (role == "business") {
        dispatch(loadBranchList()).then(() => {
          setDataLoading(false);
        });
      } else {
        setDataLoading(false);
      }
    }
    if (branches.length > 0) {
      if (role == "business") {
        //console.log("initial time slots");
        getAvailableTimeSlots(new Date(), branches[0].uuid);
      } else {
        //console.log("initial time slots");
        getAvailableTimeSlots(new Date(), user.uuid);
        return;
      }
    }
  }, []);

  useEffect(() => {
    if (chairs.length === 0) {
      if (role === "business") {
        dispatch(loadChairList()).then(() => {
          setDataLoading(false);
        });
      }
    }
  }, []);

  useEffect(() => {
    if (staff.length === 0) {
      if (role == "business") {
        dispatch(loadStaffList()).then(() => {
          setDataLoading(false);
        });
      }
    }
  }, []);

  useEffect(() => {
    if (services.length === 0) {
      if (role === "business") {
        dispatch(loadServiceList()).then(() => {
          setDataLoading(false);
          emptyServiceRow = {
            serviceUuid: services.length > 0 ? services[0].uuid : "",
            price: services.length > 0 ? services[0].price : "",
          };
        });
      }
    }
  }, []);
  //console.log(services);

  useEffect(() => {
    if (packages.length === 0) {
      if (role === "business") {
        dispatch(loadPackageList()).then(() => {
          setDataLoading(false);
          emptyPackageRow = {
            packageUuid: packages.length > 0 ? packages[0].uuid : "",
            price: packages.length > 0 ? packages[0].price : "",
          };
        });
      }
    }
  }, []);

  useEffect(() => {
    if (role === "business") {
      getBusinessBookingData(bookingUuid)
        .then((response) => {
          // console.log(response.data.data);
          const data = response.data.data;
          // console.log(data);
          setBookingData(data);
          getTimeSlots(data.date, data.branchUuid);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }, []);
  // console.log(bookingData);

  // Validation schema
  const ValidationSchema = Yup.object().shape({
    userPhone: Yup.string().required(
      "This field is required. You must choose a customer to add booking."
    ),
    date: Yup.date().required("This field is required"),
    startTime: Yup.string().required("This field is required"),
    duration: Yup.number().required("This field is required"),
    chairUuid: Yup.string().required("This field is required"),
    staffUuid: Yup.string().required("This field is required"),
    remarks: Yup.string(),
    subTotal: Yup.number().required("This field is required"),
    tax: Yup.number(),
    discount: Yup.number().required("This field is required"),
    total: Yup.number().required("This field is required"),
  });

  // console.log(availableTimeSlots);

  return (
    <CardBasic
      title={"View Booking"}
      formId={"createBookingForm"}
      headerBtnText={"Update Booking"}
    >
      <Formik
        initialValues={{
          userPhone: bookingData.customer ? bookingData.customer.name : "",
          phone: bookingData.customer ? bookingData.customer.phone : "",
          branchUuid: branches.length > 0 ? branches[0].uuid : "",
          date: bookingData.date ? new Date(bookingData.date) : "",
          startTime: bookingData.startTime
            ? `2021-10-04${bookingData.startTime.slice(10)}`
            : "",
          duration: bookingData.duration ?? "",
          chairUuid: bookingData.chairUuid ?? "",
          remarks: bookingData.remarks ?? "",
          staffUuid: bookingData.staffUuid ?? "",
          services: bookingData.services ?? [emptyServiceRow],
          packages: bookingData.packages ?? [emptyPackageRow],
          subTotal: bookingData.subTotal ?? "",
          tax: bookingData.tax ?? "",
          discount: bookingData.discount ?? "",
          total: bookingData.total ?? "",
        }}
        enableReinitialize
        validationSchema={ValidationSchema}
        onSubmit={
          (values, errors) => {
            // console.log(values);
          }

          // saveCustomer(values);
        }
      >
        {({ values, errors, setFieldValue, touched }) => (
          <Form className="form form-label-right" id="createBookingForm">
            {itemError && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">{itemError}</div>
              </div>
            )}
            <div className="form-group row">
              <div className="col-lg-12">
                <Select disabled name="userPhone" label="Customer *">
                  {bookingData.customer && (
                    <option
                      value={bookingData.customer ?? bookingData.customer.phone}
                      defaultValue
                    >{`${bookingData.customer.name} - ${bookingData.customer.phone}`}</option>
                  )}
                </Select>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-6">
                <Select
                  disabled
                  name="branchUuid"
                  label="Branch *"
                  onChange={(e) => {
                    setFieldValue("branchUuid", e.target.value);
                    setFieldValue("date", "");
                    setAvailableTimeSlots([]);
                  }}
                >
                  {branches.map((branch, index) => (
                    <option value={branch.uuid} key={index}>
                      {`${branch.name} - ${branch.city}`}
                    </option>
                  ))}
                </Select>
              </div>

              <div className="col-lg-6">
                <Select disabled name="chairUuid" label="Chair *">
                  {role == "business"
                    ? chairs.map((chair, index) => (
                        <option value={chair.uuid} key={index}>
                          {/* {console.log(chair.uuid)} */}
                          {chair.chairName}
                        </option>
                      ))
                    : branchChairs.map((chairData, index) => (
                        <option value={chairData.uuid} key={index}>
                          {/* {console.log(chairData.uuid)} */}
                          {chairData.chairName}
                        </option>
                      ))}
                </Select>
              </div>
              <div className="col-lg-6">
                <Select disabled name="staffUuid" label="Staff *">
                  {staff.map((singleStaff, index) => (
                    <option value={singleStaff.uuid} key={index}>
                      {singleStaff.name}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
            {/* Contact Number */}
            <div className="form-group row">
              <div className="col-lg-4">
                <div>
                  <label>Booking Date *</label>
                </div>
                <div className="w-100">
                  <DatePickerField
                    disabled
                    name="date"
                    className="form-control"
                    minDate={new Date()}
                    format="MM/dd/yyyy"
                    value={values.date}
                    onChange={(value) => {
                      console.log("date changed");
                      getTimeSlots(value, values.branchUuid);
                      setFieldValue("date", value);
                    }}
                  ></DatePickerField>
                </div>
              </div>
              <div className="col-lg-4">
                <div>
                  <Select disabled name="startTime" label="Start Time *">
                    <option value="">Select Start Time</option>
                    {role === "business"
                      ? availableTimeSlots.map((timeSlot, index) => (
                          <option value={timeSlot.slotTiming} key={index}>
                            {dayjs(timeSlot.slotTiming).format("hh:mm A")}
                          </option>
                        ))
                      : availableTimeSlots.map((timeSlot, index) => (
                          <option value={timeSlot.slotTiming} key={index}>
                            {dayjs(timeSlot.slotTiming).format("hh:mm A")}
                          </option>
                        ))}
                  </Select>
                </div>
              </div>
              <div className="col-lg-4">
                <Field
                  disabled
                  name="duration"
                  type="number"
                  min="30"
                  step="30"
                  component={Input}
                  placeholder="30"
                  label="Duration (Minutes) *"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-12">
                <label>Booking Remarks</label>
                <Field
                  disabled
                  name="remarks"
                  as="textarea"
                  rows="2"
                  className="form-control"
                />
              </div>
            </div>
            <h3 className="mt-10">Services</h3>
            <hr />
            <div className="form-group row mb-0">
              <div className="col-8">
                <label>Service</label>
              </div>
              <div className="col-4">
                <label>Price {`(${currencySymbol[0] ? currencySymbol[0].currencySymbol : '₹'})`}</label>
              </div>
            </div>
            <FieldArray name="services">
              {({ push, remove }) => (
                <>
                  {values.services.map((_, index) => (
                    <div className="form-group row" key={index}>
                      <div className="col-8">
                        <Select
                          disabled
                          name={`services.[${index}].serviceUuid`}
                          onChange={(e) => {
                            setFieldValue(
                              `services.[${index}].serviceUuid`,
                              e.target.value
                            );
                            setFieldValue(
                              `services.[${index}].price`,
                              String(
                                services
                                  .filter((service) => {
                                    return service.uuid === e.target.value;
                                  })
                                  .map((service) => {
                                    return service.price;
                                  })
                              )
                            );
                          }}
                        >
                          {services.map((service, index) => (
                            <option value={service.uuid} key={service.uuid}>
                              {service.name}
                            </option>
                          ))}
                        </Select>
                      </div>
                      <div className="col-4 d-flex">
                        <Field
                          name={`services.[${index}].price`}
                          type="number"
                          component={Input}
                          disabled
                        />
                        {/* <span>
                          <a
                            className="btn font-weight-bold btn-danger btn-icon ml-2"
                            onClick={() => remove(index)}
                          >
                            <i className="flaticon2-cross icon-1x"></i>
                          </a>
                        </span> */}
                      </div>
                    </div>
                  ))}

                  {role !== "business" ? (
                    <div className="form-group row">
                      <div className="col-md-5">
                        <button
                          type="button"
                          className="btn font-weight-bold btn-primary"
                          onClick={() => push(emptyServiceRow)}
                        >
                          <i className="flaticon2-add-1 icon-1x"></i>Add Service
                        </button>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </FieldArray>
            <h3 className="mt-10">Package</h3>
            <hr />
            <div className="form-group row mb-0">
              <div className="col-8">
                <label>Package</label>
              </div>
              <div className="col-4">
                <label>Price {`(${currencySymbol[0] ? currencySymbol[0].currencySymbol : '₹'})`}</label>
              </div>
            </div>
            <FieldArray name="packages">
              {({ push, remove }) => (
                <>
                  {values.packages.map((_, index) => (
                    <div className="form-group row" key={index}>
                      <div className="col-8">
                        <Select
                          disabled
                          name={`packages.[${index}].packageUuid`}
                          onChange={(e) => {
                            setFieldValue(
                              `packages.[${index}].packageUuid`,
                              e.target.value
                            );
                            setFieldValue(
                              `packages.[${index}].price`,
                              String(
                                packages
                                  .filter((pack) => {
                                    return pack.uuid === e.target.value;
                                  })
                                  .map((pack) => {
                                    return pack.price;
                                  })
                              )
                            );
                          }}
                        >
                          {packages.map((pack, index) => (
                            <option value={pack.uuid} key={pack.uuid}>
                              {pack.name}
                            </option>
                          ))}
                        </Select>
                      </div>
                      <div className="col-4 d-flex">
                        <Field
                          name={`packages.[${index}].price`}
                          type="number"
                          component={Input}
                          disabled
                        />
                        {/* <span>
                          <a
                            className="btn font-weight-bold btn-danger btn-icon ml-2"
                            onClick={() => remove(index)}
                          >
                            <i className="flaticon2-cross icon-1x"></i>
                          </a>
                        </span> */}
                      </div>
                    </div>
                  ))}

                  {role !== "business" ? (
                    <div className="form-group row">
                      <div className="col-md-5">
                        <button
                          type="button"
                          className="btn font-weight-bold btn-primary"
                          onClick={() => push(emptyPackageRow)}
                        >
                          <i className="flaticon2-add-1 icon-1x"></i>Add Package
                        </button>
                      </div>
                    </div>
                  ) : (
                    <> </>
                  )}
                </>
              )}
            </FieldArray>
            <hr />
            <div className="form-group row justify-content-end align-items-end">
              <div className="col-8">
                <h4 className="float-right">Sub-Total (₹)</h4>
              </div>
              <div className="col-4">
                <Field
                  name="subTotal"
                  type="number"
                  min="1"
                  component={Input}
                  placeholder="0.00"
                  disabled
                />
              </div>
            </div>
            <div className="form-group row justify-content-end align-items-end">
              <div className="col-8">
                <h4 className="float-right">Discount {`(${currencySymbol[0] ? currencySymbol[0].currencySymbol : '₹'})`}</h4>
              </div>
              <div className="col-4">
                <Field
                  name="discount"
                  type="number"
                  min="0"
                  component={Input}
                  placeholder="0.00"
                  disabled
                />
              </div>
            </div>
            <div className="form-group row justify-content-end align-items-end">
              <div className="col-8">
                <h4 className="float-right">Tax {`(${currencySymbol[0] ? currencySymbol[0].currencySymbol : '₹'})`}</h4>
              </div>
              <div className="col-4">
                <Field
                  name="tax"
                  type="number"
                  min="0"
                  component={Input}
                  placeholder="0.00"
                  disabled
                />
              </div>
            </div>
            <div className="form-group row justify-content-end align-items-end">
              <div className="col-8">
                <h4 className="float-right">Total {`(${currencySymbol[0] ? currencySymbol[0].currencySymbol : '₹'})`}</h4>
              </div>
              <div className="col-4">
                <Field
                  name="total"
                  type="number"
                  min="1"
                  component={Input}
                  placeholder="0.00"
                  disabled
                />
              </div>
            </div>
            {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
          </Form>
        )}
      </Formik>
    </CardBasic>
  );
};
