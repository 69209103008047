import axios from "axios";

const URL = process.env.REACT_APP_API_URL + "/business";
const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const getScheduleList = () => {
  return axios.get(`${URL}/schedule/list`);
};

export const getSchedule = async (req) => {
  return await axios.post(`${URL}/schedule/view`, req);
};

export const createSchedule = (data) => {
  return axios.post(`${URL}/schedule/create`, data, {
    headers,
  });
};

export const updateSchedule = (data, uuid) => {
  console.log(data);

  let arr = [];
  data.staff.map((key) => {
    arr.push(key.value);
  });
  data.staff = arr;
  data.uuid = uuid;
  console.log(data);
  return axios.patch(`${URL}/schedule/update`, data, {
    headers,
  });
};
