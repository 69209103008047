import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Formik, Form, Field, useFormikContext } from "formik";
import * as Yup from "yup";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Input } from "../../_metronic/_partials/controls";
import { ProfileUpdateData } from "../../store/business/authSlice";
import { branchProfileUpdateData } from "../../store/business/authSlice";
import { sendFailure, sendSuccess } from "../../store/toast/toastSlice";
import { addBranchPicture } from '../../store/business/branches/branchApi';
import {
  getBranch,
  updateBranch,
} from "../../store/business/branches/branchApi";
import { updateBranchImageData } from '../../store/business/branches/branchSlice';

export default function UploadBrancgImageDialog({ content, role, branchUuid }) {
  console.log(role);
  const [open, setOpen] = useState(false);
  const [status, setstatus] = useState("");

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <span className="navi-text" onClick={handleClickOpen}>
        {content}
      </span>

      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Upload Image</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              image: []
            }}
            enableReinitialize
            onSubmit={(values, actions) => {
              console.log(values.image);
              // const a = values.image.name;
              // console.log(a);
              // var nameSplit = a.split(".");
              // console.log(nameSplit);
              // nameSplit.pop();
              // console.log(nameSplit);
              // const name = nameSplit.join(".");
              // console.log(name);
              // const slug = a.substring(a.indexOf('.') + 1); 
              // console.log(slug);
              // const imageName = `public/images/branch/add/${name}${slug}.${slug}`;
              // console.log(imageName);
              addBranchPicture(values.image, branchUuid)
                .then((response) => {
                  console.log(response.data.data[0]);
                  dispatch(updateBranchImageData(response.data.data[0])).then(() => {
                    dispatch(sendSuccess( "The branch image has been updated succesfully"));
                  });
                  setOpen(false);
                  //history.replace("/branches");
                })
                .catch((err) => {
                  dispatch(sendFailure());
                  actions.setStatus(err.message);
                  console.log(err.message);
                });
              actions.setFormStatus("submitted");
            }}
          >
            {(formProps) => (
              <Form className="form form-label-right" id="createBranchForm">
                <div className="form-group row">
                  <div className="col">
                    <input
                      type="file"
                      name="image"
                      id="file-input"
                      className="form-control"
                      //multiple
                      onChange={(e) => {
                        console.log(e.target.files[0]);

                        let file = e.target.files[0];
                        //console.log(file.type);
                        if (file.type == "image/jpeg" || file.type == "image/jpg" || file.type == "image/png") {
                          if (file.size <= 1000000) {
                            formProps.setFieldValue("image", e.target.files[0]);
                          } else {
                            formProps.setFieldError(
                              "image",
                              "Image size should be less than 1 MB"
                            );
                          }
                        } else {
                          formProps.setFieldError(
                            "image",
                            "Image type should be JPG, PNG or JPEG"
                          );
                        }

                      }}
                    />
                    {formProps.errors.image && (
                      <div className="invalid-feedback d-flex">
                        {formProps.errors.image}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col">
                    <button
                      type="submit"
                      className="btn btn-light-success float-right"
                    >
                      Save Image
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}
