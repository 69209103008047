import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, Select } from "../../../_metronic/_partials/controls";

import { CardBasic } from "../../../components/CardBasic";
import { loadBranchList } from "../../../store/business/branches/branchSlice";
import { createStaff } from "../../../store/business/staff/staffSlice";
import { loadShiftList } from "../../../store/business/shifts/shitftsSlice";
import dayjs from "dayjs";
import { getCategory, updateCategory } from "../../../store/business/expense/Category/categoryAPi";
import { sendFailure, sendSuccess } from "../../../store/toast/toastSlice";

export const ExpenseCategoryView = () => {
  const [staffData, setStaffData] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();

  const { categoryUuid } = useParams();
  const req = {
    expenseUuid: categoryUuid,
  };
  // console.log(req);

  const branches = useSelector((state) => {
    return state.branch.branches.filter((data) => {
      return data.isActive === true;
    });
  });

  const { shifts } = useSelector((state) => state.shift);

  useEffect(() => {
    if (branches.length === 0) {
      dispatch(loadBranchList()).then(() => { });
    }

    if (shifts.length === 0) {
      dispatch(loadShiftList()).then(() => { });
    }
  }, [branches, shifts]);

  useEffect(() => {
    getCategory(req)
      .then((response) => {
        console.log(response);
        const data = response.data.data;
        console.log(data);
        setStaffData(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  // Validation schema
  const ValidationSchema = Yup.object().shape({
    categoryName: Yup.string().required("This field is required")
  });

  return (
    <CardBasic
      title={"Edit Category"}
      btnType={"submit"}
      formId={"updateForm"}
      headerBtnText={"Update Category"}
    >
      <Formik
        initialValues={{
          categoryName: staffData.categoryName ?? "",
          isActive: staffData.isActive ?? true
        }}
        enableReinitialize
        validationSchema={ValidationSchema}
        onSubmit={(values, actions) => {
          //console.log(values);
          const newValue = {...values, categoryName: values.categoryName.trim().toLowerCase(), ...req }
          //console.log(newValue);
          updateCategory(newValue)
            .then(() => {
              dispatch(
                sendSuccess(
                  "The Category information has been updated successfully"
                )
              );
              history.replace("/expense/category");
            })
            .catch((err) => {
              dispatch(sendFailure(err.response.data.message));
              actions.setStatus(err.message);
              //console.log(err.message);
            });
        }}
      >
        {({ values, errors, setFieldValue }) => (
          <Form className="form form-label-right" id="updateForm">
           <div className="form-group row">
              <div className="col-lg-8">
                <Field
                  name="categoryName"
                  component={Input}
                  placeholder="Category Name"
                  label="Category Name *"
                />
              </div>
              <div className="col-lg-4">
                <Select name="isActive" label="Status *">
                  <option value={true}>Active</option>
                  <option value={false}>Inactive</option>
                </Select>
              </div>
            </div>
            {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
          </Form>
        )}
      </Formik>
    </CardBasic>
  );
};
