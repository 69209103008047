import axios from "axios";

const URL = process.env.REACT_APP_API_URL;
console.log(URL)
const Header = {
  "Content-Type": "multipart/form-data",
  Accept: "application/json",
};
console.log(URL);

// export const login = (countryCode, phone, otp) => {
//   return axios.post(`${URL}/business/login`, { countryCode, phone, otp });
// };
export const login = (email, password) => {
  //console.log(email, password);
  return axios.post(`${URL}/business/login`, { email, password });
};

export const register = (values) => {
  //values.country = "India";
  //values.countryCode = "91";
  //console.log(values);

  return axios.post(`${URL}/business/register`, values);
};

export const forgotPassword = (email) => {
  //console.log(email);
  return axios.post(`${URL}/business/forgotPassword`, email);
};

// Branch Forgot Password
export const branchForgotPassword = (email) => {
  //console.log(email);
  return axios.post(`${URL}/userBranch/forgotPassword`, email);
};

export const resetPassword = (values) => {
  //console.log(values);
  return axios.patch(`${URL}/business/resetPassword`, values);
};
// Branch Reset Password
export const branchResetPassword = (values) => {
  //console.log(values);
  return axios.patch(`${URL}/userBranch/resetPassword`, values);
};

// export const branchLogin = (countryCode, phone, otp) => {
//   return axios.post(`${URL}/branch/login`, { countryCode, phone, otp });
// };
export const branchLogin = (email, password) => {
  //console.log(email, password);
  return axios.post(`${URL}/branch/login`, { email, password });
};

export const branchProfileUpdate = (data) => {
  //console.log(data);
  const req = new FormData();
  for (var key in data) {
    console.log(data[key]);
    req.append(key, data[key]);
  }

  req.delete("image");
  req.delete("business_segment");
  req.append("business_segment", JSON.stringify(data.business_segment));

  data.image && req.append("image", data.image);

  console.log(req);
  return axios.patch(`${URL}/userBranch/update`, req, {
    Header,
  });
};

export const getCategory = () => {
  return axios.get(`${URL}/user/categories/list`);
};
