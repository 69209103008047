import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import filterFactory from "react-bootstrap-table2-filter";
import { Formik, Form } from "formik";
import { DatePickerField } from "../../../_metronic/_partials/controls";

import { sortCaret } from "../../../_metronic/_helpers";

import {
  StatusColumnFormatter,
  ActionColumnSimple,
} from "../../../_metronic/_helpers/tableColumnFormatter";

import paginationFactory from "react-bootstrap-table2-paginator";
import { loadCasgDrawerList } from "../../../store/business/expense/cashDrawer/cashdrawerSlice";
import { loadBranchStaffList } from "../../../store/branch/branchStaffSlice";

export const CustomerStatusTitles = ["Suspended", "Active", "Pending", ""];

export const CashDrawerTable = () => {
  const dispatch = useDispatch();


  const { role, user } = useSelector((state) => state.authPersist);
  const [dataLoading, setDataLoading] = useState(true);
  const [cashDrawerData, setCashDrawerData] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const cashDrawerList = useSelector((state) => state.cashDrawer.cashDrawerList);
  const cashDrawerTotal = useSelector((state) => state.cashDrawer.casDrawerTotal);

  console.log(cashDrawerTotal);
  console.log(cashDrawerList);
  const LinkFormatter = (cell, row) => {
    // console.log(row);
    if (role == "business") {
      return <Link to={`/expense/category/${row.uuid}`}>{cell}</Link>;
    } else {
      return cell;
    }
  };
  const branchFormatter = (cell, row) => {
    return user.name;
  };

  const DateFormatter = (cell, row) => {
    return dayjs(cell).format("DD-MM-YYYY");
  };

  // const ra = cashDrawerList.filter((el) => {
  //   return el.voucherType === "RA";
  // });

  // const po = cashDrawerList.filter((el) => {
  //   return el.voucherType === "PO";
  // });

  // const raSum = ra.reduce((accumulator, object) => {
  //   return accumulator + object.amount;
  // }, 0);

  // const poSum = po.reduce((accumulator, object) => {
  //   return accumulator + object.amount;
  // }, 0);

  const date = new Date();
  //date.setDate(date.getDate() -7);

  // console.log(raSum);
  // console.log(poSum);

  //const total = raSum - poSum;

  //setTotalAmount(raSum - poSum);
  // console.log(total);

  // cashDrawerData.map(o => {
  //   console.log(o.amount);
  //   let totalAmount = 0;
  //   if(o.voucherType === 'RA') {
  //     totalAmount = o.amount;
  //   }
  //   console.log(totalAmount);
  // });

  useEffect(() => {
    if (role == "business") {
      dispatch(loadCasgDrawerList({ from: new Date(), to: new Date() })).then(() => {
        setDataLoading(false);
      });
    } else {
      dispatch(loadCasgDrawerList({ from: new Date(), to: new Date() })).then(() => {
        setDataLoading(false);
      });
    }
  }, []);

  const columns = [
    {
      dataField: "",
      text: "#",
      formatter: (row, cell, index) => {
        return index + 1;
      },
    },
    {
      dataField: "voucherType",
      text: "Type of Vouchers",
      sort: true,
      formatter: LinkFormatter,
      sortCaret: sortCaret,
      // filter: textFilter(),
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "createdAt",
      text: "Date",
      formatter: DateFormatter,
    },
    {
      dataField: "mode",
      text: "Transaction Type",
      sort: true,
      sortCaret: sortCaret,
      //formatter: role == "business" ? "" : branchFormatter,
    },
    // {
    //   dataField: "isAcitve",
    //   text: "Status",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   formatter: StatusColumnFormatter,
    // },
    // {
    //   dataField: "",
    //   text: "Actions",
    //   formatter: ActionDisplay,
    // },
  ];

  const { SearchBar, ClearSearchButton } = Search;

  return (
    <>
      <Formik
        initialValues={{
          startDate: new Date(),
          endDate: new Date()
        }}
        onSubmit={
          (values, errors) => {
            // console.log(values);
            // console.log(role);
            if (role == "business") {
              dispatch(loadCasgDrawerList({ from: values.startDate, to: values.endDate })).then(() => {
                setDataLoading(false);
              });
            } else {
              dispatch(loadCasgDrawerList({ from: values.startDate, to: values.endDate })).then(() => {
                setDataLoading(false);
              });
            }
          }
          // saveCustomer(values);
        }
      >
        {({ values, errors, setFieldValue }) => {
          //console.log(values);
          return (
            <Form className="form form-label-right" id="createBookingForm">
              <div className="container">
                <div className="row mb-5">
                  <div className="col-sm-2 ml-6"></div>
                  <div className="col-sm-3">
                    <div className="mx-3">
                      <label>From</label>
                    </div>
                    <DatePickerField
                      name="startDate"
                      className="form-control"
                      onChange={(val) => {
                        setFieldValue("startDate", val);
                      }}
                    />
                  </div>
                  <div className="col-sm-3">
                    <div className="mx-3">
                      <label>To</label>
                    </div>
                    <DatePickerField
                      name="endDate"
                      className="form-control"
                      onChange={(val) => {
                        setFieldValue("endDate", val);
                      }}
                    />
                  </div>
                  <div className="col-sm-3 mt-8">
                    <button type="submit" class="btn btn-light-success font-weight-bold" style={{ marginLeft: ' 20px' }}>
                      Filter Data
                    </button>
                  </div>
                </div>
              </div>
            </Form>)
        }}

      </Formik>
      <ToolkitProvider
        search
        keyField="name"
        data={cashDrawerList}
        columns={columns}
      >
        {(props) => (
          <>
            <div className="container">
              <div class="d-flex justify-content-center">
                {/* <div className="col-sm-4">
                <h4 className="mt-4" >Search</h4>
              </div> */}
                <SearchBar {...props.searchProps} />
                <ClearSearchButton
                  {...props.searchProps}
                  className="ml-4 mb-4 btn btn-secondary-light"
                />

              </div>
            </div>
            <div className="container mt-5">
              <div class="d-flex justify-content-center">
                <h4 className="mb-5 float-right">Total Amount <span style={{ padding: '8px', backgroundColor: '#F3F6F9', borderRadius: '5px', marginLeft: '10px' }}>{cashDrawerTotal}</span></h4>
              </div>
            </div>
            <BootstrapTable
              {...props.baseProps}
              classes="table table-head-custom table-vertical-center overflow-hidden"
              wrapperClasses="table-responsive"
              bordered={false}
              bootstrap4
              pagination={paginationFactory()}
              filter={filterFactory()}
              noDataIndication={
                <div className={"d-flex justify-content-center py-10"}>
                  <h3>{dataLoading ? "Loading Data..." : "No records found"}</h3>
                </div>
              }
            />
          </>
        )}
      </ToolkitProvider>
    </>
  );
};
