import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  row: {
    marginTop: "10px",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: "100%",
    minHeight: 40,
    fontStyle: "bold",
  },
  type: {
    backgroundColor: "red",
  },
  description: {
    textAlign: "left",
    paddingLeft: 8,
    width: "100%",
    textTransform: "capitalize",
  },
  rate: {
    width: "25%",
    textAlign: "left",
    paddingLeft: 15,
  },
  amount: {
    paddingRight: 8,
  },
  descc: {
    width: "50%",
  },
  discount: {
    paddingTop: "15px",
    width: "12%",
  },
  mount: {
    width: "25%",
    textAlign: "right",
    paddingRight: 8,
  },
  dis: {
    paddingRight: 8,
  },
});

const InvoiceRow = ({ invoice }) => {
  console.log("invoice",invoice)
  return (
    <>
      <View style={styles.row}>
        <View style={styles.descc}>
          {invoice.service?.map((service) => {
            return (
              <Text key={service.uuid} style={styles.description}>
                {service.name}
              </Text>
            );
          })}
          {invoice.package?.map((pack) => {
            return (
              <Text key={pack.uuid} style={styles.description}>
                {pack.name}
              </Text>
            );
          })}
        </View>
        <View style={styles.rate}>
          {invoice.service?.map((service) => {
            return <Text key={service.uuid}>Service</Text>;
          })}
          {invoice.package?.map((pack) => {
            return <Text key={pack.uuid}>Package</Text>;
          })}
        </View>

        <View style={styles.mount}>
          {invoice.service?.map((service) => {
            return <Text style={styles.amount}>{service.price}</Text>;
          })}

          {invoice.package?.map((pack) => {
            return <Text style={styles.amount}>{pack.price}</Text>;
          })}
        </View>
      </View>
    </>
  );
};

export default InvoiceRow;
