import React, { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  emptyTopSalon,
  topSalonsThunk,
} from "../../store/TopSalons/topSalonsSlice";
import {
  getCityList,
  getCountryCodeList,
} from "../../store/countryCode/countryCodeSlice";
import unisexIcon from "../../_metronic/_assets/icons/unisexIcon.png";
import maleIcon from "../../_metronic/_assets/icons/maleIcon.png";
import femaleIcon from "../../_metronic/_assets/icons/femaleIcon.png";
import { Formik } from "formik";
import defaultBranchImage from "../../_metronic/_assets/icons/logo2.jpg";
import { SpinnerBasic } from "../spinners/SpinnerBasic";

function BellazNearbySalons() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { topSalons, loading } = useSelector((state) => state.topSalons);
  const { cityList } = useSelector((state) => state.countryCode);
  // console.log("topSalons", topSalons, cityList);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  const getLocation = () => {
    // console.log("here", window.navigator?.geolocation);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          // Here you can send latitude and longitude to your server or wherever you need
          // console.log(position, "Latitude:", latitude, "Longitude:", longitude);
          const data = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            pageNumber: 1,
            // city:'Jaipur'
          };
          dispatch(topSalonsThunk(data));
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    dispatch(getCityList());
    if (topSalons?.length == 0) {
      getLocation();
    }
    // const data={
    //   latitude:latitude,
    //   longitude:longitude,
    //   // city:'jaipur'
    // }
    // dispatch(topSalonsThunk(data))
  }, []);

  const handleSalonView = (salon) => {
    // console.log("salon",salon)
    //   let req = {
    //     uuid:salon?.branch?.uuid
    //   };
    //   dispatch(SalonViewThunk(req)).then((res)=>{
    //   console.log("res",res)
    //  }).catch((err)=>{
    //   console.log("err",err)
    //  })
    history.push(`/top-salons/${salon?.branch?.uuid}`);
  };

  return (
    <div className="bellazWork align-items-center">
      <div className="bellazWork-inner d-flex align-items-center flex-column position-relative">
        <div className=" w-100 d-flex align-items-center justify-content-center">
          {/* <div className="col-lg-1"></div> */}
          <div className="bellazTopSalon-card row d-flex align-items-center justify-content-between bellazCard-top ">
            <Formik
              initialValues={{ city: "" }}
              onSubmit={(values) => {
                console.log("values");
              }}
            >
              {({
                values,
                handleReset,
                handleSubmit,
                handleChange,
                handleBlur,
              }) => (
                <>
                  <h1 className="display-4 col-lg-6 col-md-6 col-sm-12 col-xs-12 m-0 p-0 text-capitalize">
                    {/* Top {values.city=="" || values.city=='current_location' ? "Nearby" : values.city} Salons */}
                  Top Nearby Salons
                  </h1>
                  <div className="form w-100 col-lg-6 col-md-6 col-sm-12 col-xs-12 m-0 p-0">
                    <div className="d-flex align-items-center justify-content-end">
                      <select
                        className="form-select icon"
                        name="city"
                        onBlur={handleBlur}
                        value={values.city}
                        onChange={(e) => {
                          if (e.target.value === "current_location") {
                            dispatch(emptyTopSalon());
                            getLocation();
                          } else {
                            // console.log("e", e.target.value);
                            const data = {
                              city: e.target.value,
                              pageNumber: 1,
                            };
                            dispatch(emptyTopSalon());
                            dispatch(topSalonsThunk(data));
                          }
                          handleChange(e);
                        }}
                      >
                        <option value="" disabled>
                          Select City
                        </option>
                        <option value="current_location">
                          Current Location
                        </option>
                        {cityList &&
                          cityList?.length > 0 &&
                          cityList?.map((city, i) => (
                            <option value={city}>{city}</option>
                          ))}
                      </select>
                    </div>
                  </div>
                </>
              )}
            </Formik>
          </div>
          {/* <div className="col-lg-1"></div> */}
        </div>
        <div className="bellazTopSalon-card">
          <div className="row mb-6">
            {loading ? (
              <>
                <div className="centerContent w-100">
                  <SpinnerBasic />
                </div>
              </>
            ) : topSalons && topSalons?.length > 0 ? (
              topSalons?.map((data, index) => {
                // console.log("salon data", data);
                return (
                  <div
                    className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 mb-5"
                    key={index}
                  >
                    <div
                      className="card p-0 h-100 cursor-pointer"
                      onClick={() => handleSalonView(data)}
                    >
                      <div
                        className="position-relative"
                        style={{ borderRadius: "inherit" }}
                      >
                        <img
                          src={`${process.env.REACT_APP_MEDIA_URL}/${data?.branch?.logo}`}
                          alt=""
                          className="w-100"
                          style={{ aspectRatio: "4/3" }}
                          onError={(e) => {
                            e.target.src = defaultBranchImage; // Set default image source
                          }}
                        />
                        {data?.branch?.Business &&
                          data?.branch?.CountryMaster &&
                          data?.branch?.Business?.length > 0 &&
                          data?.branch?.CountryMaster?.length > 0 &&
                          data?.branch?.Business[0]?.subscription?.uuid !==
                            data?.branch?.CountryMaster[0]
                              .defaultSubscriptionUuid && (
                            <p
                              className="m-0 p-0 px-4 py-1 rounded-pill"
                              style={{
                                position: "absolute",
                                top: "80%",
                                left: "5%",
                                fontSize: "14px",
                                backgroundColor: "#D4B55B",
                                color: "white",
                              }}
                            >
                              Verified
                            </p>
                          )}
                      </div>
                      <div className="p-4">
                        <div className="d-flex align-items-center justify-content-between">
                          <p
                            className={"m-0 p-0 cursor-pointer"}
                            style={
                              data?.branch?.gender === "unisex"
                                ? { color: "#27AE60" }
                                : data?.branch?.gender === "male"
                                ? { color: "#007CC4" }
                                : { color: "#FF4181" }
                            }
                          
                          >
                            {data?.branch?.name}
                          </p>
                          <img
                            src={
                              data?.branch?.gender === "unisex"
                                ? unisexIcon
                                : data?.branch?.gender === "male"
                                ? maleIcon
                                : femaleIcon
                            }
                            alt=""
                          />
                        </div>
                        <div className="text-left">
                          <span>
                            {data?.branch?.address_line1}, {data?.branch?.city}
                            {data?.distance > 0
                              ? ` | ${data?.distance / 1000} km`
                              : null}
                          </span>
                        </div>
                      </div>

                      {/* <span>{data.branch.text}</span> */}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="w-100 centerContent">
                <h4>No data found for this location</h4>
              </div>
            )}
          </div>
          <div className="centerContent">
            <button
              type="button"
              className="btn btn-primary form-submit-btn px-25"
              onClick={() => {
                history.push("/top-salons");
              }}
            >
              View More
            </button>
          </div>
        </div>
        {/* <div
          href=""
          class="btn btn-primary d-flex justify-content-center mt-5 mb-5 text-uppercase text-white pt-4 pb-4 pl-10 pr-10 rounded-pill"
        >
          BOOK AN APPOINTMENT NOW
        </div> */}
      </div>
    </div>
  );
}

export default BellazNearbySalons;
