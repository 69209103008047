import React from "react";
import BellazBanner from "./BellazBanner";
import BellazHeader from "./BellazHeader";
import BellazWork from "./BellazWork";
import BellazCategory from "./BellazCategory";
import BellazCards from "./BellazCards";
import BellazBusiness from "./BellazBusiness";
import BellazReview from "./BellazReview";
import BellazDownload from "./BellazDownload";
import BellazFooter from "./BellazFooter";
import BellazLastBanner from "./BellazLastBanner";
import BellazForm from "./BellazForm";
import BellazRated from "./BellazRated";
import New from "./new";
import BellazNearbySalons from "./BellazNearbySalons";
function BellazMain() {
  return (
    <div className="p-0 m-0 bg-white">
      {/* <BellazHeader /> */}
      <BellazBanner />
      <BellazWork />
      <BellazNearbySalons />
      <BellazCategory />
      {/* <BellazCards /> */}
      <BellazBusiness />
      <BellazDownload />
      {/* <BellazRated /> */}
      <BellazLastBanner />
      
      
      
     
      {/* <BellazReview /> */}
      <BellazForm /> 
      <BellazFooter />
    </div>
  );
}

export default BellazMain;
