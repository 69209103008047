import axios from "axios";

const URL = process.env.REACT_APP_API_URL + "/business";
const URL1 = process.env.REACT_APP_API_URL + "/business/guest";
const URL2 = process.env.REACT_APP_API_URL;
const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const createSubscriptionOrder = (data) => {
  console.log(data);
  return axios.post(`${URL}/guest/order/create`, data, {
    headers,
  });
};

export const verifySubscriptionPlan = (data) => {
  return axios.post(`${URL}/guest/payment/verify`, data, {
    headers,
  });
};

export const getSubscriptionTransactions = async () => {
  return await axios.get(`${URL}/subscription/transactions/list`);
};

export const applyCouponeCode = (data) => {
  //console.log(data);
  return axios.post(`${URL}/guest/promo/apply`, data, {
    headers,
  });
};

export const getStripKey = (data) => {
  console.log(data);
  return axios.post(`${URL1}/subscription/pay`, data, {
    headers,
  });
};

