import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import { Input } from "../../_metronic/_partials/controls";

import { CardBasic } from "../../components/CardBasic";
import { useDispatch } from "react-redux";
import { createSegment } from "../../store/admin/segment/segmentSlice";
import { sendFailure, sendSuccess } from "../../store/toast/toastSlice";

export const SegmentCreate = () => {
  const [formStatus, setFormStatus] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();

  // const FILE_SIZE = 200 * 200;
  // const SUPPORTED_FORMATS = [
  //   "image/jpg",
  //   "image/jpeg",
  //   "image/gif",
  //   "image/png",
  // ];

  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required"),
    image: Yup.mixed().required("A file is required"),
  });

  return (
    <CardBasic
      title={"Add Segment"}
      btnType={"submit"}
      formId={"createForm"}
      headerBtnText={"Save Segment"}
    >
      <Formik
        initialValues={{
          name: "",
          image: "",
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values, actions, errors) => {
          console.log(values, errors);

          dispatch(createSegment(values))
            .then(() => {
              dispatch(
                sendSuccess("The segments has been created successfully")
              );
              history.replace("/segments");
            })
            .catch((err) => {
              dispatch(sendFailure(err.message));
              actions.setStatus("Error! Duplicate Segment Name.");
              console.log(err.message);
            });
          setFormStatus("submitted");
        }}
      >
        {(formProps) => (
          <Form className="form form-label-right" id="createForm">
            {formProps.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formProps.status}
                </div>
              </div>
            )}
            <div className="form-group row">
              <div className="col-lg-6">
                <Field
                  name="name"
                  component={Input}
                  placeholder="Segment Name"
                  label="Segment Name *"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-6">
                <label>Segment Image * (300 X 300 px)</label>
                <input
                  type="file"
                  name="image"
                  className="form-control"
                  onChange={(e) =>
                    formProps.setFieldValue("image", e.target.files[0])
                  }
                />
                {formProps.errors.image && (
                  <div className="invalid-feedback d-flex">
                    {formProps.errors.image}
                  </div>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </CardBasic>
  );
};
