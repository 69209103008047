import { createSlice } from "@reduxjs/toolkit";

import * as requestFromServer from "./customerApi";

const initialState = {
  addCustomers: [],
};

export const addCustomerSlice = createSlice({
  name: 'addCustomer',
  initialState,
  reducers: {
    created(state, action) {
      state.addCustomers.push(action.payload);
    }
  }
});

export const addCustomer = (data) => {
  return async (dispatch) => {
    return requestFromServer
      .addCustomer(data)
      .then((response) => {
        dispatch(addCustomerSlice.actions.created(response.data.data));
      })
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };
};