import axios from "axios";
import FormData from "form-data";

const URL = process.env.REACT_APP_API_URL + "/admin/segment";
const token = process.env.REACT_APP_ADMIN_TOKEN;
const Authorization = "Bearer " + token;

const headers = {
  Authorization,
  "Content-Type": "multipart/form-data",
  Accept: "application/json",
};

export const getSegmentList = () => {
  return axios.get(`${URL}/list`, {
    headers: {
      Authorization,
    },
  });
};

export const getSegment = async (req) => {
  return await axios.post(`${URL}/view`, req, {
    headers: {
      Authorization,
    },
  });
};

export const createSegment = (segmentData) => {
  const data = new FormData();

  data.append("name", segmentData.name);
  data.append("image", segmentData.image);

  return axios.post(`${URL}/create`, data, {
    headers,
  });
};

export const updateSegment = (data, uuid) => {
  const req = new FormData();

  req.append("uuid", uuid);
  req.append("name", data.name);
  req.append("isActive", data.isActive);
  data.image && req.append("image", data.image);

  return axios.patch(`${URL}/update`, req, {
    headers,
  });
};
