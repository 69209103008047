import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SpinnerBasic } from "../components/spinners/SpinnerBasic";
import { BarChart } from "../components/widgets/BarChart";
import {
  getBusinessReport,
  getSalesReport,
  getTopPackages,
  getTopServices,
  getBusinessReportBranch
} from "../store/business/reports/reportApi";
import { sendFailure, sendSuccess } from "../store/toast/toastSlice";
import { TilesWidget11 } from "../_metronic/_partials/widgets";
import { BaseTablesWidget11, BaseTablesWidget12 } from '../_metronic/_partials/widgets';
import { authActions } from "../store/business/authSlice";

export const Overview = (props) => {
  //console.log(props.dateData);
  const dispatch = useDispatch();
  const { role, user } = useSelector((state) => state.authPersist);
  const [businessReportData, setBusinessReportData] = useState({});
  const [salesData, setSalesData] = useState([]);
  const [topPackages, setTopPackages] = useState([]);
  const [topServices, setTopServices] = useState([]);
  const [branchReport, setBranchReport] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const { countryCode } = useSelector((state) => state.countryCode);
  // console.log(user);
  console.log(countryCode);
  const currencySymbol = countryCode.filter((el) => el.countryCode === user.countryCode);
  console.log(currencySymbol);
  useEffect(() => {
    setDataLoading(true);
    if (role == "business") {
      getBusinessReport(props.dateData).then((response) => {
        setBusinessReportData(response.data.data);
        getSalesReport(props.dateData).then((response) => {
          //console.log(response);
          // setSalesData([
          //   salesData.last7days.length > 0
          //     ? salesData.last7days[0].totalRevenue
          //     : 0,
          //   salesData.last30days.length > 0
          //     ? salesData.last30days[0].totalRevenue
          //     : 0,
          //   salesData.last365days.length > 0
          //     ? salesData.last365days[0].totalRevenue
          //     : 0,
          // ]);
          //console.log(salesData);
          //setDataLoading(false);
        });
        setDataLoading(false);
      }).catch((err) => {
        //console.log(err.response.data);
        if(err.response.data === 'Unauthorized') {
          dispatch(sendFailure('session expired! Please login again.'));
          dispatch(authActions.logout());
        }
      });
    }
  }, [props.dateData]);

  useEffect(() => {
    setDataLoading(true);
    getTopServices(props.dateData).then((response) => {
      //console.log(response.data.data);
      setTopServices(response.data.data);
    });
  }, [props.dateData]);

  useEffect(() => {
    setDataLoading(true);
    getTopPackages(props.dateData).then((response) => {
      //console.log(response.data.data);
      setTopPackages(response.data.data);
    });
  }, [props.dateData]);

  useEffect(() => {
    setDataLoading(true);
    getBusinessReportBranch(props.dateData).then((response) => {
      //console.log(response.data.data);
      setBranchReport(response.data.data);
    });
  }, [props.dateData]);

  // console.log(topPackages);
  // console.log(topServices);
  // console.log(branchReport);

  return (
    <>
      {role === "business" && (
        <>
          {dataLoading ? (
            <div style={{height : '600px', paddingTop: '200px'}}>
              <SpinnerBasic />
            </div>
          ) : (
            <>
              <div className="row">
                {/* Bookings Report */}
                <div className="col-xl-4">
                  <div className="row">
                    <div className="col-6">
                      <TilesWidget11
                        className="gutter-b"
                        baseColor="primary"
                        widgetHeight="150px"
                        count={businessReportData.bookings.total ?? 0}
                        title="Total Bookings"
                        svgUrl="/media/svg/icons/Shopping/Cart3.svg"
                        //linkUrl={`/bookings/upcoming/${props.date}`}
                      />
                    </div>

                    <div className="col-sm-6">
                      <TilesWidget11
                        className="gutter-b"
                        baseColor="primary"
                        widgetHeight="150px"
                        count={businessReportData.bookings[1] ?? 0}
                        title="Pending"
                        svgUrl="/media/svg/icons/Shopping/Cart3.svg"
                        linkUrl={`/bookings/upcoming/${props.date}`}
                      />
                    </div>
                    <div className="col-sm-6">
                      <TilesWidget11
                        className="gutter-b"
                        baseColor="primary"
                        widgetHeight="150px"
                        count={businessReportData.bookings[4] ?? 0}
                        title="Completed"
                        svgUrl="/media/svg/icons/Shopping/Cart3.svg"
                        linkUrl={`/bookings/completed/${props.date}`}
                      />
                    </div>
                    <div className="col-6">
                      <TilesWidget11
                        className="gutter-b"
                        baseColor="primary"
                        widgetHeight="150px"
                        count={businessReportData.bookings[0] ?? 0}
                        title="Cancelled Bookings"
                        svgUrl="/media/svg/icons/Shopping/Cart3.svg"
                        linkUrl={`/bookings/cancelled/${props.date}`}
                      />
                    </div>
                  </div>
                </div>
                {/* Service & Package Report */}
                <div className="col-xl-4">
                  <div className="row">
                    <div className="col-sm-6">
                      <TilesWidget11
                        className="gutter-b"
                        iconColor="success"
                        widgetHeight="150px"
                        count={businessReportData.services.active ?? 0}
                        title="Services"
                        svgUrl="/media/svg/icons/General/Scissors.svg"
                        linkUrl="/services"
                      />
                    </div>
                    <div className="col-sm-6">
                      <TilesWidget11
                        className="gutter-b"
                        iconColor="success"
                        widgetHeight="150px"
                        count={businessReportData.packages.active ?? 0}
                        title="Packages"
                        svgUrl="/media/svg/icons/General/Star.svg"
                        linkUrl="/packages"
                      />
                    </div>
                    <div className="col-sm-6">
                      <TilesWidget11
                        className="gutter-b"
                        iconColor="success"
                        widgetHeight="150px"
                        count={businessReportData.chairs.active ?? 0}
                        title="Chairs"
                        svgUrl="/media/svg/icons/Home/Chair2.svg"
                        linkUrl="/chairs"
                      />
                    </div>
                    <div className="col-sm-6">
                      <TilesWidget11
                        className="gutter-b"
                        iconColor="success"
                        widgetHeight="150px"
                        count={businessReportData.staff.active ?? 0}
                        title="Staff"
                        svgUrl="/media/svg/icons/General/User.svg"
                        linkUrl="/staff"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xl-4">
                  <div className="row">
                    <div className="col-12">
                      <TilesWidget11
                        className="gutter-b"
                        baseColor="primary"
                        widgetHeight="150px"
                        count={`${currencySymbol[0] ? currencySymbol[0].currencySymbol : '₹'}${businessReportData.revenue ?? 0}`}
                        title="Total Revenue"
                        svgUrl="/media/svg/icons/Shopping/Chart-bar1.svg"
                        linkUrl={`/bookings/completed/${props.date}`}
                      />
                    </div>
                    <div className="col-sm-6">
                      <TilesWidget11
                        className="gutter-b"
                        baseColor="primary"
                        widgetHeight="150px"
                        count={businessReportData.branches.active ?? 0}
                        title="Branches"
                        svgUrl="/media/svg/icons/Map/Marker1.svg"
                        linkUrl="/branches"
                      />
                    </div>
                    <div className="col-sm-6">
                      <TilesWidget11
                        className="gutter-b"
                        iconColor="success"
                        widgetHeight="150px"
                        count={businessReportData.shifts ?? 0}
                        title="Shifts"
                        svgUrl="/media/svg/icons/Home/Clock.svg"
                        linkUrl="/shifts"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {/* <div className="col-xl-6">
                  <BarChart
                    className="gutter-b card-stretch"
                    chartColor="danger"
                    data={salesData}
                  />
                </div> */}
                <div className="col-xl-6">
                  <BaseTablesWidget11
                    className="gutter-b card-stretch"
                    title="package"
                    // chartColor="danger"
                    data={topPackages}
                  />
                </div>
                <div className="col-xl-6">
                  <BaseTablesWidget12
                    className="gutter-b card-stretch"
                    chartColor="danger"
                    title="branch"
                    data={branchReport}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xl-6">
                  <BaseTablesWidget11
                    className="gutter-b card-stretch"
                    title="service"
                    // chartColor="danger"
                    data={topServices}
                  />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
