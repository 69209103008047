import { createSlice } from "@reduxjs/toolkit";

import * as requestFromServer from "./branchPackageApi";

const initialState = {
  branchPackages: [],
};

export const branchPackageSlice = createSlice({
  name: "branchPackages",
  initialState,
  reducers: {
    updateList(state, action) {
      state.branchPackages = action.payload;
    },
    created(state, action) {
      state.branchPackages.push(action.payload);
    },
  },
});

export const loadBranchPackageList = () => {
  return async (dispatch) => {
    return requestFromServer
      .getBranchPackageList()
      .then((response) => {
        console.log(response.data);
        dispatch(branchPackageSlice.actions.updateList(response.data.data));
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
        //alert(err.message);
      });
  };
};
