import React from "react";
import BellazCarousel from "./BellazCarousel";
import "./BellazMain.css";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import New from "./new";
function BellazBusiness() {
  return (
    <div className="business container-fluid  d-flex flex-column justify-content-between align-items-center p-0 text-center position-relative">
      <img
        className="img-fluid position-absolute flower3 d-none d-lg-flex"
        src={toAbsoluteUrl("/media/BellazMain/svg icons/flower-1.png")}
      />
      <img
        className="img-fluid position-absolute flower4 d-none d-lg-flex"
        src={toAbsoluteUrl("/media/BellazMain/svg icons/flower-1.png")}
      />
      <img
        className="img-fluid position-absolute flower5 d-none d-lg-flex"
        src={toAbsoluteUrl("/media/BellazMain/svg icons/flower 2.png")}
      />
      <div className="container business-content justify-content-center align-items-center d-flex flex-column">
        <h1 className="text-black display-4 mb-5">Business Segments</h1>
        {/* <h6 className=" text-black-50">
        Search from our wide variety of services and packages. Search from our wide valety of services.
        </h6> */}
      </div>
      <BellazCarousel />
    </div>
  );
}

export default BellazBusiness;
