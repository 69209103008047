import React from "react";

import { CashDrawerTable } from "./table";
import { CardBasic } from "../../../components/CardBasic";
import { useSelector } from "react-redux";

export function ExpenseCashDrawerPage() {
  const { role, user } = useSelector((state) => state.authPersist);
  return (
    <CardBasic
      title={"Cash Drawer List"}
      btnType={"button"}
      //headerBtnText={role === "business" ? "New Category" : null}
      //headerBtnLink={role == "business" ? "/expense/category/create" : null}
    >
      <CashDrawerTable />
    </CardBasic>
  );
}
