import axios from "axios";

const URL = process.env.REACT_APP_API_URL + "/business";
const URL1 = process.env.REACT_APP_API_URL;
const headers = {
  "Content-Type": "multipart/form-data",
  Accept: "application/json",
};

export const getBranchList = () => {
  return axios.get(`${URL}/branches`);
};

export const getBranch = async (req) => {
  return await axios.post(`${URL}/branch/view`, req);
};

export const createBranch = (data) => {
  const req = new FormData();

  for (var key in data) {
    req.append(key, data[key]);
  }
  req.delete('business_segment');
  req.append('business_segment', JSON.stringify(data.business_segment));

  // console.log([...req]);

  return axios.post(`${URL}/branch/create`, req, {
    headers,
  });
};

export const updateBranch = (data, uuid) => {
  const req = new FormData();

  for (var key in data) {
    req.append(key, data[key]);
  }
  req.delete('image');
  req.delete('business_segment');
  req.append('business_segment', JSON.stringify(data.business_segment));
  req.append("uuid", uuid);

  data.image && req.append("image", data.image);

  return axios.patch(`${URL}/branch/update`, req, {
    headers,
  });
};

export const addBranchPicture = (image, uuid) => {
  // console.log(image);
  // console.log(uuid);
  const req = new FormData();
  req.append("image", image);
  req.append("branch_uuid", uuid);
  // console.log(req);
  
  return axios.patch(`${URL1}/business/branch/images/add`, req, {
    headers,
  });
};

export const deleteBranchPicture = (uuid, name) => {
  // console.log(name);
  // console.log(uuid);
  const req = {
    branchUuid: uuid,
    image: name
  }
  // console.log(req);
  return axios.post(`${URL1}/business/branch/image/delete`, req);
};
