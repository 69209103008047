import axios from "axios";

const URL = process.env.REACT_APP_API_URL;
const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};
export const updateProfile = (data) => {
  //console.log(data);
  return axios.patch(`${URL}/business/guest/profile/update`, data, {
    headers,
  });
};

export const viewBusinessProfile = () => {
  //console.log(data);
  return axios.get(`${URL}/business/profile/view`);
};

// export const getBranchShiftList = () => {
//   return axios.get(`${URL}/userBranch/shift/list`);
// };
