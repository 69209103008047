import { createSlice } from "@reduxjs/toolkit";

import * as requestFromServer from "./loyaltyPointsApi";

const initialState = {
  loyaltyPoints: [],
  Wallet_Details: 0
};

export const loyaltyPointsSlice = createSlice({
  name: "loyaltyPoints",
  initialState,
  reducers: {
    updateList(state, action) {
      state.loyaltyPoints = action.payload;
    },
    updateWallet(state, action) {
      state.Wallet_Details = action.payload;
    }
  },
});



export const loadLoyaltyPointsList = () => {
  return async (dispatch) => {
    return requestFromServer
      .getLoyaltyPointsList()
      .then((response) => {
       //console.log(response);
        dispatch(loyaltyPointsSlice.actions.updateList(response.data.data.Loyalty_Points));
        dispatch(loyaltyPointsSlice.actions.updateWallet(response.data.data.Loyalty_Points_Balance));
      })
      .catch((err) => {
       alert(err.message);
        //throw new Error(err.message);
      });
  };
};

