import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { date } from "yup";

import * as requestFromServer from "./authApi";
import { updateProfile, viewBusinessProfile } from "./profile/updateProfile";
//import { getCountryCodeSymbol } from '../countryCode/countryCodeSlice';

const initialState = {
  user: {},
  authenticated: false,
  token: null,
  role: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(state, action) {
      const details = {...action.payload.data.details, businessCalenderType: action.payload.data.businessCalenderType}
      state.authenticated = true;
      state.token = action.payload.data.token;
      state.user = details;
      state.role = action.payload.data.role;
    },
    logout(state) {
      state.authenticated = false;
      state.token = null;
      state.user = {};
      state.role = "";
      console.log("HERE--->>>> " + state.token);
    },
    updateList(state, action) {
      state.authenticated = true;
      state.user = action.payload.data;
    },
  },
});

// export const loginAction = (countryCode, phone, otp) => {
//   return async (dispatch) => {
//     return requestFromServer
//       .login(countryCode, phone, otp)
//       .then((response) => {
//         dispatch(authSlice.actions.login(response.data));
//         return response.data;
//       })
//       .catch((error) => {
//         throw new Error(error.response.data.message);
//       });
//   };
// };

export const loginAction = (email, password) => {
  //console.log(email, password);
  return async (dispatch) => {
    return requestFromServer
      .login(email, password)
      .then((response) => {
        //console.log(response);
        if (response.data.message === "Your subscription is expired please renew it.") {
          //console.log(response.data);
          //console.log(response.data.data);
          const data = {};
          data.data = {};
          data.data.details = response.data.data.businessUser;
          data.data.role = response.data.data.role;
          data.data.token = response.data.data.address.Token
          //console.log(data);
          //dispatch(getCountryCodeSymbol(data.data.details));
          dispatch(authSlice.actions.login(data));
          return data;
        } else {
          //console.log(response.data);
          //dispatch(getCountryCodeSymbol(response.data.data.details));
          dispatch(authSlice.actions.login(response.data));
          return response.data;
        }
      })
      .catch((error) => {
        //console.log(error);
        throw new Error(error.response.data.message);
      });
  };
};

// export const branchLoginAction = (countryCode, phone, otp) => {
//   return async (dispatch) => {
//     return requestFromServer
//       .branchLogin(countryCode, phone, otp)
//       .then((response) => {
//         dispatch(authSlice.actions.login(response.data));
//         return response.data;
//       })
//       .catch((error) => {
//         throw new Error(error.response.data.message);
//       });
//   };
// };

export const branchLoginAction = (email, password) => {
  //console.log(email, password);
  return async (dispatch) => {
    return requestFromServer
      .branchLogin(email, password)
      .then((response) => {
        //console.log(response);
        dispatch(authSlice.actions.login(response.data));
        return response.data;
      })
      .catch((error) => {
       // console.log(error);
        throw new Error(error.response.data.message);
      });
  };
};

export const branchProfileUpdateData = (data) => {
  //console.log(data);
  return async (dispatch) => {
    return requestFromServer
      .branchProfileUpdate(data)
      .then((response) => {
        //console.log(response.data.data);
        dispatch(authSlice.actions.updateList(response.data));
        return response.data.data;
      })
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };
};

export const ProfileUpdateData = (data) => {
  //console.log(data);
  return async (dispatch) => {
      updateProfile(data)
      .then((response) => {
       // console.log(response);
        dispatch(authSlice.actions.updateList(response.data));
        return response.data.data;   
      })
      .catch((error) => {
        console.log(error);
        throw new Error(error.response.data.message);
      });
  };
};

export const ProfileView = (data) => {
  //console.log(data);
  return async (dispatch) => {
    viewBusinessProfile(data)
      .then((response) => {
       //console.log(response);
        dispatch(authSlice.actions.updateList(response.data));
        return response.data.data;   
      })
      .catch((error) => {
        console.log(error);
        throw new Error(error.response.data.message);
      });
  };
};

export const forgotPasswordEmail = (email) => {
  //console.log(email);
  return async (dispatch) => {
    return requestFromServer
      .forgotPassword(email)
      .then((response) => {
        //console.log(response);
        //dispatch(authSlice.actions.login(response.data));
        return response.data;
      })
      .catch((error) => {
        //console.log(error);
        throw new Error(error.response.data.message);
      });
  };
};

// Branch Forgot Password
export const branchForgotPassword = (email) => {
  //console.log(email);
  return async (dispatch) => {
    return requestFromServer
      .branchForgotPassword(email)
      .then((response) => {
        //console.log(response);
        //dispatch(authSlice.actions.login(response.data));
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw new Error(error.response.data.message);
      });
  };
};

export const resatPassword = (values) => {
  //console.log(values);
  return async (dispatch) => {
    return requestFromServer
      .resetPassword(values)
      .then((response) => {
        //console.log(response);
        //dispatch(authSlice.actions.login(response.data));
        return response.data;
      })
      .catch((error) => {
        //console.log(error);
        throw new Error(error.response.data.message);
      });
  };
};
// branch
export const branchResatPassword = (values) => {
  //console.log(values);
  return async (dispatch) => {
    return requestFromServer
      .branchResetPassword(values)
      .then((response) => {
        //console.log(response);
        //dispatch(authSlice.actions.login(response.data));
        return response.data;
      })
      .catch((error) => {
        //console.log(error);
        throw new Error(error.response.data.message);
      });
  };
};

const preducer = persistReducer(
  { storage, key: "auth-cred" },
  authSlice.reducer
);
export const authActions = authSlice.actions;
export const authPersist = preducer;
