import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import filterFactory from "react-bootstrap-table2-filter";
import dayjs from "dayjs";
import { sortCaret } from "../../_metronic/_helpers";
import DeleteIcon from '@material-ui/icons/Delete';
import { StatusColumnFormatter } from "../../_metronic/_helpers/tableColumnFormatter";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import paginationFactory from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadHolidayBookingList } from "../../store/business/holiday/branchesHolidaySlice";
import BranchHolidayDialog from "../../components/dialogs/BranchHolidayDialog";

export const CustomerStatusTitles = ["Suspended", "Active", "Pending", ""];

const LinkFormatter = (cell, row) => {
  return <Link to={`branch/${row.uuid}`}>{cell}</Link>;
};

export const BranchesHolidayTable = () => {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.branchesHoliday.bookings);

  const [dataLoading, setDataLoading] = useState(true);
  useEffect(() => {
    dispatch(loadHolidayBookingList()).then(() => {
      setDataLoading(false);
    });
  }, []);

  const DateFormatter = (cell, row) => {
    return dayjs(cell).format("DD-MM-YYYY");
  };

  const ActionDisplay = (cell, row) => {
    return <BranchHolidayDialog content={<i className="flaticon-delete" style={{ cursor: "pointer" }} />} role="business" uuid={row.uuid} />
  };

  const columns = [
    {
      dataField: "Branches[0].name",
      text: "Branch Name",
      sort: true,
      sortCaret: sortCaret,
      // formatter: LinkFormatter,
      // filter: textFilter(),
    },
    {
      dataField: "startDate",
      text: "Holiday Date",
      formatter: DateFormatter,
    },
    {
      dataField: "",
      text: "delete",
      formatter: ActionDisplay,
      //sortCaret: sortCaret,
    },
  ];

  const { SearchBar, ClearSearchButton } = Search;

  return (
    <ToolkitProvider search keyField="uuid" data={data} columns={columns}>
      {(props) => (
        <>
          <div className="container">
            <div class="row">
              <div className="col-sm-4">
                {/* <h4 className="mt-4" >Search</h4> */}
              </div>
              <div className="col-sm-8">
                <SearchBar {...props.searchProps} />
                <ClearSearchButton
                  {...props.searchProps}
                  className="ml-4 btn btn-secondary-light"
                />
              </div>
            </div>
          </div>

          <BootstrapTable
            {...props.baseProps}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            wrapperClasses="table-responsive"
            bordered={false}
            bootstrap4
            loading={true}
            pagination={paginationFactory()}
            filter={filterFactory()}
            noDataIndication={
              <div className={"d-flex justify-content-center py-10"}>
                <h3>{dataLoading ? "Loading Data..." : "No records found"}</h3>
              </div>
            }
          />
        </>
      )}
    </ToolkitProvider>
  );
};
