import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray, useFormikContext } from "formik";
import * as Yup from "yup";

import {
  Input,
  Select,
  DatePickerField,
} from "../../_metronic/_partials/controls";

import { CardBasic } from "../../components/CardBasic";
import { useDispatch, useSelector } from "react-redux";
import { loadBranchList } from "../../store/business/branches/branchSlice";
import { loadChairList } from "../../store/business/chairs/chairSlice";
import { loadStaffList } from "../../store/business/staff/staffSlice";
import { loadServiceList } from "../../store/business/services/servicesSlice";
import { loadPackageList } from "../../store/business/packages/packageSlice";
import SearchCustomerDialog from "../../components/dialogs/SearchCustomerDialog";
import { TimePickerField } from "../../components/pickers/TimePickerField";
import {
  createBooking,
  createBranchBooking,
  resetBooking,
  resetBookings,
} from "../../store/business/bookings/bookingSlice";
import { useHistory } from "react-router";
import AddCustomerDialog from "../../components/dialogs/AddCustomerDialog";
import {
  getAvailableTimeSlots,
  getBranchAvailableTimeSlots,
  getBranchCouponList,
  postBranchCouponApply,
} from "../../store/business/bookings/bookingApi";
import dayjs from "dayjs";
import BranchBookingChairAndStuffEmptyDialog from "../../components/dialogs/BranchBookingChairAndStuffEmptyDialog";
import { sendFailure, sendSuccess } from "../../store/toast/toastSlice";
import { loadBranchChairList } from "../../store/branch/branch_chair/branchChairSlice";
import { loadBranchStaffList } from "../../store/branch/branchStaffSlice";
import { loadBranchServiceList } from "../../store/branch/branch_service/branchServiceSlice";
import { loadBranchPackageList } from "../../store/branch/branch_package/branchPackageSlice";
import { getCountryCodeList } from "../../store/countryCode/countryCodeSlice";
import { loadWalkingCustomer } from "../../store/business/bookings/bookingSlice";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Button,
  FormGroup,
  TextField,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CouponCard from "./CouponCard";

const SubTotal = (props) => {
  const dispatch = useDispatch();
  const { values, setFieldValue } = useFormikContext();
  const serviceValues = values.services;
  const packageValues = values.packages;
  const discount = values.discount ? values.discount.toFixed(2) : 0;
  const { role, user } = useSelector((state) => state.authPersist);
  const { countryCode } = useSelector((state) => state.countryCode);
  const currencySymbol = countryCode.filter(
    (el) => el.countryCode === user.countryCode
  );
  // console.log(serviceValues);
  // console.log(packageValues);
  // console.log(discount);

  useEffect(() => {
    dispatch(getCountryCodeList());
  }, []);

  useEffect(() => {
    const serviceTotal = serviceValues.reduce((serviceTotal, service) => {
      return serviceTotal + parseFloat(service.price);
    }, 0);

    const packageTotal = packageValues.reduce((packageTotal, pack) => {
      return packageTotal + parseFloat(pack.price);
    }, 0);

    const subTotal = +(+serviceTotal + packageTotal * 1).toFixed(2);
    const tax = +((subTotal - discount) * (props.taxSlab / 100)).toFixed(2);
    const total = +(subTotal + tax - discount).toFixed(2);

    //console.log(total);

    setFieldValue("subTotal", subTotal);
    setFieldValue("tax", tax);
    setFieldValue("total", total);
  }, [serviceValues, packageValues, discount]);

  return (
    <div className="form-group row justify-content-end align-items-end">
      <div className="col-8">
        <h4 className="float-right">
          Sub-Total{" "}
          {`(${currencySymbol[0] ? currencySymbol[0].currencySymbol : "₹"})`}
        </h4>
      </div>
      <div className="col-4">
        <Field
          name="subTotal"
          type="number"
          min="1"
          component={Input}
          placeholder="0.00"
          disabled
        />
      </div>
    </div>
  );
};

export const BookingCreate = () => {
  const { role, user } = useSelector((state) => state.authPersist);
  const [itemError, setItemError] = useState("");
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  console.log("availableTimeSlots",availableTimeSlots);
  const dispatch = useDispatch();
  const history = useHistory();
  const [dataLoading, setDataLoading] = useState(true);
  const { countryCode } = useSelector((state) => state.countryCode);
  const [bookingCouponData, setBookingCouponData] = useState({});
  const [couponList, setCouponList] = useState([]);
  const [couponApplied, setCouponApplied] = useState([]);
  const [couponToggle, setCouponToggle] = useState({
    status: false,
    couponCode: {},
  });

  const CouponList = async ()=>{
    await getBranchCouponList()
    .then((res) => {
      console.log("Coupon Code List", res);
      setCouponList(res.data.data);
    })
    .catch((err) => {
      console.log(err.message);
    });
  }

  useEffect( () => {
   CouponList();
  }, []);

  const [expanded, setExpanded] = useState(false);
  const [showExtended, setShowExtended] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
    setShowExtended(false);
  };
  const handleButtonExpandClick = () => {
    setShowExtended(!showExtended);
  };
  const handleApplyCoupon = async (coupon, values,setFieldValue) => {
    if(values.subTotal==0){
      dispatch(sendFailure("Please select packages or services first"))
    }else if(coupon.couponCode==""){
      dispatch(sendFailure("Please select coupon first"))
    }else{
 // Add your logic here to handle the coupon application.
    // This function will be called when a user clicks the "Apply" button for a specific coupon.
    // You can implement actions like sending a request to apply the coupon code, showing a success message, etc.
    console.log(`Applied coupon:`, coupon, values);
    // You can add more functionality here based on your application's requirements.
    let couponData = {};
    couponData.couponCode = coupon.couponCode;
    couponData.services = values.services;
    couponData.packages = values.packages;
    await postBranchCouponApply(couponData)
      .then((res) => {
        dispatch(sendSuccess(res.data.message));
        console.log(res.data.data);
        setCouponApplied(res.data.data);
        setCouponToggle({ status: true, couponCode: coupon });
        setBookingCouponData(res.data.data)
        setFieldValue("subTotal",res.data.data.subTotal);
        setFieldValue("tax",res.data.data.tax);
        setFieldValue("discount",res.data.data?.discount);
        setFieldValue("total",res.data.data?.total)
        setExpanded(false)
      })
      .catch((err) => {
        dispatch(sendFailure("The Coupon is Invalid."));

        console.log(err);
      });
    }
   
  };
  const handleRemoveCoupon = (values,setFieldValue) => {
    setCouponToggle({ status: false, couponCode: {} });
    setFieldValue("couponCode","")
    // setFieldValue("subTotal","");
    // setFieldValue("tax","");
    setFieldValue("discount",0);
    // setFieldValue("total","")
    // setBookingCouponData({})
    // const { values, setFieldValue } = useFormikContext();
    const serviceValues = values.services;
    const packageValues = values.packages;
    const discount = values.discount ? values.discount.toFixed(2) : 0;

    // useEffect(() => {
      const serviceTotal = serviceValues.reduce((serviceTotal, service) => {
        return serviceTotal + parseFloat(service.price);
      }, 0);
  
      const packageTotal = packageValues.reduce((packageTotal, pack) => {
        return packageTotal + parseFloat(pack.price);
      }, 0);
  
      const subTotal = +(+serviceTotal + packageTotal * 1).toFixed(2);
      const tax = +((subTotal - discount) * (user.taxSlab / 100)).toFixed(2);
      const total = +(subTotal + tax - discount).toFixed(2);
  
      console.log(subTotal,tax,total);
  
      setFieldValue("subTotal", subTotal);
      setFieldValue("tax", tax);
      setFieldValue("total", total);
      setCouponApplied([])
    // }, [serviceValues, packageValues, discount]);
  };

  const currencySymbol = countryCode.filter(
    (el) => el.countryCode === user.countryCode
  );
  const branches = useSelector((state) => {
    if (role === "business") {
      return state.branch.branches.filter((data) => {
        return data.isActive === true;
      });
    } else {
      return [user];
    }
  });

  const staff = useSelector((state) => {
    if (role === "business") {
      return state.staff.staff.filter((singleStaff) => {
        return singleStaff.isActive === true;
      });
    } else {
      return state.branchStaff.branchStaff.filter((singleStaff) => {
        return singleStaff.isActive === true;
      });
    }
  });

  const { currentCustomer } = useSelector((state) => state.booking);
  console.log("currentCustomer",currentCustomer)
  const [currentCustomerState,SetCurrentCustomerState] = useState(null);
  useEffect(()=>{
    SetCurrentCustomerState(currentCustomer)
  },[currentCustomer])
  useEffect(()=>{
    SetCurrentCustomerState(null)
  },[dispatch,resetBooking])  
  
  console.log("current",currentCustomerState)
  const { chairs } = useSelector((state) => state.chair);

  //console.log(chairs);
  const branchChairs = useSelector((state) => state.branchChairs.branchChairs);

  const services = useSelector((state) => {
    if (role === "business") {
      return state.service.services.filter((data) => {
        return data.isActive === true;
      });
    } else {
      return state.branchServices.branchServices.filter((data) => {
        return data.isActive === true;
      });
    }
  });
  //console.log(services);
  const packages = useSelector((state) => {
    if (role === "business") {
      return state.package.packages.filter((data) => {
        return data.isActive === true;
      });
    } else {
      return state.branchPackages.branchPackages.filter((data) => {
        return data.isActive === true;
      });
    }
  });

  let emptyServiceRow = {
    serviceUuid: services.length > 0 ? services[0].uuid : "",
    price: services.length > 0 ? services[0].price : "",
    name: services.length > 0 ? services[0].name : "",
  };

  console.log(packages);
  let emptyPackageRow = {
    packageUuid: packages.length > 0 ? packages[0].uuid : "",
    price: packages.length > 0 ? packages[0].price : "",
    name: packages.length > 0 ? packages[0].name : "",
  };
  //console.log(emptyPackageRow);
  const getTimeSlots = (date, branchUuid = null) => {
    console.log("getting timeslots");

    if (role == "business") {
      //console.log("business timeslot");
      getAvailableTimeSlots(date, branchUuid)
        .then((response) => {
          console.log("time slots ",response);
          setAvailableTimeSlots(response.data.data.timeSlots);
        })
        .catch((err) => {
          console.log(err.message);
        });
    } else {
      //console.log("branch timeslot");
      getBranchAvailableTimeSlots(date, branchUuid)
        .then((response) => {
          console.log("time slots ",response);
          setAvailableTimeSlots(response.data.data.timeSlots);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };

  useEffect(() => {
    if (branches.length === 0) {
      if (role == "business") {
        dispatch(loadBranchList()).then(() => {
          setDataLoading(false);
        });
      } else {
        setDataLoading(false);
      }
    }
    // if (branches.length > 0) {
    //   if (role == "business") {
    //     console.log("initial timeslots");
    //     getAvailableTimeSlots(new Date(), branches[0].uuid);
    //   } else {
    //     console.log("initial timeslots");
    //     getBranchAvailableTimeSlots(new Date());
    //     return;
    //   }
    // }
  }, []);

  useEffect(() => {
    if (chairs.length === 0) {
      if (role === "business") {
        dispatch(loadChairList()).then(() => {
          setDataLoading(false);
        });
      } else {
        dispatch(loadBranchChairList())
          .then(() => {
            setDataLoading(false);
          })
          .catch((err) => {
            console.log(err.message);
          });
      }
    }
  }, []);

  useEffect(() => {
    if (staff.length === 0) {
      if (role == "business") {
        dispatch(loadStaffList()).then(() => {
          setDataLoading(false);
        });
      } else {
        dispatch(loadBranchStaffList())
          .then(() => {
            setDataLoading(false);
          })
          .catch((err) => {
            console.log(err.message);
          });
      }
    }
  }, []);

  useEffect(() => {
    if (services.length === 0) {
      if (role === "business") {
        dispatch(loadServiceList()).then(() => {
          setDataLoading(false);
          emptyServiceRow = {
            serviceUuid: services.length > 0 ? services[0].uuid : "",
            price: services.length > 0 ? services[0].price : "",
            name: services.length > 0 ? services[0].name : "",
          };
        });
      } else {
        dispatch(loadBranchServiceList())
          .then(() => {
            setDataLoading(false);
            emptyServiceRow = {
              serviceUuid: services.length > 0 ? services[0].uuid : "",
              price: services.length > 0 ? services[0].price : "",
              name: services.length > 0 ? services[0].name : "",
            };
          })
          .catch((err) => {
            console.log(err.message);
          });
      }
    }
  }, []);

  useEffect(() => {
    if (packages.length === 0) {
      if (role === "business") {
        dispatch(loadPackageList()).then(() => {
          setDataLoading(false);
          emptyPackageRow = {
            packageUuid: packages.length > 0 ? packages[0].uuid : "",
            price: packages.length > 0 ? packages[0].price : "",
            name: packages.length > 0 ? packages[0].name : "",
          };
        });
      } else {
        dispatch(loadBranchPackageList())
          .then(() => {
            setDataLoading(false);
            emptyPackageRow = {
              packageUuid: packages.length > 0 ? packages[0].uuid : "",
              price: packages.length > 0 ? packages[0].price : "",
              name: packages.length > 0 ? packages[0].name : "",
            };
          })
          .catch((err) => {
            console.log(err.message);
          });
      }
    }
  }, []);

  const [todayDate, setTodayDate] = useState(false);

  const addWalkingCustomerHandler = (values) => {
    // console.log(user);
    // console.log(countryCode);
    if (user.countryCode === 91) {
      dispatch(loadWalkingCustomer({ phone: "0000000000" }));
    } else if (user.countryCode === 971) {
      dispatch(loadWalkingCustomer({ phone: "000000000" }));
    } else if (user.countryCode === 256) {
      dispatch(loadWalkingCustomer({ phone: "0000000000" }));
    } else {
      dispatch(loadWalkingCustomer({ phone: "0000000000" }));
    }
    setTodayDate(true);
    console.log("date changed");
    
    let date = getTodayDate();
    getTimeSlots(date, values.branchUuid);
    console.log("today date",date, todayDate);
  };

  const getTodayDate = () => {
    let today = new Date();
    let date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    // setTodayDate(!todayDate);
    console.log(date,todayDate);
    return date;
  };

  // Validation schema

  const ValidationSchema = Yup.object().shape({
    customer: Yup.string().required(
      "This field is required. You must choose a customer to add booking."
    ),
    branchUuid:
      role == "business" ? Yup.string().required("This field is required") : "",
    date: Yup.date().required("This field is required"),
    startTime: Yup.string().required("This field is required"),
    duration: Yup.number().required("This field is required"),
    chairUuid: Yup.string().required("This field is required"),
    staffUuid: Yup.string().required("This field is required"),
    remarks: Yup.string(),
    subTotal: Yup.number().required("This field is required"),
    tax: Yup.number(),
    discount: Yup.number().required("This field is required"),
    total: Yup.number().required("This field is required"),
  });

  if (
    (dataLoading === false && staff.length === 0) ||
    (dataLoading === false && branchChairs.length === 0) ||
    (dataLoading === false && services.length === 0)
  ) {
    return (
      <BranchBookingChairAndStuffEmptyDialog
        data="There are no active Staff/Chair/service available in your branch."
        data2="Kindly create/update the same to proceed further."
      />
    );
  }

  // if (dataLoading === false && services.length === 0) {
  //   return (
  //     <BranchBookingChairAndStuffEmptyDialog
  //       data='There are no active service/package available in your branch.'
  //       data2='Kindly create/update the same to proceed further.'
  //     />
  //   );
  // }

  return (
    <CardBasic
      title={"Add Booking"}
      btnType={"submit"}
      formId={"createBookingForm"}
      headerBtnText={"Save Booking"}
    >
      <Formik
        initialValues={{
          customer: currentCustomerState
            ? `${currentCustomerState?.name} - ${currentCustomerState?.phone}`
            : "",
          branchUuid: branches.length > 0 ? branches[0].uuid : "",
          date: todayDate===true ? getTodayDate() : "",
          startTime: "",
          duration: "30",
          chairUuid: branchChairs.length > 0 ? branchChairs[0].uuid : "",
          remarks: "",
          staffUuid: staff.length > 0 ? staff[0].uuid : "",
          services: [], // [emptyServiceRow]
          packages: [], // [emptyPackageRow]
          // servicesTotal: 0,
          // packagesTotal: 0,
          couponCode:"",
          subTotal: 0,
          tax: 0,
          discount: 0,
          // coupon: "",
          total: 0,
        }}
        enableReinitialize
        validationSchema={ValidationSchema}
        onSubmit={
          (values, errors) => {
            console.log(values);
            // const newDate = values.date;
            // newDate.setDate(newDate.getDate() + 1)
            const newValues = {
              ...values,
              customer: {
                name: currentCustomerState?.name,
                phone: currentCustomerState?.phone,
              },
            };
            console.log(newValues);
            SetCurrentCustomerState(null)
            // dispatch(resetBookings({dispatch}))
            dispatch(resetBooking())
            //const newValue= {...values, date: newDate};
            //console.log(newValue);
            let sumOfServices = values.services.length + values.packages.length;
            if (sumOfServices > 0) {
              if (role === "business") {
                dispatch(createBooking(newValues))
                  .then(() => {
                    setItemError("");
                    dispatch(
                      sendSuccess("The booking has been created successfully")
                    );
                    history.replace("/bookings/upcoming");
                  })
                  .catch((err) => {
                    dispatch(sendFailure(err.message));
                    setItemError("Error. Unable to create booking");
                    console.log(err.message);
                  });
              } else if (role == "branch") {
                newValues.couponUuid = couponApplied.couponUuid ? couponApplied?.couponUuid : ""
                console.log("newValues",newValues)
                dispatch(createBranchBooking(newValues))
                  .then(() => {
                    setItemError("");
                    console.log("here in create")
                    dispatch(
                      sendSuccess("The booking has been created successfully")
                    );
                    console.log("before history")
                    history.replace("/bookings/upcoming");
                    console.log("done branch booking");
                  })
                  .catch((err) => {
                    console.log(err)
                    dispatch(sendFailure("Please select another time. All chairs are booked."));
                    setItemError("Error. Unable to create booking");
                  });
              } else {
                setItemError(
                  "At least one service should be added while creating any package"
                );
              }
            } else {
              setItemError(
                "At least one service should be added while creating any package"
              );
            }
            
          }

          // saveCustomer(values);
        }
      >
        {({ values, errors, setFieldValue, touched,handleChange,handleBlur }) => (
          <Form className="form form-label-right" id="createBookingForm">
            {itemError && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">{itemError}</div>
              </div>
            )}
            <div className="form-group row">
              <div className="col-lg-12">
                <Field
                  name="customer"
                  label="Customer *"
                  type="text"
                  className="form-control"
                  disabled
                />
                {/* {currentCustomerState?.phone && (
                    <option
                      value={currentCustomerState?}
                      defaultValue
                    >{`${currentCustomerState?.name} - ${currentCustomerState?.phone}`}</option>
                  )}
                </Select> */}
              </div>
              <div className="col-lg-12 d-flex mt-3">
                <span>
                  <button type="button" className="btn btn-light-success mr-5">
                    <SearchCustomerDialog content="Search Customer" />
                  </button>
                </span>
                <span>
                  <button type="button" className="btn btn-light-success mr-5">
                    <AddCustomerDialog content="Add Customer" />
                  </button>
                </span>
                <span>
                  <button type="button" className="btn btn-light-success" onClick={()=>addWalkingCustomerHandler(values)}>
                    <span
                      className="navi-text"
                      
                    >
                      Walk In
                    </span>
                  </button>
                </span>
              </div>
            </div>
            <div className="form-group row">
              {role == "business" ? (
                <div className="col-lg-4">
                  <Select
                    name="branchUuid"
                    label="Branch *"
                    onChange={(e) => {
                      setFieldValue("branchUuid", e.target.value);
                      setFieldValue("date", "");
                      setAvailableTimeSlots([]);
                    }}
                  >
                    {branches.map((branch, index) => (
                      <option value={branch.uuid} key={index}>
                        {`${branch.name} - ${branch.city}`}
                      </option>
                    ))}
                  </Select>
                </div>
              ) : (
                ""
              )}

              <div className="col-lg-4">
                <Select name="chairUuid" label="Chair *">
                  {role == "business"
                    ? chairs.map((chair, index) => (
                        <option value={chair.uuid} key={index}>
                          {chair.chairName}
                        </option>
                      ))
                    : branchChairs.map((chairdata, index) => (
                        <option value={chairdata.uuid} key={index}>
                          {chairdata.chairName}
                        </option>
                      ))}
                </Select>
              </div>
              <div className="col-lg-4">
                <Select name="staffUuid" label="Staff *">
                  {staff.map((singleStaff, index) => (
                    <option value={singleStaff.uuid} key={index}>
                      {singleStaff.name}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
            {/* Contact Number */}
            <div className="form-group row">
              <div className="col-lg-4">
                <div>
                  <label>Booking Date *</label>
                </div>
                <div className="w-100">
                  {todayDate === true ? (
                    <DatePickerField
                      name="date"
                      className="form-control"
                      minDate={new Date()}
                      disabled
                      onChange={(val) => {
                        //console.log(val);
                        console.log("date changed");
                        getTimeSlots(val, values.branchUuid);
                        //val.setDate(val.getDate() + 1);
                        // val.setHours(1, 1, 1);
                        // console.log(val);
                        setFieldValue("date", val);
                      }}
                    ></DatePickerField>
                  ) : (
                    <DatePickerField
                      name="date"
                      className="form-control"
                      minDate={new Date()}
                      onChange={(val) => {
                        //console.log(val);
                        //console.log("date changed");
                        getTimeSlots(val, values.branchUuid);
                        //val.setDate(val.getDate() + 1);
                        // val.setHours(1, 1, 1);
                        // console.log(val);
                        setFieldValue("date", val);
                      }}
                    ></DatePickerField>
                  )}
                </div>
              </div>
              <div className="col-lg-4">
                <div>
                  <Select name="startTime" label="Start Time *">
                    <option value="">Select Start Time</option>
                    {availableTimeSlots.map((timeSlot, index) => (
                      <option value={timeSlot.slotTiming} key={index}>
                        {dayjs(timeSlot.slotTiming).format("hh:mm A")}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="col-lg-4">
                <Field
                  name="duration"
                  type="number"
                  // min="30"
                  // step="30"
                  component={Input}
                  placeholder="30"
                  label="Duration (Minutes) *"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-12">
                <label>Booking Remarks</label>
                <Field
                  name="remarks"
                  as="textarea"
                  rows="2"
                  className="form-control"
                />
              </div>
            </div>
            <h3 className="mt-10">Select Services *</h3>
            <hr />
            <div className="form-group row mb-0">
              <div className="col-8">
                <label>Service</label>
              </div>
              <div className="col-4">
                <label>
                  Price{" "}
                  {`(${
                    currencySymbol[0] ? currencySymbol[0].currencySymbol : "₹"
                  })`}
                </label>
              </div>
            </div>
            <FieldArray name="services">
              {({ push, remove }) => (
                <>
                  {values.services.map((_, index) => (
                    <div className="form-group row" key={index}>
                      <div className="col-8">
                        <Select
                          name={`services.[${index}].serviceUuid`}
                          onChange={(e) => {
                            setFieldValue(
                              `services.[${index}].serviceUuid`,
                              e.target.value
                            );
                            setFieldValue(
                              `services.[${index}].price`,
                              String(
                                services
                                  .filter((service) => {
                                    return service.uuid === e.target.value;
                                  })
                                  .map((service) => {
                                    return service.price;
                                  })
                              )
                            );
                            setFieldValue(
                              `services.[${index}].name`,
                              String(
                                services
                                  .filter((service) => {
                                    return service.uuid === e.target.value;
                                  })
                                  .map((service) => {
                                    return service.name;
                                  })
                              )
                            );
                          }}
                        >
                          {services.map((service, index) => (
                            <option value={service.uuid} key={service.uuid}>
                              {service.name}
                            </option>
                          ))}
                        </Select>
                      </div>
                      <div className="col-4 d-flex">
                        <Field
                          name={`services.[${index}].price`}
                          type="number"
                          component={Input}
                          disabled
                        />
                        <span>
                          <a
                            className="btn font-weight-bold btn-danger btn-icon ml-2"
                            onClick={() => remove(index)}
                          >
                            <i className="flaticon2-cross icon-1x"></i>
                          </a>
                        </span>
                      </div>
                    </div>
                  ))}
                  {/* <div className="form-group row justify-content-end align-items-end">
                    <div className="col-8">
                      <h4 className="float-right">Services Total (₹)</h4>
                    </div>
                    <div className="col-4">
                      <Field
                        name="servicesTotal"
                        type="number"
                        min="1"
                        component={Input}
                        placeholder="0.00"
                        disabled
                      />
                    </div>
                  </div> */}
                  <div className="form-group row">
                    <div className="col-md-5">
                      <button
                        type="button"
                        className="btn font-weight-bold btn-primary"
                        onClick={() => push(emptyServiceRow)}
                      >
                        <i className="flaticon2-add-1 icon-1x"></i>Add Service
                      </button>
                    </div>
                  </div>
                </>
              )}
            </FieldArray>
            <h3 className="mt-10">Select Package</h3>
            <hr />
            <div className="form-group row mb-0">
              <div className="col-8">
                <label>Package</label>
              </div>
              <div className="col-4">
                <label>
                  Price{" "}
                  {`(${
                    currencySymbol[0] ? currencySymbol[0].currencySymbol : "₹"
                  })`}
                </label>
              </div>
            </div>
            <FieldArray name="packages">
              {({ push, remove }) => (
                <>
                  {values.packages.map((_, index) => (
                    <div className="form-group row" key={index}>
                      <div className="col-8">
                        <Select
                          name={`packages.[${index}].packageUuid`}
                          onChange={(e) => {
                            setFieldValue(
                              `packages.[${index}].packageUuid`,
                              e.target.value
                            );
                            setFieldValue(
                              `packages.[${index}].price`,
                              String(
                                packages
                                  .filter((pack) => {
                                    return pack.uuid === e.target.value;
                                  })
                                  .map((pack) => {
                                    return pack.price;
                                  })
                              )
                            );
                            setFieldValue(
                              `packages.[${index}].name`,
                              String(
                                packages
                                  .filter((pack) => {
                                    return pack.uuid === e.target.value;
                                  })
                                  .map((pack) => {
                                    return pack.name;
                                  })
                              )
                            );
                          }}
                        >
                          {packages.map((pack, index) => (
                            <option value={pack.uuid} key={pack.uuid}>
                              {pack.name}
                            </option>
                          ))}
                        </Select>
                      </div>
                      <div className="col-4 d-flex">
                        <Field
                          name={`packages.[${index}].price`}
                          type="number"
                          component={Input}
                          disabled
                        />
                        <span>
                          <a
                            className="btn font-weight-bold btn-danger btn-icon ml-2"
                            onClick={() => remove(index)}
                          >
                            <i className="flaticon2-cross icon-1x"></i>
                          </a>
                        </span>
                      </div>
                    </div>
                  ))}
                  {/* <div className="form-group row justify-content-end align-items-end">
                    <div className="col-8">
                      <h4 className="float-right">Packages Total (₹)</h4>
                    </div>
                    <div className="col-4">
                      <Field
                        name="packagesTotal"
                        type="number"
                        min="1"
                        component={Input}
                        placeholder="0.00"
                        disabled
                      />
                    </div>
                  </div> */}
                  <div className="form-group row">
                    <div className="col-md-5">
                      <button
                        type="button"
                        className="btn font-weight-bold btn-primary"
                        onClick={() => push(emptyPackageRow)}
                      >
                        <i className="flaticon2-add-1 icon-1x"></i>Add Package
                      </button>
                    </div>
                  </div>
                </>
              )}
            </FieldArray>
            <>
                <h3 className="mt-10">Apply Coupon</h3>
                <hr />
                <div className="">
                  <label>Coupon</label>
                  <div className="d-flex w-100 mb-4 justify-content-space-between form-group">
                    {couponToggle.status ? (
                      <input
                        name="couponCode"
                        type="text"
                        
                        // placeholder="Search..."
                        // value={searchValue}
                        onChange={handleChange}
                        className="form-control"
                        disabled
                        value={couponToggle.couponCode.couponCode}
                      />
                    ) : (
                      <input
                        name="couponCode"
                        type="text"
                        
                        // placeholder="Search..."
                        // value={searchValue}
                        onChange={handleChange}
                        className="form-control"
                        value={values.couponCode}
                      />
                    )}

                    {couponToggle.status ? (
                      <button
                        variant="contained"
                        color="primary"
                        type="button"
                        className="btn btn-danger w-25"
                        onClick={()=>handleRemoveCoupon(values,setFieldValue)}
                      >
                        {" "}
                        Remove
                      </button>
                    ) : (
                      <button
                        variant="contained"
                        color="primary"
                        type="button"
                        className="btn btn-light-success w-25"
                        onClick={() => handleApplyCoupon(values, values,setFieldValue)}
                      >
                        {" "}
                        Apply
                      </button>
                    )}
                  </div>
                </div>

                <div>
                  <ExpansionPanel
                    expanded={expanded}
                    className="border-no-shadow"
                  >
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      onClick={handleExpandClick}
                      className="border-primary"
                      style={{ border: "1 ps solid #339187" }}
                    >
                      <h3 className="text-primary" style={{ color: "#339187" }}>
                        View Coupons
                      </h3>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="">
                      <div className="w-100">
                        <div className="gap-2">
                          {couponList?.map((coupon, index) => {
                            // if (index <= 1) {
                            return (
                              <CouponCard
                                key={index}
                                coupon={coupon}
                                className={"w-100"}
                                setCouponToggle={setCouponToggle}
                                couponToggle={couponToggle}
                                onClickApply={() =>
                                  handleApplyCoupon(coupon, values,setFieldValue)
                                }
                              />
                            );
                            // } else {
                            //   return;
                            // }
                          })}
                        </div>
                        {/* {showExtended && (
                      <div className="gap-2">
                        {couponList.map((coupon, index) => {
                          if (index > 1) {
                            return (
                              <CouponCard
                                key={index}
                                coupon={coupon}
                                className={"w-100"}
                                setCouponToggle={setCouponToggle}
                                couponToggle={couponToggle}
                                onClickApply={() =>
                                  handleApplyCoupon(coupon, values)
                                }
                              />
                            );
                          }
                        })}
                      </div>
                    )} */}
                        {/* <div className="d-flex justify-content-end mb-2">
                      {couponList.length > 2 && (
                        <button
                          onClick={handleButtonExpandClick}
                          className="btn font-weight-bold btn-primary"
                          type="button"
                        >
                          {showExtended ? "Show Less" : "Show More"}
                        </button>
                      )}
                    </div> */}
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
              </>
            <hr />
            <SubTotal taxSlab={user.taxSlab} />
            <div className="form-group row justify-content-end align-items-end">
              <div className="col-8">
                <h4 className="float-right">
                  Discount{" "}
                  {`(${
                    currencySymbol[0] ? currencySymbol[0].currencySymbol : "₹"
                  })`}
                </h4>
              </div>
              <div className="col-4">
                <Field
                  name="discount"
                  type="number"
                  disabled
                  min="0"
                  value={values.discount.toFixed(2)}
                  component={Input}
                  placeholder="0.00"
                />
              </div>
            </div>
            <div className="form-group row justify-content-end align-items-end">
              <div className="col-8">
                <h4 className="float-right">
                  Tax{" "}
                  {`(${
                    currencySymbol[0] ? currencySymbol[0].currencySymbol : "₹"
                  })`}
                </h4>
              </div>
              <div className="col-4">
                <Field
                  name="tax"
                  type="number"
                  min="0"
                  value={values.tax.toFixed(2)}
                  component={Input}
                  placeholder="0.00"
                  disabled
                />
              </div>
            </div>
            <div className="form-group row justify-content-end align-items-end">
              <div className="col-8">
                <h4 className="float-right">
                  Total{" "}
                  {`(${
                    currencySymbol[0] ? currencySymbol[0].currencySymbol : "₹"
                  })`}
                </h4>
              </div>
              <div className="col-4">
                <Field
                  name="total"
                  type="number"
                  min="1"
                  value={values.total.toFixed(2)}
                  component={Input}
                  placeholder="0.00"
                  disabled
                />
              </div>
            </div>
            {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
          </Form>
        )}
      </Formik>
    </CardBasic>
  );
};
