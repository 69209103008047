import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, Select } from "../../_metronic/_partials/controls";
import { useDispatch } from "react-redux";
import { CardSecondary } from "../../components/CardSecondary";

import { updateBranch } from "../../store/business/branches/branchApi";
import { MultiSelect } from "../../_metronic/_partials/controls/forms/MultiSelect";
import { TimePickerField } from "../../components/pickers/TimePickerField";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { useHistory, useParams } from "react-router";
import { updateBranchData } from "../../store/branch/branchUpdate";
import ChangePasswordDialog from "../../components/dialogs/ChangePasswordDialog";
import { branchProfileUpdateData } from "../../store/business/authSlice";
import { sendFailure, sendSuccess } from "../../store/toast/toastSlice";

export function BranchProfile(props) {
  const [branchData, setBranchData] = useState("");
  const dispatch = useDispatch();
  // Validation schema
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const history = useHistory();

  const { segments } = useSelector((state) => state.data);

  const ValidationSchema = Yup.object().shape({
    name: Yup.string()
      .min(5, "Minimum 5 characters")
      .required("This field is required"),
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(4, "Enter 4 digit phone number")
      .max(20, "Enter 20 digit phone number")
      .required("This field is required"),
    email: Yup.string()
      .email("Enter valid email address")
      .required("This field is required"),
    password: Yup.string().min(
      8,
      "Password is too short - should be 8 chars minimum."
    ),
    c_password: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
    gender: Yup.string().required("Type is required"),
    otherContact: Yup.string(),
    address_line1: Yup.string().required("This field is required"),
    address_line2: Yup.string(),
    city: Yup.string().required("This field is required"),
    zipcode: Yup.string().required("This field is required"),
    website: Yup.string().url(),
    gmap: Yup.string().url(),
    isActive: Yup.string().required("This field is required"),
    startTime: Yup.date().required("This field is required"),
    endTime: Yup.date()
      .min(Yup.ref("startTime"), "End Time must be greater than Start Time")
      .required("This field is required"),
    description: Yup.string().required("Description is required"),
  });
  console.log(props);
  useEffect(() => {
    if (branchData === "") {
      setBranchData(props.branch);
    }
  }, []);

  return (
    <CardSecondary
      title={"Branch Profile"}
      btnType={"submit"}
      btn2Type={"other"}
      formId={"updateForm"}
      headerBtnText={"Update"}
      headerBtn2Text={
        <ChangePasswordDialog content="Change Password" role="branch" />
      }
    >
      <Formik
        initialValues={{
          name: branchData.name ?? "",
          phone: branchData.phone ?? "",
          email: branchData.email ?? "",
          password: "",
          c_password: "",
          gender: branchData.gender ?? "unisex",
          otherContact: branchData.otherContact ?? "",
          address_line1: branchData.address_line1 ?? "",
          address_line2: branchData.address_line2 ?? "",
          city: branchData.city ?? "",
          zipcode: branchData.zipcode ?? "",
          website: branchData.website ?? "",
          gmap: branchData.gmap ?? "",
          isActive: branchData.isActive ?? false,
          image: "",
          description: branchData.description ?? "",
          startTime: branchData.startTime
            ? dayjs(branchData.startTime, "hh:mm A")
            : "",
          endTime: branchData.endTime
            ? dayjs(branchData.endTime, "hh:mm A")
            : "",
        }}
        enableReinitialize
        validationSchema={ValidationSchema}
        onSubmit={(values, actions) => {
          console.log(values);

          dispatch(branchProfileUpdateData(values))
            .then((res) => {
              dispatch(
                sendSuccess("The branch profile has been updated succesfully")
              );
            })
            .catch((err) => {
              dispatch(sendFailure(err.message));
              actions.setStatus(err.message);
              console.log(err.message);
            });
          actions.setFormStatus("submitted");
        }}
      >
        {(formProps) => (
          <Form className="form form-label-right" id="updateForm">
            {branchData.image && (
              <div className="form-group row col">
                <img
                  src={`${process.env.REACT_APP_MEDIA_URL}/${branchData.image}`}
                  width="300px"
                ></img>
              </div>
            )}
            <div className="form-group row">
              {/* Branch Name */}
              <div className="col-lg-8">
                <Field
                  name="name"
                  component={Input}
                  placeholder="Branch Name"
                  label="Branch Name "
                  disabled
                />
              </div>
            </div>
            {/* Contact Number */}
            <div className="form-group row">
              <div className="col-lg-4">
                <Field
                  name="phone"
                  component={Input}
                  placeholder="9899898893"
                  label="Contact Number *"
                  pattern="[1-9]{1}[0-9]{9}"
                  //maxLength="10"
                  disabled
                />
              </div>
              <div className="col-lg-4">
                <Field
                  name="email"
                  type="email"
                  component={Input}
                  placeholder="example@domain.com"
                  label="Email Address "
                  disabled
                />
              </div>
              <div className="col-lg-4">
                <Select name="gender" label="Type *" disabled>
                  <option value="unisex">Unisex</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </Select>
              </div>
            </div>
            {/* New Password */}
            {/* <div className="form-group row">
              <div className="col-lg-6">
                <Field
                  name="password"
                  type="password"
                  component={Input}
                  placeholder=""
                  label="New Password"
                />
              </div>
              <div className="col-lg-6">
                <Field
                  name="c_password"
                  type="password"
                  component={Input}
                  placeholder=""
                  label="Conform Password"
                />
              </div>
            </div> */}

            <div className="form-group row">
              <div className="col">
                <Field
                  name="otherContact"
                  component={Input}
                  label="Additional Contact Numbers"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-4">
                <Field
                  name="address_line1"
                  component={Input}
                  label="Address Line 1 *"
                />
              </div>
              <div className="col-lg-4">
                <Field
                  name="address_line2"
                  component={Input}
                  label="Address Line 2"
                />
              </div>
              <div className="col-lg-4">
                <Field name="city" component={Input} label="City *" />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-6">
                <Field name="zipcode" component={Input} label="Zipcode *" />
              </div>

              <div className="col-lg-6">
                <label>Branch Image (*jpg,*jpeg,*png)</label>

                <input
                  type="file"
                  name="image"
                  className="form-control"
                  onChange={(e) => {
                    let file = e.target.files[0];
                    console.log(file.type);
                    if (
                      file.type == "image/jpeg" ||
                      file.type == "image/jpg" ||
                      file.type == "image/png"
                    ) {
                      if (file.size <= 1000000) {
                        formProps.setFieldValue("image", e.target.files[0]);
                      } else {
                        formProps.setFieldError(
                          "image",
                          "Image size should be less than 1 MB"
                        );
                      }
                    } else {
                      formProps.setFieldError(
                        "image",
                        "Please enter a valid file format"
                      );
                    }
                  }}
                />
                {formProps.errors.image && (
                  <div className="invalid-feedback d-flex">
                    {formProps.errors.image}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-12">
                <Field name="gmap" component={Input} label="Google Maps Link" />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-12">
                <Field
                  name="website"
                  component={Input}
                  label="Website"
                  placeholder="https://example.com"
                />
              </div>
            </div>
            <div className="form-group row">
              {/* Branch Name */}
              <div className="col-lg-6">
                <TimePickerField
                  name="startTime"
                  label="Start Time *"
                  placeholder="Start Time"
                  disabled
                />
              </div>
              <div className="col-lg-6">
                <TimePickerField
                  name="endTime"
                  label="End Time *"
                  placeholder="End Time"
                  disabled
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-12">
                <label>Branch Description *</label>
                <Field
                  name="description"
                  as="textarea"
                  rows="5"
                  className="form-control"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-4">
                <Select name="isActive" label="Disable Booking *">
                  <option value={true}>No</option>
                  <option value={false}>Yes</option>
                </Select>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </CardSecondary>
  );
}
