import React from "react";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import BookOnlineIcon from '@material-ui/icons/Book';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import DescriptionIcon from '@material-ui/icons/Description';
import UpdateIcon from '@material-ui/icons/Update';
import BusinessIcon from '@material-ui/icons/Business';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

function Privacy() {
  return (
    <div className="p-0 m-0" style={{ background: ' #f1f3f5' }}>
      <div
        className="container-fluid bg-dark d-flex flex-column justify-content-center align-items-center"
        style={{ height: "220px", borderRadius: "10px" }}
      >
        <h2 className="display-4 text-white mb-5"><DescriptionIcon className="mr-5 display-4 mb-2" />Privacy Policy</h2>
        <h4 className="display-6 text-white"><UpdateIcon className="mr-5 display-6 mb-2" />LAST UPDATE ON – 29th August 2022</h4>
      </div>
      <div
        className="container bg-white p-12"
        style={{ width: "90%", marginTop: "-40px", borderRadius: "10px" }}
      >
        <div className="mb-8">
          <h2 className="mb-4 display-5"><BusinessIcon className="display-4 pr-2 mb-2" />Welcome to BELLAZ’s PRIVACY POLICY</h2>
          <p className="h5" style={{ color: "black" }}>At BELLAZ’s, accessible from <a href="https://my-bellaz.com" target="_blank">my-bellaz.com</a> and BELLAZ’s Mobile App, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by BELLAZ’s and how we use it. If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us at <a href="mailto:support@my-bellaz.com" target="_blank">support@my-bellaz.com</a>.</p>
        </div>
        <div className="mb-8">
          <h2 className="mb-4 display-5">1. BACKGROUND OF THIS PRIVACY POLICY </h2>
          <p className="h5" style={{ color: "black" }}>We respect the privacy of your personal data (any identity revealing information such as name, contact details, and/or bank information) you share with us. We assure you that we practice industry guidelines to safeguard your privacy on our platform, both website and mobile app, as stated above.</p>
          <p className="h5" style={{ color: "black" }}>We regularly review and update of privacy policy, and we request you to regularly review this policy. It is important that the  personal data we hold about you is accurate.  Please let us know if your personal data changes during the relationship with us.</p>
        </div>
        <div className="mb-8">
          <h2 className="mb-4 display-5">2. HOW, DO WE COLLECT THE DATA  ?</h2>
          <p className="h5" style={{ color: "black" }}>We monitor the IP address of the visitors and analyse the data thus collected to understand trends and market statistics. Our platform may gather your personal information, if you;</p>
          <ul className="ml-10">
            <li className="h5" style={{ color: "black", listStyle: 'decimal' }}>Sign up and create your profile, or</li>
            <li className="h5" style={{ color: "black", listStyle: 'decimal' }}>Otherwise share your personal information with us, or</li>
            <li className="h5" style={{ color: "black", listStyle: 'decimal' }}>Use BELLAZ’s platform services in any way, or</li>
            <li className="h5" style={{ color: "black", listStyle: 'decimal' }}>Fill up our contact forms or subscribe to our newsletters or other information from us, such as emails, or</li>
            <li className="h5" style={{ color: "black", listStyle: 'decimal' }}>Apply for a job, take part in our surveys and contests or otherwise take part in activities we promote that may require your personal data.</li>
          </ul>
        </div>
        <div className="mb-8">
          <h2 className="mb-4 display-5">3. WHAT PERSONAL DATA THAT WE COLLECT ?</h2>
          <p className="h5" style={{ color: "black" }}>When you access our platform, we will automatically gather some data from your computer, which may include;</p>
          <ul className="ml-10">
            <li className="h5" style={{ color: "black", listStyle: 'decimal' }}>Your Name username. Identify identifiers, photographs and gender.</li>
            <li className="h5" style={{ color: "black", listStyle: 'decimal' }}>Email</li>
            <li className="h5" style={{ color: "black", listStyle: 'decimal' }}>Location</li>
            <li className="h5" style={{ color: "black", listStyle: 'decimal' }}>Mobile Number</li>
            <li className="h5" style={{ color: "black", listStyle: 'decimal' }}>IP address</li>
            <li className="h5"  style={{ color: "black", listStyle: 'decimal' }}>Operating system</li>
            <li className="h5"  style={{ color: "black", listStyle: 'decimal' }}>Browser type</li>
            <li className="h5"  style={{ color: "black", listStyle: 'decimal' }}>Cookies</li>
            <li className="h5"  style={{ color: "black", listStyle: 'decimal' }}>Device's ID and its location</li>
            <li className="h5"  style={{ color: "black", listStyle: 'decimal' }}>Language</li>
            <li className="h5"  style={{ color: "black", listStyle: 'decimal' }}>Pages you have visited</li>
            <li className="h5"  style={{ color: "black", listStyle: 'decimal' }}>Pop-ups or push notifications you may have seen and responded to</li>
            <li className="h5"  style={{ color: "black", listStyle: 'decimal' }}>Date, time, and duration of your visit</li>
            <li className="h5"  style={{ color: "black", listStyle: 'decimal' }}>The kind of services you seek</li>
            <li className="h5"  style={{ color: "black", listStyle: 'decimal' }}>The way you use such services</li>
          </ul>
        </div>
        <div className="mb-8">
          <h2 className="mb-4 display-5">4. HOW, WE USE THIS PERSONAL DATA ?</h2>
          <p className="h5" style={{ color: "black" }}>This type of information is gathered for analytics and evaluation so as to improve User Experience by upgrading the platform features and the services that we offer. We may internally use or share the collected data from you with third parties for some or all of the listed objectives:</p>
          <ul className="ml-10">
            <li className="h5"  style={{ color: "black", listStyle: 'disc' }}>Know your activities and interests on our platform.</li>
            <li className="h5"  style={{ color: "black", listStyle: 'disc' }}>Verify and determine user authenticity, and any financial transactions in relation to any payments you make.</li>
            <li className="h5"  style={{ color: "black", listStyle: 'disc' }}>Customize and/or tailor any form of communication that we may have with you with respect to the information, services, or shipping of our products, as the case maybe which   we think you may find useful, or which may interest you.</li>
            <li className="h5"  style={{ color: "black", listStyle: 'disc' }}>Individualize and enhance your experience on the platform.</li>
            <li className="h5"  style={{ color: "black", listStyle: 'disc' }}>Improve, personalize, and expand our services and offerings on our Website and App.</li>
            <li className="h5"  style={{ color: "black", listStyle: 'disc' }}>Develop new products, services, features, and functionality for you.</li>
            <li className="h5"  style={{ color: "black", listStyle: 'disc' }}>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes.</li>
          </ul>
        </div>
        <div className="mb-8">
          <h2 className="mb-4 display-5">5. HOW, DO WE FIND AND PREVENT FRAUD?  </h2>
          <p className="h5" style={{ color: "black" }}>When you sign up on our platform for account creation or otherwise share your data with us, we may utilize it to send promotional and/or marketing collaterals to you. However, you can at any time unsubscribe from receiving such materials.</p>
          <p className="h5" style={{ color: "black" }}>As an online services aggregator, we provide a platform to carry out a transaction (appointment booking) between salons, clinics, spas, and the customers. To provide an easy appointment booking facility and otherwise ensure fulfilment of the contractual commitments with the vendors (salons, clinics and spas), we may share some personal information with them to verify and authenticate your identity for delivery of the services and benefits that you may be entitled to. BELLAZ’s does not endorse or represent any third-party companies, services or websites. </p>
          <p className="h5" style={{ color: "black" }}>The personal information that you provide or that is gathered by any third-party website does not fall under this privacy policy. We request you to separately go through the privacy statements of those third-party services before proceeding.</p>
          <p className="h5" style={{ color: "black" }}>We may also share some collected data on social networking sites and/or with our marketing partners for sharing adverts to you, and to search engines and analytic professionals.</p>
        </div>
        <div className="mb-8">
          <h2 className="mb-4 display-5">6. DISCLOSURE OF YOUR DATA </h2>
          <dl className="ml-10">
            <dt className="h5"  style={{ color: "black" }}>•	Your personal information with any individual or organization where we believe in good faith that such data sharing is important for:</dt>
            <dd className="ml-10 h5" style={{ color: "black" }}>a.	Comply with the law or in response to a court order or administrative proceeding.</dd>
            <dd className="ml-10 h5" style={{ color: "black" }}>b.	Producing relevant information with reference to arbitration, litigation, internal or government or investigations;</dd>
            <dd className="ml-10 h5" style={{ color: "black" }}>c.	Protecting the rights, safety, property or interests of  BELLAZ’s or others;</dd>
            <dd className="ml-10 h5" style={{ color: "black" }}>d.	Otherwise as granted by the law.</dd>
            <dt className="h5" style={{ color: "black" }}>•	Personal information about our visitors - both customers and non-customers, with the given types of organization that we appoint on our behalf to perform different kinds of operations:</dt>
            <dd className="ml-10 h5" style={{ color: "black" }}>a.	Financial companies that specialize in banking and online payments.</dd>
            <dd className="ml-10 h5" style={{ color: "black" }}>b.	Non-financial agencies that are engaged in data and payment processing.</dd>
          </dl>
          <p className="h5" style={{ color: "black" }}>Notwithstanding this Privacy Statement, you acknowledge that we hold the right to share your data with any legal, regulatory, governmental, tax, law enforcement or other concerned bodies according to applicable law and our legal obligations.</p>
          <p className="h5" style={{ color: "black" }}>It is your responsibility to protect any password that you use to access your account on BELLAZ’s. Kindly treat the OTPs (One Time Passwords), special characters, and letters confidentially, and do not share it with others. We disclaim any liability arising because of your failure to do so. In case of a lost password, you might partially lose control over your personal information shared with us. Hence, you must get your password changed immediately by contacting us.</p>
        </div>
        <div className="mb-8">
          <h2 className="mb-4 display-5">7. COOKIES AND WEB BEACONS</h2>
          <p className="h5" style={{ color: "black" }}>Our website uses "Cookies" so that we can customize our content and serve you with relevant information when you revisit our website. Cookies can be described as small bits of data that web servers send to your web browser, which it saves in text form. You have the option to choose whether to accept the cookies or not by toggling on/off your browser notification. We use the data thus collected to analyse and understand the user behaviour to further improve our online services. It is to be noted that if you reject cookies, then it might restrict you from accessing certain sections or features of our website.</p>
          <p className="h5" style={{ color: "black" }}>You may please write to us at <a href="mailto:support@my-bellaz.com" target="_blank">support@my-bellaz.com</a> to express your objection regarding BELLAZ’s using and/or disclosing your personal information or any of the above-mentioned purposes at any time.</p>
        </div>
        <div className="mb-8">
          <h2 className="mb-4 display-5">8. CHILDREN'S INFORMATION</h2>
          <p className="h5" style={{ color: "black" }}>One of our priorities is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity. BELLAZ’s does not knowingly collect any Personal Identifiable Information from children under the age of 18. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately at email: help@ BELLAZ’s.com and we will do our best efforts to promptly remove such information from our records.</p>
          <p className="h5" style={{ color: "black" }}>Only those individuals, who are 18 years or above, are allowed to conduct any transaction through BELLAZ’s platform. Parents, guardians or legal caretakers of the individuals under 18 years are solely responsible for ensuring that their children and/or wards do not carry out any transaction without their permission. If any person below the age of 18 years makes any transaction on BELLAZ’s platform, it shall be automatically deemed that their parents, guardians or legal caretakers have clearly consented to their use and we deny any liability arising from their failure to do so.</p>
        </div>
        <div className="mb-8">
          <h2 className="mb-4 display-5">9. DELETION OF ACCOUNT &  PERSONAL DATA.</h2>
          <p className="h5" style={{ color: "black" }}>Notwithstanding anything contained in the terms, you may delete your account as well as your personal data stored with BELLAZ’s by sending an email to <a href="mailto:support@my-bellaz.com" target="_blank">support@my-bellaz.com</a>. Our company may take up to 30 (Thirty ) working days to process your request. However, once your account is deleted  you will lose the access to all the services.</p>
        </div>
        <div className="mb-8">
          <h2 className="mb-4 display-5">10. UPDATE TO THIS POLICY </h2>
          <p className="h5" style={{ color: "black" }}>We may occasionally update this Privacy policy . If we make changes to this policy, we will upload the revised policy on the platform or share it with you through other means, such as email. To the extent permitted under applicable law, by using our platform after such notice, you consent to update made to this policy.</p>
        </div>
        <div className="mb-8">
          <h2 className="mb-4 display-5">11. ADDITIONAL COUNTRY DISCLOSURES</h2>
          <h6 className="mb-4 mb-4" tyle={{ color: "black" }}>a. MIDDLE EAST </h6>
          <p className="h5" style={{ color: "black" }}>We at BELLAZ’S  comply with Protection of Personal Data Protection <strong>(“PDPL”)</strong> issued by United Arab Emirates <strong>(“UAE”)</strong> on 26 September 2021.</p>
          <p className="h5" style={{ color: "black" }}>If you reside in the Middle East, you have the right to request access to and obtain the following information without charge:</p>
          <ul className="ml-10">
            <li className="h5" style={{ color: "black", listStyle: 'disc' }}>the types of his/her Personal Data that is processed;</li>
            <li className="h5"  style={{ color: "black", listStyle: 'disc' }}>purposes of Processing;</li>
            <li className="h5"  style={{ color: "black", listStyle: 'disc' }}>decisions made based on Automated Processing, including Profiling;</li>
            <li className="h5"  style={{ color: "black", listStyle: 'disc' }}>targeted sectors or establishments with which his/her Personal Data is to be shared, whether inside or outside the State;</li>
            <li className="h5"  style={{ color: "black", listStyle: 'disc' }}>controls and standards for the periods of storing and keeping his/her Personal Data;</li>
            <li className="h5"  style={{ color: "black", listStyle: 'disc' }}>procedures for correcting, erasing or limiting the Processing and objection to his/her personal data;</li>
            <li className="h5"  style={{ color: "black", listStyle: 'disc' }}>protection measures for Cross-Border Processing;</li>
            <li className="h5"  style={{ color: "black", listStyle: 'disc' }}>procedures to be taken in the event of a breach or infringement of his/her Personal Data, especially if the breach or infringement poses a direct and serious threat to the privacy and confidentiality of his/her Personal Data;</li>
            <li className="h5"  style={{ color: "black", listStyle: 'disc' }}>the process of filing complaints with the Data Office.</li>
            <li className="h5"  style={{ color: "black", listStyle: 'disc' }}>to withdraw your consent to our processing of your data (where such processing is based on consent); and</li>
            <li className="h5"  style={{ color: "black", listStyle: 'disc' }}>to lodge a complaint with the data protection authority in your jurisdiction.</li>
          </ul>
          <p className="h5" style={{ color: "black" }}>The Data Subject has the right to object to decisions issued with respect to Automated Processing that have legal consequences or seriously affect the Data Subject, including Profiling.  However, the Data Subject may not object to the decisions issued with respect to Automated Processing in the following cases:</p>
          <ul className="ml-10">
            <li className="h5"  style={{ color: "black", listStyle: 'disc' }}>if the Automated Processing is included in the terms of the contract entered into between the Data Subject and Controller;</li>
            <li className="h5"  style={{ color: "black", listStyle: 'disc' }}>if the Automated Processing is necessary according to other legislation in force in the State;</li>
            <li className="h5"  style={{ color: "black", listStyle: 'disc' }}>if the Data Subject has given his/her prior Consent on the Automated Processing.</li>
          </ul>
          <p className="h5" style={{ color: "black" }}>In order to exercise any of these rights (including the right to withdraw your consent), please email us at <a href="mailto:support@my-bellaz.com" target="_blank">support@my-bellaz.com</a>. We may need to verify your identity before granting access or otherwise changing or correcting your information.</p>
        </div>
        <div className="mb-8">
          <h2 className="mb-4 display-5"><CheckBoxIcon className="display-4 pr-2 mb-2"/>CONSENT</h2>
          <p className="h5" style={{ color: "black" }}>By using our website and application, you hereby consent to our Privacy Policy and agree to its terms.</p>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
