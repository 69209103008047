import { createSlice } from "@reduxjs/toolkit";

import * as requestFromServer from "./subscriptionApi";

const initialState = {
  subscriptions: [],
};

export const subscriptionSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    updateList(state, action) {
      state.subscriptions = action.payload;
    },
    created(state, action) {
      state.subscriptions.push(action.payload);
    },
  },
});

export const loadSubscriptionList = () => {
  return async (dispatch) => {
    return requestFromServer
      .getSubscriptionList()
      .then((res) => {
        dispatch(subscriptionSlice.actions.updateList(res.data.data));
        console.log(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
      });
  };
};

export const createSubscription = (data) => {
  return async (dispatch) => {
    console.log(data);
    return requestFromServer
      .createSubscription(data)
      .then((response) => {
        console.log(response);
        dispatch(subscriptionSlice.actions.created(response.data.data));
      })
      .catch((error) => {
        throw new Error(error.response.data.error);
      });
  };
};