import axios from "axios";
const URL = process.env.REACT_APP_API_URL + "/business";
const token = process.env.REACT_APP_ADMIN_TOKEN;
const Authorization = "Bearer " + token;
const headers = {
  Authorization,
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const getCouponList = async () => {
  return await axios.get(`${URL}/booking/coupon/list`);
};

export const getCoupon = async (req) => {
  console.log(req);
  return await axios.post(`${URL}/booking/coupon/view`, req);
};

export const createCoupon = (data) => {
  return axios.post(`${URL}/booking/coupon/create`, data, {
    headers,
  });
};

export const updateCoupone = (data, uuid) => {
  data.uuid = uuid;
  console.log(data);
  return axios.patch(`${URL}/booking/coupon/update`, data, {
    headers,
  });
};

// export const deletecoupone = (data) => {
//   console.log(data);

//   return axios.delete(`${URL}/coupon/delete`, data);
// };

export const deletecoupone = (uuid) => {
  console.log(uuid);
  const req = {
    uuid: uuid,
  }
  console.log(req);
  return axios.delete(`${URL}/booking/coupon/delete`, {
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      "uuid": uuid
    }
  });
};
