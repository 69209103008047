import React from "react";

import { ShiftsTable } from "./table";
import { CardBasic } from "../../components/CardBasic";
import { useSelector } from "react-redux";

export function ShiftsPage() {
  const { role, user } = useSelector((state) => state.authPersist);
  return (
    <CardBasic
      title={"Shifts"}
      btnType={"button"}
      headerBtnText={role === "business" ? "Add Shift" : null}
      headerBtnLink={role === "business" ? "/shift/create" : null}
    >
      <ShiftsTable />
    </CardBasic>
  );
}
