import React, { Fragment, useEffect } from "react";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import InvoiceMain from "./component/InvoiceMain";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { loadInvoiceList } from "../../store/business/invoice/invoiceSlice";
import { loadBranchInvoice } from "../../store/branch/branchInvoiceSlice";
import { getCountryCodeList } from '../../store/countryCode/countryCodeSlice';
export const Invoice = (props) => {
  const { user, role } = useSelector((state) => state.authPersist);
  const { countryCode } = useSelector((state) => state.countryCode);
  const currencySymbol = countryCode.filter((el) => el.countryCode === user.countryCode);
  // console.log(countryCode)
  // console.log(currencySymbol)
  // const { id } = useParams();
  const {id,invoice} = props;
  const bookingUuid = {
    booking_uuid: id,
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCountryCodeList());
  }, []);
  useEffect(() => {
    if (role === "business") {
      dispatch(loadInvoiceList(bookingUuid))
        .then((response) => {
          const data = response.data.data;
        })
        .catch((err) => {
          console.log(err.message);
        });
    } else if (role === "branch") {
      dispatch(loadBranchInvoice(bookingUuid))
        .then((response) => {
          const data = response.data.data;
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }, []);
  // const invoice = useSelector((state) => {
  //   if (role === "business") {
  //     return state.invoice.invoice;
  //   } else if (role === "branch") {
  //     return state.branchInvoice.branchInvoice;
  //   }
  // });
  console.log("invoice",invoice)
  
  return (
    <>
      {/* <PDFViewer width="100%" height="100%">
        <InvoiceMain invoice={invoice} user={user}/>
      </PDFViewer> */}
      <PDFDownloadLink
        document={<InvoiceMain invoice={invoice} user={user}  currencySymbol={currencySymbol} />}
        fileName="invoice.pdf"
      >
        {({ blob, url, loading, error }) =>
          loading ? "Loading document..." : <button className="btn btn-light-success">Download Invoice</button>
        }
      </PDFDownloadLink>
    </>
  );
};
