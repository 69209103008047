import React from "react";

import { CardBasic } from "../../components/CardBasic";
import { CustomerTabel } from "./table";

export function CustomerPage() {
  return (
    <CardBasic
      title={"Customer"}
      btnType={"button"}
    >
      <CustomerTabel />
    </CardBasic>
  );
}
