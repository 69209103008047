import React from "react";
import OwlCarousel from "react-owl-carousel";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./BellazMain.css";

const option = {
  autoplay: true,
  rewind: true,
  margin: 20,
  animateOut: "fadeOut",
  animateIn: "fadeIn",
  responsiveClass: true,
  autoplayTimeout: 7000,
  smartSpeed: 800,
  autoplayHoverPause: true,
  responsiveRefreshRate: 10,
  // itemsDesktop : [1199,3],
  // itemsDesktopSmall : [979,3],
  dots: true,
  nav: false,
  responsive: {
    0: {
      items: 1,
      margin: 0,
    },

    600: {
      items: 2,
    },

    1024: {
      items: 2,
    },

    1366: {
      items: 3,
    
    },
  },
};

const data = [
  // {
  //   name: "Clinics",
  //   text:
  //     " Living winged said you darkness you're divided gathered and bring one seassions face great dr waters firmament place which",
  //   btn: "Read More",
  //   img: "/media/BellazMain/cardImages/Layer 3.png",
  // },
  {
    name: "Salons",
    text:
      "Love begins with you. Through our excellent variety of grooming services, receive the care and love you need for a sense of overall well-being and happiness.",
    btn: "Read More",
    img: "/media/BellazMain/cardImages/Layer 2.png",
  },
  {
    name: "Spa",
    text:
      "Come and discover yourself. It has never been easier to take a break from stress and the harmful factors that surround you every day! Indulge yourself in a relaxing spa, an experience that lasts forever",
    btn: "Read More",
    img: "/media/BellazMain/cardImages/Layer 1.png",
  },
  {
    name: "Clinics",
    text:
      "Select from a wide range of cosmetic and health clinics for an overall well-being of you.",
    btn: "Read More",
    img: "/media/BellazMain/cardImages/Layer 3.png",
  },
  // {
  //   name: "Clinics",
  //   text:
  //     "Living winged said you darkness you're divided gathered and bring one seassions face great dr waters firmament place which",
  //   btn: "Read More",
  //   img: "/media/BellazMain/cardImages/Layer 2.png",
  // },
];
function BellazCarousel() {
  return (
    <div className="carousel-main-head position-relative d-flex justify-content-lg-center justify-content-md-center">
      <OwlCarousel className="owl-carousel owl-theme carousel-outer" {...option}>
        {data.map((_data) => (
          <div className="d-flex justify-content-center pt-3 pb-3 carousel-card-outer">
            <div className="card text-center carousel-card-inner p-4 m-0 ">
              <img src={toAbsoluteUrl(_data.img)} alt="" />
              <div className="card-body m-0 p-3" style={{ height: '240px'}}>
                <h3 className="card-title font-weight-bold mt-4 p-0">
                  {_data.name}
                </h3>
                <p className="card-text mb-5 text-black-50 p-0">{_data.text}</p>
                {/* <div
                  type="button"
                  className="btn btn-primary rounded-pill text-white text-uppercase pl-5 pr-5 btn-card mb-3 border-0"
                >
                  {_data.btn}
                </div> */}
              </div>
            </div>
          </div>
        ))}
      </OwlCarousel>
    </div>
  );
}

export default BellazCarousel;
