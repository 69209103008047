import React from "react";
import { View, StyleSheet, Image } from "@react-pdf/renderer";
import { Text } from "@react-pdf/renderer";
import InvoiceHeader from "./InvoiceHeader";
import InvoiceRow from "./InvoiceRow";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    width: "100%",
    flexWrap: "wrap",
    borderWidth: 1,
    borderColor: "#000",
  },
  img: {
    width: 9,
  },
  gst: {
    marginBottom: "10px",
    marginTop: "10px",
    marginRight: "30px",
    textAlign: "right",
    width: "100%",
  },
  last: {
    display: "flex",
    alignItems: "center",
    borderTopWidth: 1,
    borderBottomColor: "#000",
    width: "100%",
    padding: "10px 0",
  },
  point: {
    marginTop: "30px",
  },
  lastMain: {
    display: "flex",
    borderTopWidth: 1,
    justifyContent: "end",
    alignItems: "center",
    width: "100%",
  },
  payment: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
    marginRight: "30px",
  },
  pay: {
    marginRight: "20px",
  },
  pa: {
    width: "20%",
  },
  du: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    flexDirection: "row",
  },
});

const foot = StyleSheet.create({
  row: {
    borderTopWidth: 1,
    flexDirection: "row",
    borderBottomColor: "#000",
    borderBottomWidth: 1,
    alignItems: "center",
    textAlign: "right",
    paddingRight: "10px",
    height: 24,
    fontSize: 12,
    fontStyle: "bold",
  },
  description: {
    width: "85%",
    textAlign: "right",
  },
  total: {
    width: "20%",
  },
});

const InvoiceTable = ({ invoice, currencySymbol }) => {
  console.log("invoice",invoice)
  return (
    <View style={styles.tableContainer}>
      <InvoiceHeader currencySymbol={currencySymbol} />
      <InvoiceRow invoice={invoice} />
      <View style={styles.lastMain}>
        <View style={styles.gst}>
          <Text>SUB TOTAL ({currencySymbol[0] ? currencySymbol[0].currencySymbol : 'INR'}): {invoice.baseSubTotal}</Text>
          {invoice.tax > 0 && <Text>TAX ({currencySymbol[0] ? currencySymbol[0].currencySymbol : 'INR'}): {invoice.tax}</Text>}
          {invoice.discount > 0 && (
            <Text>Discount ({currencySymbol[0] ? currencySymbol[0].currencySymbol : 'INR'}) - ({invoice?.coupon ? invoice?.coupon[0]?.couponCode : null}): {invoice.discount}</Text>
          )}
          <Text>TOTAL ({currencySymbol[0] ? currencySymbol[0].currencySymbol : 'INR'}): {invoice.total}</Text>
        </View>
      </View>
      <View style={styles.last}>
        <Text>Thank you! Visit Again.</Text>
        {/* <Text style={styles.point}>Bellaz Loyality Points Earned : 593</Text> */}
      </View>
    </View>
  );
};

export default InvoiceTable;
