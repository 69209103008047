import React from "react";

export function Checkbox2({ isSelected, onChange, children }) {
  console.log(children)
  return (
    <>
    <p>{children}</p>
      <input type="checkbox" style={{display: "none"}} />
      <label className="checkbox checkbox-lg checkbox-single">
        <input type="checkbox" checked={isSelected} onChange={onChange} />
        <span />
      </label>
    </>
  );
}
