import React from "react";

import { ScheduleTable } from "./table";
import { CardBasic } from "../../components/CardBasic";
import { useSelector } from "react-redux";

export function SchedulePage() {
  const { role } = useSelector((state) => state.authPersist);
  return (
    <CardBasic
      title={"Schedule"}
      btnType={"button"}
      headerBtnText={role === "branch" ? "Add Schedule" : null}
      headerBtnLink={role === "branch" ? "/schedule/create" : null}
    >
      <ScheduleTable />
    </CardBasic>
  );
}
