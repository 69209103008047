import React from "react";
import { useField, useFormikContext } from "formik";

import ReactSelect from "react-select";

const getFieldCSSClasses = (touched, errors) => {
  const classes = [];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
  }

  return classes.join(" ");
};

export function MultiSelect({
  label,
  ...props
}) {
  const { setFieldValue, errors, touched } = useFormikContext();

  const [field] = useField(props);

  return (
    <>
      {label && <label>Select {label}</label>}
      <ReactSelect
        isMulti
        className={`${getFieldCSSClasses(touched[field.name], errors[field.name])} basic-multi-select`}
        classNamePrefix="select"
        name={props.name}
        value={field.value}
        onChange={val => {
          setFieldValue(field.name, val);
        }}
        options={props.options}
      />
      {errors[field.name] && touched[field.name] ? (
        <div className="invalid-feedback">
          {errors[field.name].toString()}
        </div>
      ) : ""}
    </>
  );
}
