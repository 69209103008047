import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import filterFactory from "react-bootstrap-table2-filter";
import { sortCaret } from "../../_metronic/_helpers";
import { Input, Select } from "../../_metronic/_partials/controls";
import {
  StatusColumnFormatter,
  ActionColumnSimple,
} from "../../_metronic/_helpers/tableColumnFormatter";
import { useDispatch, useSelector } from "react-redux";
import paginationFactory from "react-bootstrap-table2-paginator";
import { loadServiceList } from "../../store/business/services/servicesSlice";
import { getCountryCodeList } from "../../store/countryCode/countryCodeSlice";
import { servicesData } from "../../demoData/servicesData";
import { Link } from "react-router-dom";

const LinkFormatter = (cell, row) => {
  return <Link to={`service/${row.uuid}`}>{cell}</Link>;
};

export const ServicesTable = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.service.services);
  //console.log(data);
  const [dataLoading, setDataLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [filteredData, setFilteredData] = useState([]);
  const { serviceCategories } = useSelector((state) => state.data);
  const { role, user } = useSelector((state) => state.authPersist);
  const { countryCode } = useSelector((state) => state.countryCode);
  const currencySymbol = countryCode.filter((el) => el.countryCode === user.countryCode);
  //console.log(serviceCategories);

  useEffect(() => {
    dispatch(getCountryCodeList());
  }, []);
  
  useEffect(() => {
    dispatch(loadServiceList()).then(() => {
      setDataLoading(false);
    });
  }, []);

  const columns = [
    {
      dataField: "",
      text: "#",
      formatter: (row, cell, index) => {
        return index + 1;
      },
    },
    {
      dataField: "name",
      text: "Service Name",
      sort: true,
      sortCaret: sortCaret,
      formatter: LinkFormatter,
      // filter: textFilter(),
    },
    {
      dataField: "category[0].name",
      text: "Category",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "price",
      text: `Price (${currencySymbol[0] ? currencySymbol[0].currencySymbol : '₹'})`,
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "time",
      text: "Time (Minutes)",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      formatter: StatusColumnFormatter,
    },
    // {
    //   dataField: "",
    //   text: "Actions",
    //   formatter: ActionColumnSimple,
    // },
  ];

  useEffect(() => {
    if (selectedCategory === 'all') {
      setFilteredData(data);
    } else {
      const res = data.filter((elem) => elem.category_uuid === selectedCategory);
      console.log(res);
      setFilteredData(res);
    }

  }, [data, selectedCategory]);
  console.log(filteredData);
  const { SearchBar, ClearSearchButton } = Search;
  function handleSelectedCategory(event) {
    console.log(event.target.value);
    setSelectedCategory(event.target.value);
  }

  return (
    <>

      <ToolkitProvider search keyField="uuid" data={filteredData} columns={columns}>
        {(props) => (
          <>
            <div className="form-group row">
              <div className="col-lg-6 col-md-6 mb-4 mb-lg-0 mb-md-0 justify-content-sm-center">
                <div className="d-flex">
                  <h4 className="my-auto pr-4 pl-4">Category</h4>
                  <select class="form-select form-select-solid" data-control="select2" data-placeholder="Select an option" onChange={handleSelectedCategory}
                    style={{ padding: '0.65rem 1rem', fontSize: '1rem', fontWeight: '400', lineHeight: '1.5', color: ' #339187', border: '1px solid #E4E6EF', borderRadius: ' 0.42rem', outline: 'none' }}>
                    <option value={'all'} key={'all'}>
                      {'All'}
                    </option>
                    {serviceCategories.map((category, index) => (
                      <option value={category.uuid} key={category.uuid}>
                        {category.name}
                      </option>

                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className=" d-flex justify-content-lg-end justify-content-md-end">
                  <h4 className="mt-3 pr-4">Search</h4>
                  <SearchBar {...props.searchProps} />
                  <ClearSearchButton
                    {...props.searchProps}
                    className="ml-4 mb-4 btn btn-secondary-light"
                  />
                </div>
              </div>
              {/* {console.log(props)} */}
            </div>

            <BootstrapTable
              {...props.baseProps}
              classes="table table-head-custom table-vertical-center overflow-hidden"
              wrapperClasses="table-responsive"
              bordered={false}
              bootstrap4
              loading={true}
              pagination={paginationFactory()}
              filter={filterFactory()}
              noDataIndication={
                <div className={"d-flex justify-content-center py-10"}>
                  <h3>{dataLoading ? "Loading Data..." : "No records found"}</h3>
                </div>
              }
            />
          </>
        )}
      </ToolkitProvider>
    </>
  );
};
