import React from "react";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import BookOnlineIcon from '@material-ui/icons/Book';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import DescriptionIcon from '@material-ui/icons/Description';
import UpdateIcon from '@material-ui/icons/Update';
import BusinessIcon from '@material-ui/icons/Business';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

function TermsAndConditions() {
  return (
    <div className="p-0 m-0" style={{ background: ' #f1f3f5' }}>
      <div
        className="container-fluid bg-dark d-flex flex-column justify-content-center align-items-center"
        style={{ height: "220px", borderRadius: "10px" }}
      >
        <h2 className="display-4 text-white mb-5"><DescriptionIcon className="mr-5 display-4 mb-2" />Terms and Conditions</h2>
        {/* <h4 className="display-6 text-white"><UpdateIcon className="mr-5 display-6 mb-2" />LAST UPDATE ON – 29th August 2022</h4> */}
      </div>
      <div
        className="container bg-white p-12"
        style={{ width: "90%", marginTop: "-40px", borderRadius: "10px" }}
      >
        <div className="mb-8">
          {/* <h2 className="mb-4 display-5"><BusinessIcon className="display-4 pr-2 mb-2" />Welcome to BELLAZ’s PRIVACY POLICY</h2> */}
          <p className="h5" style={{ color: "black" }}>This document is a digital record in terms of the Information Technology Act, 2000, and policies there under as applicable and the modified rules concerned with electronic records in various forms as amended by the IT Act, 2000. It is issued with reference to the provisions of Rule 3(1) of the IT Guidelines (Intermediaries guidelines) Rules, 2011 that require issuing the rules and regulations, Terms of Use, and Privacy Policy for access or usage of Bellaz’s platform (website: www.Bellaz’s.com and Bellaz’s app for mobile devices and tablets).</p>
          <p className="h5" style={{ color: "black" }}>Bellaz’s platform is fully owned and operated by Bellaz’s Private Limited, a company incorporated under the Companies Act, 2013 and having its registered office at B 5/6, Suphala Co-op. Housing Society, B Building, 1st Floor Behind K.C Gandhi School, Kalyan (West) 421 301 Dist. Thane, Maharashtra. </p>
        </div>
        <div className="mb-8">
          <h2 className="mb-4 display-5" style={{textAlign: 'center'}}>Terms of Use</h2>
          <p className="h5" style={{ color: "black" }}>The listed "Terms of Use" on this page govern your use of the Bellaz’s Website and Bellaz’s application for smart devices. Both the “Application” and the “Website” are referred to as the "Platform".</p>
          <p className="h5" style={{ color: "black" }}>You are requested to go through these “Terms of Use” before subscribing or using the services offered by our platform. By downloading, installing or even simply accessing the Bellaz’s web-based platform, you shall be contracting with Bellaz’s and you signify that you agree to our Terms of Use and other policies, including Privacy Policy, Cookie Policy, Cancellation, and Refund Policy, and Take Down Policy, which comes into effect on the very date when you download, install or use the Services, and form a legally binding setting to abide by the same.</p>
          <p className="h5" style={{ color: "black" }}>With reference to the implementation of these Terms of Use, “our”, “we" or "us" in this document shall denote "Bellaz’s" (a digital platform of the Iforsys Solution Pvt. Ltd.). Also, the terms "you" or “your” shall denote any individual user, either partner or customer, who has agreed to become a Bellaz’s Member by providing essential information for registration on the platform using any web-connected device.</p>
          <p className="h5" style={{ color: "black" }}>Bellaz’s enables transactions (both cash and online) between partners and customers. The partners can list their business on the Bellaz’s platform so that the potential customers can locate them and make bookings. On the other hand, Bellaz’s platform allows the customers can find, select, and book appointments and order products for required grooming and personal care services from the listed neighbourhood services including but not limited to the salons, spas, and clinics on the platform.</p>
        </div>
        <div className="mb-8">
          <h2 className="mb-4 display-5" style={{textAlign: 'center'}}>Amendments and Modifications</h2>
          <p className="h5" style={{ color: "black" }}>The above-mentioned “Terms of Use” are subject to amendments/modifications at any time. Bellaz’s reserves the right to make any kind of changes or amendments in the policies by posting the same on the platform as well as its social media handles, and you shall be responsible to keep yourself updated about the changes (if any) by checking the Bellaz’s website or social media. At all times, you shall be responsible to check the “Terms of Use” and other policies of Bellaz’s. Your continued use of the platform and the offered services after any amendment/modification means you agree to our updated policies. “Terms of Use”, Bellaz’s provides you a personalized, non-transferable, non-exclusive, limited privilege to access, search and make appointment bookings for grooming and personal care through the platform. By agreeing to accept the given “Terms of Use”, you also accept and agree to follow our Terms and Conditions as well as other policies, which include Privacy Policy, Cookie Policy, and Cancellation and Refund Policy.</p>
        </div>
        <div className="mb-8">
          <h2 className="mb-4 display-5" style={{textAlign: 'center'}}>Registration, Booking Fees, Payments and Taxation</h2>
          <h4 className="mb-4 ml-5 display-6" style={{ color: "black"}}>•	 Registration Fees</h4>
          <p className="h5" style={{ color: "black" }}>Bellaz’s doesn't charge any amount for registration or appointment booking (Booking Fees) although it shall be pertinent to note that Bellaz’s will charge shipping fee for delivery of products. However, the Company reserves the right to charge membership and/or booking fees, by giving prior notice on its platform and social channels.</p>
          <h4 className="mb-4 ml-5 display-6" style={{ color: "black"}}>•	Payments</h4>
          <p className="h5" style={{ color: "black" }}>Bellaz’s allows users to make both cash and online payments for the services.</p>
          <h5 className="mb-4 ml-10 display-6" style={{ color: "black"}}>1.	Cash Payments</h5>
          <p className="h5" style={{ color: "black" }}>Customers, when requesting for an appointment, can choose the 'Pay at Counter' option and make the payment at the venue.</p>
          <h5 className="mb-4 ml-10 display-6" style={{ color: "black"}}>2.	Online Payments</h5>
          <p className="h5" style={{ color: "black" }}>Customers, when requesting for an appointment, can choose the 'Pay Online' option to make advance payment for the service.</p>
          <p className="h5" style={{ color: "black" }}>In this case, Bellaz’s will collect the payment on behalf of the relevant partner and share a Payment Receipt (not Tax Invoice) with the user.</p>
          <p className="h5" style={{ color: "black" }}>For online transactions, Bellaz’s collects required financial information (such as your debit or credit number, CVV number, and expiration date) as required for payment processing. However, for safety reasons, we do not store the data.</p>
          <h4 className="mb-4 ml-5 display-6" style={{ color: "black"}}>•	Taxation</h4>
          <p className="h5" style={{ color: "black" }}>All the services and prices listed on Bellaz’s Website and Bellaz’s Application are bound under the Finance Act of the Government of India. Presently, BELLAZ’S follows Goods and Services Tax introduced as The Constitution Act, 2017, following the passage of Constitution 122nd amendment bill. Thus, the prices of services listed on the Platform are subject to the discretion of BELLAZ which can be inclusive and exclusive of Good and Service Tax.</p>
        </div>
        <div className="mb-8">
          <h2 className="mb-4 display-5">Appointment Cancellation, Rescheduling, and Rebooking</h2>
          <p className="h5" style={{ color: "black" }}>To make the appointment bookings and delivery of services easier and hassle-free for both customers and partners, we allow rescheduling, rebooking, and cancellation of service via Bellaz’s Website and Application.</p>
        </div>
        <div className="mb-8">
          <h2 className="mb-4 display-5">Rescheduling</h2>
          <p className="h5" style={{ color: "black" }}>Customers can reschedule their appointment 24 hours prior to their scheduled time-slot. To reschedule, you can access the Profile section (on Website) or My Bookings (on Application) and click on the Reschedule button to change the date and time. For now, we don't charge any amount for the rescheduling facility.</p>
        </div>
        <div className="mb-8">
          <h2 className="mb-4 display-5">Cancellation</h2>
          <p className="h5" style={{ color: "black" }}>Customers can also choose to cancel their appointment 24 hours prior to their scheduled timeslot. To cancel the appointment, you can access the Profile section (on Website) or My Bookings (on Application) and select the Cancel option to cancel the service. Please read the Cancellation and Refund Policy for a better understanding of our cancellation and refund policy.</p>
        </div>
        <div className="mb-8">
          <h2 className="mb-4 display-5">Rebooking</h2>
          <p className="h5" style={{ color: "black" }}>Bellaz’s allows an appointment rebooking facility via website and application. Once the booking is completed, the option to rebook the same service(s) is provided to the users. Even if Bellaz’s partner cancels the reservation, users can choose to rebook the same facility or accept the cancellation and request a fresh appointment with another partner.</p>
        </div>
        <div className="mb-8">
          <h2 className="mb-4 display-5">Text Messages and Emails</h2>
          <p className="h5" style={{ color: "black" }}>By creating a Bellaz’s Account, you agree that the company may send you SMS and emails as part of your use of the Bellaz’s Services. You consent to receive information and notifications regarding the business operations of Bellaz’s through text messages and emails and that you acknowledge that any actions you perform to prevent such messages and emails will affect your use of the Services.</p>
          <p className="h5" style={{ color: "black" }}>Bellaz’s, as a courtesy, will send a reminder through App Notification feature to the customer about the reserved appointment 1 hour prior to the scheduled time. In case, Bellaz’s is not able to send the text message, please understand that it is your responsibility to remember your appointment times and dates to avoid late arrivals and missed appointments.</p>
        </div>
        <div className="mb-8">
          <h2 className="mb-4 display-5" style={{textAlign: 'center'}}>Disclaimers</h2>
          <ul className="ml-10">
            <li className="h5"  style={{ color: "black", listStyle: 'upper-roman' }}>Prices of the services mentioned on the Bellaz’s website and Mobile apps are inclusive or exclusive of GST.</li>
            <li className="h5"  style={{ color: "black", listStyle: 'upper-roman' }}>All business and contractual policies are granted by and agreed to between the partners and customers. These terms mainly comprise without limitation price, taxes, payment terms, payment channels, time and date of the services and after-sales facilities, if provided by the partner. Bellaz’s does not regulate or have any control over the services or acceptance of any commercial/contractual agreements between our partners and the customers. However, Bellaz’s may provide support facilities to the partners as and when required to ensure fulfilment of order and collection of payment under the contractual agreement executed by the company with the partners.</li>
            <li className="h5"  style={{ color: "black", listStyle: 'upper-roman' }}>Bellaz’s doesn't endorse or ensure warranty of the item or services, such as identity, legal titles, patented facilities, creditworthiness, etc. of any partner listed on the Bellaz’s platform. You are requested to review and verify the bona fides on your own of the partner that you choose to deal with and choose the one that fits your specific requirements.</li>
            <li className="h5"  style={{ color: "black", listStyle: 'upper-roman' }}>Partner will deliver the services along with content and data on an “As is” and “As Available” basis.</li>
            <li className="h5"  style={{ color: "black", listStyle: 'upper-roman' }}>The discounts and special offers given by our partners are subject to the terms and conditions of the respective partner. The allotted staff and time-slots are subject to revision, depending on the availability.</li>
            <li className="h5"  style={{ color: "black", listStyle: 'upper-roman' }}>Bellaz’s shall not be responsible for any breach or non-acceptance or any contractual terms between our partners and the customers. Bellaz’s cannot and doesn't guarantee that the involved customers or/and partners will carry out any transaction as mentioned on the platform.</li>
            <li className="h5"  style={{ color: "black", listStyle: 'upper-roman' }}>Bellaz’s operates as an online marketplace aggregator and has taken the role of facilitator. </li>
            <li className="h5"  style={{ color: "black", listStyle: 'upper-roman' }}>Bellaz’s, during any transaction between the partner and the customer on the platform, come into or claim possession of any service provided by the partner.</li>
            <li className="h5"  style={{ color: "black", listStyle: 'upper-roman' }}>Bellaz’s only provides a digital platform (Website and Mobile App) for appointment booking and order of product as provided in website or application. It is agreed that the contract for sale of any beauty, grooming, and personal care services and goods dash all be a strictly bipartite contract between the partner and the customer. In case of complaints from the customer about online booking, quality of services, payment or any other such issues, Bellaz’s shall forward the same to the partner on behalf of the customer and assist them by providing relevant information. The partner shall be responsible for ensuring a satisfactory resolution of the complaints.</li>
            <li className="h5"  style={{ color: "black", listStyle: 'upper-roman' }}>Bellaz’s will not be held liable for any of the transactions made for using the service(s) offered by the partners.</li>
            <li className="h5"  style={{ color: "black", listStyle: 'upper-roman' }}>Each claim and complaint related to any incorrect or failed payments must be directed to our payment processing partners and/or your bank (depending on the case)</li>
          </ul>
        </div>
        <div className="mb-8">
          <h2 className="mb-4 display-4" style={{textAlign: 'center'}}>Bellaz’s - Website and Mobile Apps (iOS and Android) Access and Use</h2>
          <p className="h5" style={{ color: "black" }}>You accept, undertake, and agree that the access and use of Bellaz’s platform by you shall be regulated by the given guidelines:</p>
          <p className="h5" style={{ color: "black" }}>You shall not upload, download, host, display, post, publish, update or share any data which:</p>
          <ul className="ml-10">
            <li className="h5"  style={{ color: "black", listStyle: 'upper-roman' }}>You are not entitled to, do not have any right to or belongs to another individual or business;</li>
            <li className="h5"  style={{ color: "black", listStyle: 'upper-roman' }}>Is defamatory, harassing, paedophilic, harmful, obscene, pornographic, blasphemous, pornographic, libellous, slanderous, criminally inciting or invasive of someone's privacy, hateful, or ethnically, racially offensive, disparaging, relating or promoting gambling or money laundering, or otherwise unlawful/illegal in any way; or unlawfully threatening or unlawfully harassing including but not limited to "indecent representation of women" within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986;</li>
            <li className="h5"  style={{ color: "black", listStyle: 'upper-roman' }}>Is misleading or misrepresentative in any manner;</li>
            <li className="h5"  style={{ color: "black", listStyle: 'upper-roman' }}>Is patently offensive to the online community, such as sexually explicit content, or content that promotes obscenity, pedophilia, racism, bigotry, hatred, or physical harm of any kind against any person or community;</li>
            <li className="h5"  style={{ color: "black", listStyle: 'upper-roman' }}>Threats, harasses, or encourages harassment of any individual;</li>
            <li className="h5"  style={{ color: "black", listStyle: 'upper-roman' }}>Implicates the circulation of unsolicited mass emailing  (referred to as "spamming");</li>
            <li className="h5"  style={{ color: "black", listStyle: 'upper-roman' }}>Promotes or encourages illegal activities that are threatening, abusive, obscene or defamatory;</li>
            <li className="h5"  style={{ color: "black", listStyle: 'upper-roman' }}>Hampers any individual's or group's rights [including, but not limited to, rights related to copyright infringement, intellectual property, rights of publicity or rights of privacy (including without limitation unauthorized disclosure of their personal data);</li>
            <li className="h5"  style={{ color: "black", listStyle: 'upper-roman' }}>Advocates an unlawful or unauthorized copy of someone else's copyrighted work, such as sharing pirated tools, programs or links to them, sharing information to evade manufacture-installed copy-protect systems, etc.;</li>
            <li className="h5"  style={{ color: "black", listStyle: 'upper-roman' }}>Consists of hidden, restricted, or password-only access pages, or images;</li>
            <li className="h5"  style={{ color: "black", listStyle: 'upper-roman' }}>Provides instructions/guidelines regarding unlawful activities like making or purchasing illegal arms, breaching other's privacy, or developing or providing viruses and malware;</li>
            <li className="h5"  style={{ color: "black", listStyle: 'upper-roman' }}>Tries to access without authorization or surpasses the scope of authorized access to the platform or account information, profiles, blogs, bulletins, communities, friend request, or other sections of the platform or solicits passwords or personal identification data information for marketing, commercial or illegal purposes from other users;</li>
            <li className="h5"  style={{ color: "black", listStyle: 'upper-roman' }}>Advocates betting or engage in such activities, which are or could be unlawful;</li>
            <li className="h5"  style={{ color: "black", listStyle: 'upper-roman' }}>Restricts or intervenes another individual's access and use of the Bellaz’s platform services;</li>
            <li className="h5"  style={{ color: "black", listStyle: 'upper-roman' }}>Misleads the visitors about the source of information or communicates any information which is menacing or grossly offensive;</li>
            <li className="h5"  style={{ color: "black", listStyle: 'upper-roman' }}>Impersonate another person;</li>
            <li className="h5"  style={{ color: "black", listStyle: 'upper-roman' }}>Carries computer viruses or any malware, code or files designed to limit, disturb or damage the function of any system resource; or contains any Trojan horses, worms, easter eggs, time bombs or other computer coding/programming regimes which may destroy, detrimentally intervene, degrade value of, surreptitiously intercept or expropriate any computer, data or personal information;</li>
            <li className="h5"  style={{ color: "black", listStyle: 'upper-roman' }}>Threatens the sovereignty, integrity, unity, defence, security or sovereignty of India, friendly terms with foreign countries, or public order or results in incitement to the commission of any criminal activity or averts investigation of any offense or is demeaning to any other nation.</li>
            <li className="h5"  style={{ color: "black", listStyle: 'upper-roman' }}>Indirectly or directly, provides, attempts to provide, trades or attempts to trade in any product, the dealing of which is restricted or prohibited by the law; or</li>
            <li className="h5"  style={{ color: "black", listStyle: 'upper-roman' }}>Creates liability for Bellaz’s or makes Bellaz’s lose (partial or whole) the services of our ISPs (Internet Service Providers) or other associates.</li>
          </ul>
          <p className="h5" style={{ color: "black" }}>You shall not probe, scan, or test the vulnerability of Bellaz’s platform, or any network linked to the platform or infringes the authentication or security parameters on the platform. </p>
          <p className="h5" style={{ color: "black" }}>You may not reverse look-up, trace or attempt to trace any kind of personal data of other visitors of the platform, or any other Buyer.</p>
          <p className="h5" style={{ color: "black" }}>You shall not make any demeaning, hurtful, negative, or defamatory comment(s) or statement(s) about Bellaz’s, or otherwise carry out any action which might damage/tarnish the reputation of Bellaz’s or the service providers listed on the platform or otherwise hamper any of the Bellaz’s' service or trademarks, brand name and/or goodwill resulting due to such trade or service marks, as might be under our ownership.</p>
          <p className="h5" style={{ color: "black" }}>You may not use the platform or any content on the platform for any purpose that is unlawful or prohibited by these Terms of Use or to solicit the performance of any illegal activity or other activity that infringes the rights of Bellaz’s and/or others.</p>
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditions;
