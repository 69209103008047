import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./upgrade.css";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { useHistory } from "react-router-dom";
import {
  createSubscriptionOrder,
  verifySubscriptionPlan,
} from "../../store/business/subscriptionPlan/subscriptionPlanApi";
import SVG from "react-inlinesvg";
import { useDispatch } from "react-redux";
import { ProfileUpdateData } from "../../store/business/authSlice";
import PackageUpdateDialog from "../dialogs/PackageUpdateDialog";

export function UpgradeCards({ name, uuid, benefits, cost, active, cycle }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { role, user } = useSelector((state) => state.authPersist);
  const { countryCode } = useSelector((state) => state.countryCode);
  const currencySymbol = countryCode.filter((el) => el.countryCode === user.countryCode);
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      console.log("Errror Occured");
      return;
    }

    const result = createSubscriptionOrder({ uuid }).then((response) => {
      const options = {
        key: process.env.key,
        amount: response.data.data.amount,
        currency: response.data.data.currency,
        name: "Bellaz Subscription",
        description: "Test Transaction",
        // image: { img },
        order_id: response.data.data.id,

        theme: {
          color: "#007669",
        },
        handler: async function (response) {
          console.log(response.razorpay_payment_id);
          console.log(response.razorpay_order_id);
          console.log(response.razorpay_signature);
          const data = {
            orderCreationId: response.id,
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
          };

          verifySubscriptionPlan(data)
            .then((response) => {
              console.log(response.data);
              dispatch(ProfileUpdateData(data));
              history.replace("/profile");
            })
            .catch((err) => {
              console.log(err.message);
            });
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    });
  }
  return (
    <>
      {active === true ? (
        <div className="card_map">
          <div className="top-header">
            <h1 className="top-header-text">{name}</h1>
          </div>
          <div className="p-0 d-flex">
            {/* <p className="price">
              <SVG
                className="rupee-svg"
                src={toAbsoluteUrl("/media/svg/icons/General/GoldRupee.svg")}
              ></SVG>
              {cost}
              <span className="month">{`/ ${cycle}`}</span>
            </p> */}
            <p className="price">
              <span className="rupee-normal">{currencySymbol[0] ? currencySymbol[0].currencySymbol : '₹'}</span>
              {cost}
              <span className="month">{`/ ${cycle}`}</span>
            </p>
          </div>
          <div
              className="btn btn-light-primary font-weight-bold"
            //onClick={displayRazorpay}
            >
              <PackageUpdateDialog content="Purchase"
                bookingDetails={
                  {
                    packageName: name,
                    duration: cycle,
                    amount: cost,
                    total: 100,
                    paidAmount: 50,
                    subTotal: 100,
                    tax: 0,
                    discount: 0,
                    uuid: uuid
                  }}
              />
            </div>
          <div className="discription m-0 text-left pl-3 pl-sm-0">
            {benefits.map((s) => {
              return (
                <div className="p-3 d-flex">
                  <SVG
                    className="thumb-svg"
                    src={toAbsoluteUrl("/media/svg/icons/General/Thumb.svg")}
                  ></SVG>
                  <i class="bi bi-arrow-return-right"></i>
                  <p key={s.index} className="pl-1 m-0">
                    {s}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
