import React from "react";

import { CategoryTable } from "./table";
import { CardBasic } from "../../../components/CardBasic";
import { useSelector } from "react-redux";

export function ExpenseRaPage() {
  const { role, user } = useSelector((state) => state.authPersist);
  return (
    <CardBasic
      title={"RA List"}
      btnType={"button"}
      headerBtnText={"Add RA"}
      headerBtnLink={"/expense/ra/create"}
    >
      <CategoryTable />
    </CardBasic>
  );
}
