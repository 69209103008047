import { createSlice } from "@reduxjs/toolkit";

import * as requestFromServer from "./shiftsApi";

const initialState = {
  shifts: [],
};

export const shiftSlice = createSlice({
  name: 'shift',
  initialState,
  reducers: {
    updateList(state, action) {
      state.shifts = action.payload;
    },
    created(state, action) {
      state.shifts.push(action.payload);
    }
  }
});

export const loadShiftList = () => {
  return async (dispatch) => {
    return requestFromServer
      .getShiftList()
      .then((response) => {
        dispatch(shiftSlice.actions.updateList(response.data.data));
      })
      .catch((err) => {
        alert(err.message);
      });
  }
}

export const createShift = (data) => {
  return async (dispatch) => {
    return requestFromServer
      .createShift(data)
      .then((response) => {
        dispatch(shiftSlice.actions.created(response.data.data));
      })
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };
};