import React from "react";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import BookOnlineIcon from '@material-ui/icons/Book';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import DescriptionIcon from '@material-ui/icons/Description';
import UpdateIcon from '@material-ui/icons/Update';
import BusinessIcon from '@material-ui/icons/Business';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

function CookiePolicy() {
  return (
    <div className="p-0 m-0" style={{ background: ' #f1f3f5' }}>
      <div
        className="container-fluid bg-dark d-flex flex-column justify-content-center align-items-center"
        style={{ height: "220px", borderRadius: "10px" }}
      >
        <h2 className="display-4 text-white mb-5"><DescriptionIcon className="mr-5 display-4 mb-2" />Cookie Policy</h2>
      </div>
      <div
        className="container bg-white p-12"
        style={{ width: "90%", marginTop: "-40px", borderRadius: "10px" }}
      >
        <div className="mb-8">
          {/* <h2 className="mb-4 display-5"><BusinessIcon className="display-4 pr-2 mb-2" />Welcome to BELLAZ’s PRIVACY POLICY</h2> */}
          <p className="h5" style={{ color: "black" }}>While cherishing the relationship that we share with you as our valued user, we duly understand and respect your concerns related to your data security and privacy. Our website uses “Cookies” as well that helps us improve user experience by allowing us to understand what kind of information is most useful to the visitors. It enables us to customize our content as per your preference and deliver relevant information whenever you revisit our website.</p>
          <p className="h5" style={{ color: "black" }}>We request you to kindly go through this Cookie Policy, which is a part and incorporated within and is to be read along the Terms and Conditions and Privacy Policy. If you have any concerns or queries regarding the same, kindly send an email at  <a href="mailto:support@my-bellaz.com" target="_blank">support@my-bellaz.com</a>.</p>
        </div>
        <div className="mb-8">
          <h2 className="mb-4 display-5">What does cookie mean? </h2>
          <p className="h5" style={{ color: "black" }}>Cookies can be described as small bits of data that get downloaded to any internet-enabled device - such as your desktop, laptop, smartphone or iPad - when you access a website. Such small packets of data are then sent back to the web servers (from where they originated) on each subsequent website visit or to another site that identifies such a cookie. Cookies are very useful when it comes to enhancing web experience as it allows servers to understand users’ preferences and help us serve you them with relevant content.</p>
        </div>
        <div className="mb-8">
          <h2 className="mb-4 display-5">Who is collecting it ?</h2>
          <p className="h5" style={{ color: "black" }}>Any personal information collected by or provided to Bellaz’s  via Cookies is managed and analysed by Bellaz’s. Bellaz’s  website will only collect, use or disclose your data where it is fair and lawful to do so.</p>
          <p className="h5" style={{ color: "black" }}>It is to be noted that this Cookie Policy applies to all the visitors of the website. Also, it applies to the websites, or any other third-party platforms, such as Facebook, LinkedIn, YouTube, etc., and Apps accessed or used through our Platform.</p>
          <p className="h5" style={{ color: "black" }}>By accessing our website or using its services, you are agreeing to our Cookie Policy, Privacy Policy, and our Terms and Conditions. If you do not agree to our use of cookies, then you are advised to disable the cookie notification on your web browser. In case you disallow/disable the cookies that our website uses, then you may not be able to access certain sections or some features of our website.</p>
          <p className="h5" style={{ color: "black" }}>The sections below outline the different kinds of cookies that our website uses along with specific purposes and help you in managing the cookies in a better way.</p>
          <p className="h5" style={{ color: "black" }}>What types of cookies does our platform use?</p>
          <p className="h5" style={{ color: "black" }}>The cookies that our website uses can be categorized as:</p>
          <ul className="ml-10">
            <li className="h5"  style={{ color: "black", listStyle: 'disc' }}><strong>Essential Cookies:</strong> These kinds of cookies don't distinguish you as an individual.</li>
            <li className="h5"  style={{ color: "black", listStyle: 'disc' }}><strong>Performance Cookies:</strong> These kinds of cookies don't distinguish you as an individual.</li>
            <li className="h5"  style={{ color: "black", listStyle: 'disc' }}><strong>Targeting or Marketing Cookies: </strong> Generally, these kinds of cookies track users via their IP address and thus, collect some personal data</li>
            <li className="h5"  style={{ color: "black", listStyle: 'disc' }}><strong>Functionality Cookies:</strong> The data that these cookies gather generally includes the information that you have disclosed.</li>
            <li className="h5"  style={{ color: "black", listStyle: 'disc' }}><strong>Third-Party Cookies: </strong> These kinds of cookies are used by relevant third-party websites or platforms, and they usually collect the personal information that you have disclosed.</li>
          </ul>
        </div>
        <div className="mb-8">
          <h2 className="mb-4 display-5">Duration of cookies that our platform uses</h2>
          <p className="h5" style={{ color: "black" }}>About the time duration, our website and application mostly use two different types of cookie:</p>
          <p className="h5" style={{ color: "black" }}>Session Cookies: These are temporary cookies that stay on your system until you exit; or Persistent Cookies: These are permanent cookies that remain on your system unless you delete them manually. The duration for these kinds of cookies depends on the “lifetime” of that particular cookie and your browser settings.</p>
        </div>
        <div className="mb-8">
          <h2 className="mb-4 display-5">Why do we use cookies?</h2>
          <p className="h5" style={{ color: "black" }}>We use cookies for a variety of purposes. First of all, the cookies that we use help us in understanding user behaviour by analysing their actions while navigating through the website. Also, these cookies empower us in tailoring our services and content to match users interests and requirements, and therefore, deliver a more personalized experience. Cookies also play a significant role in improving the loading speed and performance when you re-access our website.</p>
          <p className="h5" style={{ color: "black" }}>Also, we use some cookies that help us in preparing a detailed matrix, allowing us to determine how visitors use our website and its features. It also helps us in measuring the effectiveness of content and marketing campaigns.</p>
          <p className="h5" style={{ color: "black" }}>How can you manage or delete Cookies?</p>
          <p className="h5" style={{ color: "black" }}>There are many ways to manage your cookies:</p>
          <p className="h5" style={{ color: "black" }}>You can disagree/disallow our use of cookies;</p>

         <ul className="ml-10">
            <li className="h5"  style={{ color: "black", listStyle: 'disc' }}>You can disallow Bellaz’s  or third-party cookies by modifying the setting of your web browser; or</li>
            <li className="h5"  style={{ color: "black", listStyle: 'disc' }}>You can utilize our cookie management feature to disallow our use of cookies.</li>
          </ul>
        </div>
        <div className="mb-8">
          <h2 className="mb-4 display-5">Manage cookies via web browser settings</h2>
          <p className="h5" style={{ color: "black" }}>By default, most of the web browsers used today accept cookies. In case, you don't want Bellaz’s  to store cookies on your device, you can go to the settings menu of your browser and make necessary changes so that you receive a notification before certain cookies are stored. You can also adjust your settings so that your browser refuses most of our cookies or only certain cookies from third parties. You can also withdraw your consent to cookies by deleting the cookies that have already been stored.</p>
          <p className="h5" style={{ color: "black" }}>If you disallow/disable the cookies that our platform uses, then it might affect your Bellaz’s  platform's experience. For example, you may not be able to visit certain areas of a website or you may not receive personalized information when you visit a website.</p>
          <p className="h5" style={{ color: "black" }}>Bellaz’s  website, when accessed or used on different devices (such as computers, smartphones, tablets), you need to ensure that each browser on each device is adjusted to suit your cookie preferences. The methods to change the settings and cookies vary from browser to browser. If required, use the help/support option given on the browser you use or click on one of the links below to directly access the user manual.</p>
          <ul className="ml-10">
            <li className="h5"  style={{ color: "black", listStyle: 'disc' }}><a href="https://www.zoylee.com/cookie-policy" target="_blank">Google Chrome</a></li>
            <li className="h5"  style={{ color: "black", listStyle: 'disc' }}><a href="https://www.zoylee.com/cookie-policy" target="_blank">Firefox Mozilla</a></li>
            <li className="h5"  style={{ color: "black", listStyle: 'disc' }}><a href="https://www.zoylee.com/cookie-policy" target="_blank">Internet Explorer</a></li>
            <li className="h5"  style={{ color: "black", listStyle: 'disc' }}><a href="https://www.zoylee.com/cookie-policy" target="_blank">Safari</a></li>
            <li className="h5"  style={{ color: "black", listStyle: 'disc' }}><a href="https://www.zoylee.com/cookie-policy" target="_blank">UC Browser</a></li>
            <li className="h5"  style={{ color: "black", listStyle: 'disc' }}><a href="https://www.zoylee.com/cookie-policy" target="_blank">Opera</a></li>
            <li className="h5"  style={{ color: "black", listStyle: 'disc' }}><a href="https://www.zoylee.com/cookie-policy" target="_blank">Epic Browser</a></li>
          </ul>
        </div>
        <div className="mb-8">
          <h2 className="mb-4 display-5">Control via our cookie management tool</h2>
          <p className="h5" style={{ color: "black" }}>You can disable Bellaz’s  or third-party cookies by use of our cookie consent tool. Switching off tracking cookies, for example, ensures that Bellaz’s  will no longer track your online behaviour. However please note that opting out from tracking cookies does not necessarily mean that you will receive less Bellaz’s  advertising. It just means that the advertising you receive will not be tailored to your interests.</p>
        </div>
      </div>
    </div>
  );
}

export default CookiePolicy;
