import React from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import "../BellazMain.css";
function StoreButton() {
  return (
    <div className="d-flex store-button">
      <a href="https://apps.apple.com/in/app/bellaz-customer/id6444063935" target="_blank" className="mr-5">
        {" "}
        <img
          src={toAbsoluteUrl("/media/BellazMain/button/apple.png")}
          alt=""
          className="w-100 mr-5"
        />
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.mybellaz" target="_blank">
      <img
        src={toAbsoluteUrl("/media/BellazMain/button/google-play-badge.png")}
        alt=""
        className="w-100 h-100 bg-transparent"
      />
      </a>
    </div>
  );
}

export default StoreButton;
