import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Link, Redirect, useLocation, useParams } from "react-router-dom";
import * as Yup from "yup";
import { requestPassword } from "../_redux/authCrud";
import LockIcon from "@material-ui/icons/Lock";
import { sendFailure, sendSuccess } from "../../../../store/toast/toastSlice";
import { forgotPasswordEmail, branchForgotPassword } from "../../../../store/business/authSlice";
const initialValues = {
  email: "",
};

function ForgotPassword(props) {
  const [isRequested, setIsRequested] = useState(false);
  const location = useLocation();
  const { branch } = useParams();
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Enter a valid Email ID format")
      .min(3, "Minimum 3 symbols")
      .required("This field is required"),
    // .required(
    //   intl.formatMessage({
    //     id: "AUTH.VALIDATION.REQUIRED_FIELD",
    //   })
    // ),
  });

  //console.log(branch);

  const dispatch = useDispatch();

  const forgotPasswordHandler = (values) => {
    //console.log(values);
    if (branch === ":branch") {
      //console.log('Hello Yen');
      dispatch(branchForgotPassword(values))
        .then((response) => {
          //console.log(response);
          dispatch(
            sendSuccess("A verification link has been sent to your account")
          );
          //disableLoading();
        })
        .catch((err) => {
          //console.log(err);
          dispatch(sendFailure("Please try again"));
          formik.setSubmitting(false);
          //disableLoading();
          formik.setStatus(err.message);
        });
    } else {
      dispatch(forgotPasswordEmail(values))
        .then((response) => {
          //console.log(response);
          dispatch(
            sendSuccess("A verification link has been sent to your account")
          );
          //disableLoading();
        })
        .catch((err) => {
          //console.log(err);
          dispatch(sendFailure("Please try again"));
          formik.setSubmitting(false);
          //disableLoading();
          formik.setStatus(err.message);
        });
    }
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      //console.log(values);
      //console.log(values.email);
      // requestPassword(values.email)
      //   .then(() => setIsRequested(true))
      //   .catch(() => {
      //     setIsRequested(false);
      //     setSubmitting(false);
      //     // setStatus(
      //     //   intl.formatMessage(
      //     //     { id: "AUTH.VALIDATION.NOT_FOUND" },
      //     //     { name: values.email }
      //     //   )
      //     // );
      //   });
      setSubmitting(true);
      forgotPasswordHandler(values);
      setSubmitting(false);
    },
  });

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <LockIcon className="mb-4 display-1" />
            <h3 className="font-size-h1">Reset Your Password</h3>
            <div className="text-muted font-weight-bold">
              Reset your password and restore your activities
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Enter your registered Email ID"
                type="email"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "email"
                )}`}
                name="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              {/* <Link to="/auth/new-password"> */}

              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.isSubmitting}
                disabled={
                  !Boolean(formik.touched.email)
                    ? true
                    : Boolean(formik.errors.email)
                }
              >
                Send Verification Link
              </button>
              {/* </Link> */}
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Cancel
                </button>
              </Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default ForgotPassword;
