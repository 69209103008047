import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, NavLink, Redirect, useHistory } from "react-router-dom";
import { register } from "../../../../store/business/authApi";
import { useDispatch, useSelector } from "react-redux";
import { sendOtpAction, resendOtpAction } from "../../../../store/otp/otpSlice";
import TermsCondition from "../../../../components/dialogs/TermsCondition";
import { getCountryCodeList } from "../../../../store/countryCode/countryCodeSlice";

const initialValues = {
  business_name: "",
  owner_name: "",
  email: "",
  countryCode: "+91",
  phone: "",
  otp: "",
  password: "",
  c_password: "",
  calendarType: "",
  terms: false,
};

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

function Registration(props) {
  const dispatch = useDispatch();

  const [sendOtpLoader, setSendOtpLoading] = useState(false);
  const [otpState, setOtpState] = useState(false);
  const [otpResendState, setResendOtpState] = useState(false);

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [formStatus, setFormStatus] = useState("danger");
  const { countryCode } = useSelector((state) => state.countryCode);
  const { calenderType } = useSelector((state) => state.countryCode);
  // console.log(countryCode);
  // console.log(calenderType);
  const RegistrationSchema = Yup.object().shape({
    business_name: Yup.string()
      .min(3, "Minimum 3 characters are required")
      .required("This field is required"),

    owner_name: Yup.string()
      .min(3, "Minimum 3 characters are required")
      .required("This field is required"),

    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .required("This field is required"),

    password: Yup.string()
      .required("Please Enter your password")
      .min(8, "Password is too short - should be 8 chars minimum."),

    c_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Please confirm your password."),

    countryCode: Yup.string().required("This field is required"),

    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(4, "Enter 4 digit phone number")
      .max(20, "Enter 20 digit phone number")
      .required("This field is required"),

    otp: Yup.string()
      .matches(phoneRegExp, "OTP is not valid")
      .min(6, "OTP must be of 6 digits")
      .max(6, "OTP must be of 6 digits")
      .required("Kindly enter VERIFICATION CODE sent to your phone"),

    calendarType: Yup.string().required("This field is required"),

    terms: Yup.bool().required("You must accept the terms and conditions"),

    // password: Yup.string()
    //   .min(3, "Minimum 3 symbols")
    //   .max(50, "Maximum 50 symbols")
    //   .required(
    //     intl.formatMessage({
    //       id: "AUTH.VALIDATION.REQUIRED_FIELD",
    //     })
    //   ),
    // changepassword: Yup.string()
    //   .required(
    //     intl.formatMessage({
    //       id: "AUTH.VALIDATION.REQUIRED_FIELD",
    //     })
    //   )
    //   .when("password", {
    //     is: (val) => (val && val.length > 0 ? true : false),
    //     then: Yup.string().oneOf(
    //       [Yup.ref("password")],
    //       "Password and Confirm Password didn't match"
    //     ),
    //   }),
  });

  useEffect(() => {
    dispatch(getCountryCodeList());
  }, []);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      //const { terms, country, ...requiredValues } = values;
      delete values.terms;
      console.log(values);
      setSubmitting(true);
      enableLoading();
      register(values)
        .then((response) => {
          // console.log(response);
          disableLoading();
          //console.log(response.data.message);
          if (
            response.data.message === "Business already exists. Kindly login."
          ) {
            setSubmitting(false);
            setFormStatus("danger");
            setStatus("Error! Phone or email already exists.");
            setTimeout(() => {
              history.replace("/auth/login");
            }, 3000);
          } else {
            setFormStatus("success");
            setStatus("Registration Successful. Redirecting to login page...");
            setTimeout(() => {
              history.replace("/auth/login");
            }, 3000);
          }
        })
        .catch((err) => {
          setSubmitting(false);
          setFormStatus("danger");
          disableLoading();
          if (err && err.response.data.error.includes("E11000")) {
            setStatus("Error! Phone or email already exists.");
          } else {
            setStatus("Incorrect Verification Code. Kindly retry.");
          }
        });
    },
  });

  const otpStateHandler = () => {
    setResendOtpState(true);
    setSendOtpLoading(false);
    setTimeout(() => {
      setResendOtpState(false);
    }, 30000);
  };

  const sendOtpHandler = () => {
    //console.log(formik.values.countryCode);
    setSendOtpLoading(true);
    dispatch(sendOtpAction(formik.values.countryCode, formik.values.phone))
      .then(() => {
        setOtpState(true);
        otpStateHandler();
        //console.log("otp send success");
      })
      .catch((error) => {
        // console.log(error.message);
        setSendOtpLoading(false);
      });
  };

  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      <div className="text-center mb-10">
        <h3 className="font-size-h1">Sign Up to Bellaz</h3>
        <p className="text-muted font-weight-bold">
          Enter your details to create an account
        </p>
      </div>

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status && (
          <div
            className={`mb-10 alert alert-custom alert-light-${formStatus} alert-dismissible`}
          >
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Business Name"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "business_name"
            )}`}
            name="business_name"
            {...formik.getFieldProps("business_name")}
          />
          {formik.touched.business_name && formik.errors.business_name ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.business_name}</div>
            </div>
          ) : null}
        </div>

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Owner Name"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "owner_name"
            )}`}
            name="owner_name"
            {...formik.getFieldProps("owner_name")}
          />
          {formik.touched.owner_name && formik.errors.owner_name ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.owner_name}</div>
            </div>
          ) : null}
        </div>

        <div className="form-group fv-plugins-icon-container">
          <select
            placeholder="Calendar Type"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "calendarType"
            )}`}
            name="calendarType"
            {...formik.getFieldProps("calendarType")}
          >
            <option value={"Select Calender Type"} key={"Select Calender Type"}>
              {"Select Calender Type"}
            </option>
            {calenderType.map((calenderType, index) => (
              <option value={calenderType} key={calenderType}>
                {calenderType}
              </option>
            ))}
          </select>
          {formik.touched.calendarType && formik.errors.calendarType ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.calendarType}</div>
            </div>
          ) : null}
        </div>

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "Password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="ConfirmPassword"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "c_password"
            )}`}
            name="c_password"
            {...formik.getFieldProps("c_password")}
          />
          {formik.touched.c_password && formik.errors.c_password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.c_password}</div>
            </div>
          ) : null}
        </div>

        {/* <div className="form-group fv-plugins-icon-container">
          <select
            placeholder="Country Code"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "countryCode"
            )}`}
            name="countryCode"
            {...formik.getFieldProps("countryCode")}
          >
            <option value="">Country Code</option>
            <option value="+91">+91</option>
            <option value="+971">+971</option>
          </select>
          {formik.touched.countryCode && formik.errors.countryCode ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.countryCode}</div>
            </div>
          ) : null}
        </div> */}

        <div className="form-group fv-plugins-icon-container row">
          <select
            placeholder="Country Code"
            type="text"
            className={`form-control form-control-solid h-auto py-2 px-3 col-lg-2 ml-4 mr-4 ${getInputClasses(
              "countryCode"
            )}`}
            name="countryCode"
            {...formik.getFieldProps("countryCode")}
          >
             <option
                    value={"Select Country Code"}
                    key={"Select Country Code"}
                  >
                    {"Select Country Code"}
                  </option>
            {countryCode.map((countryCode, index) => (
              <option
                value={countryCode.countryCode}
                key={countryCode.countryCode}
              >
                {countryCode.countryCode}
              </option>
            ))}
          </select>

          <input
            placeholder="Phone"
            type="phone"
            className={`form-control form-control-solid h-auto py-5 px-6 col-lg-9 ${getInputClasses(
              "phone"
            )}`}
            name="phone"
           // maxLength="10"
            {...formik.getFieldProps("phone")}
          />
          {formik.touched.phone && formik.errors.phone ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.phone}</div>
            </div>
          ) : null}

          {/* {formik.touched.countryCode && formik.errors.countryCode ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.countryCode}</div>
            </div>
          ) : null} */}

          {formik.touched.phone && !formik.errors.phone ? (
            <button
              id="kt_login_signin_submit"
              type="button"
              className={`btn btn-primary font-weight-bold px-9 py-4 my-3 ml-4`}
              disabled={otpResendState}
              onClick={sendOtpHandler}
            >
              <span>
                {otpState
                  ? "Resend Verification Code"
                  : "Send Verification Code"}
              </span>
              {sendOtpLoader && (
                <span className="ml-3 spinner spinner-white"></span>
              )}
            </button>
          ) : null}
        </div>

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Verification Code"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "otp"
            )}`}
            name="otp"
            maxLength="6"
            {...formik.getFieldProps("otp")}
          />
          {formik.touched.otp && formik.errors.otp ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.otp}</div>
            </div>
          ) : null}
        </div>

        {/* begin: Terms and Conditions */}
        <div className="form-group">
          <label className="checkbox">
            <input
              type="checkbox"
              name="terms"
              className="m-1"
              {...formik.getFieldProps("terms")}
            />
            <span />
            {/* <Link to="/terms" className="ml-3">
              I agree the Terms & Conditions and Privacy Policy
            </Link> */}
            <a href="/terms" target="_blank" className="ml-3">
              I agree the Terms & Conditions and Privacy Policy
            </a>
          </label>
          {formik.touched.terms && formik.errors.terms ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.terms}</div>
            </div>
          ) : null}
        </div>
        {/* end: Terms and Conditions */}
        <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="submit"
            disabled={
              formik.isSubmitting || !formik.isValid || !formik.values.terms
            }
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>Register</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>

          <Link to="/auth/login">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              Cancel
            </button>
          </Link>
        </div>
      </form>
    </div>
  );
}

export default Registration;
