import React from "react";
import { Dropdown } from "react-bootstrap";

import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../_helpers";
import { DropdownCustomToggler } from "../../_metronic/_partials/dropdowns";
import { BookingDropdownMenu } from "../../components/dropdowns/bookingDropdownMenu";

const BookingStatusTitles = [
  {
    status: 0,
    label: "Cancelled",
    labelClass: "light-danger",
  },
  {
    status: 1,
    label: "Pending",
    labelClass: "light-warning",
  },
  {
    status: 2,
    label: "Confirmed",
    labelClass: "light-success",
  },
  {
    status: 3,
    label: "Present",
    labelClass: "light-info",
  },
  {
    status: 4,
    label: "Completed",
    labelClass: "success",
  },
  {
    status: 5,
    label: "Invoiced",
    labelClass: "info",
  },
];

export const StatusColumnFormatter = (cellContent, row) => {
  const labelClass = row.isActive ? "success" : "danger";
  return (
    <span className={`label label-lg label-light-${labelClass} label-inline`}>
      {row.isActive ? "Active" : "Inactive"}
    </span>
  );
};

export const StatusColumnFormatterPo = (cellContent, row) => {
  const labelClass = row.isPaid ? "success" : "danger";
  return (
    <span className={`label label-lg label-light-${labelClass} label-inline`}>
      {row.isPaid ? "Yes" : "No"}
    </span>
  );
};

export const BookingStatusColumnFormatter = (cellContent, row) => {
  const labelClass = BookingStatusTitles[row.status].labelClass;
  return (
    <span className={`label label-lg label-${labelClass} label-inline`}>
      {BookingStatusTitles[row.status].label}
    </span>
  );
};

export const TransactionColumnFormatter = (cellContent, row) => {
  const labelClass = row.status == "success" ? "success" : "danger";
  return (
    <span className={`label label-lg label-light-${labelClass} label-inline`}>
      {row.status == "success" ? "Success" : "Failed"}
    </span>
  );
};

export const ActionColumnSimple = () => {
  return (
    <div>
      <a
        href=""
        className="btn btn-sm btn-clean btn-icon mr-2"
        data-toggle="dropdown"
      >
        <span className="svg-icon svg-icon-primary menu-icon">
          <SVG src={toAbsoluteUrl("/media/svg/icons/General/Edit.svg")} />
        </span>
      </a>
      <a
        href=""
        className="btn btn-sm btn-clean btn-icon"
        title="Delete"
        onClick={() => {
          console.log("_id");
        }}
      >
        <span className="svg-icon svg-icon-danger menu-icon">
          <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
        </span>
      </a>
    </div>
  );
};

export const BookingActionColumn = (cellContent, row) => {
  return row.status > 0 ? (
    <div>
      <Dropdown className=" " alignRight>
        <Dropdown.Toggle id="" as={DropdownCustomToggler}>
          <i className="ki ki-bold-more-ver" />
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right  ">
          <BookingDropdownMenu bookingDetails={row} />
        </Dropdown.Menu>
      </Dropdown>
    </div>
  ) : (
    null
  );
};
