import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, Select } from "../../_metronic/_partials/controls";
import { CardBasic } from "../../components/CardBasic";
import { getCountryCodeList } from "../../store/countryCode/countryCodeSlice";
import { createService } from "../../store/business/services/servicesSlice";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getService,
  updateService,
} from "../../store/business/services/servicesApi";
import { sendFailure, sendSuccess } from "../../store/toast/toastSlice";
export const ServiceView = () => {
  const [serviceData, setServiceData] = useState({});

  const history = useHistory();
  const dispatch = useDispatch();
  const { role, user } = useSelector((state) => state.authPersist);
  const { countryCode } = useSelector((state) => state.countryCode);
  const currencySymbol = countryCode.filter((el) => el.countryCode === user.countryCode);
  const { serviceUuid } = useParams();

  useEffect(() => {
    dispatch(getCountryCodeList());
  }, []);

  const req = {
    uuid: serviceUuid,
  };

  const { serviceCategories } = useSelector((state) => state.data);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required"),
    category_uuid: Yup.string().required("This field is required"),
    price: Yup.number()
      .positive("Price must be greater than 0")
      .required("This field is required")
      .typeError("Price must be an number"),
    time: Yup.number().required("This field is required"),
    discount: Yup.bool(),
    isActive: Yup.string().required("This field is required"),
    short_description: Yup.string()
      .max(200, "Maximum length should be 200 characters.")
      .required("This field is required"),
  });

  useEffect(() => {
    getService(req)
      .then((response) => {
        const data = response.data.data;
        // console.log(data);
        setServiceData(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return (
    <CardBasic
      title={"Edit Service"}
      btnType={"submit"}
      formId={"updateForm"}
      headerBtnText={"Update Service"}
    >
      <Formik
        initialValues={{
          name: serviceData.name ?? "",
          category_uuid: serviceData.category_uuid ?? "",
          price: serviceData.price ?? "",
          time: serviceData.time ?? "",
          short_description: serviceData.short_description ?? "",
          isActive: serviceData.isActive ?? false,
          applicable_discount: serviceData.applicable_discount ?? false,
        }}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          // console.log(values);
          updateService(values, serviceUuid)
            .then(() => {
              dispatch(
                sendSuccess(
                  "The service information has been updated successfully"
                )
              );
              history.replace("/services");
            })
            .catch((err) => {
              dispatch(sendFailure(err.message));
              actions.setStatus(err.message);
              console.log(err.message);
            });
          actions.setFormStatus("submitted");
        }}
      >
        <Form className="form form-label-right" id="updateForm">
          <div className="form-group row">
            <div className="col-lg-12">
              <Field
                name="name"
                component={Input}
                placeholder="Service Name"
                label="Service Name *"
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-4">
              <Select
                name="category_uuid"
                label="Service Category *"
                placeholder="Select Category"
              >
                {serviceCategories.map((category, index) => (
                  <option value={category.uuid} key={category.uuid}>
                    {category.name}
                  </option>
                ))}
              </Select>
            </div>
            <div className="col-lg-4">
              <Field
                name="price"
                type="number"
                min="1"
                component={Input}
                placeholder="0.00"
                label={`Price (${currencySymbol[0] ? currencySymbol[0].currencySymbol : '₹'}) *`}
              />
            </div>
            <div className="col-lg-4">
              <Field
                name="time"
                type="number"
                min="5"
                step="5"
                component={Input}
                placeholder="15"
                label="Time (Minutes) *"
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-4">
              <Select name="isActive" label="Status *">
                <option value={true}>Active</option>
                <option value={false}>Inactive</option>
              </Select>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-12">
              <Field
                name="short_description"
                component={Input}
                label="Short Description *"
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-lg-12 checkbox-inline">
              <label className="checkbox">
                <Field type="checkbox" name="applicable_discount" />
                <span></span>Applicable for discount offers
              </label>
            </div>
          </div>
          {/* <div className="form-group row">
            <div className="col-lg-12">
              <label>Service Full Description</label>
              <Field
                name="description"
                as="textarea"
                rows="5"
                className="form-control"
              />
            </div>
          </div> */}
        </Form>
      </Formik>
    </CardBasic>
  );
};
