import axios from "axios";

const URL = process.env.REACT_APP_API_URL + "/business";
const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const getChairList = () => {
  return axios.get(`${URL}/chair/list`);
};

export const getChair = async (req) => {
  return await axios.post(`${URL}/chair/view`, req);
};

export const createChair = (data) => {
  return axios.post(`${URL}/chair/create`, data, {
    headers,
  });
};

export const updateChair = (data, uuid) => {
  console.log(data);
  data.uuid = uuid;
  console.log(data);
  return axios.patch(`${URL}/chair/update`, data, {
    headers,
  });
};
