import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import filterFactory from "react-bootstrap-table2-filter";
import { sortCaret } from "../../../_metronic/_helpers";
import { Formik, Form } from "formik";
import paginationFactory from "react-bootstrap-table2-paginator";
import { DatePickerField } from "../../../_metronic/_partials/controls";
import { loadHolidayList } from "../../../store/business/staff/holiday/holidaySlice";
import dayjs from "dayjs";
import StuffHolidayDialog from "../../../components/dialogs/StuffHolidayDialog";
import { loadBranchHolidayList } from "../../../store/branch/branch_holidays/branchHolidaySlice";

const TimeFormatter = (cell, row) => {
  return dayjs(cell).format("DD-MM-YYYY");
};

export const StaffHolidayTable = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.holiday.leaves);
  const branchHoliday = useSelector(
    (state) => state.branchHoliday.branchHoliday
  );
  console.log(data);
  const { role } = useSelector((state) => state.authPersist);

  const [dataLoading, setDataLoading] = useState(true);

  const date = new Date();
  date.setDate(date.getDate() + 15);

  useEffect(() => {
    if (role === "business") {
      dispatch(loadHolidayList()).then(() => {
        setDataLoading(false);
      });
    } else {
      dispatch(loadBranchHolidayList({from: new Date(), to: date})).then(() => {
        setDataLoading(false);
      });
    }
  }, []);

  const ActionDisplay = (cell, row) => {
    //console.log(row);
    return <StuffHolidayDialog content={<i className="flaticon-delete" style={{ cursor: "pointer" }} />} role="business" uuid={row.uuid} />
  };

  const columns = [
    {
      dataField: "",
      text: "#",
      formatter: (row, cell, index) => {
        return index + 1;
      },
    },
    {
      dataField: "staff[0].name",
      text: "Staff Name",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "start_date",
      text: "Start Date",
      sort: true,
      formatter: TimeFormatter,
    },
    {
      dataField: "end_date",
      text: "End Date",
      sort: true,
      formatter: TimeFormatter,
    },
    {
      dataField: "",
      text: "delete",
      formatter: ActionDisplay,
      //sortCaret: sortCaret,
    },
  ];
  console.log(role);
  const { SearchBar, ClearSearchButton } = Search;

  return (
    <>
      {role == "business" ? null : <Formik
        initialValues={{
          startDate: new Date(),
          endDate: date
        }}
        onSubmit={
          (values, errors) => {
            console.log(values);
            if (role == "business") {
              dispatch(loadHolidayList()).then(() => {
                setDataLoading(false);
              });
            } else {
              dispatch(loadBranchHolidayList({ from: values.startDate, to: values.endDate })).then(() => {
                setDataLoading(false);
              });
            }
          }
          // saveCustomer(values);
        }
      >
        {({ values, errors, setFieldValue }) => {
          console.log(values);
          return (
            <Form className="form form-label-right" id="createBookingForm">
              <div className="container">
                <div className="row mb-5">
                  <div className="col-sm-2"></div>
                  <div className="col-sm-3">
                    <div className="mx-3">
                      <label>From</label>
                    </div>
                    <DatePickerField
                      name="startDate"
                      className="form-control"
                      onChange={(val) => {
                        setFieldValue("startDate", val);
                      }}
                    />
                  </div>
                  <div className="col-sm-3">
                    <div className="mx-3">
                      <label>To</label>
                    </div>
                    <DatePickerField
                      name="endDate"
                      className="form-control"
                      onChange={(val) => {
                        setFieldValue("endDate", val);
                      }}
                    />
                  </div>
                  <div className="col-sm-3 mt-8">
                    <button type="submit" class="btn btn-light-success font-weight-bold" style={{ marginLeft: ' 20px' }}>
                      Filter Data
                    </button>
                  </div>
                </div>
              </div>
            </Form>)
        }}

      </Formik>}
      <ToolkitProvider
        search
        keyField="id"
        data={role == "business" ? data : branchHoliday}
        columns={columns}
      >
        {(props) => (
          <>
            <div className="container">
              <div class="row">
                <div className="col-sm-4">
                  {/* <h4 className="mt-4" >Search</h4> */}
                </div>
                <div className="col-sm-8">
                  <SearchBar {...props.searchProps} />
                  <ClearSearchButton
                    {...props.searchProps}
                    className="ml-4 btn btn-secondary-light"
                  />
                </div>
              </div>
            </div>

            <BootstrapTable
              {...props.baseProps}
              classes="table table-head-custom table-vertical-center overflow-hidden"
              wrapperClasses="table-responsive"
              bordered={false}
              bootstrap4
              pagination={paginationFactory()}
              filter={filterFactory()}
              noDataIndication={
                <div className={"d-flex justify-content-center py-10"}>
                  <h3>{dataLoading ? "Loading Data..." : "No records found"}</h3>
                </div>
              }
            />
          </>
        )}
      </ToolkitProvider>
    </>
  );
};
