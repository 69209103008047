import React from "react";
import { useField, useFormikContext } from "formik";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control", "form-control-solid"];
  if (touched && errors) {
    classes.push("is-invalid-select");
  }

  if (touched && !errors) {
    classes.push("is-valid-select");
  }

  return classes.join(" ");
};

export function Select({
  label,
  withFeedbackLabel = true,
  type = "text",
  customFeedbackLabel,
  children,
  ...props
}) {
  const { errors, touched } = useFormikContext();
  const [field] = useField(props);
  return (
    <>
      {label && <label>{label}</label>}
      <select
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        {...field}
        {...props}
      >
        {children}
      </select>
      {errors[field.name] && touched[field.name] ? (
        <div
          className="invalid-datepicker-feedback"
          style={{ color: "#F64E60" }}
        >
          {errors[field.name].toString()}
        </div>
      ) : (
        ""
      )}
    </>
  );
}
