import { createSlice } from "@reduxjs/toolkit";

import * as requestFromServer from "./categoryAPi";

const initialState = {
  category: [],
};

export const expenseCategoryBranchSlice = createSlice({
  name: 'expenseCategory',
  initialState,
  reducers: {
    updateList(state, action) {
      state.category = action.payload;
    },
    created(state, action) {
      state.category.push(action.payload);
    }
  }
});

export const loadCategoryList = () => {
  return async (dispatch) => {
    return requestFromServer
      .getCategoryList()
      .then((response) => {
        console.log(response.data);
        dispatch(expenseCategoryBranchSlice.actions.updateList(response.data.data));
      })
      .catch((err) => {
        alert(err.message);
      });
  }
}
