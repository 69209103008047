import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import filterFactory from "react-bootstrap-table2-filter";
import { sortCaret } from "../../_metronic/_helpers";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useDispatch, useSelector } from "react-redux";
import { loadScheduleList } from "../../store/business/schedule/scheduleSlice";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { loadBranchScheduleList } from "../../store/branch/branch_schedule/branchScheduleSlice";

const TimeFormatter = (cell, row) => {
  return dayjs(cell).format("hh:mm A");
};
const nameFormat = (cell, row) => {
  return cell.map((key) => {
    return (
      <>
        <Link to={`schedule/${row.uuid}`}>{key.name}</Link>
        <br />
      </>
    );
  });
};
const DateFormatter = (cell, row) => {
  return `${dayjs(`${cell}`).format("DD-MM-YYYY")} to ${dayjs(
    `${row.end_date}`
  ).format("DD-MM-YYYY")}`;
};
export const ScheduleTable = () => {
  const dispatch = useDispatch();
  const schedule = useSelector((state) => state.schedule.schedule);
  const branchSchedule = useSelector(
    (state) => state.branchSchedule.branchSchedule
  );
  const { role } = useSelector((state) => state.authPersist);
  const [dataLoading, setDataLoading] = useState(true);

  useEffect(() => {
    if (role === "business") {
      dispatch(loadScheduleList()).then(() => {
        setDataLoading(false);
      });
    } else {
      dispatch(loadBranchScheduleList()).then(() => {
        setDataLoading(false);
      });
    }
  }, []);
  const columns = [
    {
      dataField: "staff",
      text: "Staff Name",
      sort: true,
      sortCaret: sortCaret,
      formatter: nameFormat,

      // filter: textFilter(),
    },
    {
      dataField: "start_date",
      text: "Applicable For",
      sort: true,
      sortCaret: sortCaret,
      formatter: DateFormatter,
      // filter: textFilter(),
    },
    {
      dataField: "start_time",
      text: "Start Time",
      sort: true,
      sortCaret: sortCaret,
      formatter: TimeFormatter,
    },
    {
      dataField: "end_time",
      text: "End Time",
      sort: true,
      sortCaret: sortCaret,
      formatter: TimeFormatter,
    },
    {
      dataField: "break_start_time",
      text: "Break Start Time",
      sort: true,
      sortCaret: sortCaret,
      formatter: TimeFormatter,
    },
    {
      dataField: "break_end_time",
      text: "Break End Time",
      sort: true,
      sortCaret: sortCaret,
      formatter: TimeFormatter,
    },
  ];

  const data = schedule;

  const { SearchBar, ClearSearchButton } = Search;

  return (
    <ToolkitProvider
      search
      keyField="id"
      data={role == "business" ? data : branchSchedule}
      columns={columns}
    >
      {(props) => (
        <>
          <div className="form-group row align-items-center ">
            <div className="col-lg-4">
              <h4 className="mb-0 float-right">Search</h4>
            </div>
            <div className="col-lg-8">
              <SearchBar {...props.searchProps} />
              <ClearSearchButton
                {...props.searchProps}
                className="ml-4 btn btn-secondary-light"
              />
            </div>
          </div>

          <BootstrapTable
            {...props.baseProps}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            wrapperClasses="table-responsive"
            bordered={false}
            bootstrap4
            pagination={paginationFactory()}
            filter={filterFactory()}
          />
        </>
      )}
    </ToolkitProvider>
  );
};
