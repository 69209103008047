import axios from "axios";

const URL = process.env.REACT_APP_API_URL + "/business";
const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const getShiftList = () => {
  return axios.get(`${URL}/shift/list`);
};

export const getShift = async (req) => {
  return await axios.post(`${URL}/shift/view`, req);
};

export const createShift = (data) => {
  return axios.post(`${URL}/shift/create`, data, {
    headers,
  });
};

export const updateShift = (data, uuid) => {
  data.shift_uuid = uuid;
  console.log(data);
  return axios.patch(`${URL}/shift/update`, data, {
    headers,
  });
};