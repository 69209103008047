import axios from "axios";

export const OTP_URL = process.env.REACT_APP_API_URL+"/otp";

export const sendOtp = (countryCode, phone) => {
  return axios.post(`${OTP_URL}/send`, { countryCode, phone });
}

export const resendOtp = (countryCode, phone, medium = "text") => {
  return axios.post(`${OTP_URL}/resend`, { countryCode, phone, medium });
}