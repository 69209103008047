import axios from "axios";

const URL = process.env.REACT_APP_API_URL + "/branch";
const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const getRaList = () => {
  return axios.get(`${URL}/voucher/RA/list`);
};

export const createRa = (data) => {
  return axios.post(`${URL}/voucher/RA/create`, data);
};

export const getRa = async (req) => {
  return await axios.post(`${URL}/voucher/RA/view`, req);
};

export const updateRa = (data) => {
  console.log(data);
  return axios.patch(`${URL}/voucher/RA/update`, data);
};

export const deleteRa = (data) => {
  console.log(data);
  const req = {
    voucherUuid: data,
  };
  return axios.delete(`${URL}/voucher/RA/delete`, {
    headers: {
      'Content-Type': 'application/json',
    },
    data: { "voucherUuid": data }
  });
};