import React from "react";

import { StaffHolidayTable } from "./table";
import { CardBasic } from "../../../components/CardBasic";
import { useSelector } from "react-redux";

export function StaffHolidayPage() {
  const { role } = useSelector((state) => state.authPersist);

  return (
    <CardBasic
      title={"Staff Leaves"}
      btnType={"button"}
      headerBtnText={role === "business" ? "Add Staff Leave" : null}
      headerBtnLink={role === "business" ? "/staff/holiday/create" : null}
    >
      <StaffHolidayTable />
    </CardBasic>
  );
}
