import React from "react";

import { PackagesTable } from "./table";
import { CardBasic } from "../../components/CardBasic";

export function PackagesPage() {
  return (
    <CardBasic
      title={"Packages List"}
      btnType={"button"}
      headerBtnText={"Add Package"}
      headerBtnLink={"/package/create"}
    >
      <PackagesTable />
    </CardBasic>
  );
}
