import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import filterFactory from "react-bootstrap-table2-filter";

import { sortCaret } from "../../../_metronic/_helpers";

import {
  StatusColumnFormatter,
  ActionColumnSimple,
} from "../../../_metronic/_helpers/tableColumnFormatter";

import paginationFactory from "react-bootstrap-table2-paginator";
import { loadRaList } from "../../../store/branch/ra/raSlice";
import { loadBranchStaffList } from "../../../store/branch/branchStaffSlice";
import DeleteRaAndPoDialog from "../../../components/dialogs/DeleteRaAndPoDialog";

export const CustomerStatusTitles = ["Suspended", "Active", "Pending", ""];

export const CategoryTable = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.category.ctegory);

  const raList = useSelector((state) => state.ra.ra);
  console.log(raList);
  const { role, user } = useSelector((state) => state.authPersist);
  const [dataLoading, setDataLoading] = useState(true);

  const LinkFormatter = (cell, row) => {
    console.log(row);
    return <Link to={`/expense/ra/${row.uuid}`}>{cell}</Link>;
  };
  const branchFormatter = (cell, row) => {
    return user.name;
  };
  const ActionDisplay = (cell, row) => {
    console.log(row);
    return <DeleteRaAndPoDialog content={<i className="flaticon-delete" style={{cursor: "pointer"}} />} role="ra" uuid={row.uuid}  text='Are you sure you want to delete this RA ?' />
 };

  const DateFormatter = (cell, row) => {
    return dayjs(cell).format("DD-MM-YYYY");
  };

  // const ActionDisplay = (cell, row) => {
  //   if (role == "branch") {
  //     return BookingActionColumn(cell, row);
  //   }
  //   return null;
  // };

  useEffect(() => {
    dispatch(loadRaList()).then(() => {
      setDataLoading(false);
    });
  }, []);

  const columns = [
    {
      dataField: "",
      text: "#",
      formatter: (row, cell, index) => {
        return index + 1;
      },
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
      //formatter: LinkFormatter,
      sortCaret: sortCaret,
    },
    {
      dataField: "createdAt",
      text: "Date",
      formatter: DateFormatter,
    },
    {
      dataField: "modeOfPayment",
      text: "Mode Of Payment",
      //formatter: DateFormatter,
    },
    {
      dataField: "remarks",
      text: "Remarks",
      sort: true,
      //formatter: LinkFormatter,
      sortCaret: sortCaret,
      // filter: textFilter(),
    },
    {
      dataField: "",
      text: "delete",
      formatter: ActionDisplay,
      //sortCaret: sortCaret,
    },
  ];

  const { SearchBar, ClearSearchButton } = Search;

  return (
    <ToolkitProvider
      search
      keyField="name"
      data={raList}
      columns={columns}
    >
      {(props) => (
        <>
          <div className="container">
            <div class="row">
              <div className="col-sm-4">
                {/* <h4 className="mt-4" >Search</h4> */}
              </div>
              <div className="col-sm-8">
                <SearchBar {...props.searchProps} />
                <ClearSearchButton
                  {...props.searchProps}
                  className="ml-4 btn btn-secondary-light"
                />
              </div>
            </div>
          </div>

          <BootstrapTable
            {...props.baseProps}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            wrapperClasses="table-responsive"
            bordered={false}
            bootstrap4
            pagination={paginationFactory()}
            filter={filterFactory()}
            noDataIndication={
              <div className={"d-flex justify-content-center py-10"}>
                <h3>{dataLoading ? "Loading Data..." : "No records found"}</h3>
              </div>
            }
          />
        </>
      )}
    </ToolkitProvider>
  );
};
