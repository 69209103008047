import { createSlice } from "@reduxjs/toolkit";

import * as requestFromServer from "../holiday/holidayAPi";

const initialState = {
  leaves: [],
};

export const holidaySlice = createSlice({
  name: 'holiday',
  initialState,
  reducers: {
    updateList(state, action) {
      state.leaves = action.payload;
    },
    created(state, action) {
      state.leaves.push(action.payload);
    },
    updateStatus(state, action) {
      const holidayIndex = state.leaves.findIndex(
        (holiday) => holiday.uuid === action.payload
      );
      console.log(holidayIndex);
      if (holidayIndex > -1) {
        state.leaves.splice(holidayIndex, 1);
      }
    },
  }
});

export const loadHolidayList = () => {
  return async (dispatch) => {
    return requestFromServer
      .getHolidayList()
      .then((response) => {
        console.log(response.data);
        dispatch(holidaySlice.actions.updateList(response.data.data));
      })
      .catch((err) => {
        alert(err.message);
      });
  }
}

export const createHoliday = (data) => {
  return async (dispatch) => {
    return requestFromServer
      .createHoliday(data)
      .then((response) => {
        dispatch(holidaySlice.actions.created(response.data.data));
      })
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };
};

export const updateStuffHolidayList = (data) => {
  console.log(data);
  return async (dispatch) => {
        dispatch(holidaySlice.actions.updateStatus(data));
  };
};
