import axios from "axios";

const URL = process.env.REACT_APP_API_URL + "/business";
const headers = {
  "Content-Type": "multipart/form-data",
  Accept: "application/json",
};

export const getStaffList = () => {
  return axios.get(`${URL}/staff/list`);
};
export const getStaff = async (req) => {
  return await axios.post(`${URL}/staff/view`, req);
};

export const createStaff = (data) => {
  const req = new FormData();
  for (var key in data) {
    req.append(key, data[key]);
  }

  console.log([...req]);

  return axios.post(`${URL}/staff/create`, req, {
    headers,
  });
};
export const updateStaff = (data, uuid) => {
  const req = new FormData();

  for (var key in data) {
    req.append(key, data[key]);
  }
  req.append("uuid", uuid);

  return axios.patch(`${URL}/staff/update`, req, {
    headers,
  });
};
