import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import {
	PaymentElement,
	useStripe,
	useElements
} from "@stripe/react-stripe-js";
//import { showMessage } from 'app/store/fuse/messageSlice';

const CheckoutForm = (props) => {
	const stripe = useStripe();
	const elements = useElements();
	const dispatch = useDispatch();
	const [message, setMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (!stripe) {
			return;
		}

		if (!elements) {
			return;
		}
		stripe.retrievePaymentIntent(elements._commonOptions.clientSecret.clientSecret).then(({ paymentIntent }) => {
			// if (paymentIntent.status === "succeeded") {
			// 	dispatch(
			// 		showMessage({
			// 			message: "Payment succeeded!",
			// 			variant: 'success',
			// 			autoHideDuration: 3000,
			// 		})
			// 	);
			// } else if (paymentIntent.status === 'processing') {
			// 	dispatch(
			// 		showMessage({
			// 			message: "Your payment is processing.",
			// 			variant: 'info',
			// 			autoHideDuration: 3000,
			// 		})
			// 	);
			// } else if (paymentIntent.status === 'error') {
			// 	dispatch(
			// 		showMessage({
			// 			message: "Your payment was not successful, please try again.",
			// 			variant: 'error',
			// 			autoHideDuration: 3000,
			// 		})
			// 	);
			// }
		});
	}, [stripe]);

	//console.log(elements);
	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		setIsLoading(true);

		const { error } = await stripe.confirmPayment({
			elements,
			confirmParams: {
				// Make sure to change this to your payment completion page
				return_url: `${process.env.REACT_APP_STRIPE_REDIRECT_URL}/profile`,
			},
		});

		// This point will only be reached if there is an immediate error when
		// confirming the payment. Otherwise, your customer will be redirected to
		// your `return_url`. For some payment methods like iDEAL, your customer will
		// be redirected to an intermediate site first to authorize the payment, then
		// redirected to the `return_url`.
		if (error.type === "card_error" || error.type === "validation_error") {
			//setMessage(error.message);
			dispatch(
				// showMessage({
				// 	message: `${error.message}`,
				// 	variant: 'error',
				// 	autoHideDuration: 3000,
				// })
			);
		} else {
			//setMessage("An unexpected error occurred.");
			dispatch(
				// showMessage({
				// 	message: `An unexpected error occurred`,
				// 	variant: 'error',
				// 	autoHideDuration: 3000,
				// })
			);
		}

		setIsLoading(false);
	};

	return (
		<form id="payment-form" style={{ width: '50%', backgroundColor: '#FFF', padding: '50px', margin: '0 auto', borderRadius: '25px' }} onSubmit={handleSubmit}>
			<PaymentElement id="payment-element" />
			<button disabled={isLoading || !stripe || !elements} id="submit" className="strip-button" type="submit">
				<span id="button-text">
					{isLoading ? <div className="spinner" id="spinner" /> : "Pay now"}
				</span>
			</button>
			{/* Show any error or success messages */}
			{/* {message && <div id="payment-message">{message}</div>} */}
		</form>
	);
};

export default CheckoutForm;