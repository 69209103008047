import React from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Link, Redirect, useHistory, useParams } from "react-router-dom";
function HeaderLink() {
  return (
    <Link to="/auth/login" className="float-right p-lg-15 p-md-10 p-sm-0">
      <button className="header-btn font-italic py-5 px-20">
        Log in
      </button>
    </Link>
  );
}

export default HeaderLink;
