import axios from "axios";
const URL = process.env.REACT_APP_API_URL + "/admin";
const token = process.env.REACT_APP_ADMIN_TOKEN;
const Authorization = "Bearer " + token;
const headers = {
  Authorization,
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const getCustomerList = async () => {
  return await axios.get(`${URL}/user/list`);
};
