import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, Select } from "../../../_metronic/_partials/controls";

import { CardBasic } from "../../../components/CardBasic";
import { loadBranchList } from "../../../store/business/branches/branchSlice";
import { createStaff } from "../../../store/business/staff/staffSlice";
import { loadShiftList } from "../../../store/business/shifts/shitftsSlice";
import dayjs from "dayjs";
import { getRa, updateRa } from "../../../store/branch/ra/raAPi";
import { sendFailure, sendSuccess } from "../../../store/toast/toastSlice";

export const ExpenseRaView = () => {
  const [staffData, setStaffData] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();

  const { raUuid } = useParams();
  const req = {
    voucherUuid: raUuid,
  };
  // console.log(req);

  useEffect(() => {
    getRa(req)
      .then((response) => {
        // console.log(response);
        const data = response.data.data;
        // console.log(data);
        setStaffData(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  // Validation schema
  const ValidationSchema = Yup.object().shape({
    remarks: Yup.string().required("This field is required"),
    amount: Yup.number().required("This field is required"),
  });

  return (
    <CardBasic
      title={"Edit RA"}
      btnType={"submit"}
      formId={"updateForm"}
      headerBtnText={"Update RA"}
    >
      <Formik
        initialValues={{
          remarks: staffData.remarks ?? "",
          amount: staffData.amount ?? "",
          modeOfPayment: staffData.modeOfPayment ?? "",
        }}
        enableReinitialize
        validationSchema={ValidationSchema}
        onSubmit={(values, actions) => {
          console.log(values);
          const newValue = { ...values, ...req }
          console.log(newValue);
          updateRa(newValue)
            .then(() => {
              dispatch(
                sendSuccess(
                  "The Ra information has been updated successfully"
                )
              );
              history.replace("/expense/ra");
            })
            .catch((err) => {
              dispatch(sendFailure(err.message));
              actions.setStatus(err.message);
              console.log(err.message);
            });
        }}
      >
        {({ values, errors, setFieldValue }) => (
          <Form className="form form-label-right" id="updateForm">
            <div className="form-group row">
              <div className="col-lg-6">
                <Field
                  name="remarks"
                  component={Input}
                  placeholder="Remarks"
                  label="Remarks *"
                />
              </div>
              <div className="col-lg-6">
                <Select name="modeOfPayment" label="Mode Of Payment">
                  <option value="cash">Cash</option>
                  <option value="online">Online</option>
                </Select>
              </div>
              <div className="col-lg-12">
                <Field
                  name="amount"
                  component={Input}
                  placeholder="Amount"
                  label="Amount *"
                />
              </div>
            </div>
            {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
          </Form>
        )}
      </Formik>
    </CardBasic>
  );
};
