import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import SVG from "react-inlinesvg";
import { useDispatch } from "react-redux";
import { sendFailure, sendSuccess } from "../../store/toast/toastSlice";
import { deleteStuffHoliday } from "../../store/business/staff/holiday/holidayAPi";
import { updateStuffHolidayList } from '../../store/business/staff/holiday/holidaySlice';
import { updateBookingStatus } from "../../store/business/bookings/bookingSlice";

export default function StuffHolidayDialog({
  content,
  uuid,
  svg
}) {

  // console.log(content);
  // console.log(uuid);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const Confirm = () => {
    deleteStuffHoliday(uuid)
      .then((response) => {
        //console.log(response);
        dispatch(updateStuffHolidayList(uuid)).then(() => {
          dispatch(sendSuccess(
            "The stuff holiday has been deleted successfully"
          ));
        });
        setOpen(false);
      })
      .catch((err) => {
        dispatch(sendFailure(content.errorMessage));
        //console.log(err.message);
      });
  };

  return (
    <div>
      <span className="navi-text" onClick={handleClickOpen}>
        {content}
      </span>

      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{content.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content.text ?? "Are you sure you want to delete this holiday?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button onClick={Confirm} className="btn btn-light-success">
            Yes, Continue.
          </button>
          <button onClick={handleClose} className="btn btn-danger">
            No, Go back.
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
