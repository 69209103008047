import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import { sendOtpAction, resendOtpAction } from "../../../../store/otp/otpSlice";

import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { branchLoginAction } from "../../../../store/business/authSlice";
import { sendFailure, sendSuccess } from "../../../../store/toast/toastSlice";
import { secondsInHour } from "date-fns";

const initialValues = {
  // phone: "",
  // otp: "",
  email: "",
  password: "",
};

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export function BranchLogin(props) {
  const [sendOtpLoader, setSendOtpLoading] = useState(false);
  const [otpState, setOtpState] = useState(false);
  const [otpResendState, setResendOtpState] = useState(false);

  const history = useHistory();
  const user = useSelector((state) => state.authPersist);
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [resendOtpLoader, setResendOtpLoading] = useState(false);
  const dispatch = useDispatch();

  const LoginSchema = Yup.object().shape({
    // phone: Yup.string()
    //   .matches(phoneRegExp, "Phone number is not valid")
    //   .min(10, "Enter 10 digit phone number")
    //   .max(10, "Enter 10 digit phone number")
    //   .required("This field is required"),
    // otp: Yup.string()
    //   .matches(phoneRegExp, "OTP is not valid")
    //   .min(6, "OTP must be of 6 digits")
    //   .max(6, "OTP must be of 6 digits")
    //   .required("Kindly enter VERIFICATION CODE sent to your phone"),
    email: Yup.string()
      .email("You must enter a valid email")
      .required("You must enter an email"),
    password: Yup.string()
      .required("Please Enter your password")
      .min(8, "Password is too short - should be 8 chars minimum."),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const otpStateHandler = () => {
    setResendOtpState(true);
    setSendOtpLoading(false);
    setTimeout(() => {
      setResendOtpState(false);
    }, 30000);
  };

  const sendOtpHandler = () => {
    setSendOtpLoading(true);
    dispatch(sendOtpAction(91, formik.values.phone))
      .then(() => {
        setOtpState(true);
        otpStateHandler();
        dispatch(sendSuccess("OTP sent successfully"));
        //console.log("otp send success");
      })
      .catch((error) => {
        dispatch(sendFailure());
        //console.log(error.message);
        setSendOtpLoading(false);
      });
  };

  const loginHandler = () => {
    //console.log(formik.values);
    //dispatch(branchLoginAction(91, formik.values.phone, formik.values.otp))
    dispatch(branchLoginAction(formik.values.email, formik.values.password))
      .then((response) => {
        //console.log(response);
        if (user != null) {
          dispatch(sendSuccess("Logged in successfully"));
          history.replace("/dashboard");
          //console.log("Successfully Login");
        }

        disableLoading();
      })
      .catch((err) => {
        console.log(err);
        dispatch(sendFailure());
        formik.setSubmitting(false);
        disableLoading();
        formik.setStatus(err.message);
        if (navigator.onLine) {
          if (err.message == "Error! Wrong OTP.") {
            formik.setStatus("The OTP you have entered is incorrect");
          } else {
            formik.setStatus("Incorrect Email-Id or Password");
          }
        } else {
          formik.setStatus("Please check your internet connection.");
        }
      });
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      //console.log(values);
      setSubmitting(true);
      enableLoading();
      loginHandler();
      setSubmitting(false);
      //console.log(user);
    },
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-10">
        <a href="#" className="mb-10 text-center">
          <img
            src={`${toAbsoluteUrl("/media/brand/logo2.jpg")}`}
            className="max-h-150px"
            alt=""
          />
        </a>
        <h3 className="font-size-h1 mt-10">Branch Login</h3>
        <p className="text-muted font-weight-bold">
          Enter your phone and verification code
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}

        {/* <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Enter your Phone Number to get the Verification Code"
            type="phone"
            maxLength="10"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "phone"
            )}`}
            name="phone"
            maxLength="10"
            disabled={otpState}
            {...formik.getFieldProps("phone")}
          />
          {formik.touched.phone && formik.errors.phone ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.phone}</div>
            </div>
          ) : null}
        </div> */}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "Password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>

        {/* {otpState && (
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="6 digit verification code"
              type="text"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "otp"
              )}`}
              name="otp"
              maxLength="6"
              {...formik.getFieldProps("otp")}
            />
            {formik.touched.otp && formik.errors.otp ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.otp}</div>
              </div>
            ) : null}
          </div>
        )} */}
        {/* <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <button
            id="kt_login_signin_submit"
            type="button"
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
            disabled={
              !Boolean(formik.touched.phone)
                ? true
                : Boolean(formik.errors.phone)
            }
            onClick={sendOtpHandler}
          >
            <span>
              {otpState ? "Resend Verification Code" : "Send Verification Code"}
            </span>
            {sendOtpLoader && (
              <span className="ml-3 spinner spinner-white"></span>
            )}
          </button>
          {otpState && (
            <div>
              <button
                id="kt_login_signin_submit"
                type="submit"
                disabled={formik.isSubmitting}
                className={`btn btn-primary font-weight-bold px-9 py-4 my-3 ml-3`}
              >
                <span>Sign In</span>
                {loading && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button>
            </div>
          )}
        </div> */}

        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          {/* <button
            id="kt_login_signin_submit"
            type="button"
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
            disabled={
              !Boolean(formik.touched.phone)
                ? true
                : Boolean(formik.errors.phone)
            }
            onClick={sendOtpHandler}
          >
            <span>
              {otpState ? "Resend Verification Code" : "Send Verification Code"}
            </span>
            {sendOtpLoader && (
              <span className="ml-3 spinner spinner-white"></span>
            )}
          </button> */}
            <div>
              <button
                id="kt_login_signin_submit"
                type="submit"
                //disabled={formik.isSubmitting}
                disabled={formik.dirty === false || !formik.isValid}
                className={`btn btn-primary font-weight-bold px-9 py-4 my-3 ml-3`}
              >
                <span>Sign In</span>
                {loading && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button>
            </div>
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center mb-2">
          <div className="font-weight-bold text-dark-50">
            <span>Forgot password? </span>

            <Link
              to={`/auth/:branch/forgot-password`}
              id="kt_login_signup"
              onClick={() => {
                console.log("hello");
              }}
            >
              Reset here.
            </Link>
          </div>
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <div className="font-weight-bold text-dark-50">
            Go to
            <Link
              to="/auth/login"
              className="font-weight-bold ml-2 "
              id="kt_branch_login"
              image="saiyam"
              onClick={() => {
                //console.log("hello");
              }}
            >
              Business Login
            </Link>
          </div>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}
