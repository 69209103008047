/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";

export function BaseTablesWidget11({ className, chartColor = "danger", data, title }) {
  const tabs = {
    tab1: "kt_tab_pane_3_1",
    tab2: "kt_tab_pane_3_2",
    tab3: "kt_tab_pane_3_3",
  };
  console.log(data);
  const [activeTab, setActiveTab] = useState(tabs.tab1);
  const data1 = 'Pservice';

  if (data.length === 0) {
    return (
      <>
        {/* begin::Base Table Widget 6 */}
        <div className={`card card-custom ${className}`}>
          {/* begin::Header */}
          <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label font-weight-bolder text-dark">
                {title === 'branch' ? "Top Branches" : title === 'service' ? "Top 5 Services" : title === "customet" ? "Top 5 Customers" : title === "staff" ?  "Staff Report List"  : "Top 5 Packages"}
              </span>
              {/* <span className="text-muted mt-3 font-weight-bold font-size-sm">
              More than 400+ new members
            </span> */}
            </h3>
          </div>
          {/* end::Header */}

          {/* begin::Body */}
          <div class="d-flex justify-content-center" style={{height: "500px"}}><h4>No Data</h4></div>

        </div>
      </>
    )
  };

  return (
    <>
      {/* begin::Base Table Widget 6 */}
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              {title === 'branch' ? "Top Branches" : title === 'service' ? "Top 5 Services" : title === "customet" ?  "Top 5 Customers" : title === "staff" ?  "Staff Report List" : "Top 5 Packages"}
            </span>
            {/* <span className="text-muted mt-3 font-weight-bold font-size-sm">
              More than 400+ new members
            </span> */}
          </h3>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body pt-2 pb-0">
          {/* begin::Table */}
          <div className="table-responsive">
            <table className="table table-borderless table-vertical-center">
              <thead>
                <tr>
                  <th className="p-0" style={{ width: "50px" }} />
                  <th className="p-0" style={{ minWidth: "150px" }} />
                  <th className="p-0" style={{ minWidth: "120px" }} />
                  {/* <th className="p-0" style={{ minWidth: "70px" }} />
                  <th className="p-0" style={{ minWidth: "70px" }} />
                  <th className="p-0" style={{ minWidth: "50px" }} /> */}
                </tr>
              </thead>
              <tbody>
                {title === "service" ? data.slice(0, 5).map((el) => {
                  //console.log(el);
                  return (<tr>
                    <td className="pl-0">
                      <div className="symbol symbol-50 symbol-light mr-2 mt-2">
                        <div
                          className="symbol-label"
                          style={{
                            backgroundColor: '#007669',
                            color: "#ffffff",
                            fontSize: '30px',
                            fontWeight: 'bold',
                            width: '90px',
                            height: '55px'
                          }}
                        >{el.name.charAt(0).toUpperCase()}</div>
                      </div>
                    </td>
                    <td className="pl-0">
                      <a
                        href="#"
                        className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                      >
                        {el.name}
                      </a>
                      <span className="text-muted font-weight-bold d-block">
                        {`category : ${el.category}`}
                      </span>
                    </td>
                    <td className="text-right">
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {el.count}
                      </span>
                      <span className="text-muted font-weight-bold d-block font-size-sm">
                        Times
                      </span>
                    </td>
                  </tr>)
                }) : title === "customet" ? data.map((el) => {
                  console.log(el);
                  return (<tr style={{ height: '100px' }}>
                    <td className="pl-0">
                      <div className="symbol symbol-50 symbol-light mr-2 mt-2">
                        <div
                          className="symbol-label"
                          style={{
                            backgroundColor: '#007669',
                            color: "#ffffff",
                            fontSize: '30px',
                            fontWeight: 'bold',
                            width: '90px',
                            height: '55px'
                          }}
                        >{el.customerName.charAt(0).toUpperCase()}</div>
                      </div>
                    </td>
                    <td className="pl-0">
                      <a
                        href="#"
                        className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                      >
                        {el.customerName}
                      </a>
                      <span className="text-muted font-weight-bold d-block">
                        {`Count : ${el.count}`}
                      </span>
                    </td>
                    <td className="text-right">
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {el.totalReveneue}
                      </span>
                      <span className="text-muted font-weight-bold d-block font-size-sm">
                        Total Reveneue
                      </span>
                    </td>
                  </tr>)
                }) : title === "staff" ? data.map((el) => {
                  console.log(el);
                  return (<tr style={{ height: '100px' }}>
                    <td className="pl-0">
                      <div className="symbol symbol-50 symbol-light mr-2 mt-2">
                        <div
                          className="symbol-label"
                          style={{
                            backgroundColor: '#007669',
                            color: "#ffffff",
                            fontSize: '30px',
                            fontWeight: 'bold',
                            width: '90px',
                            height: '55px'
                          }}
                        >{el.staffName ? el.staffName.charAt(0).toUpperCase() : ""}</div>
                      </div>
                    </td>
                    <td className="pl-0">
                      <a
                        href="#"
                        className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                      >
                        {el.staffName}
                      </a>
                      <span className="text-muted font-weight-bold d-block">
                        {`Count : ${el.count}`}
                      </span>
                    </td>
                    <td className="text-right">
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {el.totalReveneue}
                      </span>
                      <span className="text-muted font-weight-bold d-block font-size-sm">
                        Total Reveneue
                      </span>
                    </td>
                  </tr>)
                }) : data.length>0 && console.log(data) && data?.slice(0, 5)?.map((el) => {
                  //console.log(el);
                  return (<tr>
                    <td className="pl-0">
                      <div className="symbol symbol-50 symbol-light mr-2 mt-2">
                        <div
                          className="symbol-label"
                          style={{
                            backgroundColor: '#007669',
                            color: "#ffffff",
                            fontSize: '30px',
                            fontWeight: 'bold',
                            width: '90px',
                            height: '55px'
                          }}
                        >{el.name.charAt(0).toUpperCase()}</div>
                      </div>
                    </td>
                    <td className="pl-0">
                      <a
                        href="#"
                        className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                      >
                        {el.name.match(/(\w+)/g).length > 1 ? el.name.charAt(0).toUpperCase() + el.name.slice(1) : el.name.charAt(0).toUpperCase() + el.name.slice(1)}
                      </a>
                      {/* <span className="text-muted font-weight-bold d-block">
                        {`category : ${el.category}`}
                      </span> */}
                    </td>
                    <td className="text-right">
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {el.count}
                      </span>
                      <span className="text-muted font-weight-bold d-block font-size-sm">
                        Times
                      </span>
                    </td>
                  </tr>)
                })}
              </tbody>
            </table>
          </div>
          {/* end::Table */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Base Table Widget 6 */}
    </>
  );
}
