import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, Select } from "../../_metronic/_partials/controls";

import { CardBasic } from "../../components/CardBasic";
import { Toast } from "../../components/Toast";
import { loadBranchList } from "../../store/business/branches/branchSlice";
import { createChair } from "../../store/business/chairs/chairSlice";
import { sendFailure, sendSuccess } from "../../store/toast/toastSlice";

export const ChairCreate = () => {
  const [formStatus, setFormStatus] = useState("");
  const [dataLoading, setDataLoading] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();

  const ChairCreateSchema = Yup.object().shape({
    chairName: Yup.string().required("This field is required"),
    branchUuid: Yup.string().required("This field is required"),
  });

  const { branches } = useSelector((state) => state.branch);

  useEffect(() => {
    if (branches.length === 0) {
      dispatch(loadBranchList()).then(() => {
        setDataLoading(false);
      });
    }
  }, [branches]);

  //console.log(">>>>" + JSON.stringify(branches));

  return (
    <CardBasic
      title={"Add Chair"}
      btnType={"submit"}
      formId={"createChairForm"}
      headerBtnText={"Save Chair"}
    >
      {/* <Toast
        formStatus={formStatus}
        type={"success"}
        message={"Form submitted successfully."}
      /> */}
      <Formik
        initialValues={{
          chairName: "",
          branchUuid: branches.length > 0 ? branches[0].uuid : "",
        }}
        enableReinitialize
        validationSchema={ChairCreateSchema}
        onSubmit={(values, actions) => {
          console.log(values);
          dispatch(createChair(values))
            .then(() => {
              dispatch(sendSuccess("The chair has been created successfully"));
              history.replace("/chairs");
            })
            .catch((err) => {
              dispatch(sendFailure(err.message));
              actions.setStatus("Error! Duplicate Chair Name.");
              console.log(err.message);
            });
          setFormStatus("submitted");
        }}
      >
        {({ values, errors, setFieldValue }) => (
          <Form className="form form-label-right" id="createChairForm">
            <div className="form-group row">
              <div className="col-12">
                <Field
                  name="chairName"
                  component={Input}
                  placeholder="Chair Name"
                  label="Chair Name *"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-12">
                <Select name="branchUuid" label="Branch *">
                  {branches.map((branch, index) => (
                    <option value={branch.uuid} key={index}>
                      {`${branch.name} - ${branch.city}`}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </CardBasic>
  );
};
