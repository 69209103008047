import { createSlice } from "@reduxjs/toolkit";

import * as requestFromServer from "./categoryAPi";

const initialState = {
  category: [],
};

export const expenseCategorySlice = createSlice({
  name: 'expenseCategory',
  initialState,
  reducers: {
    updateList(state, action) {
      state.category = action.payload;
    },
    created(state, action) {
      state.category.push(action.payload);
    }
  }
});

export const loadCategoryList = () => {
  return async (dispatch) => {
    return requestFromServer
      .getCategoryList()
      .then((response) => {
        console.log(response.data);
        dispatch(expenseCategorySlice.actions.updateList(response.data.data));
      })
      .catch((err) => {
        alert(err.message);
      });
  }
}

export const createCategory = (data) => {
  //console.log(data);
  return async (dispatch) => {
    return requestFromServer
      .createCategory(data)
      .then((response) => {
       // console.log(response);
        dispatch(expenseCategorySlice.actions.created(response.data.data));
      })
      .catch((error) => {
        //console.log(error.response);
        throw new Error(error.response.data.message);
      });
  };
};