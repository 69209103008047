import axios from "axios";

const URL = process.env.REACT_APP_API_URL + "/data";

export const getSegments = () => {
  return axios.get(`${URL}/segment/list`);
};

export const getServiceCategories = () => {
  return axios.get(`${URL}/service/categories`);
};