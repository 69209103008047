import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import SVG from "react-inlinesvg";
import { useDispatch } from "react-redux";
import { sendFailure, sendSuccess } from "../../store/toast/toastSlice";
import { deleteBranchPicture } from "../../store/business/branches/branchApi";
import { updateBranchImageDeleteData } from '../../store/business/branches/branchSlice';
import { updateBookingStatus } from "../../store/business/bookings/bookingSlice";

export default function BranchImageDeleteDialog({
  content,
  name,
  uuid
}) {

  console.log(content);
  console.log(uuid);
  console.log(name);

  const nameSplit = name.split("/");
  console.log(nameSplit);
  const lastElement = nameSplit[nameSplit.length - 1];
  console.log(lastElement);





  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const Confirm = () => {
    deleteBranchPicture(uuid, lastElement)
      .then((response) => {
        console.log(response);
        dispatch(updateBranchImageDeleteData(lastElement)).then(() => {
          dispatch(sendSuccess(
            "The image has been deleted successfully"
          ));
        });
        setOpen(false);
      })
      .catch((err) => {
        dispatch(sendFailure(content.errorMessage));
        console.log(err.message);
      });
  };

  return (
    <div>
      <span className="navi-text" onClick={handleClickOpen}>
        {content}
      </span>

      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{content.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content.text ?? "Are you sure you want to delete this image?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button onClick={Confirm} className="btn btn-light-success">
            Yes, Continue.
          </button>
          <button onClick={handleClose} className="btn btn-danger">
            No, Go back.
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
