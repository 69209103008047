import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import ReactSelect from "react-select";

import { Input, Select } from "../../_metronic/_partials/controls";

import { CardBasic } from "../../components/CardBasic";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { MultiSelect } from "../../_metronic/_partials/controls/forms/MultiSelect";
import {
  getBranch,
  updateBranch,
} from "../../store/business/branches/branchApi";
import { TimePickerField } from "../../components/pickers/TimePickerField";
import dayjs from "dayjs";
import { sendFailure, sendSuccess } from "../../store/toast/toastSlice";
import PlacesAutocomplete from "../../components/googleMaps/PlacesAutocomplete";

export const BranchView = () => {
  const [branchData, setBranchData] = useState({});

  // Validation schema
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const history = useHistory();
  const dispatch = useDispatch();

  const { branchUuid } = useParams();

  const req = {
    uuid: branchUuid,
  };

  const { segments } = useSelector((state) => state.data);

  const ValidationSchema = Yup.object().shape({
    name: Yup.string()
      .min(5, "Minimum 5 characters")
      .required("This field is required"),
    business_segment: Yup.array().required("This field is required"),
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(4, "Enter 4 digit phone number")
      .max(20, "Enter 20 digit phone number")
      .required("This field is required"),
    email: Yup.string()
      .email("Enter valid email address")
      .required("This field is required"),
    gender: Yup.string().required("Type is required"),
    otherContact: Yup.string(),
    address_line1: Yup.string().required("This field is required"),
    address_line2: Yup.string(),
    city: Yup.string().required("This field is required"),
    zipcode: Yup.string().required("This field is required"),
    website: Yup.string().url(),
    gmap: Yup.string(),
    latitude: Yup.number(),
    longitude: Yup.number(),
    isActive: Yup.string().required("This field is required"),
    startTime: Yup.date().required("This field is required"),
    endTime: Yup.date()
      .min(Yup.ref("startTime"), "End Time must be greater than Start Time")
      .required("This field is required"),
    description: Yup.string().required("This field is required"),
    taxSlab: Yup.number().test(
      'Is positive?', 
      'Tax Slab must be greater than 0', 
      (value) => value >= 0
    ).integer().max(100, "max Tax Slab is 100").required("This field is required"),
    taxNo: Yup.string(),
    holiday: Yup.string(),
  });

  useEffect(() => {
    getBranch(req)
      .then((response) => {
        const data = response.data.data;
        setBranchData(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);
  //console.log(branchData);
  return (
    <CardBasic
      title={"Edit Branch"}
      btnType={"submit"}
      formId={"updateForm"}
      headerBtnText={"Update Branch"}
    >
      <Formik
        initialValues={{
          name: branchData.name ?? "",
          business_segment: branchData.business_segment
            ? branchData.business_segment.map((segment, index) => segment)
            : "",
          phone: branchData.phone ?? "",
          email: branchData.email ?? "",
          gender: branchData.gender ?? "unisex",
          otherContact: branchData.otherContact ?? "",
          address_line1: branchData.address_line1 ?? "",
          address_line2: branchData.address_line2 ?? "",
          city: branchData.city ?? "",
          zipcode: branchData.zipcode ?? "",
          website: branchData.website ?? "",
          gmap: branchData.gmap ?? "",
          isActive: branchData.isActive ?? false,
          image: "",
          logo: "",
          description: branchData.description ?? "",
          startTime: branchData.startTime
            ? dayjs(branchData.startTime, "hh:mm A")
            : "",
          endTime: branchData.endTime
            ? dayjs(branchData.endTime, "hh:mm A")
            : "",
          taxSlab: branchData.taxSlab ?? 0,
          taxNo: branchData.taxNo ?? "",
          holiday: branchData.holiday ?? "",
          latitude: branchData.latitude ?? "",
          longitude: branchData.longitude ?? "",
        }}
        enableReinitialize
        validationSchema={ValidationSchema}
        onSubmit={(values, actions) => {
          //console.log(values);
          updateBranch(values, branchUuid)
            .then(() => {
              dispatch(
                sendSuccess(
                  "The branch information has been updated successfully"
                )
              );
              history.replace("/branches");
            })
            .catch((err) => {
              dispatch(sendFailure(err.message));
              actions.setStatus(err.message);
              console.log(err.message);
            });
          actions.setFormStatus("submitted");
        }}
      >
        {(formProps) => (
          <Form className="form form-label-right" id="updateForm">
            {/* {branchData.image && (
              <div className="form-group row col">
                <img
                  src={`${process.env.REACT_APP_MEDIA_URL}/${branchData.image}`}
                  width="300px"
                ></img>
              </div>
            )} */}
            <div className="form-group row">
              {/* Branch Name */}
              <div className="col-lg-8">
                <Field
                  name="name"
                  component={Input}
                  placeholder="Branch Name"
                  label="Branch Name *"
                />
              </div>
              <div className="col-lg-4">
                <MultiSelect
                  label="Segment *"
                  name="business_segment"
                  options={segments.map((segment, index) => ({
                    value: segment.uuid,
                    label: segment.name,
                  }))}
                />
              </div>
            </div>
            {/* Contact Number */}
            <div className="form-group row">
              <div className="col-lg-4">
                <Field
                  name="phone"
                  component={Input}
                  placeholder="9899898893"
                  label="Contact Number *"
                  //maxlength="10"
                />
              </div>
              <div className="col-lg-4">
                <Field
                  name="email"
                  type="email"
                  component={Input}
                  placeholder="example@domain.com"
                  label="Email Address *"
                />
              </div>
              <div className="col-lg-4">
                <Select name="gender" label="Type *">
                  <option value="unisex">Unisex</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </Select>
              </div>
            </div>
            <div className="form-group row">
              <div className="col">
                <Field
                  name="otherContact"
                  component={Input}
                  label="Additional Contact Numbers"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-4">
                <Field
                  name="address_line1"
                  component={Input}
                  label="Address Line 1 *"
                />
              </div>
              <div className="col-lg-4">
                <Field
                  name="address_line2"
                  component={Input}
                  label="Address Line 2"
                />
              </div>
              <div className="col-lg-4">
                <Field name="city" component={Input} label="City *" />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-4">
                <Field name="zipcode" component={Input} label="Zipcode *" />
              </div>
              <div className="col-lg-4">
                <Select name="isActive" label="Active *">
                  <option value={true}>Active</option>
                  <option value={false}>Inactive</option>
                </Select>
              </div>
              <div className="col-lg-4">
                <label>Branch Image (*jpg,*jpeg,*png)</label>

                <input
                  type="file"
                  name="image"
                  className="form-control"
                  onChange={(e) => {
                    let file = e.target.files[0];
                    console.log(file.type);
                    if (
                      file.type == "image/jpeg" ||
                      file.type == "image/jpg" ||
                      file.type == "image/png"
                    ) {
                      if (file.size <= 1000000) {
                        formProps.setFieldValue("image", e.target.files[0]);
                      } else {
                        formProps.setFieldError(
                          "image",
                          "Image size should be less than 1 MB"
                        );
                      }
                    } else {
                      formProps.setFieldError(
                        "image",
                        "Please enter a valid file format"
                      );
                    }
                  }}
                />
                {formProps.errors.image && (
                  <div className="invalid-feedback d-flex">
                    {formProps.errors.image}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group row">
              {/* <div className="col-lg-8">
                <Field name="gmap" component={Input} label="Google Maps Link" />
              </div> */}
              <div className="col-lg-8">
                <Field
                  name="website"
                  component={Input}
                  label="Website"
                  placeholder="https://example.com"
                />
              </div>
              <div className="col-lg-4">
                <label>Branch Logo</label>
                <input
                  type="file"
                  name="logo"
                  className="form-control"
                  onChange={(e) => {
                    let file = e.target.files[0];
                    console.log(file.type);
                    if (
                      file.type == "image/jpeg" ||
                      file.type == "image/jpg" ||
                      file.type == "image/png"
                    ) {
                      if (file.size <= 1000000) {
                        formProps.setFieldValue("logo", e.target.files[0]);
                      } else {
                        formProps.setFieldError(
                          "logo",
                          "logo size should be less than 1 MB"
                        );
                      }
                    } else {
                      formProps.setFieldError(
                        "logo",
                        "Please enter a valid file format"
                      );
                    }
                  }}
                />
                {formProps.errors.logo && (
                  <div className="invalid-feedback d-flex">
                    {formProps.errors.logo}
                  </div>
                )}
              </div>
            </div>
            {/* <div className="form-group row">
              <div className="col-lg-12">
                <Field
                  name="website"
                  component={Input}
                  label="Website"
                  placeholder="https://example.com"
                />
              </div>
            </div> */}
            <div className="form-group row">
              {/* Branch Name */}
              <div className="col-lg-4">
                <TimePickerField
                  name="startTime"
                  label="Start Time *"
                  placeholder="Start Time"
                />
              </div>
              <div className="col-lg-4">
                <TimePickerField
                  name="endTime"
                  label="End Time *"
                  placeholder="End Time"
                />
              </div>
              <div className="col-lg-4">
                <Select name="holiday" label="Weekly Off">
                  <option value="">No Off</option>
                  <option value="0">Sunday</option>
                  <option value="1">Monday</option>
                  <option value="2">Tuesday</option>
                  <option value="3">Wednesday</option>
                  <option value="4">Thursday</option>
                  <option value="5">Friday</option>
                  <option value="6">Saturday</option>
                </Select>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-12">
                <label>Branch Description *</label>
                <Field
                  name="description"
                  as="textarea"
                  rows="5"
                  className="form-control"
                />
              </div>
            </div>

            <div className="form-group row">
              <div className="col-lg-12">
                <Field
                  name="otherContact"
                  component={Input}
                  label="Current Branch Location"
                  disabled={true}
                  value={branchData.gmap}
                />
              </div>
            </div>

            <div className="form-group row">
              <div className="col-lg-12">
                <PlacesAutocomplete setValue={formProps.setFieldValue} label='Update Branch Location' />
                {formProps.errors.latitude && (
                  <div className="invalid-feedback d-flex">
                    {formProps.errors.latitude}
                  </div>
                )}
              </div>
            </div>

            {/* Tax */}
            <div className="form-group row">
              <div className="col-lg-6">
                <Field
                  name="taxSlab"
                  type="number"
                  component={Input}
                  placeholder="Tax Slab %"
                  label="Tax Slab (%) *"
                />
              </div>
              <div className="col-lg-6">
                <Field
                  name="taxNo"
                  type="text"
                  component={Input}
                  placeholder="Tax Number"
                  label="Tax Number"
                  style={{'text-transform': 'uppercase'}}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </CardBasic>
  );
};
