import React from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: "#000",
    borderBottomWidth: 1,
    alignItems: "center",
    fontWeight: 700,
    marginTop: 2,
    height: 24,
    textAlign: "center",
    justifyContent: "space-between",
    fontSize: 11,
    flexGrow: 1,
  },
  description: {
    width: "50%",
    textAlign: "left",
    marginLeft: "10px",
  },
  type: {
    width: "15%",
    textAlign: "left",
  },
  amount: {
    width: "25%",
    textAlign: "right",
    marginRight: "13px",
  },
});

const InvoiceHeader = ({currencySymbol}) => (
  <View style={styles.container}>
    <Text style={styles.description}>PARTICULARS</Text>
    <Text style={styles.type}>TYPE</Text>
    <Text style={styles.amount}>
      AMOUNT ({currencySymbol[0] ? currencySymbol[0].currencySymbol : '₹'})
    </Text>
  </View>
);

export default InvoiceHeader;
