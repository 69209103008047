import { createSlice } from "@reduxjs/toolkit";

import * as requestFromServer from "./segmentApi";

const initialState = {
  segments: [],
};

export const segmentSlice = createSlice({
  name: "segment",
  initialState,
  reducers: {
    segmentList(state, action) {
      state.segments = action.payload;
    },
    segmentCreated(state, action) {
      state.segments.push(action.payload);
    },
  },
});

export const getSegmentList = () => {
  return async (dispatch) => {
    return requestFromServer
      .getSegmentList()
      .then((response) => {
        console.log(response.data);
        dispatch(segmentSlice.actions.segmentList(response.data.data));
      })
      .catch((err) => {
        alert(err.message);
      });
  };
};

export const createSegment = (data) => {
  return async (dispatch) => {
    return requestFromServer
      .createSegment(data)
      .then((response) => {
        dispatch(segmentSlice.actions.segmentCreated(response.data.data));
      })
      .catch((error) => {
        console.log(error.response.data.error);
        throw new Error(error.response.data.error);
      });
  };
};
