import { createSlice } from "@reduxjs/toolkit";

import * as requestFromServer from "./typeAPi";

const initialState = {
  type: [],
};

export const expenseCategoryTypeBranchSlice = createSlice({
  name: 'expenseCategoryTye',
  initialState,
  reducers: {
    updateList(state, action) {
      state.type = action.payload;
    },
    created(state, action) {
      state.type.push(action.payload);
    }
  }
});


export const loadCategoryTypeList = (data) => {
  console.log(data);
  return async (dispatch) => {
    return requestFromServer
      .getCategoryTypeList(data)
      .then((response) => {
        console.log(response.data);
        dispatch(expenseCategoryTypeBranchSlice.actions.updateList(response.data.data));
      })
      .catch((err) => {
        alert(err.message);
      });
  }
}