import { createSlice } from "@reduxjs/toolkit";

import * as requestFromServer from "./branchShiftsApi";

const initialState = {
  branchShifts: [],
};

export const branchShiftSlice = createSlice({
  name: "branchShifts",
  initialState,
  reducers: {
    updateList(state, action) {
      state.branchShifts = action.payload;
    },
    created(state, action) {
      state.branchShifts.push(action.payload);
    },
  },
});

export const loadBranchShiftList = () => {
  return async (dispatch) => {
    return requestFromServer
      .getBranchShiftList()
      .then((response) => {
        dispatch(branchShiftSlice.actions.updateList(response.data.data));
      })
      .catch((err) => {
        alert(err.message);
      });
  };
};

export const createBranchShiftData = (data) => {
  return async (dispatch) => {
    return requestFromServer
      .createBranchShift(data)
      .then((response) => {
        dispatch(branchShiftSlice.actions.created(response.data.data));
      })
      .catch((error) => {
        throw new Error(error.response.data.error);
      });
  };
};
