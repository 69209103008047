import React from "react";

import { ServiceCategoriesTable } from "./table";
import { CardBasic } from "../../components/CardBasic";

export function ServiceCategoriesPage() {
  return (
    <CardBasic
      title={"Services Categories List"}
      btnType={"button"}
      headerBtnText={"Add Service Category"}
      headerBtnLink={"/service/category/create"}
    >
      <ServiceCategoriesTable />
    </CardBasic>
  );
}
