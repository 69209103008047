import axios from "axios";

const URL = process.env.REACT_APP_API_URL + "/branch";
const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const getCategoryList = () => {
  return axios.get(`${URL}/voucher/PO/expenseCategory/list`);
};

export const createRa = (data) => {
  return axios.post(`${URL}/voucher/RA/create`, data);
};

export const getRa = async (req) => {
  return await axios.post(`${URL}/voucher/RA/view`, req);
};

export const updateRa = (data) => {
  console.log(data);
  return axios.patch(`${URL}/voucher/RA/update`, data);
};
