import React from "react";
import "./BellazMain.css";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import MenuIcon from "@material-ui/icons/Menu";
import HeaderLink from "./component/HeaderLink";
function BellazHeader() {
  return (
    <nav className="navbar navbar-expand-md bellazheader p-0">
      <div className="container-fluid align-items-center w-75">
        <img
          className="bellaz-logo navbar-brand p-0"
          src={toAbsoluteUrl("/media/BellazMain/Images/logo.png")}
          alt="bellaz logo"
        />
        <div
          type="button"
          class="btn btn-primary d-lg-none d-md-none"
          data-toggle="modal"
          data-target=".bd-example-modal-sm"
        >
          <MenuIcon />
        </div>

        <div
          class="modal fade bd-example-modal-sm"
          tabindex="-1"
          role="dialog"
          aria-labelledby="mySmallModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-sm">
            <div class="modal-content">
              <HeaderLink />
            </div>
          </div>
        </div>
        <div
          className="collapse navbar-collapse justify-content-end p-0 m-0"
          id="navbarCollapse"
        >
          <HeaderLink />
        </div>
      </div>
    </nav>
  );
}

export default BellazHeader;
