import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import filterFactory from "react-bootstrap-table2-filter";

import { sortCaret } from "../../_metronic/_helpers";

import {
  StatusColumnFormatter,
  ActionColumnSimple,
} from "../../_metronic/_helpers/tableColumnFormatter";

import paginationFactory from "react-bootstrap-table2-paginator";

import { packagesData } from "../../demoData/packagesData";
import { getCountryCodeList } from "../../store/countryCode/countryCodeSlice";
import { loadPackageList } from "../../store/business/packages/packageSlice";

const LinkFormatter = (cell, row) => {
  return <Link to={`package/${row.uuid}`}>{cell}</Link>;
};

export const PackagesTable = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.package.packages);
  const { role, user } = useSelector((state) => state.authPersist);
  const { countryCode } = useSelector((state) => state.countryCode);
  const currencySymbol = countryCode.filter((el) => el.countryCode === user.countryCode);
  const [dataLoading, setDataLoading] = useState(true);

  useEffect(() => {
    dispatch(getCountryCodeList());
  }, []);
  
  useEffect(() => {
    dispatch(loadPackageList()).then(() => {
      setDataLoading(false);
    });
  }, []);

  const columns = [
    {
      dataField: "",
      text: "#",
      formatter: (row, cell, index) => {
        return index+1;
      }
    },
    {
      dataField: "name",
      text: "Package Name",
      sort: true,
      sortCaret: sortCaret,
      formatter: LinkFormatter,
    },
    {
      dataField: "price",
      text: `Price (${currencySymbol[0] ? currencySymbol[0].currencySymbol : '₹'})`,
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "services",
      text: "Services",
      formatter: (cell) => {
        return cell.length
      },
    },
    {
      dataField: "time",
      text: "Time (Minutes)",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "isActive",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      formatter: StatusColumnFormatter,
    },
  ];


  const { SearchBar, ClearSearchButton } = Search;

  return (
    <ToolkitProvider search keyField="uuid" data={data} columns={columns}>
      {(props) => (
        <>
          <div className="container">
            <div class="row">
              <div className="col-sm-4">
                {/* <h4 className="mt-4" >Search</h4> */}
              </div>
              <div className="col-sm-8">
                <SearchBar {...props.searchProps} />
                <ClearSearchButton
                  {...props.searchProps}
                  className="ml-4 btn btn-secondary-light"
                />
              </div>
            </div>
          </div>

          <BootstrapTable
            {...props.baseProps}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            wrapperClasses="table-responsive"
            bordered={false}
            bootstrap4
            pagination={paginationFactory()}
            filter={filterFactory()}
            noDataIndication={
              <div className={"d-flex justify-content-center py-10"}>
                <h3>{dataLoading ? "Loading Data..." : "No records found"}</h3>
              </div>
            }
          />
        </>
      )}
    </ToolkitProvider>
  );
};
