import React, { useState } from "react";
import "./toggleButton.css";
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

export default function ToggleButton({handleClick}) {
  const [subType, setSubType] = useState("monthly");
    console.log(subType);
  return (
    <Box sx={{ display: "flex",display: 'inline-block' }}>
      <Box className="mask-box d-flex">
        <Box
          className="mask"
          style={{
            transform: `translateX(${subType === "monthly" ? 0 : "100px"})`
          }}
        />
        <Button
          disableRipple
          variant="text"
          sx={{ color: subType === "monthly" ? "#ffffff" : "#5316AE" }}
          onClick={() => {
            setSubType("monthly");
            handleClick("monthly");
        }}
        >
          Monthly
        </Button>
        <Button
          disableRipple
          variant="text"
          sx={{ color: subType === "yearly" ? "#ffffff" : "#5316AE" }}
          onClick={() => {
            setSubType("yearly");
            handleClick("yearly");
        }}
        >
          Yearly
        </Button>
      </Box>
    </Box>
  );
}
