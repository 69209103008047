import React from "react";
import ShoppingBagIcon from "@material-ui/icons/ShoppingBasket";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import BookIcon from '@material-ui/icons/Book';
const data = [
  {
    toogleMain: "#collapseOne",
    heading: "How can I book appointment?",
    toogle: "collapseOne",
    body: `Kindly follow the given steps to book your appointment for the required services:`,
    li: [
      'Step-1 Signup/Login to your MY-BELLAZ Account.',
      'Step-2 Enter the location on the “Location Bar” where you want to avail the service. You can also use the Auto Locate feature to set the location.',
      'Step-3 Click on the Categories Menu and select your preferred category to get a detailed listing of the service providers in that area.',
      'Step-4 Select your preferred service provider and add the required services to your cart.',
      'Step-5 Click on the checkout button, select your desired date and time.',
      'Step-6 Select the mode of payment and book your appointment.',
    ]
  },
  {
    toogleMain: "#collapseTwo",
    heading: "Can I cancel my appointment?",
    toogle: "collapseTwo",
    body:
      ` In order to cancel your appointment, please go to your Profile section and select 'Bookings' and there you'll have the option to cancel your appointment. Please note that our partner may charge some cancellation fees, if applicable, in such cases.`,
  },
  {
    toogleMain: "#collapseThree",
    heading: "Can I book appointment for any location?",
    toogle: "collapseThree",
    body:
      ` Yes, you can book appointment with the partners available on the search results from any location.`,
  },
  {
    toogleMain: "#collapse4",
    heading: "Is there a minimum order value?",
    toogle: "collapse4",
    body:
      ` We have no minimum order value, and you can book appointment for any amount.`,
  },
  {
    toogleMain: "#collapse5",
    heading: "Can I book multiple appointments from multiple service providers?",
    toogle: "collapse5",
    body:
      `Yes, you can book multiple appointments from different partners.`,
  },
  {
    toogleMain: "#collapse6",
    heading: "How to I place an order at Bellaz’s ",
    toogle: "collapse6",
    body:
      `You can place your order in a few simple steps:`,
    li: [
      `Step 1: Click the 'Add to Bag' button on the products you wish to purchase and they will be added to your shopping bag.`,
      `Step 2: Register with Bellaz’s  by clicking on the “Account” button on the top right-hand corner of the page and clicking on “Sign Up”

      When you sign up for your Bellaz’s  account, you can enter all your details including your  shipping address and phone number on to your account page and it will be recorded for future purposes!
      While this sign-up step is optional, we highly recommend that you do create a Bellaz’s  account. Not only will you be able to rate and review products, and avail members-only coupons/offers, but also you can accumulate Reward Points and obtain discounts on future purchases.
      `,
      'Step 3: You can then click on your shopping bag in the top right corner of the page and use any coupon codes by clicking on “Got a Coupon Code? Apply it here” to receive a discount on your order.',
      'Step 4: Either you can “Continue shopping” or click on the “Checkout” button to proceed to the next steps.',
      `Step 5: Provide us with your email address, shipping address and payment details. Finally click on 'place order' to go ahead. Minimum order value must be Rs. 149. `,
      'Step 6: Once the order is placed, you will see a confirmation page with an order number. Also, we will send you an email confirming your order.',
      'Step 7: Enjoy your Bellaz’s  order!',
    ],
    body2: 'If you have any further questions, you can write to us at support@my-bellaz .com or chat with us by clicking on the “Chat with us” tab at the bottom of the screen'
  },
  {
    toogleMain: "#collapse7",
    heading: "Can I edit my order after confirmation ?",
    toogle: "collapse7",
    body:
      `Unfortunately, we cannot add any product to the existing order. However, you can remove any product before the order has been packed & shipped. You will get an option to cancel an item under 'Orders' section in your Bellaz’s  account`,
  },
  {
    toogleMain: "#collapse8",
    heading: "How do I know that my order is confirmed ?",
    toogle: "collapse8",
    body:
      `Once your order is confirmed, you will see a confirmation page displaying a unique Order ID, a listing of the item(s) you have ordered and the shipping address.
      `,
    body2: `In addition, you will receive an email and a text message confirming your order details. You will also be notified when we dispatch the item(s) from our warehouse with the respective tracking details.`
  },
  {
    toogleMain: "#collapse9",
    heading: "Can I leave items in my shopping bag for future purchase ? ",
    toogle: "collapse9",
    body:
      `Yes, you can leave items in your shopping bag, however the price and availability of these products will be reflected on a real time basis. Hence, there is a possibility that the product may get sold out or the offer on the product may expire from the time you added it to your shopping bag`,
  },
];
const data2 = [
  {
    toogleMain: "#collapseFour",
    heading: "How can I edit my BELLAZ’s profile?",
    toogle: "collapseFour",
    h2: "On Website:",
    body:
      ` Please visit the Profile Page`,
    li: [
      `Click the 'Edit' Button and edit the desired details.`,
      `Click the 'Save' Button to save the edited Profile.`
    ],
    h3: "On App:",
    li2: [
      `Tap the 'My Account' Icon on the Tab Bar.`,
      `Tap the 'Edit' Button and edit the desired details.`,
      `Tap the 'Save' Button to save the edited Profile.`,
      `Partner Registration`,
    ],
  },
  {
    toogleMain: "#collapseFive",
    heading: "I want to partner with BELLAZ’s  What is the process?",
    toogle: "collapseFive",
    body:
      ` •	If you want to partner with BELLAZ,  please visit https://business.MY-BELLAZ.com/ or drop an email at support@my-BELLAZ.com.`,
  },
  {
    toogleMain: "#collapse10",
    heading: "I didn't get any confirmation regarding my MY-BELLAZ Partner Registration.",
    toogle: "collapse10",
    body:
      ` Please send an email at  support@MY-BELLAZ.com`,
  },
  {
    toogleMain: "#collapse11",
    heading: "How can I create my BELLAZ’s Profile?",
    toogle: "collapse11",
    body:
      ` Visit MY-BELLAZ.com or download MY-BELLAZ App from Play Store or App Store.
      `,
    body2: `Click on the 'Signup' button.`,
    body3: `Fill the required field, and click on the 'Register' button`
  },
  {
    toogleMain: "#collapse12",
    heading: "Didn't receive OTP?",
    toogle: "collapse12",
    body:
      ` Please check if your app is due for an update. If not, please share the details via support@my-BELLAZ.com.`,
  },
  {
    toogleMain: "#collapse13",
    heading: "Do sellers on Bellaz’s ship internationally ?",
    toogle: "collapse13",
    body:
      ` We are happy to inform that we have started with international shipping to middle east  with many more countries to follow suit in near future.`,
  },
  {
    toogleMain: "#collapse14",
    heading: "What is the range of location to which Bellaz’s ship their products ?",
    toogle: "collapse14",
    body:
      ` Bellaz’s  ships throughout India!`,
    body2: `We would suggest that you check the pincode on product page under 'Delivery Options' 
      `,
  },
  {
    toogleMain: "#collapse15",
    heading: "What is the estimate delivery time ? ",
    toogle: "collapse15",
    body:
      ` We dispatch most orders within 1-4 business days (i.e. excluding Sundays and public holidays).`,
    liNumber: [
      `Though, we keep 95% of our catalogue in our inventory, certain products need to be sourced directly from the brand itself so that we can live up to our promise of providing fresh, non-expired products.`,
      `While we are trying our best to avoid this situation, these products might delay your order.`,
      `If you are ordering our products from a Mega Sale event, dispatches may be a bit delayed due to increased volumes. We will target to dispatch all orders within 5 days of order date.`,
    ],
  },
];
function Faq() {
  return (
    <div className="container-fluid p-0 m-0 w-100" style={{ background: ' #f1f3f5' }}>
      <div
        className="d-flex justify-content-center align-items-center w-100 bg-dark"
        style={{ height: "220px", borderRadius: "10px" }}
      >
        <h2 className="display-4 text-white">
          <LiveHelpIcon className="mr-5 display-4" />
          Frequently Asked Question
        </h2>
      </div>

      <div
        class="container bg-white p-5"
        style={{ width: "90%", marginTop: "-40px", borderRadius: "10px" }}
      >

        <div
          class="panel-group mb-10"
          id="accordion"
          role="tablist"
          aria-multiselectable="true"
        >
          <h2 className="display-5 ml-2 mt-5 mb-5">
            <BookIcon className="display-5" /> Booking
          </h2>
          {data.map((_data) => {
            return (
              <div
                class="panel panel-default mb-3"
                style={{
                  boxShadow:
                    " 0 1px 2px rgba(0, 0, 0, 0.075), 0 2px 4px rgba(0, 0, 0, 0.0375)",
                }}
              >
                <div class="panel-heading" id="headingOne" role="tab">
                  <h4 class="panel-title">
                    <a
                      role="button"
                      class="collapsed d-flex justify-content-between flex-row w-100 p-4"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href={_data.toogleMain}
                      aria-expanded="true"
                      aria-controls={_data.toogle}
                    >
                      {_data.heading}
                      <KeyboardArrowDownIcon className="icon_rotate" />
                    </a>
                  </h4>
                </div>
                <div
                  class="panel-collapse collapse in"
                  id={_data.toogle}
                  role="tabpanel"
                  aria-labelledby="headingOne"
                >
                  <div class="panel-body">
                    <p className="pl-5 pr-5 h5">{_data.body}</p>
                    {_data.li && <ul className="pl-20 pb-5 pr-5">
                      {_data.li.map(e => {
                        return <li className="mb-2 h5" style={{ color: 'black', listStyle: 'disc' }}>{e}</li>
                      })}
                    </ul>}
                    {_data.body2 && <p className="pl-5 pr-5 pb-5 h5">{_data.body2}</p>}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div class="panel panel-default">
          <div
            class="panel-group"
            id="accordion"
            role="tablist"
            aria-multiselectable="true"
          >
            <h2 className="display-5 ml-2 mt-5 mb-5">
              <CardMembershipIcon className="display-5" /> Registration & Subscription
            </h2>
            {data2.map((_data) => {
              console.log(_data);
              return (
                <div
                  class="panel panel-default mb-3"
                  style={{
                    boxShadow:
                      " 0 1px 2px rgba(0, 0, 0, 0.075), 0 2px 4px rgba(0, 0, 0, 0.0375)",
                  }}
                >
                  <div class="panel-heading" id="headingOne" role="tab">
                    <h4 class="panel-title">
                      <a
                        role="button"
                        class="collapsed d-flex justify-content-between flex-row w-100 p-4 h5"
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href={_data.toogleMain}
                        aria-expanded="true"
                        aria-controls={_data.toogle}
                      >
                        {_data.heading}
                        <KeyboardArrowDownIcon className="icon_rotate" />
                      </a>
                    </h4>
                  </div>
                  <div
                    class="panel-collapse collapse in"
                    id={_data.toogle}
                    role="tabpanel"
                    aria-labelledby="headingOne"
                  >
                    <div class="panel-body">
                      <p className="pl-5 pr-5 pb-5 h5">{_data.body}</p>
                      {_data.body2 && <p className="pl-5 pr-5 pb-5 h5">{_data.body2}</p>}
                      {_data.body3 && <p className="pl-5 pr-5 pb-5 h5">{_data.body3}</p>}
                      {_data.body3 && <p className="pl-5 pr-5 pb-5 h5">{_data.body3}</p>}
                      {_data.h2 && <h3 className="pl-5 pr-5 pb-5 h5">{_data.h2}</h3>}
                      {_data.li && <ul className="pl-20 pb-5 pr-5 h5">
                        {_data.li.map(e => {
                          return <li className="mb-2 h5" style={{ color: 'black', listStyle: 'disc' }}>{e}</li>
                        })}
                      </ul>}
                      {_data.liNumber && <ul className="pl-20 pb-5 pr-5">
                        {_data.liNumber.map(e => {
                          return <li className="mb-2 h5" style={{ color: 'black', listStyle: 'decimal' }}>{e}</li>
                        })}
                      </ul>}
                      {_data.h3 && <h3 className="pl-5 pr-5 pb-5 h5">{_data.h3}</h3>}
                      {_data.li2 && <ul className="pl-20 pb-5 pr-5 h5">
                        {_data.li2.map(e => {
                          return <li className="mb-2 h5" style={{ color: 'black', listStyle: 'disc' }}>{e}</li>
                        })}
                      </ul>}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

      </div>
    </div>
  );
}

export default Faq;
