/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useSelector } from "react-redux";
import ReceiptIcon from "@material-ui/icons/Receipt";
import PaymentIcon from "@material-ui/icons/Payment";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const [isBusiness, setIsBusiness] = useState(false);
  const [isGuest, setIsGuest] = useState(false);
  const { user, role } = useSelector((state) => state.authPersist);
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
      "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };
  console.log(user);
  useEffect(() => {
    if (role === "business") {
      setIsBusiness(true);
    } else if (role === "guest") {
      setIsGuest(true);
    }
  }, [role]);

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        {isBusiness && (
          <li
            className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/dashboard">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
                />
              </span>
              <span className="menu-text">Dashboard</span>
            </NavLink>
          </li>
        )}
        {/*end::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/subscription", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/subscriptions">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Gift.svg")} />
            </span>
            <span className="menu-text">Subscriptions</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/segments", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/segments">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Folder.svg")} />
            </span>
            <span className="menu-text">Business Segment</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive(
            "/service/categories",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/service/categories">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-grid.svg")}
              />
            </span>
            <span className="menu-text">Service Categories</span>
          </NavLink>
        </li> */}

        {/*begin::1 Level*/}
        {isBusiness && (
          <>
            <li
              className={`menu-item ${getMenuItemActive("/branches", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/branches">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Map/Marker1.svg")}
                  />
                </span>
                <span className="menu-text">Branches</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive("/services", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/services">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/General/Scissors.svg")}
                  />
                </span>
                <span className="menu-text">Services</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive("/packages", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/packages">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/General/Star.svg")}
                  />
                </span>
                <span className="menu-text">Packages</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive("/chairs", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/chairs">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Home/Chair2.svg")}
                  />
                </span>
                <span className="menu-text">Chair</span>
              </NavLink>
            </li>
          </>
        )}
        {/* <li
          className={`menu-item ${getMenuItemActive("/services", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/customers">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/Scissors.svg")}
              />
            </span>
            <span className="menu-text">Customers</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {!isGuest && (
          <>
            <li
              className={`menu-item menu-item-submenu`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/bookings">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Cart3.svg")} />
                </span>
                <span className="menu-text">Bookings</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  <li className="menu-item  menu-item-parent" aria-haspopup="true">
                    <span className="menu-link">
                      <span className="menu-text">Bookings</span>
                    </span>
                  </li>

                  {/*begin::2 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/bookings/upcoming",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/bookings/upcoming">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Upcoming</span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}
                  {/*begin::2 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/bookings/completed",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/bookings/completed">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Completed</span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}
                  {/*begin::2 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/bookings/cancelled",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/bookings/cancelled">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Cancelled</span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}
                </ul>
              </div>
            </li>
          </>
        )}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {isBusiness && user.calendarType !== 'schedule' && (
          <li
            className={`menu-item ${getMenuItemActive("/shifts", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/shifts">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Clock.svg")} />
              </span>
              <span className="menu-text">Shifts</span>
            </NavLink>
          </li>
        )}

        {!isGuest && <li
          className={`menu-item menu-item-submenu`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/staff">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
            </span>
            <span className="menu-text">Staff</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Staff</span>
                </span>
              </li>

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/staff", true)}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/staff">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">List</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/staff/leaves",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/staff/leaves">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Leaves</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {role === "business" || isGuest || user.businessCalenderType === 'shift' ? null : (
          <li
            className={`menu-item ${getMenuItemActive("/schedule", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/schedule">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Clipboard.svg")}
                />
              </span>
              <span className="menu-text">Schedule</span>
            </NavLink>
          </li>
        )}

        {isBusiness && (
          <li
            className={`menu-item ${getMenuItemActive("/holidays", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/holidays">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Door-open.svg")} />
              </span>
              <span className="menu-text">Holiday</span>
            </NavLink>
          </li>
        )}

        {/*end::1 Level*/}

        {/*begin::1 Level*/}

        {/* TODO uncomment when done */}
        {/* {isBusiness && (
          <li
            className={`menu-item ${getMenuItemActive("/settings", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/settings">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg")} />
              </span>
              <span className="menu-text">Settings</span>
            </NavLink>
          </li>
        )} */}

        {isBusiness && (
          <li
            className={`menu-item ${getMenuItemActive("/payment", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/payment">
              <span className="svg-icon menu-icon">
                <ReceiptIcon />
                {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Export.svg")} /> */}
              </span>
              <span className="menu-text">Sales</span>
            </NavLink>
          </li>
        )}

        {!isGuest && <li
          className={`menu-item menu-item-submenu`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/expense">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Money.svg")} />
            </span>
            <span className="menu-text">Expense</span>
            <i className="menu-arrow" />
          </NavLink>
          {isBusiness ? <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Expense</span>
                </span>
              </li>

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/expense/category", true)}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/expense/category">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Category</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/expense/type",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/expense/type">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Type</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
            </ul>
          </div> : <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Cash Drawer</span>
                </span>
              </li>

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/expense/cashDrawer", true)}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/expense/cashDrawer">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Cash Drawer</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/expense/type",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/expense/ra">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">RA</span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive(
                  "/expense/type",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/expense/po">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">PO</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
            </ul>
          </div>}
        </li>}

        {isBusiness && (
          <li
          className={`menu-item ${getMenuItemActive("/coupons", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/coupons">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Ticket.svg")} />
            </span>
            <span className="menu-text">Coupons</span>
          </NavLink>
        </li>
        )}

        {isBusiness && (
          <li
          className={`menu-item ${getMenuItemActive("/loyaltyPoints", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/loyaltyPoints">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Gift.svg")} />
            </span>
            <span className="menu-text">Loyalty Points</span>
          </NavLink>
        </li>
        )}

        {/*end::1 Level*/}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
