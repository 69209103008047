import axios from "axios";

const URL = process.env.REACT_APP_API_URL;
const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};



export const topSalonsAPI = (data) => {
  return axios.post(`${URL}/open/branch/list`, data, {
    headers,
  });
};

export const salonView = (req) => {
  return axios.post(`${URL}/open/view/branch`, req, {
    headers,
  });
};