import React from "react";
import Autocomplete, {getLatLang} from "react-google-autocomplete";

export default function PlacesAutocomplete(props) {
  return (
    <>
      <label>{props.label}</label>
      <Autocomplete
        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        className="form-control"
        style={{ width: "100%" }}
        onPlaceSelected={(place) => {
          console.log(place);
          props.setValue("longitude", place.geometry['location'].lng());
          props.setValue("latitude", place.geometry['location'].lat());
          props.setValue("gmap", place.formatted_address);
        }}
        options={{
          types: ["establishment"],
        }}
      />
    </>
  );
}
