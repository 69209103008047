import { createSlice } from "@reduxjs/toolkit";

import * as requestFromServer from "./scheduleApi";

const initialState = {
  schedule: [],
};

export const scheduleSlice = createSlice({
  name: "schedule",
  initialState,
  reducers: {
    updateList(state, action) {
      state.schedule = action.payload;
    },
    created(state, action) {
      state.schedule.push(action.payload);
    },
  },
});

export const loadScheduleList = () => {
  return async (dispatch) => {
    return requestFromServer
      .getScheduleList()
      .then((response) => {
        console.log(response.data);
        dispatch(scheduleSlice.actions.updateList(response.data.data));
      })
      .catch((err) => {
        alert(err.message);
      });
  };
};

export const createScheduleData = (data) => {
  let arr = [];
  data.staff.map((key) => {
    arr.push(key.value);
  });
  data.staff = arr;
  console.log(data);
  return async (dispatch) => {
    return requestFromServer
      .createSchedule(data)
      .then((response) => {
        dispatch(scheduleSlice.actions.created(response.data.data));
      })
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };
};
