import React from "react";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { useSelector } from "react-redux";
import "./upgrade.css";
export function ChartMain() {
  const data = useSelector(
    (state) => state.subscriptionCategories.subscriptionCategories
  );
  console.log(data, "this is changes");
  const classes = `w-25 d-flex justify-content-center`;
  const tr_class = `d-flex align-items-center line justify-content-between w-100`;
  const tr_head = `container-fluid w-100 d-flex flex-row justify-content-end p-0 m-0`;
  return (
    <div className="tabel-responsive">
      <table class="table m-0 p-0 borderless ">
        <tbody className="border-0">
          <tr className={tr_class}>
            <th className="w-25" scope="row">
              Branch Registration
            </th>
            <div className={tr_head}>
              {data.map((_data) =>
                _data.isActive === true ? (
                  <td className={classes}>{_data.branch_credit}</td>
                ) : (
                  ""
                )
              )}
            </div>
          </tr>
          <tr className={tr_class}>
            <th className="w-25" scope="row">
              Appointment Booking
            </th>
            <div className={tr_head}>
              {data.map((_data) =>
                _data.isActive === true ? (
                  <td className={classes}>unlimited</td>
                ) : (
                  ""
                )
              )}
            </div>
          </tr>
          <tr className={tr_class}>
            <th className="w-25" scope="row">
              {" "}
              Clients
            </th>
            <div className={tr_head}>
              {data.map((_data) =>
                _data.isActive === true ? (
                  <td className={classes}>unlimited</td>
                ) : (
                  ""
                )
              )}
            </div>
          </tr>
          <tr className={tr_class}>
            <th className="w-25" scope="row">
              Chair Limit
            </th>
            <div className={tr_head}>
              {data.map((_data) =>
                _data.isActive === true ? (
                  <td className={classes}>{_data.chair_credit}/Branch</td>
                ) : (
                  ""
                )
              )}
            </div>
          </tr>
           <tr className={tr_class}>
            <th className="w-25" scope="row">
              {" "}
              Staff Limit
            </th>
            <div className={tr_head}>
              {data.map((_data) =>
                _data.isActive === true ? (
                  <td className={classes}>{_data.staff_credit}/Branch</td>
                ) : (
                  ""
                )
              )}
            </div>
          </tr>
          {/*<tr className={tr_class}>
            <th className="w-25" scope="row">
              Customer Feedback Dashboard
            </th>
            <div className={tr_head}>
              {data.map((_data) =>
                _data.isActive === true ? (
                  <td className={classes}>
                    <CheckIcon />
                  </td>
                ) : (
                  ""
                )
              )}
            </div>
          </tr>
          <tr className={tr_class}>
            <th className="w-25" scope="row">
              Appointment Report
            </th>
            <div className={tr_head}>
              {data.map((_data) =>
                _data.isActive === true ? (
                  <td className={classes}>
                    <CheckIcon />
                  </td>
                ) : (
                  ""
                )
              )}
            </div>
          </tr>
          <tr className={tr_class}>
            <th className="w-25" scope="row">
              {" "}
              Activity Dashboard
            </th>
            <div className={tr_head}>
              {data.map((_data) =>
                _data.isActive === true ? (
                  <td className={classes}>
                    <CheckIcon />
                  </td>
                ) : (
                  ""
                )
              )}
            </div>
          </tr>
          <tr className={tr_class}>
            <th className="w-25" scope="row">
              Staff work Schedule
            </th>
            <div className={tr_head}>
              {data.map((_data) =>
                _data.isActive === true ? (
                  <td className={classes}>
                    <CheckIcon />
                  </td>
                ) : (
                  ""
                )
              )}
            </div>
          </tr>
          <tr className={tr_class}>
            <th className="w-25" scope="row">
              {" "}
              Appointment Scheduling
            </th>
            <div className={tr_head}>
              {data.map((_data) =>
                _data.isActive === true ? (
                  <td className={classes}>
                    <CheckIcon />
                  </td>
                ) : (
                  ""
                )
              )}
            </div>
          </tr>
          <tr className={tr_class}>
            <th className="w-25" scope="row">
              Point of Scale(POS)
            </th>
            <div className={tr_head}>
              {data.map((_data) =>
                _data.isActive === true ? (
                  <td className={classes}>Advance</td>
                ) : (
                  ""
                )
              )}
            </div>
          </tr>
          <tr className={tr_class}>
            <th className="w-25" scope="row">
              GST Report
            </th>
            <div className={tr_head}>
              {data.map((_data) =>
                _data.isActive === true ? (
                  <td className={classes}>
                    <CheckIcon />
                  </td>
                ) : (
                  ""
                )
              )}
            </div>
          </tr>
          <tr className={tr_class}>
            <th className="w-25" scope="row">
              Provisional Activity
            </th>
            <div className={tr_head}>
              {data.map((_data) =>
                _data.isActive === true ? (
                  <td className={classes}>Basic</td>
                ) : (
                  ""
                )
              )}
            </div>
          </tr>
          <tr className={tr_class}>
            <th className="w-25" scope="row">
              Consolidated Reports
            </th>
            <div className={tr_head}>
              {data.map((_data) =>
                _data.isActive === true ? (
                  <td className={`${classes} red`}>
                    <CheckIcon />
                  </td>
                ) : (
                  ""
                )
              )}
            </div>
          </tr>
          <tr className={tr_class}>
            <th className="w-25" scope="row">
              Loyalty & Rewards
            </th>
            <div className={tr_head}>
              {data.map((_data) =>
                _data.isActive === true ? (
                  <td className={classes}>
                    <CheckIcon />
                  </td>
                ) : (
                  ""
                )
              )}
            </div>
          </tr>
          <tr className={tr_class}>
            <th className="w-25" scope="row">
              Marketing Notifications
            </th>
            <div className={tr_head}>
              {data.map((_data) =>
                _data.isActive === true ? (
                  <td className={classes}>4 credits/month</td>
                ) : (
                  ""
                )
              )}
            </div>
          </tr>
          <tr className={tr_class}>
            <th className="w-25" scope="row">
              Expense Management
            </th>
            <div className={tr_head}>
              {data.map((_data) =>
                _data.isActive === true ? (
                  <td className={classes}>
                    <CheckIcon />
                  </td>
                ) : (
                  ""
                )
              )}
            </div>
          </tr>
          <tr className={tr_class}>
            <th className="w-25" scope="row">
              Profits Analysis Reports
            </th>
            <div className={tr_head}>
              {data.map((_data) =>
                _data.isActive === true ? (
                  <td className={`${classes} red`}>
                    <CheckIcon />
                  </td>
                ) : (
                  ""
                )
              )}
            </div>
          </tr>
          <tr className={tr_class}>
            <th className="w-25" scope="row">
              Staff Managment
            </th>
            <div className={tr_head}>
              {data.map((_data) =>
                _data.isActive === true ? (
                  <td className={classes}>
                    <CheckIcon />
                  </td>
                ) : (
                  ""
                )
              )}
            </div>
          </tr> */}
        </tbody>
      </table>
    </div>
  );
}
