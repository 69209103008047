import axios from "axios";

const URL = process.env.REACT_APP_API_URL + "/business";

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const getPaymentList = async (data) => {
  const req = {
    from: data.from,
    to: data.to,
    paymentMode: data.paymentMode
  };
  console.log(req);
    return await axios.post(`${URL}/payment/list`, req, {
      headers,
    });
  };
