import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import filterFactory from "react-bootstrap-table2-filter";

import { sortCaret } from "../../../_metronic/_helpers";

import {
  StatusColumnFormatter,
  ActionColumnSimple,
} from "../../../_metronic/_helpers/tableColumnFormatter";

import paginationFactory from "react-bootstrap-table2-paginator";
import { loadCategoryList } from "../../../store/business/expense/Category/categorySlice";
import { loadBranchStaffList } from "../../../store/branch/branchStaffSlice";
import { capitalize } from "@material-ui/core";

export const CustomerStatusTitles = ["Suspended", "Active", "Pending", ""];

export const CategoryTable = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.category.category);

  const categoryList = useSelector((state) => state.category.category);
  console.log(categoryList);
  const { role, user } = useSelector((state) => state.authPersist);
  const [dataLoading, setDataLoading] = useState(true);
  const LinkFormatter = (cell, row) => {
    return <Link to={`/expense/category/${row.uuid}`}>{capitalize(cell)}</Link>;
    console.log(row);
  };
  const branchFormatter = (cell, row) => {
    return user.name;
  };

  // const ActionDisplay = (cell, row) => {
  //   if (role == "branch") {
  //     return BookingActionColumn(cell, row);
  //   }
  //   return null;
  // };

  useEffect(() => {
    dispatch(loadCategoryList()).then(() => {
      setDataLoading(false);
    });
  }, []);

  const columns = [
    {
      dataField: "",
      text: "#",
      formatter: (row, cell, index) => {
        return index + 1;
      },
    },
    {
      dataField: "categoryName",
      text: "Category Name",
      sort: true,
      formatter: LinkFormatter,
      sortCaret: sortCaret,
      // filter: textFilter(),
    },
    // {
    //   dataField: "designation",
    //   text: "Designation",
    //   sort: true,
    //   sortCaret: sortCaret,
    // },
    // {
    //   dataField: "phone",
    //   text: "Phone",
    // },
    // {
    //   dataField: "branch[0].name",
    //   text: "Branch",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   formatter: role == "business" ? "" : branchFormatter,
    // },
    {
      dataField: "isAcitve",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      formatter: StatusColumnFormatter,
    },
    // {
    //   dataField: "",
    //   text: "Actions",
    //   formatter: ActionDisplay,
    // },
  ];

  const { SearchBar, ClearSearchButton } = Search;

  return (
    <ToolkitProvider
      search
      keyField="name"
      data={role == "business" ? data : categoryList}
      columns={columns}
    >
      {(props) => (
        <>
          <div className="container">
            <div class="row">
              <div className="col-sm-4">
                {/* <h4 className="mt-4" >Search</h4> */}
              </div>
              <div className="col-sm-8">
                <SearchBar {...props.searchProps} />
                <ClearSearchButton
                  {...props.searchProps}
                  className="ml-4 btn btn-secondary-light"
                />
              </div>
            </div>
          </div>

          <BootstrapTable
            {...props.baseProps}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            wrapperClasses="table-responsive"
            bordered={false}
            bootstrap4
            pagination={paginationFactory()}
            filter={filterFactory()}
            noDataIndication={
              <div className={"d-flex justify-content-center py-10"}>
                <h3>{dataLoading ? "Loading Data..." : "No records found"}</h3>
              </div>
            }
          />
        </>
      )}
    </ToolkitProvider>
  );
};
