import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import filterFactory from "react-bootstrap-table2-filter";
import { Formik, Form } from "formik";
import { DatePickerField } from "../../_metronic/_partials/controls";
import { CardBasic } from "../../components/CardBasic";
import { sortCaret } from "../../_metronic/_helpers";

import {
  BookingStatusColumnFormatter,
  BookingActionColumn,
} from "../../_metronic/_helpers/tableColumnFormatter";

import paginationFactory from "react-bootstrap-table2-paginator";
import {
  loadBookingList,
  loadBranchBookingList,
} from "../../store/business/bookings/bookingSlice";

export const TablePresent = (props) => {
  const dispatch = useDispatch();
  const bookingData = useSelector((state) => state.booking.bookings);
  const { role, user } = useSelector((state) => state.authPersist);
  const { countryCode } = useSelector((state) => state.countryCode);
  const currencySymbol = countryCode.filter(
    (el) => el.countryCode === user.countryCode
  );
  // console.log(user);
  const [dataLoading, setDataLoading] = useState(true);
  let bookingStatus = [];

  // console.log(bookingStatus);

  const newBookingData = bookingData.filter((el) => {
    // console.log(el.present);
    return el.present === true;
  });
  // console.log(newBookingData);
  if (props.status == "cancelled") {
    bookingStatus = [0];
  } else if (props.status == "upcoming") {
    bookingStatus = [1, 2, 3];
  } else if (props.status == "completed") {
    bookingStatus = [4];
  }

  const linkFormatter = (cell, row) => {
    // console.log(cell);
    if (role == "branch") {
      return (
        <Link className="text-uppercase" to={`../booking/${row.uuid}`}>
          <span className="text-uppercase">{cell}</span>
        </Link>
      );
    } else {
      return (
        <Link to={`../booking/view/${row.uuid}`}>
          <span className="text-uppercase">{cell}</span>
        </Link>
      );
    }
  };

  const oldDate = new Date();
  oldDate.setDate(oldDate.getDate() - 30);

  const date = new Date();
  date.setDate(date.getDate() + 15);

  useEffect(() => {
    if (role == "business") {
      dispatch(
        loadBookingList({ bookingStatus, from: oldDate, to: date })
      ).then(() => {
        setDataLoading(false);
      });
    } else {
      dispatch(
        loadBranchBookingList({ bookingStatus, from: new Date(), to: date })
      ).then(() => {
        setDataLoading(false);
      });
    }
  }, [props.status]);

  const TimeFormatter = (cell, row) => {
    return dayjs(cell).format("hh:mm A");
  };

  const DateFormatter = (cell, row) => {
    return dayjs(cell).format("DD-MM-YYYY");
  };

  const ActionDisplay = (cell, row) => {
    if (role == "branch") {
      return BookingActionColumn(cell, row);
    }
    return null;
  };

  const RatingDisplay = (cell, row) => {
    // console.log(row);
    if (row.CustomerRating) {
      if (row.CustomerRating.length > 0 && row.customer) {
        return (
          <div style={{ display: "flex" }}>
            {/* <i className="flaticon-star" style={{ color: '#339187' }} /> */}
            <div
              style={{
                marginLeft: "5px",
                marginTop: "3px",
                marginRight: "10px",
                fontSize: "15px",
              }}
            >
              {`${row.customer.name}`}
            </div>
            <i className="flaticon-star" style={{ color: "#339187" }} />
            <div
              style={{ marginLeft: "5px", marginTop: "3px", fontSize: "15px" }}
            >
              {`${row.CustomerRating[0].rating}`}
            </div>
          </div>
        );
      } else if (row.CustomerRating.length === 0 && row.customer) {
        return (
          <div style={{ display: "flex" }}>
            {/* <i className="flaticon-star" style={{ color: '#339187' }} /> */}
            <div
              style={{ marginLeft: "5px", marginTop: "3px", fontSize: "15px" }}
            >
              {`${row.customer.name}`}
            </div>
          </div>
        );
      } else return null;
    } else {
      return (
        <div style={{ display: "flex" }}>
          {/* <i className="flaticon-star" style={{ color: '#339187' }} /> */}
          <div
            style={{ marginLeft: "5px", marginTop: "3px", fontSize: "15px" }}
          >
            {`${row.customer.name}`}
          </div>
        </div>
      );
    }
  };

  // console.log(bookingData);

  const bookings = [];
  const CustomerRating = [
    {
      bookingUuid: "uuid",
      rating: "rating",
    },
  ];
  bookings.push(CustomerRating);
  // console.log(bookings);

  const columns = [
    {
      dataField: "bookingId",
      text: "Booking Number",
      sort: true,
      sortCaret: sortCaret,
      // formatter: (cell, row) => {
      //   //return <span className="text-uppercase">{cell}</span>;
      // },
      formatter: linkFormatter,
    },
    {
      dataField: "customer.name",
      text: "Customer Name",
      sort: true,
      sortCaret: sortCaret,
      formatter: RatingDisplay,
    },
    {
      dataField: "customer.phone",
      text: "Customer Phone",
      sort: true,
    },
    {
      dataField: "branch[0].name",
      text: "Branch Name",
      sort: true,
      sortCaret: sortCaret,
      // filter: textFilter(),
    },
    {
      dataField: "date",
      text: "Booking Date",
      sort: true,
      sortCaret: sortCaret,
      formatter: DateFormatter,
    },
    {
      dataField: "dateTime",
      text: "Start Time",
      sort: true,
      sortCaret: sortCaret,
      formatter: TimeFormatter,
    },
    {
      dataField: "duration",
      text: "Duration (Minutes)",
    },
    {
      dataField: "total",
      text: `Booking Amount (${
        currencySymbol[0] ? currencySymbol[0].currencySymbol : "₹"
      })`,
    },
    {
      dataField: "paidAmount",
      text: `Paid Amount (${
        currencySymbol[0] ? currencySymbol[0].currencySymbol : "₹"
      })`,
    },
    {
      dataField: "status",
      text: "Booking Status",
      sort: true,
      sortCaret: sortCaret,
      formatter: BookingStatusColumnFormatter,
    },
    {
      dataField: "",
      text: "Actions",
      formatter: ActionDisplay,
    },
  ];

  const { SearchBar, ClearSearchButton } = Search;

  return (
    <>
      <CardBasic
        title={"Active Booking List"}
        // btnType={"button"}
        // headerBtnText={role == "business" ? "" : "New Booking"}
        //headerBtnLink={toAbsoluteUrl("../booking/create")}
      >
        {/* <Formik
                    initialValues={{
                        startDate: new Date(),
                        endDate: date
                    }}
                    onSubmit={
                        (values, errors) => {
                            console.log(values);
                            if (role == "business") {
                                dispatch(loadBookingList({ bookingStatus, from: values.startDate, to: values.endDate })).then(() => {
                                    setDataLoading(false);
                                });
                            } else {
                                dispatch(loadBranchBookingList({ bookingStatus, from: values.startDate, to: values.endDate })).then(() => {
                                    setDataLoading(false);
                                });
                            }
                        }
                        // saveCustomer(values);
                    }
                >
                    {({ values, errors, setFieldValue }) => {
                        console.log(values);
                        return (
                            <Form className="form form-label-right" id="createBookingForm">
                                <div className="container">
                                    <div className="row mb-5">
                                        <div className="col-sm-2"></div>
                                        <div className="col-sm-3">
                                            <div className="mx-3">
                                                <label>From</label>
                                            </div>
                                            <DatePickerField
                                                name="startDate"
                                                className="form-control"
                                                onChange={(val) => {
                                                    setFieldValue("startDate", val);
                                                }}
                                            />
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="mx-3">
                                                <label>To</label>
                                            </div>
                                            <DatePickerField
                                                name="endDate"
                                                className="form-control"
                                                onChange={(val) => {
                                                    setFieldValue("endDate", val);
                                                }}
                                            />
                                        </div>
                                        <div className="col-sm-3 mt-8">
                                            <button type="submit" class="btn btn-light-success font-weight-bold" style={{ marginLeft: ' 20px' }}>
                                                Filter Data
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Form>)
                    }}

                </Formik> */}

        <ToolkitProvider
          search
          keyField="bookingId"
          data={newBookingData}
          columns={columns}
        >
          {(props) => (
            <>
              <div className="container">
                <div class="row">
                  <div className="col-sm-4">
                    {/* <h4 className="mt-4" >Search</h4> */}
                  </div>
                  <div className="col-sm-8">
                    <SearchBar {...props.searchProps} />
                    <ClearSearchButton
                      {...props.searchProps}
                      className="ml-4 btn btn-secondary-light"
                    />
                  </div>
                </div>
              </div>

              <BootstrapTable
                {...props.baseProps}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                wrapperClasses="table-responsive"
                bordered={false}
                bootstrap4
                loading={true}
                pagination={paginationFactory()}
                filter={filterFactory()}
                noDataIndication={
                  <div className={"d-flex justify-content-center py-10"}>
                    <h3>
                      {dataLoading ? "Loading Data..." : "No records found"}
                    </h3>
                  </div>
                }
              />
            </>
          )}
        </ToolkitProvider>
      </CardBasic>
    </>
  );
};
