import React, {useEffect} from "react";
import { useDispatch } from "react-redux";
import { BusinessProfile } from "./profile";
import { BranchProfile } from "./branchProfile";
import { BillingDetails } from "./billing";
import { useSelector } from "react-redux";
import AddCustomerDialog from "../../components/dialogs/ChangePasswordDialog";
import { ProfileView } from "../../store/business/authSlice";
import { getCountryCodeList } from '../../store/countryCode/countryCodeSlice';

export const ProfilePage = () => {
  const business = useSelector((state) => state.authPersist.user);
  const { role, user } = useSelector((state) => state.authPersist);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(ProfileView());
    dispatch(getCountryCodeList());
  },[]);
  // console.log(business);
  // console.log(user);
  // console.log(role);

  return (
    <>
      {role === "business" || role === "guest" ? (
        <>
          {console.log(user)}
          <BusinessProfile business={business} />
          <BillingDetails business={business} />
        </>
      ) : (
        <BranchProfile branch={user} />
      )}
    </>
  );
};
