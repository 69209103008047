import axios from "axios";

const URL = process.env.REACT_APP_API_URL + "/business";
const headers = {
  "Content-Type": "multipart/form-data",
  Accept: "application/json",
};

export const getServiceList = () => {
  return axios.get(`${URL}/services`);
};

export const getService = async (req) => {
  return await axios.post(`${URL}/service/view`, req);
};

export const createService = (service) => {
  const req = new FormData();
  for (var key in service) {
    req.append(key, service[key]);
  }

  return axios.post(`${URL}/service/create`, req, {
    headers,
  });
};

export const updateService = (data, uuid) => {
  const req = new FormData();

  for (var key in data) {
    req.append(key, data[key]);
  }
  // req.delete('business_segment');
  // req.append('business_segment', JSON.stringify(data.business_segment));
  req.append("uuid", uuid);

  return axios.patch(`${URL}/service/update`, req, {
    headers,
  });
};
