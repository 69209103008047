/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import {
  getSegmentData,
  getServiceCategoryData,
} from "../store/data/dataSlice";
import BellazMain from "../components/bellazNew/BellazMain";
import TermsConditions from "../components/TermsConditions";
import { Invoice } from "../components/invoice/Invoice";
import Privacy from "../components/Privacy";
import FAQ from '../components/Faq';
import CancellationAndRefund from '../components/CancellationAndRefund';
import TermsAndConditions from '../components/TermsAndConditions';
import LegalInformation from '../components/LegalInformation';
import CookiePolicy from '../components/CookiePolicy';
import AboutUs from '../components/AboutUs';
import AllTopSalons from "../pages/TopSalons/AllTopSalons";
import TopSalon from "../pages/TopSalons/TopSalon";

export function Routes() {
  const { token, authenticated, user, role } = useSelector(
    (state) => state.authPersist
  );

  console.log(authenticated, token);
  // console.log(role);
  // console.log(user);
  // const { isAuthorized } = useSelector(
  //   ({ auth }) => ({
  //     isAuthorized: auth.user != null,
  //   }),
  //   shallowEqual
  // );

  const dispatch = useDispatch();
  useEffect(() => {
    if (authenticated) {
      dispatch(getSegmentData())
        .then(() => {
          console.log("Segments Loaded");
        })
        .catch((err) => {
          console.log(err.message);
        });

      dispatch(getServiceCategoryData())
        .then(() => {
          console.log("Service Categories Loaded");
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }, [authenticated]);

  return (
    <Switch>
      {!authenticated ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route path="/auth">
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/dashboard" />
      )}

      <Route path="/tncs" component={TermsConditions} />
      <Route path='/about' component={AboutUs} />
      <Route path="/privacy" component={Privacy} />
      <Route path="/faq" component={FAQ} />
      <Route path="/cancellation" component={CancellationAndRefund} />
      <Route path="/terms" component={TermsAndConditions} />
      <Route path="/legalInfo" component={LegalInformation} />
      <Route path="/cookiePolicy" component={CookiePolicy} />
      <Route path="/invoice/:id" component={Invoice} />
      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />
      <Route exact path="/" component={BellazMain} />
      <Route exact path="/top-salons" component={AllTopSalons} />
      <Route exact path="/top-salons/:uuid" component={TopSalon} />

      {role == "branch" && (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect from="/dashboard" to="/bookings/upcoming" />
      )}

      {role == "guest" && (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect from="/dashboard"  to="/subscription/upgrade" />
      )}

      {!authenticated ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}

    </Switch>
  );
}
