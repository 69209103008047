import React from "react";

import { TypeTable } from "./table";
import { CardBasic } from "../../../components/CardBasic";
import { useSelector } from "react-redux";

export function ExpensePoPage() {
  const { role } = useSelector((state) => state.authPersist);

  return (
    <CardBasic
      title={"PO List"}
      btnType={"button"}
      headerBtnText={"Add PO"}
      headerBtnLink={"/expense/po/create"}
    >
      <TypeTable />
    </CardBasic>
  );
}
