import { createSlice } from "@reduxjs/toolkit";

import * as requestFromServer from "./subscriptionListApi";

const initialState = {
    subscriptionCategories: [],
  };
  
  export const subscriptionCategoriesSlice = createSlice({
    name: 'subscriptionCategories',
    initialState,
    reducers: {
      updateList(state, action) {
        state.subscriptionCategories = action.payload;
      },
    }
  });

export const loadSubscriptionCategories = () => {
      return async (dispatch) => {
        return requestFromServer
          .getSubscriptionList()
          .then((response) => {
            response.data.data.sort((a, b) => (a.month_cost < b.month_cost ? -1 : 1));
            dispatch(subscriptionCategoriesSlice.actions.updateList(response.data.data));
          })
          .catch((err) => {
            alert(err.message);
          });
      }
  }
