import React from "react";
import { toAbsoluteUrl } from "../_metronic/_helpers";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import BookOnlineIcon from '@material-ui/icons/Book';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import DescriptionIcon from '@material-ui/icons/Description';
import UpdateIcon from '@material-ui/icons/Update';
import BusinessIcon from '@material-ui/icons/Business';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

function AboutUs() {
  return (
    <div className="p-0 m-0" style={{ background: ' #f1f3f5' }}>
      <div
        className="container-fluid bg-dark d-flex flex-column justify-content-center align-items-center"
        style={{ height: "220px", borderRadius: "10px" }}
      >
        <h2 className="display-4 text-white mb-5"><DescriptionIcon className="mr-5 display-4 mb-2" />About Us</h2>
      </div>
      <div
        className="container bg-white p-12"
        style={{ width: "90%", marginTop: "-40px", borderRadius: "10px" }}
      >
        <div className="mb-8">
          <h2 className="mb-4 display-5">Who we are ?</h2>
          <p className="h5" style={{ color: "black" }}>A mix of creativity, technology and experience. The <span style={{ color: '#007669' }}>Bellaz</span> Management Team brings decades of diverse experience and a history of success. We have a leadership team built upon a foundation of expertise and experience. Combining business acumen with technical savvy our team guides our talented and experienced employees to create innovative solutions that enable customers to achieve ambitious results.</p>
          <p className="h5" style={{ color: "black" }}>Our mission is to support our clients to accomplish their goals by providing smart, sustainable and practical solutions through cutting edge technology.</p>
        </div>
        <div className="mb-8">
          <h2 className="mb-4 display-5">BELLAZ</h2>
          <p className="h5" style={{ color: "black" }}>In Spanish BELLAZ means BEAUTY.</p>
        </div>
        <div className="mb-8">
          <p className="h5" style={{ color: "black" }}>Our team identified an opportunity two years ago to digitize India's beauty industry. In order to fill the gap in the market, we connected users directly with salons, spas, and clinics. We strive to be India's largest aggregator and digital platform service provider for the beauty industry. We believe beauty is one meet away. </p>
        </div>
        <div className="mb-8">
          <p className="h5" style={{ color: "black" }}>BELLAZ will increase your revenue in no time!!!</p>
        </div>
        <div className="mb-8">
          <p className="h5" style={{ color: "black" }}>A saturated market is slowly but steadily developing in the beauty industry. Standing out requires pretty darn good work. In addition to providing a consistent client experience, improving your bottom line is also crucial. BELLAZ application is a fool proof way to accomplish this. Make your guests feel beautiful and happy or manage your end-to-end operations with BELLAZ application.</p>
          <p className="h5" style={{ color: "black" }}>Booking an appointment for beauty services with BELLAZ is as simple as three clicks for the potential customer. </p>
        </div>
        <div className="mb-8">
          <h2 className="mb-4 display-5" style={{ textAlign: 'center' }}>Our Leadership Team</h2>
        </div>
        <div className="container w-50">
          <div className="row mb-5" style={{justifyContent:'center'}}>
            <div className="col-sm-5 h-100 ">
              <div className="mb-5" style={{ overflow: 'hidden', backgroundImage: `url("/media/bellazTeam/1.jpg")`, backgroundPosition: 'top', backgroundSize: 'cover',height:'300px' }}>
                {/* <img src="/media/bellazTeam/1.jpg" class="w-100"/> */}
              </div>
              <h3 style={{ textAlign: 'center' }}>Ashish Sejpal</h3>
              <h6 style={{ textAlign: 'center', color: 'black' }}>Founder Director</h6>
            </div>
            <div className="col-sm-5 h-100  border-left-0">
              <div className="mb-5" style={{ overflow: 'hidden', backgroundImage: `url("/media/bellazTeam/2.jpg")`, backgroundPosition: 'top', backgroundSize: 'cover',height:'300px'  }}>
              {/* <img src="/media/bellazTeam/2.jpg" class="w-100"/> */}
              </div>
              <h3 style={{ textAlign: 'center' }}>Aarathi Sejpal</h3>
              <h6 style={{ textAlign: 'center', color: 'black' }}>Founder Director</h6>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4 h-100 ">
              <div className="mb-5" style={{ overflow: 'hidden', backgroundImage: `url("/media/bellazTeam/5.jpg")`, backgroundPosition: 'top', backgroundSize: 'cover',height:'300px'  }}>
              {/* <img src="/media/bellazTeam/5.jpg" class="w-100"/> */}
              </div>
              <h3 style={{ textAlign: 'center' }}>Megha Sejpal</h3>
              <h6 style={{ textAlign: 'center', color: 'black' }}>Beauty Consultant</h6>
            </div>
            <div  className="col-sm-4 h-100 ">
              <div className="mb-5" style={{ overflow: 'hidden', backgroundImage: `url("/media/bellazTeam/3.jpg")`, backgroundPosition: 'top', backgroundSize: 'cover',height:'300px'  }}>
              {/* <img src="/media/bellazTeam/3.jpg" class="w-100"/> */}
              </div>
              <h3 style={{ textAlign: 'center' }}>Prabhat Sharma</h3>
              <h6 style={{ textAlign: 'center', color: 'black' }}>Associate Director Projects</h6>
            </div>
            <div className="col-sm-4 h-100 0">
              <div className="mb-5" style={{  overflow: 'hidden', backgroundImage: `url("/media/bellazTeam/6.jpg")`, backgroundPosition: 'top', backgroundSize: 'cover',height:'300px'  }}>
              {/* <img src="/media/bellazTeam/6.jpg" class="w-100"/> */}
              </div>
              <h3 style={{ textAlign: 'center' }}>CA Hitesh Nathwani</h3>
              <h6 style={{ textAlign: 'center', color: 'black' }}>Finance Advisor</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
