import axios from "axios";

const URL = process.env.REACT_APP_API_URL + "/business";
const URL2 = process.env.REACT_APP_API_URL + "/userBranch";
const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const branchHoliday = (values) => {
  console.log(values);
  return axios.post(`${URL}/branch/holiday/create`, values);
};

export const getBranchHolidayList = () => {
  return axios.get(`${URL}/branch/holidays`);
};

export const deleteHoliday = (data) => {
  console.log(data);
  const req = {
    uuid: data,
  };
  return axios.delete(`${URL}/branch/holiday/delete`, {
    headers: {
      'Content-Type': 'application/json',
    },
    data: { "uuid": data }
  });
};

