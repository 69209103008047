import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import filterFactory from "react-bootstrap-table2-filter";

import { sortCaret } from "../../_metronic/_helpers";

import { StatusColumnFormatter } from "../../_metronic/_helpers/tableColumnFormatter";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadBranchList } from "../../store/business/branches/branchSlice";
import { capitalize } from "@material-ui/core";

export const CustomerStatusTitles = ["Suspended", "Active", "Pending", ""];

const LinkFormatter = (cell, row) => {
  return <Link to={`branch/${row.uuid}`}>{cell}</Link>;
};

export const BranchesTable = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.branch.branches);
  const [dataLoading, setDataLoading] = useState(true);
  useEffect(() => {
    dispatch(loadBranchList()).then(() => {
      setDataLoading(false);
    });
  }, []);

  const ActionDisplay = (cell, row) => {
    return (
      <Link to={`branch/${row.uuid}/addImage`}>
        <CloudUploadIcon />
      </Link>
    );
  };

  const capitalFirstLetter = (cell, row) => {
    return capitalize(cell);
  };
  //console.log(data);

  const columns = [
    {
      dataField: "name",
      text: "Branch Name",
      sort: true,
      sortCaret: sortCaret,
      formatter: LinkFormatter,
      // filter: textFilter(),
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "phone",
      text: "Phone",
    },
    {
      dataField: "city",
      text: "City",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "gender",
      text: "Type",
      sort: true,
      formatter: capitalFirstLetter,
      sortCaret: sortCaret,
    },
    {
      dataField: "isActive",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      formatter: StatusColumnFormatter,
    },
    {
      dataField: "",
      text: "Upload Image",
      formatter: ActionDisplay,
    },
  ];

  const { SearchBar, ClearSearchButton } = Search;

  return (
    <ToolkitProvider search keyField="uuid" data={data} columns={columns}>
      {(props) => (
        <>
          <div className="container">
            <div class="d-flex justify-content-center">
              {/* <div className="col-sm-4">
                <h4 className="mt-4" >Search</h4>
              </div> */}
              <SearchBar {...props.searchProps} />
              <ClearSearchButton
                {...props.searchProps}
                className="ml-4 mb-4 btn btn-secondary-light"
              />
            </div>
          </div>

          <BootstrapTable
            {...props.baseProps}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            wrapperClasses="table-responsive"
            bordered={false}
            bootstrap4
            loading={true}
            pagination={paginationFactory()}
            filter={filterFactory()}
            noDataIndication={
              <div className={"d-flex justify-content-center py-10"}>
                <h3>{dataLoading ? "Loading Data..." : "No records found"}</h3>
              </div>
            }
          />
        </>
      )}
    </ToolkitProvider>
  );
};
