import { createSlice } from "@reduxjs/toolkit";

import * as requestFromServer from "./branchApi";

const initialState = {
  branches: [],
  branch: {}
};

export const branchSlice = createSlice({
  name: 'branch',
  initialState,
  reducers: {
    updateList(state, action) {
      state.branches = action.payload;
    },
    created(state, action) {
      state.branches.push(action.payload);
    },
    updateBranch(state, action) {
      state.branch = action.payload;
    },
    updateBranchStatus(state, action) {
      state.branch.image.push(action.payload);
      const key = state.branch.image;
      // console.log(key);
      //state.branch.image
      // const holidayIndex = state.bookings.findIndex(
      //   (holiday) => holiday.branchUuid === action.payload
      // );
      // console.log(holidayIndex);
      // if (holidayIndex > -1) {
      //   state.bookings.splice(holidayIndex, 1);
      // }
    },
    updateBranchImageStatus(state, action) {
      // state.branch.image.push(action.payload);
      // const key = state.branch.image;
      // console.log(key);
      const imageIndex = state.branch.image.findIndex(
        (img) => img === action.payload
      );
      // console.log(imageIndex);
      if (imageIndex > -1) {
        state.branch.image.splice(imageIndex, 1);
      }
    },
  }
});

export const loadBranchList = () => {
  return async (dispatch) => {
    return requestFromServer
      .getBranchList()
      .then((response) => {
        // console.log(response.data);
        dispatch(branchSlice.actions.updateList(response.data.data));
      })
      .catch((err) => {
        alert(err.message);
      });
  }
}

export const loadBranch = (req) => {
  return async (dispatch) => {
    return requestFromServer
      .getBranch(req)
      .then((response) => {
        // console.log(response.data);
        dispatch(branchSlice.actions.updateBranch(response.data.data));
      })
      .catch((err) => {
        alert(err.message);
      });
  }
}

export const createBranch = (data) => {
  // console.log(data);
  return async (dispatch) => {
    return requestFromServer
      .createBranch(data)
      .then((response) => {
        dispatch(branchSlice.actions.created(response.data.data));
      })
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };
};

export const updateBranchImageData = (imageName) => {
  return async (dispatch) => {
    dispatch(branchSlice.actions.updateBranchStatus(imageName));
  };
};

export const updateBranchImageDeleteData = (imageName) => {
  return async (dispatch) => {
    dispatch(branchSlice.actions.updateBranchStatus(imageName));
  };
};