import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Formik, Form, Field, useFormikContext } from "formik";
import * as Yup from "yup";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Input } from "../../_metronic/_partials/controls";
import { ProfileUpdateData } from "../../store/business/authSlice";
import { branchProfileUpdateData } from "../../store/business/authSlice";
import { sendFailure, sendSuccess } from "../../store/toast/toastSlice";
import { addBranchPicture } from '../../store/business/branches/branchApi';
import {
  getBranch,
  updateBranch,
} from "../../store/business/branches/branchApi";
import { updateBranchImageData } from '../../store/business/branches/branchSlice';

export default function BranchBookingChairAndStuffEmptyDialog(props) {
  const [open, setOpen] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  console.log(open);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    history.push("/bookings/upcoming");
  };
  const Confirm = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {/* <DialogTitle id="alert-dialog-title">{'dsdsdsds'}</DialogTitle> */}
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.data} <br />
          {props.data2}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {/* <button onClick={Confirm} className="btn btn-light-success">
          Yes, Continue.
        </button> */}
        <button onClick={handleClose} className="btn btn-danger">
          Go back.
        </button>
      </DialogActions>
    </Dialog>
  );
}
