import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import {
  Input,
  Select,
  DatePickerField,
  Checkbox2,
} from "../../_metronic/_partials/controls";
import { CardBasic } from "../../components/CardBasic";

import {
  getCoupon,
  updateCoupone,
} from "../../store/business/coupon/couponApi";
import { loadBranchList } from "../../store/business/branches/branchSlice";
import { loadServiceList } from "../../store/business/services/servicesSlice";

export const CouponView = () => {
  const dispatch = useDispatch();
  const [couponData, setCouponData] = useState({});
  const [dataLoading, setDataLoading] = useState(true);
  const history = useHistory();
  const data = useSelector((state) => state.branch.branches);
  const dataService = useSelector((state) => state.service.services);

  const { couponUuid } = useParams();
  console.log(couponData);
  console.log("couponUuid");
  const req = {
    uuid: couponUuid,
  };
  useEffect(() => {
    dispatch(loadBranchList()).then(() => {
      setDataLoading(false);
    });
    dispatch(loadServiceList()).then(() => {
      setDataLoading(false);
    });
  }, []);

  useEffect(() => {
    getCoupon(req)
      .then((response) => {
        console.log(response);
        const data = response.data.data;
        console.log(data);
        setCouponData(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const validationSchema = Yup.object().shape({
    couponCode: Yup.string().required("This field is required"),
    // discount: Yup.number()
    //   .min(1)
    //   .required("This field is required")
    //   .when("discountType", {
    //     is: 1,
    //     then: Yup.number().max(100),
    //   }),
    description: Yup.string().required("This field is required"),
    discount: Yup.number()
      .min(1)
      .max(100)
      .required("This field is required"),
    startDate: Yup.string().required("This field is required"),
    expiryDate: Yup.string().required("This field is required"),
    minimumAmount: Yup.number()
      .min(1)
      .required("This field is required"),
  });

  return (
    <CardBasic
      title={"Edit Coupon"}
      btnType={"submit"}
      formId={"updateForm"}
      headerBtnText={"Update Coupon"}
    >
      <Formik
        initialValues={{
          couponCode: couponData.couponCode ?? "",
          discount: couponData.discount ?? "",
          startDate: couponData.startDate ? new Date(couponData.startDate) : "",
          expiryDate: couponData.expiryDate
            ? new Date(couponData.expiryDate)
            : "",
          minimumAmount: couponData.minimumAmount ?? "",
          description: couponData.description ?? "",
          singleUseCheck: couponData.singleUseCheck === 1 ? true : false,
          branchUuid: couponData.branchUuid ?? "",
          category: couponData.category ?? "",
          applicableOn: couponData.applicableOn ?? "service",
          quantity: couponData.quantity ?? "",
          loyaltyCredit: couponData.loyaltyCredit ?? "0",
          termAndConditions:couponData.termAndConditions,
        }}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          console.log(values);
          // if (values.singleUseCheck === false) {
          //   values.singleUseCheck = 0;
          // } else {
          //   values.singleUseCheck = 1;
          // }
          if (values.branchUuid === "") {
            delete values.branchUuid;
          }
          if (values.category === "") {
            delete values.category;
          }
          // console.log(values);
          updateCoupone(values, couponUuid)
            .then(() => {
              history.replace("/coupons");
            })
            .catch((err) => {
              //actions.setStatus(err.message);
              console.log(err.message);
            });
          //actions.setFormStatus("submitted");
        }}
      >
        {({ values, errors, setFieldValue }) => {
          //console.log(values);
          return (
            <Form className="form form-label-right" id="updateForm">
              <div className="form-group row">
                <div className="col-lg-6">
                  <Field
                    name="couponCode"
                    type="text"
                    component={Input}
                    placeholder="Coupon Code"
                    label="Coupon Code *"
                  />
                </div>
                <div className="col-lg-6">
                  <Field
                    name="discount"
                    type="number"
                    min="1"
                    component={Input}
                    placeholder="Discount"
                    label="Discount(%) *"
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-6">
                  <div>
                    <label>Start Date *</label>
                  </div>
                  <div className="w-100">
                    <DatePickerField
                      name="startDate"
                      className="form-control"
                      minDate={new Date()}
                      format="dd/mm/yyyy"
                      value={values.startDate}
                      onChange={(val) => {
                        setFieldValue("startDate", val);
                      }}
                    ></DatePickerField>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div>
                    <label>Expiry Date *</label>
                  </div>
                  <div className="w-100">
                    <DatePickerField
                      name="expiryDate"
                      className="form-control"
                      minDate={new Date()}
                      format="dd/mm/yyyy"
                      value={values.expiryDate}
                      onChange={(val) => {
                        setFieldValue("expiryDate", val);
                      }}
                    ></DatePickerField>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-6">
                  <Select name="branchUuid " label="Branch Name">
                    <option value={null}>All</option>
                    {data.length > 0 &&
                      data.map((el) => {
                        console.log(el);
                        return <option value={el.uuid}>{el.name}</option>;
                      })}
                  </Select>
                </div>
                <div className="col-lg-6">
                  <Select name="category" label="Category">
                    <option value={null}>All</option>
                    {dataService.length > 0 &&
                      dataService.map((el) => {
                        console.log(el);
                        return <option value={el.uuid}>{el.name}</option>;
                      })}
                  </Select>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-6">
                  <Select name="applicableOn" label="Applicable On">
                    {/* <option value={null}>All</option> */}
                    <option value="service">Service</option>
                    <option value="package">Package</option>
                    <option value="both">Both</option>
                  </Select>
                </div>
                <div className="col-lg-6">
                  <Field
                    name="quantity"
                    type="number"
                    min="1"
                    component={Input}
                    label="Quantity "
                  />
                </div>
              </div>
              <div className="form-group row">
                {/* <div className="col-lg-6">
              <Select name="applicableOn" label="Applicable On">
                  <option value='service'>Service</option>
                  <option value='package'>Package</option>
                  <option value='Both'>Both</option>
                </Select>
              </div> */}
                <div className="col-lg-6">
                  <Field
                    name="loyaltyCredit"
                    type="number"
                    min="0"
                    component={Input}
                    label="Loyalty Credit"
                  />
                </div>
                <div className="col-lg-6">
                  <Field
                    name="minimumAmount"
                    type="number"
                    min="1"
                    component={Input}
                    label="Minimum Amount *"
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-6">
                  <Checkbox2
                    name="singleUseCheck"
                    isSelected={values.singleUseCheck}
                    onChange={(val) => {
                      setFieldValue("singleUseCheck", val.target.checked);
                    }}
                    children={`Single Use Check   `}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-12">
                  <Field
                    name="description"
                    component={Input}
                    label="Description *"
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-12">
                <label>Terms & Conditions</label>
                  <Field
                    name="termAndConditions"
                    type="textarea"
                    // component={Input}
                    as="textarea"
                    rows="2"
                    label="Terms & Conditions"
                    className="form-control"
                  />
                </div>
              </div>
              {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
            </Form>
          );
        }}
      </Formik>
    </CardBasic>
  );
};
