import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, Select } from "../../_metronic/_partials/controls";

import { CardBasic } from "../../components/CardBasic";
import { TimePickerField } from "../../components/pickers/TimePickerField";
import { loadBranchList } from "../../store/business/branches/branchSlice";
import { createShift } from "../../store/business/shifts/shitftsSlice";
import { sendFailure, sendSuccess } from "../../store/toast/toastSlice";

export const ShiftCreate = () => {
  const [formStatus, setFormStatus] = useState("");
  const [dataLoading, setDataLoading] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const ValidationSchema = Yup.object().shape({
    shift_name: Yup.string().required("This field is required"),
    // branch_uuid: Yup.string().required("This field is required"),
    start_time: Yup.date().required("This field is required"),
    end_time: Yup.date()
      .min(Yup.ref("start_time"), "End Time must be greater than Start Time")
      .required("This field is required"),
    break1_start_time: Yup.date()
      .min(
        Yup.ref("start_time"),
        "Break start time must be between start time and end time"
      )
      .max(
        Yup.ref("break1_end_time"),
        "Break start time must be between start time and end time"
      )
      .required("This field is required"),

    break1_end_time: Yup.date()
      .min(
        Yup.ref("break1_start_time"),
        "Break end time must be between start time and end time"
      )
      .max(
        Yup.ref("end_time"),
        "Break end time must be between start time and end time"
      )
      .required("This field is required"),
  });

  const { branches } = useSelector((state) => state.branch);

  useEffect(() => {
    if (branches.length === 0) {
      dispatch(loadBranchList()).then(() => {
        setDataLoading(false);
      });
    }
  }, [branches]);

  return (
    <CardBasic
      title={"Add Shift"}
      btnType={"submit"}
      formId={"createShiftForm"}
      headerBtnText={"Save Shift"}
    >
      <Formik
        initialValues={{
          shift_name: "",
          // branch_uuid: branches.length > 0 ? branches[0].uuid : "",
          start_time: "",
          end_time: "",
          break1_start_time: "",
          break1_end_time: "",
        }}
        enableReinitialize
        validationSchema={ValidationSchema}
        onSubmit={(values, actions) => {
          console.log(values);
          dispatch(createShift(values))
            .then(() => {
              dispatch(sendSuccess("The shift has been created succesfully"));
              history.replace("/shifts");
            })
            .catch((err) => {
              dispatch(sendFailure(err.message));
              actions.setStatus("Error! Duplicate Chair Name.");
              console.log(err.message);
            });
        }}
      >
        {({ values, errors, setFieldValue }) => (
          <Form className="form form-label-right" id="createShiftForm">
            <div className="form-group row">
              <div className="col-12">
                <Field
                  name="shift_name"
                  component={Input}
                  placeholder="Shift Name"
                  label="Shift Name *"
                />
              </div>
            </div>
            {/* <div className="form-group row">
            <div className="col-lg-12">
              <Select name="branch_uuid" label="Branch *">
                {branches.map((branch, index) => (
                  <option value={branch.uuid} key={index}>
                    {`${branch.name} - ${branch.city}`}
                  </option>
                ))}
              </Select>
            </div>
          </div> */}
            <div className="form-group row">
              <div className="col-lg-6">
                <div>
                  <label> Shift Start Time *</label>
                </div>
                <div>
                  <TimePickerField name="start_time" className="form-control" />
                </div>
              </div>
              <div className="col-lg-6">
                <div>
                  <label> Shift End Time *</label>
                </div>
                <div>
                  <TimePickerField name="end_time" className="form-control" />
                </div>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-6">
                <div>
                  <label> Break Start Time *</label>
                </div>
                <div>
                  <TimePickerField
                    name="break1_start_time"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div>
                  <label> Break End Time *</label>
                </div>
                <div>
                  <TimePickerField
                    name="break1_end_time"
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </CardBasic>
  );
};
