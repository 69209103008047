import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
//import FuseLoading from '@fuse/core/FuseLoading';
import Typography from "@material-ui/core/Typography";
// import { resetCartState } from 'app/marketPlace/store/marketPlaceCartSlice';
import { getStripKey } from "../../store/business/subscriptionPlan/subscriptionPlanApi";
import CheckoutForm from "./checkOut";
import "./style.css";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_SRC);
  //console.log(process.env)
const CheckoutElement = () => {
 // console.log(stripePromise)
  const { id, du, code } = useParams();

  const [scrKey, setScrKey] = useState(" ");
  const [orderId, setOrderId] = useState(null);
  const [isFeatching, setIsFeatching] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    let val = {}
    //console.log(code)
    if (!code) {
      val = {
        subscriptionUuid: id,
        duration: du === "monthly" ? 1 : 12,
      }
    } else {
      val = {
        subscriptionUuid: id,
        duration: du === "monthly" ? 1 : 12,
        couponCode: code,
      }
    }
   // console.log(val)
    getStripKey(val)
      .then((response) => {
        //console.log(response.data.data.client_secret);
        setScrKey(response.data.data.client_secret);
        //setOrderId(response.orderResponse.id);
        //dispatch(resetCartState());
        setIsFeatching(false);
      })
      .catch((errorss) => {
        // return dispatch(
        //     showMessage({
        //         message: errorss, // text or html
        //         variant: 'error' // success error info warning null
        //     })
        // );
      });
  }, [id, du]);

  const appearance = {
    theme: "stripe",
  };

  const options = {
    // passing the client secret obtained from the server
    clientSecret: scrKey,
    appearance,
  };
  const healthProductDetails = () => {
    // cancelOrder(orderId)
    //   .then(response => {
    //     //console.log(response);
    //   })
    //   .catch(errorss => {
    //     // return dispatch(
    //     //     showMessage({
    //     //         message: errorss, // text or html
    //     //         variant: 'error' // success error info warning null
    //     //     })
    //     // );
    //   });
    //history.push({ pathname: `/order/${orderId}` });
    history.push({ pathname: `/dashboard` });
  };

  if (isFeatching) {
    return (
      <div
        className="h-screen"
        style={{
          display: "block",
          justifyContent: "center",
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translatey(-50%) translatex(-50%)",
        }}
      >
        {/* <FuseLoading className="my-96 mx-96" /> */}
        Loading ...
      </div>
    );
  }
  return (
    <div
      style={{
        display: "block",
        justifyContent: "center",
        textAlign: "center",
        borderRadius: "25px",
      }}
    >
      <p
        className="text-2xl font-bold leading-6"
        style={{ color: "rgba(21, 137, 152, 1)" }}
      >
        Make Payment
      </p>
      <Typography
        className="text-lg font-semibold leading-4 my-4"
        style={{ color: "rgb(68, 68, 68)" }}
      >
        Complete your payment
      </Typography>
      <Typography
        className="text-base font-normalleading-4"
        style={{ color: "rgb(68, 68, 68)", width: "65%", margin: "15px auto" }}
      >
        Note: Please do not press back button or close the screen until the
        payment is completed.
      </Typography>
      {scrKey && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm orderId={orderId} />
        </Elements>
      )}
      <button
        className="text-lg font-semibold leading-4 my-4 underline cursor-pointer"
        style={{ color: "#00534a", border: "none", background: "transparent" }}
        onClick={() => healthProductDetails()}
      >
        Cancel Order and Go Back
      </button>
    </div>
  );
};

export default CheckoutElement;
