import React from "react";
import {
  Page,
  Document,
  StyleSheet,
  Image,
  Text,
  View,
} from "@react-pdf/renderer";
import dayjs from "dayjs";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import InvoiceTable from "./InvoiceTable";

const styles = StyleSheet.create({
  page: {
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: "column",
    display: "flex",
  },
  logo: {
    width: 74,
    height: 66,
    marginLeft: "auto",
    marginRight: "auto",
  },
  main: {
    fontWeight: "bolder",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    padding: "6px",
  },
  img: {
    width: "23%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  header: {
    paddingTop: "10px",
    paddingBottom: "10px",
    borderTopWidth: 1,
    borderRightWidth: 1,
    borderLeftWidth: 1,
  },
});
const title = StyleSheet.create({
  head: {
    marginTop: 10,
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  reportTitle: {
    color: "#61dafb",
    letterSpacing: 4,
    fontSize: 25,
    textAlign: "center",
    textTransform: "uppercase",
  },
  heading: {
    fontWeight: "bold",
    fontSize: 15,
  },
  gst: {
    fontWeight: "bold",
    fontSize: 14,
  },
});

const Head = StyleSheet.create({
  invoiceNoContainer: {
    flexDirection: "row",
  },
  invoiceDateContainer: {
    flexDirection: "row",
  },
  invoiceNameContainer: {
    display: "flex",
    flexDirection: "row",
  },
  invoiceTimeContainer: {
    display: "flex",
    flexDirection: "row",
  },
  label: {
    marginRight: 5,
  },
});

const InvoiceMain = ({ invoice, user, currencySymbol }) => (
  <>
    {/* {console.log(currencySymbol)}
    {console.log(user)} */}
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          {/* {user.logo !== '' && <Image src={toAbsoluteUrl(`${process.env.REACT_APP_MEDIA_URL}/${user.logo}`)} style={styles.img} />} */}
          {/* <Image
          src={toAbsoluteUrl("/media/logos/logo-light.png")}
          style={styles.img}
        /> */}
          <View style={title.head}>
            <Text style={title.heading}>{user.name}</Text>
            <Text>{user.address_line1}
              {user.address_line2 != "" && (
                `,  ${user.address_line2}`
              )}
              {`, ${user.city} - ${user.zipcode}`}
            </Text>
            <Text>{`+91 ${user.phone}, ${user.email}`}</Text>
            {user.website != "" && <Text>{user.website}</Text>}
            {user.taxNo != "" && <Text>TAX NO: {user.taxNo}</Text>}
          </View>
        </View>

        <View style={styles.main}>
          <View>
            <View style={Head.invoiceNameContainer}>
              <Text style={Head.label}>Customer Name:</Text>
              <Text>{invoice?.customer?.name}</Text>
            </View>
            <View style={Head.invoiceNameContainer}>
              <Text style={Head.label}>Booking Id:</Text>
              <Text style={{ textTransform: "uppercase" }}>
                {invoice?.bookingId}
              </Text>
            </View>
          </View>
          <View>
            <View style={Head.invoiceDateContainer}>
              <Text style={Head.label}>Date:</Text>
              <Text>{dayjs(invoice?.date).format("DD-MM-YYYY")}</Text>
            </View>
            <View style={Head.invoiceTimeContainer}>
              <Text style={Head.label}>Time:</Text>
              <Text>{dayjs(invoice?.dateTime).format("hh:mm A")}</Text>
            </View>
          </View>
        </View>
        <InvoiceTable invoice={invoice} currencySymbol={currencySymbol} />
      </Page>
    </Document>
  </>
);

export default InvoiceMain;
