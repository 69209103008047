/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import CollectPaymentDialog from "../dialogs/CollectPaymentDialog";
import BookingStatusDialog from "../dialogs/BookingStatusDialog";
import RatingDialog from '../dialogs/RatingDialog';
import InvoiceDialog from '../dialogs/InvoiceDialog';
import { toAbsoluteUrl } from "../../_metronic/_helpers";
export const BookingDropdownMenu = (props) => {
  console.log(props);
  const bookingDetails = props.bookingDetails;
  const status = bookingDetails.status;
  const id = bookingDetails.uuid;
  const phone = bookingDetails.customer.phone;
  const rating = bookingDetails.CustomerRating ? bookingDetails.CustomerRating.length : 0;
  const { user, role } = useSelector((state) => state.authPersist);
  console.log(status);
  console.log(rating);
  return (
    <>
      {/* 
        0 => cancelled
        1 => pending
        2 => confirmed
        3 => present
        4 => complete
      */}

      <ul className="navi flex-column navi-hover py-2">
        {/* <li className="navi-item">
          <a href={`branchbooking/${id}`} className="navi-link">
            <span className="navi-text">
              <span className="navi-text">
                {role === "business" ? "View" : "View/Edit"}
              </span>
            </span>
          </a>
        </li> */}

        {status === 1 && (
          <>
            <li className="navi-item">
              <a href="#" className="navi-link">
                <span className="navi-text">
                  <BookingStatusDialog
                    uuid={bookingDetails.uuid}
                    status={2}
                    content={{
                      navigation: "Accept",
                      title: "ACCEPT BOOKING",
                      text: "Are you sure you want to confirm this action?",
                      successMessage: "Success! Booking Confirmed",
                      errorMessage: "Error! Please try again.",
                    }}
                  />
                </span>
              </a>
            </li>
            <li className="navi-item">
              <a href="#" className="navi-link">
                <span className="navi-text">
                  <BookingStatusDialog
                    uuid={bookingDetails.uuid}
                    status={0}
                    content={{
                      navigation: "Cancel",
                      title: "CANCEL BOOKING",
                      text: "Are you sure you want to confirm this action?",
                      successMessage: "Success! Booking Cancelled",
                      errorMessage: "Error! Please try again.",
                    }}
                  />
                </span>
              </a>
            </li>
          </>
        )}

        {status === 2 && (
          <>
            <li className="navi-item">
              <Link to={toAbsoluteUrl(`../booking/${id}`)} className="navi-link">
                <span className="navi-text">
                  <span className="navi-text">Reschedule</span>
                </span>
              </Link>
            </li>
            <li className="navi-item">
              <a href="#" className="navi-link">
                <span className="navi-text">
                  <BookingStatusDialog
                    uuid={bookingDetails.uuid}
                    status={3}
                    content={{
                      navigation: "Mark Present",
                      title: "BOOKING PRESENT",
                      text: "Are you sure you want to confirm this action?",
                      successMessage: "Success! Booking Present ",
                      errorMessage: "Error! Please try again.",
                    }}
                  />
                </span>
              </a>
            </li>
          </>
        )}

        {status === 4 && (
          <>
          <li className="navi-item">
            <a href="#" className="navi-link">
              <span className="navi-text">
                {/* <NavLink className="navi-text" target="_blank" to={`/invoice/${id}`}>
                  View Invoice
                </NavLink> */}
                <InvoiceDialog
                    id={id}
                    // status={3}
                    content={{
                      navigation: "View Invoice",
                      title: "BOOKING PRESENT",
                      text: "Are you sure you want to confirm this action?",
                      successMessage: "Success! Booking Present ",
                      errorMessage: "Error! Please try again.",
                    }}
                  />
              </span>
            </a>
          </li>
          {rating === 0 && <li className="navi-item">
              <a href="#" className="navi-link">
                <span className="navi-text">
                  <RatingDialog
                    uuid={bookingDetails.uuid}
                    status={2}
                    totalStars={5}
                    content={{
                      navigation: "Rate Customer",
                      title: "CUSTOMER RATING",
                      successMessage: "Success! Customer rating",
                      errorMessage: "Error! Please try again.",
                    }}
                  />
                </span>
              </a>
            </li>}
          </>
        )}

        {(status === 2 || status === 3) && (
          <li className="navi-item">
            <a href="#" className="navi-link">
              <span className="navi-text">
                <BookingStatusDialog
                  uuid={bookingDetails.uuid}
                  status={4}
                  content={{
                    navigation: "Mark Complete",
                    title: "MARK BOOKING COMPLETE",
                    text: "Are you sure you want to confirm this action?",
                    successMessage: "Success! Booking Complete ",
                    errorMessage: "Error! Please try again.",
                  }}
                />
              </span>
            </a>
          </li>
        )}

        {(status === 2 || status === 3 || status === 4) && (
          <>
            {(status === 3 || status === 4 && !bookingDetails.isPaid) && (
              <li className="navi-item">
                <a href="#" className="navi-link">
                  <span className="navi-text">
                    <CollectPaymentDialog content="Collect Payment" bookingDetails={bookingDetails} />
                  </span>
                </a>
              </li>
            )}
            <li className="navi-item">
              <a href={`tel:${phone}`} className="navi-link">
                <span className="navi-text">
                  <span className="navi-text">Call Customer</span>
                </span>
              </a>
            </li>
          </>
        )}
      </ul>
      {/*end::Navigation*/}
    </>
  );
};
