import { createSlice } from "@reduxjs/toolkit";

import * as requestFromServer from "./dataApi";

const initialState = {
  segments: [],
  serviceCategories: [],
};

export const dataSlice = createSlice({
  name: "dataSlice",
  initialState,
  reducers: {
    segmentList(state, action) {
      state.segments = action.payload;
    },
    serviceCategoryList(state, action) {
      state.serviceCategories = action.payload;
    },
  },
});

export const getSegmentData = () => {
  return async (dispatch) => {
    return requestFromServer
      .getSegments()
      .then((response) => {
        console.log(response.data);
        dispatch(dataSlice.actions.segmentList(response.data.data));
      })
      .catch((err) => {
        alert(err.message);
      });
  };
};

export const getServiceCategoryData = () => {
  return async (dispatch) => {
    return requestFromServer
      .getServiceCategories()
      .then((response) => {
        console.log(response.data);
        dispatch(dataSlice.actions.serviceCategoryList(response.data.data));
      })
      .catch((err) => {
        alert(err.message);
      });
  };
};