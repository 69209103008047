import axios from "axios";

const URL = process.env.REACT_APP_API_URL + "/branch";
const headers = {
  Accept: "application/json",
};

export const getCashDrawerList = (data) => {
  const req = {
    from: data.from,
    to: data.to,
  };
  return axios.post(`${URL}/cashDrawer/transaction/list`, req, {
    headers,
  });
};

