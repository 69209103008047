import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import filterFactory from "react-bootstrap-table2-filter";
import {
  StatusColumnFormatterPo,
  ActionColumnSimple,
} from "../../../_metronic/_helpers/tableColumnFormatter";
import { sortCaret } from "../../../_metronic/_helpers";
import paginationFactory from "react-bootstrap-table2-paginator";
import { loadExpenseTypeList } from "../../../store/business/expense/type/typeSlice";
import { loadPoList } from "../../../store/branch/po/poSlice";
import DeleteRaAndPoDialog from "../../../components/dialogs/DeleteRaAndPoDialog";

const TimeFormatter = (cell, row) => {
  return dayjs(cell).format("DD-MM-YYYY");
};

export const TypeTable = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.categoryType.type);
  console.log(data);
  const poList = useSelector((state) => state.po.po);
  console.log(poList);
  const branchHoliday = useSelector(
    (state) => state.branchHoliday.branchHoliday
  );
  const { role } = useSelector((state) => state.authPersist);

  const [dataLoading, setDataLoading] = useState(true);

  const LinkFormatter = (cell, row) => {
    //console.log(row);
    return <Link to={`/expense/po/${row.uuid}`}>{cell}</Link>;
  };

  const ActionDisplay = (cell, row) => {
    console.log(row);
    if (row.isPaid === false) {
      return <DeleteRaAndPoDialog content={<i className="flaticon-delete" style={{ cursor: "pointer" }} />} role="po" uuid={row.uuid} text='Are you sure you want to delete this PO ?' />
    } else {
      return null
    }
  };

  const DateFormatter = (cell, row) => {
    return dayjs(cell).format("DD-MM-YYYY");
  };

  useEffect(() => {
    dispatch(loadPoList()).then(() => {
      setDataLoading(false);
    });
  }, []);

  const columns = [
    {
      dataField: "",
      text: "#",
      formatter: (row, cell, index) => {
        return index + 1;
      },
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
      //formatter: LinkFormatter,
      sortCaret: sortCaret,
    },
    {
      dataField: "createdAt",
      text: "Date",
      formatter: DateFormatter,
    },
    {
      dataField: "remarks",
      text: "Remarks",
      sort: true,
      //formatter: LinkFormatter,
      sortCaret: sortCaret,
      //filter: textFilter(),
    },
    {
      dataField: "modeOfPayment",
      text: "Mode Of Payment",
      //formatter: DateFormatter,
    },
    {
      dataField: "ExpenseCategory[0].categoryName",
      text: "Expense Category",
      sort: true,
      sortCaret: sortCaret,
      //formatter: role == "business" ? "" : branchFormatter,
    },
    {
      dataField: "ExpenseType[0].typeName",
      text: "Expense Type",
      sort: true,
      sortCaret: sortCaret,
      //formatter: role == "business" ? "" : branchFormatter,
    },
    {
      dataField: "isPaid",
      text: "Paid",
      sort: true,
      sortCaret: sortCaret,
      formatter: StatusColumnFormatterPo,
    },
    {
      dataField: "",
      text: "delete",
      formatter: ActionDisplay,
      //sortCaret: sortCaret,
    },
  ];

  const { SearchBar, ClearSearchButton } = Search;

  return (
    <ToolkitProvider
      search
      keyField="id"
      data={poList}
      columns={columns}
    >
      {(props) => (
        <>
          <div className="container">
            <div class="row">
              <div className="col-sm-4">
                {/* <h4 className="mt-4" >Search</h4> */}
              </div>
              <div className="col-sm-8">
                <SearchBar {...props.searchProps} />
                <ClearSearchButton
                  {...props.searchProps}
                  className="ml-4 btn btn-secondary-light"
                />
              </div>
            </div>
          </div>

          <BootstrapTable
            {...props.baseProps}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            wrapperClasses="table-responsive"
            bordered={false}
            bootstrap4
            pagination={paginationFactory()}
            filter={filterFactory()}
            noDataIndication={
              <div className={"d-flex justify-content-center py-10"}>
                <h3>{dataLoading ? "Loading Data..." : "No records found"}</h3>
              </div>
            }
          />
        </>
      )}
    </ToolkitProvider>
  );
};
