import React from "react";
import { BranchesHolidayTable } from "./table";
import { CardBasic } from "../../components/CardBasic";

export function HolidayPage() {
  return (
    <CardBasic
      title={"Branch Holiday List"}
      btnType={"button"}
      headerBtnText={"Add Holiday"}
      headerBtnLink={"/holiday/create"}
    >
      <BranchesHolidayTable />
    </CardBasic>
  );
}