import React from "react";
import "./BellazMain.css";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
function BellazLastBanner() {
  return (
    <div className="container w-100 lastBanner-Main d-flex align-items-center justify-content-center align-self-center position-relative">
      <img
        className="img-fluid position-absolute flower12 d-none d-lg-flex"
        src={toAbsoluteUrl("/media/BellazMain/svg icons/flower-1.png")}
      />
      <img
        className="img-fluid position-absolute flower13 d-none d-lg-flex"
        src={toAbsoluteUrl("/media/BellazMain/svg icons/flower 2.png")}
      />
      <div className="row lastbanner-inner">
        <div className="col-12 col-lg-6 col-md-6 d-flex justify-content-center flex-column align-items-start lastbanner-inner-main">
        <p class="mb-4 display-4">
            One Step Solution for Salon and Spa Owners -
          </p>
          <span class="mb-4">
            Supercharge your business for free with the world's top booking
            platform for salons and spas. Independently voted no. 1 by industry
            professionals. Stop paying subs option fees and join the revolution!
          </span>
          {/* <div type="button" className="btn btn-primary font-weight-bolder text-uppercase card-btn-overlay pl-5 pr-5 rounded-pill">
            Learn More
          </div> */}
        </div>
  
          <img
            className="col-lg-6 col-md-6 banner-removeimg"
            src={toAbsoluteUrl("/media/BellazMain/Images/bg-pattern.png")}
            alt="banner-bg"
          />
     
      </div>
    </div>
  );
}

export default BellazLastBanner;
