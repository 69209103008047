import React from "react";
import { useSelector } from "react-redux";
import { Table } from "react-bootstrap";
import dayjs from "dayjs";

import { CardSecondary } from "../../components/CardSecondary";

export function BillingDetails(props) {
  const business = props.business;
  const { role, user } = useSelector((state) => state.authPersist);
  const { countryCode } = useSelector((state) => state.countryCode);
  const currencySymbol = countryCode.filter((el) => el.countryCode === user.countryCode);
  return (
    <CardSecondary
      title={"SUBSCRIPTION DETAILS"}
      btnType={"button"}
      headerBtnText={"CHANGE PLAN"}
      headerBtnLink={"/subscription/upgrade"}
      headerBtn2Text ={"TRANSACTIONS"}
      headerBtn2Link={"/transaction"}
    >
      <div className="row">
        <Table borderless>
          <tbody>
            <tr>
              <td width="300px">
                <h5>Subscription Plan:</h5>
              </td>
              <td>
                <span className="badge badge-primary font-size-lg">
                  {business.subscription.name}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <h5>Subscription Amount:</h5>
              </td>
              <td>
                <h5>{`(${currencySymbol[0] ? currencySymbol[0].currencySymbol : '₹'}) ${business.subscription.cost}`}</h5>
              </td>
            </tr>
            <tr>
              <td>
                <h5>Subscription Cycle:</h5>
              </td>
              <td>
                <h5 style={{textTransform: 'capitalize'}}>{business.subscription.cycle}</h5>
              </td>
            </tr>
            <tr>
              <td>
                <h5>Next Billing Date:</h5>
              </td>
              <td>
                <h5>
                  {dayjs(business.subscription.expiry).format("DD-MM-YYYY")}
                </h5>
              </td>
            </tr>
            <tr>
              <td>
                <h5>Subscription Status:</h5>
              </td>
              <td>
                <span className="badge badge-primary font-size-lg">{business.subscription.status.toUpperCase()}</span>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </CardSecondary>
  );
}
