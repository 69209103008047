import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Formik, Form, Field, useFormikContext } from "formik";
import * as Yup from "yup";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Input } from "../../_metronic/_partials/controls";
import { sendOtpAction } from "../../store/otp/otpSlice";
import {
  addBranchCustomer,
  getBranchCustomerDetails,
} from "../../store/business/bookings/bookingApi";
import { loadBranchCustomer } from "../../store/business/bookings/bookingSlice";
import { sendFailure, sendSuccess } from "../../store/toast/toastSlice";

const PhoneOtp = () => {
  const { values, touched, errors, setFieldValue } = useFormikContext();
  const [sendOtpLoader, setSendOtpLoading] = useState(false);
  const [otpState, setOtpState] = useState(false);
  const [otpResendState, setResendOtpState] = useState(false);
  const dispatch = useDispatch();

  const otpStateHandler = () => {
    setResendOtpState(true);
    setSendOtpLoading(false);
    setTimeout(() => {
      setResendOtpState(false);
    }, 30000);
  };

  const sendOtpHandler = () => {
    setSendOtpLoading(true);
    getBranchCustomerDetails({phone: values.phone})
      .then((response) => {
        console.log(response);
        if (response.data.message === "No customer found.") {
          dispatch(sendOtpAction(91, values.phone))
          .then(() => {
            setOtpState(true);
            otpStateHandler();
            dispatch(sendSuccess("OTP send success"))
            console.log("otp send success");
          })
          .catch((error) => {
            console.log(error.message);
            setSendOtpLoading(false);
          });
        } else {
          dispatch(sendFailure('Customer already exists. Kindly search the customer.'));
          setSendOtpLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="form-group row">
      <div className="col">
        <Field
          name="phone"
          component={Input}
          placeholder="9899898893"
          label="Contact Number *"
          //maxLength="10"
        />
        {touched.phone && !errors.phone ? (
          <button
            id="kt_login_signin_submit"
            type="button"
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
            disabled={otpResendState}
            onClick={sendOtpHandler}
          >
            <span>
              {otpState ? "Resend Registration Code" : "Send Registration Code"}
            </span>
            {sendOtpLoader && (
              <span className="ml-3 spinner spinner-white"></span>
            )}
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default function AddCustomerDialog({ content }) {
  const [open, setOpen] = useState(false);
  const [status, setstatus] = useState("");

  const dispatch = useDispatch();

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Minimum 3 characters are required")
      .required("This field is required"),

    email: Yup.string()
      .email("Wrong email format")
      .required("This field is required"),

    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(4, "Enter 4 digit phone number")
      .max(20, "Enter 20 digit phone number")
      .required("This field is required"),

    otp: Yup.string()
      .matches(phoneRegExp, "OTP is not valid")
      .min(6, "OTP must be of 6 digits")
      .max(6, "OTP must be of 6 digits")
      .required("Kindly enter VERIFICATION CODE sent to your phone"),

    // terms: Yup.bool().oneOf([true],"You must accept the terms and conditions"),
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <span className="navi-text" onClick={handleClickOpen}>
        {content}
      </span>

      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add Customer</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              name: "",
              email: "",
              phone: "",
              otp: "",
              // terms: false,
            }}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              console.log(values);
              addBranchCustomer(values)
                .then((customer) => {
                  dispatch(loadBranchCustomer(values))
                    .then((response) => {
                      console.log(response);
                      if (response != "") {
                        setstatus("");
                        setOpen(false);
                      } else {
                        setstatus(
                          "Customer not found. Kindly check the registered phone number."
                        );
                      }
                    })
                    .catch((err) => {
                      actions.setStatus(err.message);
                      console.log(err.message);
                    });
                })
                .catch((err) => {
                  actions.setStatus(err.response.data.message);
                  console.log(err.response.data.message);
                });
              actions.setFormStatus("submitted");
            }}
          >
            {({ values, errors, touched, status }) => (
              <Form className="form form-label-right" id="createBranchForm">
                {status && (
                  <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                    <div className="alert-text font-weight-bold">{status}</div>
                  </div>
                )}
                <div className="form-group row">
                  <div className="col">
                    <Field
                      name="name"
                      component={Input}
                      placeholder="Name"
                      label="Name *"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col">
                    <Field
                      name="email"
                      type="email"
                      component={Input}
                      placeholder="example@domain.com"
                      label="Email Address *"
                    />
                  </div>
                </div>
                <PhoneOtp />
                <div className="form-group row">
                  <div className="col">
                    <Field
                      name="otp"
                      component={Input}
                      placeholder="Enter verification code sent on customer's phone"
                      maxLength="6"
                      label="Registration Code *"
                    />
                  </div>
                </div>
                {/* <div className="form-group row">
                    <div className="col-lg-12 checkbox-inline">
                      <label className="checkbox">
                        <Field type="checkbox" name="terms" />
                        <span></span>
                        <Link
                          to="/terms"
                          target="_blank"
                          className="ml-3"
                          rel="noopener noreferrer"
                        >
                        I agree the Terms & Conditions
                        </Link>
                      </label>
                    </div>
                  </div> */}
                <div className="form-group row">
                  <div className="col">
                    <button
                      type="submit"
                      className="btn btn-light-success float-right"
                    >
                      Add Customer
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}
