import { createSlice } from "@reduxjs/toolkit";

import * as requestFromServer from "./branchScheduleApi";

const initialState = {
  branchSchedule: [],
};

export const branchScheduleSlice = createSlice({
  name: "branchSchedule",
  initialState,
  reducers: {
    updateList(state, action) {
      state.branchSchedule = action.payload;
    },
    created(state, action) {
      state.branchSchedule.push(action.payload);
    },
  },
});

export const loadBranchScheduleList = () => {
  return async (dispatch) => {
    return requestFromServer
      .getBranchScheduleList()
      .then((response) => {
        dispatch(branchScheduleSlice.actions.updateList(response.data.data));
      })
      .catch((err) => {
        alert(err.message);
      });
  };
};

export const createSchedulBranchData = (data) => {
  let arr = [];
  data.staff.map((key) => {
    arr.push(key.value);
  });
  data.staff = arr;
  console.log(data);
  return async (dispatch) => {
    return requestFromServer
      .createBranchSchdeule(data)
      .then((response) => {
        dispatch(branchScheduleSlice.actions.created(response.data.data));
      })
      .catch((error) => {
        throw new Error(error.response.data.error);
      });
  };
};
