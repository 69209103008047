import axios from "axios";

const URL = process.env.REACT_APP_API_URL + "/business";
const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const getSubscriptionList = () => {
  return axios.get(`${URL}/guest/subscription/list`);
};
