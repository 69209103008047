import { createSlice } from "@reduxjs/toolkit";

import * as requestFromServer from "./bookingApi";

const initialState = {
  currentCustomer: "",
  bookings: [],
};

export const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    updateCurrentCustomer(state, action) {
      state.currentCustomer = action.payload;
    },
    updateList(state, action) {
      state.bookings = action.payload;
    },
    updateBooking(state, action) {
      console.log("action.payload",action.payload)
      const bookingIndex = state.bookings.findIndex(
        (booking) => booking.uuid === action.payload?.bookingUuid
      );
        console.log("bookingIndex",bookingIndex)
      if (bookingIndex > -1) {
         state.bookings[bookingIndex].paidAmount =  state.bookings[bookingIndex]?.paidAmount + action?.payload?.amount ;
     
      }

     
    },
    updateStatus(state, action) {
      const bookingIndex = state.bookings.findIndex(
        (booking) => booking.uuid === action.payload.uuid
      );
      // console.log(bookingIndex);
      state.bookings[bookingIndex].status = action.payload.status;
    },
    updateRating(state, action) {
      const bookingIndex = state.bookings.findIndex(
        (booking) => booking.uuid === action.payload.uuid
      );
      state.bookings[bookingIndex].CustomerRating = action.payload.CustomerRating;
    },
    created(state, action) {
      state.bookings.push(action.payload);
    },
    resetBooking(state,action){

      // state.bookings = [];
      state.currentCustomer="";
      console.log("state bookings",state)
    }
  },
});


export const loadCustomer = (data) => {
  return async (dispatch) => {
    return requestFromServer
      .getCustomerDetails(data)
      .then((response) => {
        //console.log(response.data);
        dispatch(
          bookingSlice.actions.updateCurrentCustomer(response.data.data)
        );
        return response.data.data;
      })
      .catch((err) => {
        alert(err.message);
      });
  };
};

export const loadWalkingCustomer = (data) => {
  return async (dispatch) => {
    return requestFromServer
      .getBranchCustomerDetails(data)
      .then((response) => {
        // console.log(response.data);
        dispatch(
          bookingSlice.actions.updateCurrentCustomer(response.data.data)
        );
        return response.data.data;
      })
      .catch((err) => {
        alert(err.message);
      });
  };
};

export const createBooking = (data) => {
  return async (dispatch) => {
    return requestFromServer
      .createBooking(data)
      .then((response) => {
        dispatch(bookingSlice.actions.created(response.data.data));
      })
      .catch((error) => {
        throw new Error(error.response.data.error);
      });
  };
};

export const updateBookingStatus = (data) => {
  return async (dispatch) => {
    // console.log(data);
    dispatch(bookingSlice.actions.updateStatus(data));
  };
};

export const updateCustomerRating = (uuid, CustomerRating) => {
  return async (dispatch) => {
    dispatch(bookingSlice.actions.updateRating({uuid, CustomerRating}));
  };
};

export const loadBookingList = (from, to, bookingStatus) => {
  return async (dispatch) => {
    return requestFromServer
      .getBookingsList(from, to, bookingStatus)
      .then((response) => {
        console.log(response.data);
        dispatch(bookingSlice.actions.updateList(response.data.data));
      })
      .catch((err) => {
        alert(err.message);
      });
  };
};

export const loadBranchCustomer = (data) => {
  return async (dispatch) => {
    return requestFromServer
      .getBranchCustomerDetails(data)
      .then((response) => {
        // console.log(response.data);
        dispatch(
          bookingSlice.actions.updateCurrentCustomer(response.data.data)
        );
        return response.data.data;
      })
      .catch((err) => {
        alert(err.message);
      });
  };
};

export const createBranchBooking = (data) => {
  return async (dispatch) => {
    return requestFromServer
      .createBranchBooking(data)
      .then((response) => {
        console.log("create thunk api call",response.data.data);
        dispatch(bookingSlice.actions.created(response.data.data));
      })
      .catch((error) => {
        console.log(error);
        throw new Error(error.response.data.data);
      });
  };
};

export const updateBranchBookingData = (data) => {
  return async (dispatch) => {
    return requestFromServer
      .updateBranchBooking(data)
      .then((response) => {
        dispatch(bookingSlice.actions.updateBooking(response.data.data));
        // console.log(response.data.data);
      })
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };
};

export const loadBranchBookingList = (from, to, bookingStatus) => {
  return async (dispatch) => {
    return requestFromServer
      .getBranchBookingsList(from, to, bookingStatus)
      .then((response) => {
        // console.log(response.data);
        dispatch(bookingSlice.actions.updateList(response.data.data));
      })
      .catch((err) => {
        alert(err.message);
      });
  };
};

export const collectPayment = (data) => {
  return async (dispatch) => {
    return requestFromServer
      .createCollectPayment(data)
      .then((response) => {
        // console.log(response.data);
        console.log("update collect payment", response.data.data)
        dispatch(bookingSlice.actions.updateBooking(response.data.data));
        
        return response.data.data;
      })
      .catch((err) => {
        console.log("error",err)
        // alert(err.message);
      });
  };
};


export const {
  updateCurrentCustomer,
  updateList,
  updateBooking,
  updateStatus,
  updateRating,
  created,
  resetBooking,
} = bookingSlice.actions;