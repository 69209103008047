import { createSlice } from "@reduxjs/toolkit";

import * as requestFromServer from "./branchHolidayApi";

const initialState = {
  branchHoliday: [],
};

export const branchHolidaySlice = createSlice({
  name: "branchHoliday",
  initialState,
  reducers: {
    updateList(state, action) {
      state.branchHoliday = action?.payload;
    },
    created(state, action) {
      state.branchHoliday.push(action?.payload);
    },
  },
});

export const loadBranchHolidayList = (from, to) => {
  return async (dispatch) => {
    return await requestFromServer
      .getBranchHolidayList(from, to)
      .then((response) => {
        console.log(response);
        if(response.data.message == 'No Staff Holiday found.') {
          dispatch(branchHolidaySlice.actions.updateList([]));
        } else {
          dispatch(branchHolidaySlice.actions.updateList(response?.data?.data));
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };
};
