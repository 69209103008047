import React, { useEffect, useState } from "react";
import { Formik, Form, validateYupSchema } from "formik";
import * as Yup from "yup";
import { DatePickerField, Select } from "../../_metronic/_partials/controls";
import { CardBasic } from "../../components/CardBasic";
import { TimePickerField } from "../../components/pickers/TimePickerField";
import { MultiSelect } from "../../_metronic/_partials/controls/forms/MultiSelect";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { loadStaffList } from "../../store/business/staff/staffSlice";
import {
  createBranchScheduleData,
  createScheduleData,
} from "../../store/business/schedule/scheduleSlice";
import { sendFailure, sendSuccess } from "../../store/toast/toastSlice";
import { loadBranchStaffList } from "../../store/branch/branchStaffSlice";
import { createBranchSchdeule } from "../../store/branch/branch_schedule/branchScheduleApi";
import { createSchedulBranchData } from "../../store/branch/branch_schedule/branchScheduleSlice";

export const ScheduleCreate = () => {
  const ScheduleCreateSchema = Yup.object().shape({
    staff: Yup.string().required("This field is required"),
    start_date: Yup.date().required("This field is required"),
    end_date: Yup.date()
      .min(Yup.ref("start_date"), "End Date must be greater than Start Date")
      .required("This field is required"),
    start_time: Yup.date().required("This field is required"),
    end_time: Yup.date()
      .min(Yup.ref("start_time"), "End Time must be greater than Start Time")
      .required("This field is required"),
    break_start_time: Yup.date()
      .min(
        Yup.ref("start_time"),
        "Break start time must be between start time and end time"
      )
      .max(
        Yup.ref("break_end_time"),
        "Break start time must be between start time and end time"
      )
      .required("This field is required"),

    break_end_time: Yup.date()
      .min(
        Yup.ref("break_start_time"),
        "Break end time must be between start time and end time"
      )
      .max(
        Yup.ref("end_time"),
        "Break end time must be between start time and end time"
      )
      .required("This field is required"),
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const data = useSelector((state) => state.branchStaff.branchStaff);

  const { role } = useSelector((state) => state.authPersist);
  const [dataLoading, setDataLoading] = useState(true);

  useEffect(() => {
    if (role == "business") {
      dispatch(loadStaffList()).then(() => {
        setDataLoading(false);
      });
    } else {
      dispatch(loadBranchStaffList()).then(() => {
        setDataLoading(false);
      });
    }
  }, []);

  return (
    <CardBasic
      title={"Add Schedule"}
      btnType={"submit"}
      formId={"createScheduleForm"}
      headerBtnText={"Save Schedule"}
    >
      <Formik
        initialValues={{
          staff: "",
          start_date: "",
          end_date: "",
          start_time: "",
          end_time: "",
          break_start_time: "",
          break_end_time: "",
        }}
        validationSchema={ScheduleCreateSchema}
        onSubmit={(values, actions) => {
          console.log(values);
          dispatch(createSchedulBranchData(values))
            .then(() => {
              dispatch(sendSuccess("The schedule is created successfully"));
              history.replace("/schedule");
            })
            .catch((err) => {
              dispatch(sendFailure(err.message));
              actions.setStatus("Error! Duplicate Chair Name.");
              console.log(err.message);
            });
        }}
      >
        {({ values, errors }) => (
          <Form className="form form-label-right" id="createScheduleForm">
            <div className="form-group row">
              <div className="col-lg-12">
                <MultiSelect
                  label="Staff *"
                  name="staff"
                  options={data.map((staff, index) => ({
                    value: staff.uuid,
                    label: staff.name,
                  }))}
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-6">
                <div>
                  <label> Applicable From *</label>
                </div>
                <div>
                  <DatePickerField
                    name="start_date"
                    className="form-control"
                    minDate={new Date()}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div>
                  <label> Applicable Till *</label>
                </div>
                <div>
                  <DatePickerField name="end_date" className="form-control" minDate={new Date()} />
                </div>
              </div>
            </div>

            <div className="form-group row">
              <div className="col-lg-6">
                <div>
                  <label> Schedule Start Time *</label>
                </div>
                <div>
                  <TimePickerField name="start_time" className="form-control" />
                </div>
              </div>
              <div className="col-lg-6">
                <div>
                  <label> Schedule End Time *</label>
                </div>
                <div>
                  <TimePickerField name="end_time" className="form-control" />
                </div>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-6">
                <div>
                  <label> Break Start Time *</label>
                </div>
                <div>
                  <TimePickerField
                    name="break_start_time"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div>
                  <label> Break End Time *</label>
                </div>
                <div>
                  <TimePickerField
                    name="break_end_time"
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </CardBasic>
  );
};
