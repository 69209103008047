import { createSlice } from "@reduxjs/toolkit";

import * as requestFromServer from "./customerApi";

const initialState = {
  user: [],
};

export const customerSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateList(state, action) {
      state.user = action.payload;
    },
    created(state, action) {
      state.user.push(action.payload);
    },
  },
});

export const loadUserList = () => {
  return async (dispatch) => {
    return requestFromServer
      .getCustomerList()
      .then((res) => {
        dispatch(customerSlice.actions.updateList(res.data.data));
        console.log(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
      });
  };
};
