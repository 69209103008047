import React from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";

import { authActions } from "../../../../store/business/authSlice";

const Logout = () => {
  const dispatch = useDispatch();
  console.log('here')
  dispatch(authActions.logout());

  return <Redirect to="/auth/login" />;
};

export default Logout;
