import { createSlice } from "@reduxjs/toolkit";

import * as requestFromServer from "./categoryApi";

const initialState = {
  categories: [],
};

export const categorySlice = createSlice({
  name: "serviceCategory",
  initialState,
  reducers: {
    updateCategoryList(state, action) {
      state.categories = action.payload;
    },
    created(state, action) {
      state.categories.push(action.payload);
    },
  },
});

export const getCategoryList = () => {
  return async (dispatch) => {
    return requestFromServer
      .getCategoryList()
      .then((response) => {
        console.log(response.data);
        dispatch(categorySlice.actions.updateCategoryList(response.data.data));
      })
      .catch((err) => {
        alert(err.message);
      });
  };
};

export const createCategory = (data) => {
  return async (dispatch) => {
    return requestFromServer
      .createCategory(data)
      .then((response) => {
        dispatch(categorySlice.actions.created(response.data.data));
      })
      .catch((error) => {
        throw new Error(error.response.data.error);
      });
  };
};
