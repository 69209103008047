import axios from "axios";

const URL = process.env.REACT_APP_API_URL + "/branch";
const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const getPoList = () => {
  return axios.get(`${URL}/voucher/PO/list`);
};

export const createPo = (data) => {
  return axios.post(`${URL}/voucher/PO/create`, data);
};

export const getPo = async (req) => {
  return await axios.post(`${URL}/voucher/PO/view`, req);
};

export const updatePo = (data) => {
  console.log(data);
  return axios.patch(`${URL}/voucher/PO/update`, data);
};

export const deletePo = (data) => {
  console.log(data);
  const req = {
    voucherUuid: data,
  };
  return axios.delete(`${URL}/voucher/PO/delete`, {
    headers: {
      'Content-Type': 'application/json',
    },
    data: { "voucherUuid": data }
  });
};
