import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import filterFactory from "react-bootstrap-table2-filter";
import dayjs from "dayjs";
import { sortCaret } from "../../_metronic/_helpers";
import DeleteIcon from "@material-ui/icons/Delete";
import { StatusColumnFormatter } from "../../_metronic/_helpers/tableColumnFormatter";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadLoyaltyPointsList } from "../../store/business/loyaltyPoints/loyaltyPointsSlice";
import BranchHolidayDialog from "../../components/dialogs/BranchHolidayDialog";

export const CustomerStatusTitles = ["Suspended", "Active", "Pending", ""];

const LinkFormatter = (cell, row) => {
  return <Link to={`branch/${row.uuid}`}>{cell}</Link>;
};

export const LoyaltyPointsTable = () => {
  const [copy, setCopy] = useState(false);
  const dispatch = useDispatch();

  const data = useSelector((state) => state.loyaltyPointsL.loyaltyPoints);
  const walletDetails = useSelector(
    (state) => state.loyaltyPointsL.Wallet_Details
  );
  //console.log(user);
  //console.log(walletDetails);

  const [dataLoading, setDataLoading] = useState(true);
  useEffect(() => {
    dispatch(loadLoyaltyPointsList()).then(() => {
      setDataLoading(false);
    });
  }, []);

  const DateFormatter = (cell, row) => {
    return dayjs(cell).format("DD-MM-YYYY");
  };

  const TypeFormatter = (cell, row) => {
    if (row.type === "debit") {
      return (
        <p>
          <span
            style={{
              color: "red",
              fontWeight: "700",
              fontSize: "18px",
              marginRight: "5px",
            }}
          >
            -
          </span>
          {cell}
        </p>
      );
    }
    return (
      <p>
        <span
          style={{ fontWeight: "700", fontSize: "18px", marginRight: "5px" }}
        >
          +
        </span>
        {cell}
      </p>
    );
  };

  const ActionDisplay = (cell, row) => {
    return (
      <BranchHolidayDialog
        content={
          <i className="flaticon-delete" style={{ cursor: "pointer" }} />
        }
        role="business"
        uuid={row.uuid}
      />
    );
  };

  const myFunction = (data) => {
    //console.log(data);
    // Get the text field
    var copyText = document.getElementById("myInput");

    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text inside the text field
    navigator.clipboard.writeText(copyText.value);
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 3000);
    // Alert the copied text
    // alert("Copied the text: " + copyText.value);
  };

  const columns = [
    {
      dataField: "",
      text: "#",
      formatter: (cell, row, index) => index + 1,
    },
    {
      dataField: "createdAt",
      text: "Date",
      sort: true,
      sortCaret: sortCaret,
      formatter: DateFormatter,
      // filter: textFilter(),
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
      formatter: TypeFormatter,
      sortCaret: sortCaret,
    },
    // {
    //   dataField: "type",
    //   text: "Type",
    //   sort: true,
    //   //formatter: LinkFormatter,
    //   sortCaret: sortCaret,
    // },
    {
      dataField: "remarks",
      text: "Remarks",
      sort: true,
      //formatter: LinkFormatter,
      sortCaret: sortCaret,
    },
  ];

  const { SearchBar, ClearSearchButton } = Search;

  return (
    <>
      <div class="container" style={{ backgroundColor: "#007669" }}>
        <div class="row align-items-center" style={{ height: "200px" }}>
          <div class="col d-flex">
            <img src={toAbsoluteUrl("/media/BellazMain/startLogo.png")} />
            <div style={{ margin: "auto 5px" }}>
              <h1 style={{ color: "white", margin: "auto" }}>
                Loyalty Points{" "}
              </h1>
              <h1
                style={{
                  width: "80px",
                  //padding: "8px",
                  //backgroundColor: "#F3F6F9",
                  // borderRadius: "5px",
                  marginLeft: "10px",
                  color: "rgb(180 234 228)",
                  textAlign: "center",
                  margin: "10px auto",
                }}
              >
                {walletDetails !== null 
                  ? walletDetails
                  : "0"}
              </h1>
            </div>
          </div>
          <div class="col"></div>
          <div class="col">
            <div class="float-right text-center">
              <h1 className="mb-5" style={{ color: "#aac8c4" }}>
                Invite your friends
              </h1>
              <h4 className="mb-5" style={{ color: "#97c2bd" }}>
                Get 50 loyalty points share this code below
              </h4>
              <input
                className="mb-5"
                style={{
                  border: "none",
                  backgroundColor: "#007669",
                  color: "white",
                  fontSize: "medium",
                  width: "100px",
                }}
                type="text"
                disabled
                value="5XDw24Wd"
                id="myInput"
              />
              <button
                onClick={() => {
                  myFunction("Hello World");
                }}
                style={{ border: "none", backgroundColor: "#007669" }}
              >
                {/* <span className={`svg-icon svg-icon-xl svg-icon-success ml-n2`}>
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/custom/copy-icon.svg")}
                  />
                </span>
                <span></span> */}
                <i
                  className="far fa-clone"
                  style={{ cursor: "pointer", color: "rgb(180 234 228)" }}
                />
              </button>
              {copy && <span style={{ color: "white" }}>Copied!</span>}
            </div>
          </div>
        </div>
      </div>
      <ToolkitProvider search keyField="uuid" data={data} columns={columns}>
        {(props) => (
          <>
            <BootstrapTable
              {...props.baseProps}
              classes="table table-head-custom table-vertical-center overflow-hidden"
              wrapperClasses="table-responsive"
              bordered={false}
              bootstrap4
              loading={true}
              pagination={paginationFactory()}
              //filter={filterFactory()}
              noDataIndication={
                <div className={"d-flex justify-content-center py-10"}>
                  <h3>
                    {dataLoading ? "Loading Data..." : "No records found"}
                  </h3>
                </div>
              }
            />
          </>
        )}
      </ToolkitProvider>
    </>
  );
};
