import React from "react";
import "./BellazMain.css";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

const data = [
  {
    name: "Address",
    img: "/media/BellazMain/location.png",
    text: "B5/6,Suphala Coop Society, 1st Floor, Near Joker Plaza, Kalyan West 421301, Maharashtra, India",
  },
  {
    name: "Email Id",
    img: "/media/BellazMain/message.png",
    text: "Support@my-bellaz.com",
  },
  {
    name: "Phone Number",
    img: "/media/BellazMain/message.png",
    text: "9706067373",
  },
];
function BellazForm() {
  return (
    <div className="container-fluid w-100 p-0 form-start d-flex align-items-end">
      <div className="container-fluid w-100 p-0 m-0 d-flex flex-column text-center align-items-center justify-content-around form-Main">
        <div className="container form-header justify-content-center align-items-center d-flex flex-column">
          <p className="text-black display-4 mb-5">Get In Touch</p>
          <h6 className=" font-weight-light text-black-50">
            We are eager to discuss your business needs, and answer any
            questions may have. Enter your details and we'll get back to you
            shortly.
          </h6>
        </div>
        <div className="row w-100 align-items-center text-center p-0 container-fluid form-remove-img w-100">
          <img
            className="col-12 col-lg-2 col-md-2 p-0 remove-img"
            src={toAbsoluteUrl("/media/BellazMain/Images/pattern1.png")}
            alt=""
          />
          <div className="row p-0 m-0 col-12 col-lg-8 col-md-8 d-flex flex-row justify-content-center">
            <div className="row p-0 d-flex m-0 form-width">
              <div className=" p-0 col-12 col-lg-5 col-md-5 d-flex flex-column align-items-center detail-avatar m-0">
                {data.map((_data) => (
                  <div className="d-flex flex-row text-left p-0 justify-content-lg-center mb-10">
                    <img
                      className="review-card p-0 m-0"
                      src={toAbsoluteUrl(_data.img)}
                      alt=""
                    />
                    <div className="ml-4 d-flex flex-column text-left align-items-start p-0 mt-1 form-location">
                      <p className="p-0 m-0 text-black-50 h6"><strong>{_data.name}</strong></p>
                      <p className="p-0 m-0 text-black-50">{_data.text}</p>
                    </div>
                  </div>
                ))}
              </div>

              <form className="col-12 col-lg-7 col-md-7 p-0 m-0">
                <div class="d-flex justify-content-around flex-column w-100 form-inner align-items-center">
                  <div class="w-100 mb-4 col-10 col-lg-12 col-md-8 col-sm-8">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="NAME"
                    />
                  </div>
                  <div class="col mb-4 col-10 col-lg-12 col-md-8 col-sm-8">
                    <input
                      type="email"
                      class="form-control"
                      placeholder="EMAIL ADDRESS"
                    />
                  </div>
                  <div class="col sel-last mb-4 col-10 col-lg-12 col-md-8 col-sm-8">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="WRITE COMMENT"
                    />
                  </div>

                  <button
                    type="button"
                    className="btn col-5 col-md-4 col-sm-4 col-lg-4 btn-primary text-uppercase align-self-lg-baseline align-self-md-center form-submit-btn pl-3"
                  >
                    Submit now
                  </button>
                </div>
              </form>
            </div>
          </div>
          <img
            src={toAbsoluteUrl("/media/BellazMain/images/pattern1.png")}
            alt=""
            className="form-leaf-right right-align img-fluid col-12 col-lg-2 col-md-2 p-0 remove-img"
          />
        </div>
      </div>
    </div>
  );
}

export default BellazForm;
