export const segmentData = [
  {
    id: "1",
    name: "Spa",
    segmentUuid: "96d4db30-8e1f-4b78-98fa-698d0a2b5ab3",
    imageUrl: "https://images.pexels.com/photos/3757942/pexels-photo-3757942.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "2",
    name: "Clinic",
    segmentUuid: "96d4db30-8e1f-4b78-98fa-678d0a2b5ab3",
    imageUrl: "https://images.pexels.com/photos/4386466/pexels-photo-4386466.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260",
  },
  {
    id: "3",
    name: "Salon",
    segmentUuid: "96d4db30-8e1f-4b78-98fa-688d0a2b5ab3",
    imageUrl: "https://images.pexels.com/photos/705255/pexels-photo-705255.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260 ",
  },
];
