import React from "react";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import HeaderLink from "./component/HeaderLink";
import MenuIcon from "@material-ui/icons/Menu";
import "./BellazMain.css";
import StoreButton from "./component/Button";
function BellazBanner() {
  return (
    <div className="p-0 banner-main position-relative bg-white">
      <div class="row w-100 p-0 m-0 main-banner">
        <img
          className="col-lg-2 col-md-2 col-sm-2 align-self-center p-0 banner-leaf-img w-100 banner-person-img"
          src={toAbsoluteUrl("/media/BellazMain/Images/pattern1.png")}
          alt=""
        />
        <div class="col-lg-5 col-md-5 col-sm-5 align-self-center">
          <div className="border-0">
            <div style={{ width: '80%', margin: "0 auto" }}>
              <img
                style={{ width: "75%" }}
                src={toAbsoluteUrl("/media/logos/goldenLogo.png")}
                alt="bellaz logo"
              />
            </div>
            {/* <h6 className="h2" style={{ marginTop: '-5px', marginLeft: '37%', fontWeight: '700'}}>
              Beauty is one meet away ...!
            </h6> */}
            <h1 className="mb-lg-5 display-3 pr-lg-10 pr-0 font-italic mt-lg-5" style={{color: "black"}}>
              Instantly book salons and spa nearby
            </h1>
            {/* <p className="mb-lg-5 h5 pr-lg-10 pr-0 font-italic" style={{color: "#959595"}}>
              Together creeping heaven upon third dominions be upon won't
              darkness upon
            </p> */}
            <StoreButton />
          </div>
        </div>
        <div className="position-absolute" style={{ width: "100%", zIndex: '1' }}>
          <HeaderLink />
        </div>
        <img
          src={toAbsoluteUrl("/media/BellazMain/Images/Banner1.png")}
          alt=""
          className="img-fluid w-100 banner-person-img col-lg-5 col-md-5 col-sm-5 p-0"
        />
      </div>
    </div>
  );
}

export default BellazBanner;
