import React, { useState, useEffect } from "react";
import Switch from '@material-ui/core/Switch';
import { ChartMain } from "./chartMain";
import "./upgrade.css";
import { UpgradeCards } from "./upgradeCards";
import ToggleButton from "./toggleButton";
import { useDispatch, useSelector } from "react-redux";
import { loadSubscriptionCategories } from "../../store/admin/subscriptionList.js/subscriptionListSlice";

export function Upgrade() {
  const [cycle, setCycle] = useState(false);
  const dispatch = useDispatch();
  const data = useSelector(
    (state) => state.subscriptionCategories.subscriptionCategories
  );
  //console.log(data);
  useEffect(() => {
    dispatch(loadSubscriptionCategories());
  }, []);

  const handleChange = (event) => {
    //console.log(event);
    setCycle(event);
  };

  return (
    <>
      <div className="top-box" >
        <h2 className="d-block">Pricing & Plans</h2>
        <p className="d-block">Pick the best subscription plan for your business</p>
        <ToggleButton handleClick={handleChange} />
      </div>
      <div className="upgrade">
        <div className="cards">
          <div className="mainCard">
            {data.map((_data) => {
              return (
                <UpgradeCards
                  active={_data.isActive}
                  name={_data.name}
                  uuid={_data.uuid}
                  benefits={_data.benefits}
                  cost={cycle === 'yearly' ? _data.annual_cost : _data.month_cost}
                  cycle={cycle === 'yearly' ? 'yearly' : 'monthly'}
                />
              );
            })}
          </div>
        </div>
        <ChartMain />
      </div>
    </>
  );
}
