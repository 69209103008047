import React, { useEffect } from "react";
import HeaderTopSalon from "./Components/HeaderTopSalon";
import { useLocation, useParams } from "react-router-dom";
import { SalonViewThunk } from "../../store/TopSalons/topSalonsSlice";
import { useDispatch, useSelector } from "react-redux";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { SpinnerBasic } from "../../components/spinners/SpinnerBasic";
import defaultBannerImage from "../../_metronic/_assets/icons/defaultBannerImage.png";
import unisexIcon from "../../_metronic/_assets/icons/unisexIcon.png";
import maleIcon from "../../_metronic/_assets/icons/maleIcon.png";
import femaleIcon from "../../_metronic/_assets/icons/femaleIcon.png";
import playStoreBackgroundImage from "../../_metronic/_assets/icons/playStoreBackgroundImage.jpeg";
import StarIcon from "../../_metronic/_assets/icons/StarIcon.png";
import timeIcon from "../../_metronic/_assets/icons/timeIcon.png";
import BellazDownload from "../../components/bellazNew/BellazDownload";
import BellazFooter from "../../components/bellazNew/BellazFooter";
import { useHistory } from "react-router";

const TopSalon = () => {
  const { uuid } = useParams();
  const history = useHistory()
  const location = useLocation();
  const dispatch = useDispatch();
//   console.log("uuid,location", uuid, location);
  const { topSalons, loading, salonView } = useSelector(
    (state) => state.topSalons
  );
//   console.log("salon view", salonView);
  useEffect(() => {
    // console.log("salon",salon)
    let req = {
      uuid: uuid,
    };
    dispatch(SalonViewThunk(req));
  }, []);
  const option = {
    autoplay: true,
    rewind: true,
    margin: 20,
    animateOut: "fadeOut",
    animateIn: "fadeIn",
    responsiveClass: true,
    autoplayTimeout: 7000,
    smartSpeed: 800,
    autoplayHoverPause: true,
    responsiveRefreshRate: 10,
    // itemsDesktop : [1199,3],
    // itemsDesktopSmall : [979,3],
    dots: true,
    nav: false,
    responsive: {
      0: {
        items: 1,
        margin: 0,
      },

      600: {
        items: 1,
      },

      1024: {
        items: 1,
      },

      1366: {
        items: 1,
      },
    },
  };

  const getTime = (dateTime) => {
    const timestamp = dateTime;
    const dateObj = new Date(timestamp);
    const options = { hour: "2-digit", minute: "2-digit", hour12: true };
    const time = dateObj.toLocaleTimeString([], options);
    return time;
  };

  return (
    <div className="bg-white">
      {loading ? (
        <div className="h-100 w-100 centerContent">
          <SpinnerBasic />
        </div>
      ) : (
        <>
          <HeaderTopSalon />

          <div className="" style={{ height: "400px", marginTop: "128px" }}>
            <OwlCarousel className=" owl-theme" {...option}>
              {salonView?.branch && salonView?.branch?.image && salonView?.branch?.image?.length > 0 ? (
                salonView?.branch?.image?.map((data) => (
                  <img
                    src={`${process.env.REACT_APP_MEDIA_URL}/${data}`}
                    alt="salon banner"
                    style={{
                      // backgroundImage:`${process.env.REACT_APP_MEDIA_URL}/${data}`,
                      height: "400px",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                    onError={(e) => {
                      e.target.src = defaultBannerImage; // Set default image source
                    }}
                  />
                ))
              ) : (
                <div
                  //    src={defaultBannerImage}
                  //    alt=""
                  style={{
                    backgroundImage: `url(
                ${defaultBannerImage}
               )`,
                    height: "400px",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
              )}
            </OwlCarousel>
            <style jsx global>{`
              .owl-dots {
                position: absolute;
                bottom: 10px; /* adjust as needed */
                width: 100%;
                text-align: center;
              }
              .owl-dot {
                display: inline-block;
                margin: 0 5px;
                width: 10px; /* adjust dot size */
                height: 10px; /* adjust dot size */
                background-color: #000; /* dot color */
                border-radius: 50%;
                cursor: pointer;
                transition: all 0.3s ease;
              }
              .owl-dot.active {
                background-color: #fff; /* active dot color */
              }
            `}</style>
          </div>

          <div className="bellazWork align-items-center position-relative">
            <img
              className="img-fluid position-absolute flower14 d-none d-lg-flex"
              src={toAbsoluteUrl("/media/BellazMain/svg icons/flower-1.png")}
            />
            <img
              className="img-fluid position-absolute flower15 d-none d-lg-flex"
              src={toAbsoluteUrl("/media/BellazMain/svg icons/flower 2.png")}
            />
            <div
              className=" d-flex align-items-center flex-column position-relative"
              style={{ zIndex: "99" }}
            >
              <div className="bellazTopSalon-card">
                <div className="row">
                  <div className="col-xl-9 col-lg-8 col-md-8 col-sm-12 col-xs-12">
                    <div className=" p-0 h-100 position-relative">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="h-100 d-flex flex-column align-items-start justify-content-between">
                          <div className="d-flex align-items-center mb-2 flex-wrap">
                            {/* <div className="row"> */}

                            <h1
                              style={{ color: "black" }}
                              className=" m-0 p-0 mr-3"
                            >
                              {" "}
                              {salonView?.branch?.name}
                            </h1>
                            <div className="d-flex">
                              <span
                                style={
                                  salonView?.branch?.gender === "unisex"
                                    ? {
                                        backgroundColor: "#27AE60",
                                        color: "white",
                                      }
                                    : salonView?.branch?.gender === "male"
                                    ? {
                                        backgroundColor: "#007CC4",
                                        color: "white",
                                      }
                                    : {
                                        backgroundColor: "#FF4181",
                                        color: "white",
                                      }
                                }
                                className="badge rounded-pill font-size-lg text-capitalize mr-3"
                              >
                                {salonView?.branch?.gender}
                              </span>
                              {salonView?.branch?.Business &&
                                      salonView?.branch?.CountryMaster &&
                                      salonView?.branch?.Business?.length > 0 &&
                                      salonView?.branch?.CountryMaster?.length > 0 &&
                                      salonView?.branch?.Business[0]?.subscription
                                        ?.uuid !==
                                        salonView?.branch?.CountryMaster[0]
                                          .defaultSubscriptionUuid && (
                                        <p
                                          className="m-0 p-0 px-4 py-1 mr-3 rounded-pill"
                                          style={{
                                            // position: "absolute",
                                            // top: "85%",
                                            // left: "5%",
                                            fontSize: "14px",
                                            backgroundColor: "#D4B55B",
                                            color: "white",
                                          }}
                                        >
                                          Verified
                                        </p>
                                      )}
                              <img
                                src={
                                  salonView?.branch?.gender === "unisex"
                                    ? unisexIcon
                                    : salonView?.branch?.gender === "male"
                                    ? maleIcon
                                    : femaleIcon
                                }
                                alt=""
                                className=""
                              />
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="text-left">
                            <span style={{ fontSize: "16px" }}>
                              {salonView?.branch?.address_line1},{" "}
                              {salonView?.branch?.address_line2 ? `${salonView?.branch?.address_line2},` : null} {salonView?.branch?.city}
                              {/* {data?.distance > 0
                                  ? ` | ${data?.distance / 1000} km`
                                  : null} */}
                            </span>
                          </div>
                        </div>
                        <div
                          style={{ backgroundColor: "#007669" }}
                          className="card p-5 "
                        >
                          <div className="centerContent mb-2">
                            <img src={StarIcon} alt="rating" />
                            <h6
                              className="m-0 p-0 ml-2"
                              style={{
                                color: "white",
                                fontSize: "16px",
                                fontWeight: "700",
                              }}
                            >
                              {salonView?.branch?.avg_rating}
                            </h6>
                          </div>
                          <span
                            className="text-center"
                            style={{
                              color: "white",
                              fontSize: "12px",
                              fontWeight: "400",
                            }}
                          >
                            {salonView?.reviewLength} Reviews
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center my-1">
                        <img
                          src={timeIcon}
                          alt="time"
                          width={22}
                          height={22}
                          className="mr-3"
                        />
                        <h5
                          className="mr-3 m-0 p-0"
                          style={{
                            fontSize: "16px",
                            fontWeight: "700",
                            color: "#999999",
                          }}
                        >
                          Timings:{" "}
                          <span
                            className="text-uppercase"
                            style={{
                              fontSize: "16px",
                              fontWeight: "400",
                              color: "#999999",
                            }}
                          >
                            {getTime(salonView?.branch?.startTime)}
                            {" - "}
                            {getTime(salonView?.branch?.endTime)}
                          </span>
                        </h5>
                      </div>
                      <div className="my-4 mb-6">
                        <span style={{ fontSize: "14px", color: "black" }}>
                          {salonView?.branch?.description}
                        </span>
                      </div>
                      <button
                        type="button"
                        className="btn btn-primary form-submit-btn px-25 mb-6"
                        onClick={() => {
                          const platform = window.navigator.platform;
                          // console.log("platform",platform)
                          if(platform.includes('Mac68K') || platform.includes("MacPPC" )
                          || platform.includes("MacIntel") || platform.includes("iPhone")
                          || platform.includes("iPod")
                          || platform.includes("iPad")){
                            window.open('https://apps.apple.com/in/app/bellaz-customer/id6444063935', '_blank');
                          }else{
                            window.open('https://play.google.com/store/apps/details?id=com.mybellaz', '_blank');
                          }
                        }}
                      >
                        View Our Services
                      </button>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div
                      className="card p-8 h-100 centerContent"
                      style={{
                        backgroundImage: `url(${playStoreBackgroundImage})`,
                        borderRadius: "20px",
                      }}
                    >
                      <img
                        className="bellaz-logo navbar-brand m-0 p-0 mb-2"
                        src={toAbsoluteUrl("/media/logos/goldenLogo.png")}
                        alt="bellaz logo"
                      />
                      <h1
                        className="text-center"
                        style={{
                          color: "white",
                          fontSize: "24px",
                          fontWeight: "600",
                        }}
                      >
                        Download Bellaz App
                      </h1>
                      <p
                        className="text-center"
                        style={{ fontSize: "14px", color: "white" }}
                      >
                        Book unforgettable beauty and wellness experience with
                        bellaz mobile app - the best way to discover top-rated
                        nearby salon’s & spa’s.
                      </p>
                      <div className="d-flex flex-column w-50">
                        <a
                          href="https://apps.apple.com/in/app/bellaz-customer/id6444063935"
                          target="_blank"
                          className=""
                        >
                          {" "}
                          <img
                            src={toAbsoluteUrl(
                              "/media/BellazMain/button/apple.png"
                            )}
                            alt=""
                            className="w-100 mb-2"
                          />
                        </a>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.mybellaz"
                          target="_blank"
                        >
                          <img
                            src={toAbsoluteUrl(
                              "/media/BellazMain/button/google-play-badge.png"
                            )}
                            alt=""
                            className="w-100 h-100 bg-transparent"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <BellazDownload />
          <BellazFooter />
        </>
      )}
    </div>
  );
};

export default TopSalon;
