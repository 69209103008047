import React, { useState } from "react";
import { useFormik } from "formik";
import { connect, useDispatch } from "react-redux";
import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { requestPassword } from "../_redux/authCrud";
import LockIcon from "@material-ui/icons/Lock";
import { sendFailure, sendSuccess } from "../../../../store/toast/toastSlice";
import { resatPassword, branchResatPassword } from "../../../../store/business/authSlice";
const initialValues = {
  newPassword: "",
  confirmPassword: "",
};

function NewPassword(props) {
  const { intl } = props;
  const history = useHistory();
  const [formStatus, setFormStatus] = useState("danger");
  const [isRequested, setIsRequested] = useState(false);
  const NewPasswordSchema = Yup.object().shape({
    // newPassword: Yup.string()
    //   .required("Please Enter your password")
    //   .min(8, "Password is too short - should be 8 chars minimum."),
    // confirmPassword: Yup.string()
    //   .oneOf([Yup.ref("password"), null], "Passwords must match")
    //   .required("Please confirm your password."),
    newPassword: Yup.string()
      .required("Please Enter your password")
      .min(8, "Password is too short - should be 8 chars minimum."),

    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Please confirm your password."),
    // confirmPassword: Yup.string()
    //   .min(8, "Password should have minimum 6 characters")
    //   .required("This field is required")
    //   .when("password", {
    //     is: (val) => (val && val.length > 0 ? true : false),
    //     then: Yup.string().oneOf(
    //       [Yup.ref("password")],
    //       "Credentials do not match"
    //     ),
    //   }),
  });

  const dispatch = useDispatch();

  const { role, token } = useParams();
  console.log(role);
  console.log(token);

  const resetPasswordHandler = (values) => {
   // console.log(values);
    const newValues = { ...values, token: token };
    // console.log(token);
    // console.log(newValues);
    if (role === "branch") {
      //console.log("Hello Yen");
      dispatch(branchResatPassword(newValues))
        .then((response) => {
         // console.log(response);
          dispatch(
            sendSuccess("Password Reset Successful")
          );
          setTimeout(() => {
            history.replace("/");
          }, 3000);
          //disableLoading();
        })
        .catch((err) => {
          //console.log(err);
          dispatch(sendFailure("Please try again"));
          formik.setSubmitting(false);
          //disableLoading();
          formik.setStatus(err.message);
        });
    } else {
      //console.log("Hello Yen");
      console.log(newValues);
      dispatch(resatPassword(newValues))
        .then((response) => {
         // console.log(response);
          dispatch(
            sendSuccess("Password Reset Successful")
          );
          setTimeout(() => {
            history.replace("/");
          }, 3000);
          //disableLoading();
        })
        .catch((err) => {
          //console.log(err);
          dispatch(sendFailure("Please try again"));
          formik.setSubmitting(false);
          //disableLoading();
          formik.setStatus(err.message);
        });
    }
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: NewPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
     // console.log(values);
      // requestPassword(values.email)
      //   .then(() => setIsRequested(true))
      //   .catch(() => {
      //     setIsRequested(false);
      //     setSubmitting(false);
      //     setFormStatus("success");
      //     setStatus("Password Updated. Redirecting to login page...");
      //     setTimeout(() => {
      //       history.replace("/");
      //     }, 3000);
      //     // setStatus(
      //     //   intl.formatMessage(
      //     //     { id: "AUTH.VALIDATION.NOT_FOUND" },
      //     //     { name: values.email }
      //     //   )
      //     // );
      //   });
      setSubmitting(true);
      resetPasswordHandler(values);
      setSubmitting(false);
    },
  });

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          {formik.status && (
            <div
              className={`mb-10 alert alert-custom alert-light-${formStatus} alert-dismissible`}
            >
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          )}
          <div className="text-center mb-10 mb-lg-20">
            <LockIcon className="mb-4 display-1" />
            <h3 className="font-size-h1">Just One Step Away</h3>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="New Password"
                type="password"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "password"
                )}`}
                name="newPassword"
                {...formik.getFieldProps("newPassword")}
              />
              {formik.touched.newPassword && formik.errors.newPassword ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.newPassword}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Confirm Password"
                type="password"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "confirmPassword"
                )}`}
                name="confirmPassword"
                {...formik.getFieldProps("confirmPassword")}
              />
              {formik.touched.confirmPassword &&
              formik.errors.confirmPassword ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.confirmPassword}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              {/* <Link to="/auth"> */}
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.isSubmitting}
                disabled={
                  !Boolean(
                    formik.touched.newPassword && formik.touched.confirmPassword
                  )
                    ? true
                    : Boolean(
                        formik.errors.newPassword &&
                          formik.errors.confirmPassword
                      )
                }
              >
                Save
              </button>
              {/* </Link> */}
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Cancel
                </button>
              </Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default NewPassword;
