import axios from "axios";

const URL = process.env.REACT_APP_API_URL;
const headers = {
  "Content-Type": "multipart/form-data",
  Accept: "application/json",
};

export const getBranchStaffList = () => {
  return axios.get(`${URL}/userBranch/staff/list`);
};

export const createBranchStaff = (data) => {
  const req = new FormData();
  for (var key in data) {
    req.append(key, data[key]);
  }

  console.log([...req]);

  return axios.post(`${URL}/userBranch/staff/create`, req, {
    headers,
  });
};
