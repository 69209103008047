import React from "react";

import { ChairsTable } from "./table";
import { CardBasic } from "../../components/CardBasic";

export function ChairsPage() {
  return (
    <CardBasic
      title={"Chairs"}
      btnType={"button"}
      headerBtnText={"Add Chair"}
      headerBtnLink={"/chair/create"}
    >
      <ChairsTable />
    </CardBasic>
  );
}
