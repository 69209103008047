import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, Select } from "../../../_metronic/_partials/controls";

import { CardBasic } from "../../../components/CardBasic";
import { loadBranchList } from "../../../store/business/branches/branchSlice";
import { createRa } from "../../../store/branch/ra/raSlice";
import { loadShiftList } from "../../../store/business/shifts/shitftsSlice";
import dayjs from "dayjs";
import { sendFailure, sendSuccess } from "../../../store/toast/toastSlice";
import { number } from "prop-types";

export const ExpenseRaCreate = () => {
  const [formStatus, setFormStatus] = useState("");
  const [dataLoading, setDataLoading] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();

  // Validation schema
  const ValidationSchema = Yup.object().shape({
    remarks: Yup.string().required("This field is required"),
    amount: Yup.number().required("This field is required"),
    modeOfPayment: Yup.string().required("This field is required"),
  });

  return (
    <CardBasic
      title={"Add RA"}
      btnType={"submit"}
      formId={"createCategoryForm"}
      headerBtnText={"Save RA"}
    >
      <Formik
        initialValues={{
          remarks: "",
          amount: "",
          modeOfPayment: 'cash'
        }}
        enableReinitialize
        validationSchema={ValidationSchema}
        onSubmit={(values, actions) => {
          console.log(values);
          dispatch(createRa(values))
            .then(() => {
              dispatch(sendSuccess("The RA has been created succesfully"));
              history.replace("/expense/ra");
            })
            .catch((err) => {
              dispatch(sendFailure(err.message));
              actions.setStatus("Error! Duplicate Category Name.");
              console.log(err.message);
            });
        }}
      >
        {({ values, errors, setFieldValue, setFieldError }) => (
          <Form className="form form-label-right" id="createCategoryForm">
            <div className="form-group row">
              <div className="col-lg-6">
                <Field
                  name="amount"
                  component={Input}
                  placeholder="Amount"
                  label="Amount *"
                />
              </div>
              <div className="col-lg-6">
                <Select name="modeOfPayment" label="Mode Of Payment *">
                  <option value="cash">Cash</option>
                  <option value="online">Online</option>
                </Select>
              </div>
              <div className="col-lg-12">
                <Field
                  name="remarks"
                  component={Input}
                  placeholder="Remarks"
                  label="Remarks *"
                />
              </div>
            </div>
            {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
          </Form>
        )}
      </Formik>
    </CardBasic>
  );
};
