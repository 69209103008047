import axios from "axios";

const URL = process.env.REACT_APP_API_URL + "/business";
const URL1 = process.env.REACT_APP_API_URL + "/userBranch";
const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const addCustomer = (data) => {
  return axios.post(`${URL}/customer/add`, data, {
    headers,
  });
};

export const giveCustomerRating = (uuid, rating, values) => {
  // console.log(uuid, rating, values);
  let req = {
    BookingUuid: uuid,
    rating: rating,
    remark: values
  };
  // console.log(req);
  return axios.post(`${URL1}/customer/rate`, req);
};

