import React, { useEffect, useState } from "react";
import HeaderTopSalon from "./Components/HeaderTopSalon";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { useDispatch, useSelector } from "react-redux";
import { getCityList } from "../../store/countryCode/countryCodeSlice";
import { useHistory } from "react-router";
import { Formik } from "formik";
import {
  SalonViewThunk,
  emptyTopSalon,
  topSalonsThunk,
} from "../../store/TopSalons/topSalonsSlice";
import unisexIcon from "../../_metronic/_assets/icons/unisexIcon.png";
import maleIcon from "../../_metronic/_assets/icons/maleIcon.png";
import femaleIcon from "../../_metronic/_assets/icons/femaleIcon.png";
import forward_arrow_icon from "../../_metronic/_assets/icons/arrow_forward.png";
import StoreButton from "../../components/bellazNew/component/Button";
import BellazFooter from "../../components/bellazNew/BellazFooter";
import { getBranch } from "../../store/business/branches/branchApi";
import { salonView } from "../../store/TopSalons/topSalonsAPI";
import defaultBranchImage from "../../_metronic/_assets/icons/logo2.jpg";
import { SpinnerBasic } from "../../components/spinners/SpinnerBasic";

const AllTopSalons = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { topSalons, loading } = useSelector((state) => state.topSalons);
  const { cityList } = useSelector((state) => state.countryCode);
  // console.log("topSalons", topSalons, cityList);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [pageCount, setPageCount] = useState(1);
  const [moreDataAvailable, setMoreDataAvailable] = useState(true); // State to track if more data is available
  const [selectedGender, setSelectedGender] = useState("");
  const [displaySalons,setDisplaySalons]=useState();
  useEffect(()=>{
    setDisplaySalons(topSalons
      .filter((salon) => {
        if (selectedGender === "unisex") {
          return salon.branch.gender === "unisex";
        } else if (selectedGender === "male") {
          return salon.branch.gender === "male";
        } else if (selectedGender === "female") {
          return salon.branch.gender === "female";
        } else {
          return true; // Show all salons if no gender selected
        }
      }))
  },[topSalons,selectedGender])
  const getLocation = () => {
    // console.log("here", window.navigator?.geolocation);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          // Here you can send latitude and longitude to your server or wherever you need
          // console.log(position, "Latitude:", latitude, "Longitude:", longitude);
          const data = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            pageNumber: 1,
            // city:'Jaipur'
          };
          dispatch(topSalonsThunk(data));
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const loadMoreData = () => {
    const data = {
      latitude: latitude,
      longitude: longitude,
      pageNumber: pageCount + 1, // Increment page number
    };
    dispatch(topSalonsThunk(data));
    setPageCount(pageCount + 1); // Increment page count for next load
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getCityList());
    // console.log("topSalons", topSalons);
    if (topSalons.length == 0) {
      // console.log("here");
      getLocation();
    }

    // const data={
    //   latitude:latitude,
    //   longitude:longitude,
    //   // city:'jaipur'
    // }
    // dispatch(topSalonsThunk(data))
  }, []);

  useEffect(() => {
    // Check if topSalons array length is zero, if so, set more data to false
    if (topSalons.length === 0) {
      setMoreDataAvailable(false);
    }
  }, [topSalons]);

  const handleSalonView = (salon) => {
    // console.log("salon",salon)
    //   let req = {
    //     uuid:salon?.branch?.uuid
    //   };
    //   dispatch(SalonViewThunk(req)).then((res)=>{
    //   console.log("res",res)
    //  }).catch((err)=>{
    //   console.log("err",err)
    //  })
    history.push(`/top-salons/${salon?.branch?.uuid}`);
  };

  return (
    <div className="bg-white">
      <HeaderTopSalon />
      <div className="w-100 marginTop180 position-relative">
        <img
          className="img-fluid position-absolute flower14 d-none d-lg-flex"
          src={toAbsoluteUrl("/media/BellazMain/svg icons/flower-1.png")}
        />
        <img
          className="img-fluid position-absolute flower15 d-none d-lg-flex"
          src={toAbsoluteUrl("/media/BellazMain/svg icons/flower 2.png")}
        />
        <Formik
          initialValues={{ city: "", gender: "" }}
          onSubmit={(values) => {
            // console.log("values", values);
            if (values.city == "current_location") {
              loadMoreData();
            } else {
              const data = {
                city: values.city,
                pageNumber: pageCount + 1,
              };
              dispatch(topSalonsThunk(data));
              setPageCount(pageCount + 1);
            }
          }}
        >
          {({
            values,
            handleReset,
            handleSubmit,
            handleChange,
            handleBlur,
          }) => (
            <div className="container w-100 lastBanner-Main d-flex align-items-center justify-content-center align-self-center">
              <div className="row w-100">
                {/* <div className="bellazWork-inner d-flex align-items-center flex-column position-relative"> */}
                <div className=" w-100 d-flex align-items-center justify-content-center">
                  {/* <div className="col-lg-1"></div> */}
                  <div className="w-100 row d-flex align-items-center justify-content-between bellazCard-top ">
                    <div className="d-flex flex-column col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                      {/* <h1 className="display-4 m-0 p-0 text-capitalize">All {values.city=="" || values.city=='current_location' ? "Nearby" : values.city} Salons</h1> */}
                      <h1 className="display-4 m-0 p-0 text-capitalize">All Nearby Salons</h1>
                      <p className={"m-0 p-0"} style={{ color: "#999999" }}>
                        Browse lists of the best salons and spa’s
                      </p>
                    </div>

                    <div className="form w-100 col-lg-6 col-md-6 col-sm-12 col-xs-12 m-0 p-0 d-flex align-items-center justify-content-end gap-2 flex-wrap">
                      <div className="d-flex align-items-center justify-content-end mr-2">
                        <select
                          className="form-select filterIcon "
                          name="gender"
                          onBlur={handleBlur}
                          value={values.gender}
                          onChange={(e) => {
                            setSelectedGender(e.target.value);
                            handleChange(e);
                          }}
                        >
                          <option value="" disabled>
                            Filter by Gender
                          </option>
                          <option value="all">All</option>
                          <option value="unisex">Unisex</option>

                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </select>
                      </div>
                      <div className="d-flex align-items-center justify-content-end">
                        <select
                          className="form-select icon"
                          name="city"
                          onBlur={handleBlur}
                          value={values.city}
                          onChange={(e) => {
                            if (e.target.value === "current_location") {
                              dispatch(emptyTopSalon());
                              getLocation();
                            } else {
                              dispatch(emptyTopSalon());
                              console.log("e", e.target.value);
                              const data = {
                                city: e.target.value,
                                pageNumber: 1,
                              };
                              dispatch(topSalonsThunk(data));
                            }
                            handleChange(e);
                          }}
                        >
                          <option value="" disabled>
                            Select City
                          </option>
                          <option value="current_location">
                            Current Location
                          </option>
                          {cityList &&
                            cityList?.length > 0 &&
                            cityList?.map((city, i) => (
                              <option value={city}>{city}</option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-1"></div> */}
                </div>
                <div className="w-100" style={{ zIndex: "99" }}>
                  <div className="row mb-6">
                    {loading ? (
                      <>
                        <div className="centerContent w-100">
                          <SpinnerBasic />
                        </div>
                      </>
                    ) : displaySalons && displaySalons?.length > 0 ? (
                      displaySalons?.map((data, index) => {
                          // console.log("data", data);
                          return (
                            <div
                              className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-xl-8 mb-6"
                              key={index}
                            >
                              <div
                                className="card p-0 h-100 cursor-pointer"
                                // style={{
                                //   display: "flex",
                                //   flexDirection: "row",
                                // }}
                                onClick={() => handleSalonView(data)}
                              >
                                <div
                                  className="m-0 p-0 row h-100"
                                  style={{ borderRadius: "inherit" }}
                                >
                                  <div
                                    className="position-relative d-flex flex-column col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 m-0 p-0"
                                    style={{ borderRadius: "inherit" }}
                                  >
                                    <img
                                      src={`${process.env.REACT_APP_MEDIA_URL}/${data?.branch?.logo}`}
                                      alt={data?.branch?.name}
                                      className="allSalonImage"
                                      style={{
                                        // borderTopLeftRadius: "inherit",
                                        // borderBottomLeftRadius: "inherit",
                                        // borderRadius:'inherit',
                                        height: "260px",
                                        width: "100%",
                                        height: "100%",
                                        aspectRatio: "4/3",
                                      }}
                                      onError={(e) => {
                                        e.target.src = defaultBranchImage; // Set default image source
                                      }}
                                    />
                                    {data?.branch?.Business &&
                                      data?.branch?.CountryMaster &&
                                      data?.branch?.Business?.length > 0 &&
                                      data?.branch?.CountryMaster?.length > 0 &&
                                      data?.branch?.Business[0]?.subscription
                                        ?.uuid !==
                                        data?.branch?.CountryMaster[0]
                                          .defaultSubscriptionUuid && (
                                        <p
                                          className="m-0 p-0 px-4 py-1 rounded-pill"
                                          style={{
                                            position: "absolute",
                                            top: "85%",
                                            left: "5%",
                                            fontSize: "14px",
                                            backgroundColor: "#D4B55B",
                                            color: "white",
                                          }}
                                        >
                                          Verified
                                        </p>
                                      )}
                                  </div>
                                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 m-0 p-0">
                                    <div className="p-6 h-100 ps-4 d-flex flex-column justify-content-between">
                                      <div>
                                        <div className="d-flex align-items-center justify-content-between mb-2">
                                          <p
                                            className={"m-0 p-0"}
                                            style={
                                              data?.branch?.gender === "unisex"
                                                ? {
                                                    color: "#27AE60",
                                                    fontSize: "16px",
                                                    lineHeight: "22px",
                                                  }
                                                : data?.branch?.gender ===
                                                  "male"
                                                ? {
                                                    color: "#007CC4",
                                                    fontSize: "16px",
                                                    lineHeight: "22px",
                                                  }
                                                : {
                                                    color: "#FF4181",
                                                    fontSize: "16px",
                                                    lineHeight: "22px",
                                                  }
                                            }
                                          >
                                            {data?.branch?.name}
                                          </p>
                                          <img
                                            src={
                                              data?.branch?.gender === "unisex"
                                                ? unisexIcon
                                                : data?.branch?.gender ===
                                                  "male"
                                                ? maleIcon
                                                : femaleIcon
                                            }
                                            alt=""
                                          />
                                        </div>
                                        <div className="text-left mb-2">
                                          <span
                                            style={{
                                              color: "gray",
                                              fontSize: "12px",
                                              lineHeight: "16px",
                                            }}
                                          >
                                            {data?.branch?.address_line1},{" "}
                                            {data?.branch?.city}
                                            {data?.distance > 0
                                              ? ` | ${data?.distance / 1000} km`
                                              : null}
                                          </span>
                                        </div>
                                        <div className="text-left">
                                          <span
                                            style={{
                                              color: "gray",
                                              fontSize: "12px",
                                              lineHeight: "16px",
                                            }}
                                          >
                                            About Us
                                          </span>
                                        </div>
                                        <div className="">
                                          <span
                                            className="textWrapper"
                                            style={{
                                              fontSize: "14px",
                                              color: "black",
                                            }}
                                          >
                                            {data?.branch?.description}
                                          </span>
                                        </div>
                                      </div>

                                      <div className="d-flex align-items-center justify-content-end">
                                        <img
                                          className="cursor-pointer"
                                          src={forward_arrow_icon}
                                          alt=""
                                          
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/* <span>{data.branch.text}</span> */}
                              </div>
                            </div>
                          );
                        })
                    ) : (
                      <div className="w-100 centerContent">
                        <h4>No data found for this Location or Filter</h4>
                      </div>
                    )}
                  </div>
                  <div className="centerContent w-100">
                    {/* <button
                type="button"
                className="btn btn-primary form-submit-btn px-25"
                onClick={() => {
                  console.log("here")
                }}
              >
                View More
              </button> */}
              {displaySalons?.length<8 ? null : 
                    <button
                      type="submit"
                      className="btn btn-primary form-submit-btn px-25"
                      onClick={handleSubmit}
                      disabled={topSalons?.length < 8}
                      // disabled={!moreDataAvailable || loading} // Disable button when no more data or when loading
                    >
                      {loading ? "Loading..." : "View More"}
                    </button>
              }
                  </div>
                </div>
                {/* <div
          href=""
          class="btn btn-primary d-flex justify-content-center mt-5 mb-5 text-uppercase text-white pt-4 pb-4 pl-10 pr-10 rounded-pill"
        >
          BOOK AN APPOINTMENT NOW
        </div> */}
                {/* </div> */}
              </div>
            </div>
          )}
        </Formik>
      </div>
      <div class="card text-white download-bg mb-20">
        <img
          class="card-img h-100"
          src={toAbsoluteUrl("/media/BellazMain/cardImages/bgstrip.png")}
          alt="Card image"
        />
        <div className="card-img-overlay d-flex pr-5 align-items-sm-center align-items-center justify-content-end flex-lg-row flex-md-row m-0">
          <div className="text-white download-left-content p-0 ">
            <img
              src={toAbsoluteUrl("/media/logos/goldenLogo.png")}
              alt=""
              className="donwload-banner-logo img-fluid p-0 "
            />
            <h1 className="display-4">Download our app</h1>
            <h6 className="mb-lg-5">
              Book unforgettable beauty and wellness experiance with bellaz
              mobile app - the best way to discover top-rated nearby salons and
              spas
            </h6>
            <StoreButton />
          </div>
          <img
            src={toAbsoluteUrl("/media/BellazMain/cardImages/phone.png")}
            alt=""
            className="phone-img img-fluid m-0"
          />
        </div>
      </div>
      <BellazFooter />
    </div>
  );
};

export default AllTopSalons;
