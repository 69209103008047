import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Select, DatePickerField, Input } from "../../../_metronic/_partials/controls";
import { useHistory } from "react-router";
import { CardBasic } from "../../../components/CardBasic";
import { createExpenseType } from "../../../store/business/expense/type/typeSlice";
import { useDispatch, useSelector } from "react-redux";
import { loadCategoryList } from "../../../store/branch/category/categorySlice";
import { loadBranchStaffList } from "../../../store/branch/branchStaffSlice";
import { sendFailure, sendSuccess } from "../../../store/toast/toastSlice";
import { loadCategoryTypeList } from '../../../store/branch/type/typeSlice';
import { createPo } from '../../../store/branch/po/poSlice';

export const ExpensePoCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [dataLoading, setDataLoading] = useState(true);
  const [categoryUUid, setCategoryUUid] = useState('');
  //console.log(categoryUUid);

  const category = useSelector((state) => state.brahchCategory.category);
  //console.log(category);

  const type = useSelector((state) => state.brahchCategoryType.type);
  //console.log(type);

  const staff = useSelector((state) => state.branchStaff.branchStaff);
  //console.log(staff);

  useEffect(() => {
    if (staff.length === 0) {
      dispatch(loadBranchStaffList()).then(() => {
        setDataLoading(false);
      });
    }
  }, [staff]);

  useEffect(() => {
    if (categoryUUid !== "") {
      dispatch(loadCategoryTypeList(categoryUUid)).then(() => {
        //setDataLoading(false);
      });
      //console.log(categoryUUid);
    }
  }, [categoryUUid]);

  useEffect(() => {
    dispatch(loadCategoryList());
  }, []);
  //console.log(category);

  const ValidationSchema = Yup.object().shape({
    expenseCategory: Yup.string().required("This field is required"),
    amount: Yup.number().required("Type is required"),
    remarks : Yup.string().required("This field is required"),
  });

  return (
    <CardBasic
      title={"Add PO"}
      btnType={"submit"}
      formId={"createForm"}
      headerBtnText={"Save PO"}
    >
      <Formik
        initialValues={{
          expenseCategory: "",
          expenseType: "",
          isPaid: false,
          staffUuid: "",
          amount: '',
          remarks: '',
          modeOfPayment: 'cash'
        }}
        enableReinitialize
        validationSchema={ValidationSchema}
        onSubmit={(values, actions) => {
          //console.log(values);
          if (values.isPaid === 'true') {
            values.isPaid = true;
          } else {
            values.isPaid = false;
          }
          //console.log(values);
          dispatch(createPo(values))
            .then(() => {
              dispatch(
                sendSuccess(
                  "The PO has been created succesfully"
                )
              );
              history.replace("/expense/po");
            })
            .catch((err) => {
              dispatch(sendFailure(err.message));
              actions.setStatus("Error!");
              console.log(err.message);
            });
        }}
      >
        {({ values, errors, setFieldValue }) => {
          //console.log(values);
          if (values.expenseCategory !== "") {
            setCategoryUUid(values.expenseCategory);
          }
          return (
            <Form className="form form-label-right" id="createForm">
              <div className="form-group row">
                <div className="col-lg-4">
                  <Select name="expenseCategory" label="Category Name *" placeholder="Select Category" >
                    <option value={'Select Category'} key={'Select Category'}>
                      {'Select Category'}
                    </option>
                    {category.map((category, index) => (
                      <option value={category.uuid} key={category.uuid}>
                        {category.categoryName}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className="col-lg-4">
                  <Select name="expenseType" label="Type Name" placeholder="Select Type" >
                    <option value={'Select Type'} key={'Select Type'}>
                      {'Select Type'}
                    </option>
                    {type.map((category, index) => (
                      <option value={category.uuid} key={category.uuid}>
                        {category.typeName}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className="col-lg-4">
                  <Select name="isPaid" label="Is Paid">
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </Select>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-6">
                  <Select name="staffUuid" label="Staff Name" placeholder="Select Staff" >
                    <option value={'Select Staff'} key={'Select Staff'}>
                      {'Select Staff'}
                    </option>
                    {staff.map((staff, index) => (
                      <option value={staff.uuid} key={index}>
                        {staff.name}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className="col-lg-6">
                  <Field
                    name="amount"
                    component={Input}
                    placeholder="Amount"
                    label="Amount *"
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-6">
                  <Select name="modeOfPayment" label="Mode Of Payment">
                    <option value="cash">Cash</option>
                    <option value="online">Online</option>
                  </Select>
                </div>
                <div className="col-lg-6">
                  <Field
                    name="remarks"
                    component={Input}
                    placeholder="Remarks"
                    label="Remarks *"
                  />
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </CardBasic>
  );
};
