import axios from "axios";

const URL = process.env.REACT_APP_API_URL + "/branch";
const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const getCategoryTypeList = (data) => {
  console.log(data);
  const req ={
    "categoryUuid": data
  }
  console.log(req);
  return axios.post(`${URL}/voucher/PO/expenseType/list`, req);
};

