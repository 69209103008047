import axios from "axios";

const URL = process.env.REACT_APP_API_URL + "/business";
const headers = {
  "Content-Type": "multipart/form-data",
  Accept: "application/json",
};

export const getTypeList = () => {
  return axios.get(`${URL}/expense/type/list`);
};

export const createType = (data) => {
  return axios.post(`${URL}/expense/type/create`, data);
};

export const getType = async (req) => {
  return await axios.post(`${URL}/expense/type/view`, req);
};

export const updateType = (data) => {
  console.log(data);
  return axios.patch(`${URL}/expense/type/update`, data);
};
