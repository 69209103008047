import React from "react";

import { BookingsTable } from "./table";
import { CardBasic } from "../../components/CardBasic";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

export function BookingsPage() {
  const { role, user } = useSelector((state) => state.authPersist);
  const { status } = useParams();
  const { date } = useParams();
  return (
    <>
      {role == "business" ? "" : ""}
      <CardBasic
        title={"Booking List"}
        btnType={"button"}
        headerBtnText={role == "business" ? "" : "Add Booking"}
        headerBtnLink={toAbsoluteUrl("../booking/create")}
      >
        <BookingsTable status={status} date={date} />
      </CardBasic>
    </>
  );
}
