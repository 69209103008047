import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import filterFactory from "react-bootstrap-table2-filter";
import { sortCaret } from "../../_metronic/_helpers";
import { Formik, Form } from "formik";
import { DatePickerField } from "../../_metronic/_partials/controls";
import { Link } from "react-router-dom";
import { Input, Select } from "../../_metronic/_partials/controls";

import paginationFactory from "react-bootstrap-table2-paginator";
import dayjs from "dayjs";
import { getPaymentList } from "../../store/business/paymentSummary/paymentApi";
import { getCountryCodeList } from "../../store/countryCode/countryCodeSlice";

const LinkFormatter = (cell, row) => {
  return (
    <span className="text-uppercase">{cell}</span>
  );
};
const CapitalLetterFormatter = (cell, row) => {
  return (
    <Link to={`booking/view/${row.bookingUuid}`}>
      <span className="text-uppercase">{cell}</span>
    </Link>
  );
};
const CustomerFormatter = (cell, row) => {
  if (row.Booking[0]) {
    return `${cell} - ${row.Booking[0].customer.phone}`;
  } else return null;
};
const DateFormatter = (cell, row) => {
  return dayjs(cell).format("DD-MM-YYYY @ hh:mm A");
};
export const PaymentSummaryTable = (props) => {
  const dispatch = useDispatch();
  const [payments, setPaymentList] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const { role, user } = useSelector((state) => state.authPersist);
  const { countryCode } = useSelector((state) => state.countryCode);
  const currencySymbol = countryCode.filter((el) => el.countryCode === user.countryCode);
  const date = new Date();
  
  useEffect(() => {
    dispatch(getCountryCodeList());
  }, []);

  if (props.date == 1) {
    date.setDate(date.getDate());
  } else if (props.date == 2) {
    date.setDate(date.getDate() - 7);
    console.log(date);
  }
  else if (props.date == 3) {
    date.setDate(date.getDate() - 15);
  }
  else if (props.date == 4) {
    date.setDate(date.getDate() - 30);
  } else if (props.date == 5) {
    date.setDate(date.getDate() - 365);
  } else if (props.date == 6) {
    date.setDate(date.getDate() - 730);
  } else {
    date.setDate(date.getDate() - 30);
  }

  console.log(date);

  useEffect(() => {
    if (props.date === undefined) {
      console.log("HIIIIIII");
      getPaymentList({ from: date, to: new Date(), paymentMode: "All" }).then((res) => {
        setPaymentList(res.data.data);
        setDataLoading(false);
        console.log(payments);
      });
    } else {
      console.log("HOOOOOOO");
      console.log(date);
      getPaymentList({ from: date, to: new Date(), paymentMode: "All" }).then((res) => {
        setPaymentList(res.data.data);
        setDataLoading(false);
        console.log(payments);
      });
    }

  }, [props.date]);
  

  const columns = [
    {
      dataField: "date",
      text: "Date",
      sort: true,
      sortCaret: sortCaret,
      formatter: DateFormatter,
    },

    {
      dataField: "Branch[0].name",
      text: "Branch Name",
      sort: true,
      sortCaret: sortCaret,
      formatter: LinkFormatter,
    },

    {
      dataField: "transactionId",
      text: "Transaction Id",
      sort: true,
      sortCaret: sortCaret,
      formatter: CapitalLetterFormatter,
    },

    {
      dataField: "amount",
      text: `Amount (${currencySymbol[0] ? currencySymbol[0].currencySymbol : '₹'})`,
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "Booking[0].bookingId",
      text: "Booking Id",
      sort: true,
      sortCaret: sortCaret,
      formatter: LinkFormatter,
    },
    {
      dataField: "Booking[0].customer.name",
      text: "Customer Name",
      sort: true,
      sortCaret: sortCaret,
      formatter: CustomerFormatter,
    },

    {
      dataField: "paymentMode",
      text: "Payment Mode",
      sort: true,
      sortCaret: sortCaret,
    },
  ];

  const { SearchBar, ClearSearchButton } = Search;

  return (
    <>
      <Formik
        initialValues={{
          startDate: date,
          endDate: new Date(),
          paymentMode: "All"
        }}
        onSubmit={(values) => {
          console.log(values);
          setDataLoading(true);
          getPaymentList({ from: values.startDate, to: values.endDate, paymentMode: values.paymentMode }).then(
            (res) => {
              console.log(res);
              setPaymentList(res.data.data);
              setDataLoading(false);
            }
          );
        }}
      >
        {({ values, errors, setFieldValue }) => {
          console.log(values);
          return (
            <Form className="form form-label-right" id="createBookingForm">
              <div className="container">
                <div className="row mb-5">
                  <div className="col-sm-3">
                    <div className="mx-3">
                      <label>From</label>
                    </div>
                    <DatePickerField
                      name="startDate"
                      className="form-control"
                      onChange={(val) => {
                        setFieldValue("startDate", val);
                      }}
                    />
                  </div>
                  <div className="col-sm-3">
                    <div className="mx-3">
                      <label>To</label>
                    </div>
                    <DatePickerField
                      name="endDate"
                      className="form-control"
                      onChange={(val) => {
                        setFieldValue("endDate", val);
                      }}
                    />
                  </div>
                  <div className="col-sm-3 mt-8">
                    <Select name="paymentMode">
                      <option value="All">All</option>
                      <option value="Cash">Cash</option>
                      <option value="Card">Card</option>
                      <option value="Upi">UPI</option>
                      <option value="Wallet">Wallet</option>
                      <option value="Bank Transfer">Bank Transfer</option>
                      <option value="Online">Online</option>
                    </Select>
                  </div>
                  <div className="col-sm-3 mt-8">
                    <button
                      type="submit"
                      className="btn btn-light-success font-weight-bold"
                      style={{ marginLeft: " 20px" }}
                    >
                      Filter Data
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
      <ToolkitProvider
        search
        keyField="id"
        data={payments}
        columns={columns}
        autoValue
      >
        {(props) => (
          <>
            <div className="container">
              <div class="row">
                <div className="col-sm-4">
                  {/* <h4 className="mt-4" >Search</h4> */}
                </div>
                <div className="col-sm-8">
                  <SearchBar {...props.searchProps} />
                  <ClearSearchButton
                    {...props.searchProps}
                    className="ml-4 btn btn-secondary-light"
                  />
                </div>
              </div>
            </div>

            <BootstrapTable
              {...props.baseProps}
              classes="table table-head-custom table-vertical-center overflow-hidden"
              wrapperClasses="table-responsive"
              bordered={false}
              insertRow
              bootstrap4
              pagination={paginationFactory()}
              filter={filterFactory()}
              noDataIndication={
                <div className={"d-flex justify-content-center py-10"}>
                  <h3>
                    {dataLoading ? "Loading Data..." : "No records found"}
                  </h3>
                </div>
              }
            />
          </>
        )}
      </ToolkitProvider>
    </>
  );
};
