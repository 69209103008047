import React, {useEffect} from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, Select } from "../../_metronic/_partials/controls";
import { CardBasic } from "../../components/CardBasic";
import { createService } from "../../store/business/services/servicesSlice";
import { getCountryCodeList } from "../../store/countryCode/countryCodeSlice";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { sendFailure, sendSuccess } from "../../store/toast/toastSlice";
export const ServiceCreate = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { serviceCategories } = useSelector((state) => state.data);
  const { role, user } = useSelector((state) => state.authPersist);
  const { countryCode } = useSelector((state) => state.countryCode);
  const currencySymbol = countryCode.filter((el) => el.countryCode === user.countryCode);
  //console.log(">>>>>>>" + JSON.stringify(serviceCategories));

  useEffect(() => {
    dispatch(getCountryCodeList());
  }, []);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required"),
    category_uuid: Yup.string().required("This field is required"),
    price: Yup.number()
      .positive("Price must be greater than 0")
      .required("This field is required")
      .typeError("Price must be an number"),
    // discount_price: Yup.number(),
    time: Yup.number().required("This field is required"),
    discount: Yup.bool(),
    isActive: Yup.string().required("This field is required"),
    short_description: Yup.string()
      .max(200, "Maximum length should be 200 characters.")
      .required("This field is required"),
    // description: Yup.string(),
  });

  return (
    <CardBasic
      title={"Add Service"}
      btnType={"submit"}
      formId={"createServiceForm"}
      headerBtnText={"Save Service"}
    >
      <Formik
        initialValues={{
          name: "",
          category_uuid:
            serviceCategories.length > 0 ? serviceCategories[0].uuid : "",
          price: "",
          // discount_price: "",
          time: "",
          short_description: "",
          isActive: true,
          applicable_discount: false,
          // description: "",
        }}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          console.log(values);
          dispatch(createService(values))
            .then(() => {
              dispatch(
                sendSuccess("The service has been created successfully")
              );
              history.replace("/services");
            })
            .catch((err) => {
              dispatch(sendFailure(err.message));
              actions.setStatus("Error! Duplicate Service Name.");
              console.log(err.message);
            });
          actions.setFormStatus("submitted");
        }}
      >
        {({ values, errors }) => (
          <Form className="form form-label-right" id="createServiceForm">
            <div className="form-group row">
              <div className="col-lg-12">
                <Field
                  name="name"
                  component={Input}
                  placeholder="Service Name"
                  label="Service Name *"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-4 mb-4 mb-lg-0">
                <Select
                  name="category_uuid"
                  label="Service Category *"
                  placeholder="Select Category"
                >
                  {serviceCategories.map((category, index) => (
                    <option value={category.uuid} key={category.uuid}>
                      {category.name}
                    </option>
                  ))}
                </Select>
              </div>
              <div className="col-lg-4 mb-4 mb-lg-0">
                <Field
                  name="price"
                  type="number"
                  min="1"
                  component={Input}
                  placeholder="0.00"
                  label={`Price (${currencySymbol[0] ? currencySymbol[0].currencySymbol : '₹'}) *`}
                />
              </div>
              <div className="col-lg-4">
                <Field
                  name="time"
                  type="number"
                  min="5"
                  step="5"
                  component={Input}
                  placeholder="15"
                  label="Time (Minutes) *"
                />
              </div>
              {/* <div className="col-lg-4">
              <Field
                name="discount_price"
                type="number"
                min="0"
                component={Input}
                placeholder="0.00"
                label="Discounted Price (₹)"
              />
            </div> */}
            </div>
            <div className="form-group row">
              <div className="col-lg-4">
                <Select name="isActive" label="Status *">
                  <option value={true}>Active</option>
                  <option value={false}>Inactive</option>
                </Select>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-12">
                <Field
                  name="short_description"
                  component={Input}
                  label="Short Description *"
                />
              </div>
            </div>

            <div className="form-group row">
              <div className="col-lg-12 checkbox-inline">
                <label className="checkbox">
                  <Field type="checkbox" name="applicable_discount" />
                  <span></span>Applicable for discount offers
                </label>
              </div>
            </div>
            {/* <div className="form-group row">
            <div className="col-lg-12">
              <label>Service Full Description</label>
              <Field
                name="description"
                as="textarea"
                rows="5"
                className="form-control"
              />
            </div>
          </div> */}
          </Form>
        )}
      </Formik>
    </CardBasic>
  );
};
