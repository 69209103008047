import { createSlice } from "@reduxjs/toolkit";

import * as requestFromServer from "./invoiceApi";

const initialState = {
  invoice: [],
};

export const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    updateList(state, action) {
      state.invoice = action.payload;
    },
  }
});

export const loadInvoiceList = (data) => {
  return async (dispatch) => {
    return requestFromServer
      .getInvoice(data)
      .then((response) => {
        console.log(response.data);
        dispatch(invoiceSlice.actions.updateList(response.data.data));
      })
      .catch((err) => {
        alert(err.message);
      });
  }
}
