/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { toAbsoluteUrl } from "../../../_helpers";

export function BaseTablesWidget12({ className, chartColor = "danger", data, title }) {
  const tabs = {
    tab1: "kt_tab_pane_3_1",
    tab2: "kt_tab_pane_3_2",
    tab3: "kt_tab_pane_3_3",
  };
  // console.log(data);

  // const newData = [...data, { name: 'Facial', category: 'Facial', count: 10 }, { name: 'Facial', category: 'Facial', count: 10 }, { name: 'Facial', category: 'Facial', count: 10 }, { name: 'Facial', category: 'Facial', count: 10 }, { name: 'Facial', category: 'Facial', count: 10 }, { name: 'Facial', category: 'Facial', count: 10 }]
  // console.log(newData);

  if (data.length === 0) {
    return (
      <>
        {/* begin::Base Table Widget 6 */}
        <div className={`card card-custom ${className}`}>
          {/* begin::Header */}
          <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label font-weight-bolder text-dark">
                {title === 'branch' ? "Top Branches" : title === 'service' ? "Top 5 Services" : title === "customer" ? "Top 5 Customers" : title === "staff" ?  "Staff Report List"  : "Top 5 Packages"}
              </span>
              {/* <span className="text-muted mt-3 font-weight-bold font-size-sm">
              More than 400+ new members
            </span> */}
            </h3>
          </div>
          {/* end::Header */}

          {/* begin::Body */}
          <div class="d-flex justify-content-center" style={{height: "500px"}}><h4>No Data</h4></div>

        </div>
      </>
    )
  };

  return (
    <>
      {/* begin::Base Table Widget 6 */}
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              {title === 'branch' ? "Top Branches" : title === 'service' ? "Top 5 Services" : "Top 5 Packages"}
            </span>
            {/* <span className="text-muted mt-3 font-weight-bold font-size-sm">
              More than 400+ new members
            </span> */}
          </h3>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body pt-2 pb-0">
          {/* begin::Table */}
          <div className="table-responsive  table-y-scroll" style={{ height: '450px', scrollbarWidth: 'thin' }}>
            <table className="table table-borderless table-vertical-center">
              <thead>
                <tr>
                  <th className="p-0" style={{ width: "50px" }} />
                  <th className="p-0" style={{ minWidth: "150px" }} />
                  <th className="p-0" style={{ minWidth: "120px" }} />
                  {/* <th className="p-0" style={{ minWidth: "70px" }} />
                  <th className="p-0" style={{ minWidth: "70px" }} />
                  <th className="p-0" style={{ minWidth: "50px" }} /> */}
                </tr>
              </thead>
              <tbody>
                {data.map((el) => {
                  //console.log(el);
                  return (
                    <tr>
                      {el.image ? <td className="pl-0">
                      <div className="symbol symbol-50 symbol-light mr-2 mt-2">
                        <div
                          className="symbol-label"
                          style={{
                            backgroundImage: `url('${toAbsoluteUrl(`${process.env.REACT_APP_MEDIA_URL}/public/images/branch/${el.image[0]}`)}')`,
                            width: '90px',
                            height: '55px'
                          }}
                        ></div>
                        </div>
                      </td> : <td className="pl-0">
                      <div className="symbol symbol-50 symbol-light mr-2 mt-2">
                        <div
                          className="symbol-label"
                          style={{
                            backgroundColor: '#007669',
                            color: "#ffffff",
                            fontSize: '30px',
                            fontWeight: 'bold',
                            width: '90px',
                            height: '55px'
                          }}
                        >{el.name.charAt(0).toUpperCase()}</div>
                      </div>
                    </td>}
                      <td className="pl-0">
                        <a
                          href="#"
                          className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                        >
                          {el.name}
                        </a>
                        <span className="text-muted font-weight-bold d-block">
                          {`City : ${el.city}`}
                        </span>
                      </td>
                      <td className="text-right">
                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                          {el.totalAmount ? el.totalAmount : "00"}
                        </span>
                        <span className="text-muted font-weight-bold d-block font-size-sm">
                          Revenue
                        </span>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          {/* end::Table */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Base Table Widget 6 */}
    </>
  );
}
