// CouponCard.js
import React from "react";
import { Card, CardContent, Button } from "@material-ui/core";
import { CouponView } from "../coupons/view";

const CouponCard = ({
  coupon,
  onClickApply,
  setCouponToggle,
  couponToggle,
}) => {
  // console.log(coupon,onClickApply,setCouponToggle,couponToggle)
  return (
    <Card className="w-100 mb-3">
      <CardContent>
        <h4 className="">{coupon?.couponCode}</h4>
        <p>{coupon?.description}</p>
        {coupon?.termAndConditions && (
          <>
            <h6>Terms & Conditions*</h6>
            <p>{coupon?.termAndConditions}</p>
          </>
        )}

        <div className="d-flex justify-content-end">
          {couponToggle.status ? (
            <button
              onClick={onClickApply}
              type="button"
              disabled
              variant="contained"
              color="primary"
              className="btn btn-light-success"
            >
              Apply
            </button>
          ) : (
            <button
              onClick={onClickApply}
              type="button"
              variant="contained"
              color="primary"
              className="btn btn-light-success"
            >
              Apply
            </button>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default CouponCard;
