import axios from "axios";
const URL = process.env.REACT_APP_API_URL + "/admin";
const token = process.env.REACT_APP_ADMIN_TOKEN;
const Authorization = "Bearer " + token;
const headers = {
  Authorization,
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const getSubscriptionList = async () => {
  return await axios.get(`${URL}/subscription/list`);
};

export const getSubscription = async (req) => {
  console.log(req);
  return await axios.post(`${URL}/subscription/view`, req);
};

export const createSubscription = (data) => {
  return axios.post(`${URL}/subscription/create`, data, {
    headers,
  });
};

export const updateSubscription = (data, uuid) => {
  data.uuid = uuid;
  console.log(data);
  return axios.patch(`${URL}/subscription/update`, data, {
    headers,
  });
};

export const deleteSubscription = (data) => {
  console.log(data);

  return axios.delete(`${URL}/subscription/delete`, data);
};
