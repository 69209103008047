import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import filterFactory from "react-bootstrap-table2-filter";

import { StatusColumnFormatter } from "../../_metronic/_helpers/tableColumnFormatter";
import { sortCaret } from "../../_metronic/_helpers";
import { useDispatch, useSelector } from "react-redux";
import { loadChairList } from "../../store/business/chairs/chairSlice";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";
const LinkFormatter = (cell, row) => {
  return <Link to={`chair/${row.uuid}`}>{cell}</Link>;
};
export const ChairsTable = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.chair.chairs);
  const [dataLoading, setDataLoading] = useState(true);
  useEffect(() => {
    dispatch(loadChairList()).then(() => {
      setDataLoading(false);
    });
  }, []);

  const columns = [
    {
      dataField: "",
      text: "#",
      formatter: (row, cell, index) => {
        return index+1;
      }
    },
    {
      dataField: "chairName",
      text: "Chair Name",
      sort: true,
      sortCaret: sortCaret,
      formatter: LinkFormatter,
      // filter: textFilter(),
    },
    {
      dataField: "branch[0].name",
      text: "Branch Name",
      sort: true,
      sortCaret: sortCaret,
      // filter: textFilter(),
    },
    {
      dataField: "isActive",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      formatter: StatusColumnFormatter,
    },
  ];

  const { SearchBar, ClearSearchButton } = Search;

  return (
    <ToolkitProvider search keyField="uuid" data={data} columns={columns}>
      {(props) => (
        <>
          <div className="container">
            <div class="row">
              <div className="col-sm-4">
                {/* <h4 className="mt-4" >Search</h4> */}
              </div>
              <div className="col-sm-8">
                <SearchBar {...props.searchProps} />
                <ClearSearchButton
                  {...props.searchProps}
                  className="ml-4 btn btn-secondary-light"
                />
              </div>
            </div>
          </div>

          <BootstrapTable
            {...props.baseProps}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            wrapperClasses="table-responsive"
            bordered={false}
            bootstrap4
            loading={true}
            pagination={paginationFactory()}
            filter={filterFactory()}
            noDataIndication={
              <div className={"d-flex justify-content-center py-10"}>
                <h3>{dataLoading ? "Loading Data..." : "No records found"}</h3>
              </div>
            }
          />
        </>
      )}
    </ToolkitProvider>
  );
};
