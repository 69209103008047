import { createSlice } from "@reduxjs/toolkit";

import * as requestFromServer from "./holidayApi";

const initialState = {
  currentCustomer: "",
  bookings: [],
};

export const branchesHolidaySlice = createSlice({
  name: "branchesHoliday",
  initialState,
  reducers: {
    updateCurrentCustomer(state, action) {
      state.currentCustomer = action.payload;
    },
    updateList(state, action) {
      state.bookings = action.payload;
    },
    updateBooking(state, action) {
      const bookingIndex = state.bookings.findIndex(
        (booking) => booking.branchUuid === action.payload.uuid
      );


      if (bookingIndex > -1) {
        state.bookings.splice(bookingIndex, 1);
      }

      // state.bookings.push(action.payload);
      console.log(action.payload);
    },
    updateStatus(state, action) {
      const holidayIndex = state.bookings.findIndex(
        (holiday) => holiday.uuid === action.payload
      );
      console.log(holidayIndex);
      if (holidayIndex > -1) {
        state.bookings.splice(holidayIndex, 1);
      }
    },
    created(state, action) {
      state.bookings.push(action.payload);
    },
  },
});



export const loadHolidayBookingList = () => {
  return async (dispatch) => {
    return requestFromServer
      .getBranchHolidayList()
      .then((response) => {
        console.log(response.data.data);
        dispatch(branchesHolidaySlice.actions.updateList(response.data.data));
      })
      .catch((err) => {
        alert(err.message);
      });
  };
};

export const updateHolidayBookingList = (data) => {
  console.log(data);
  return async (dispatch) => {
        dispatch(branchesHolidaySlice.actions.updateStatus(data));
  };
};

