/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_helpers";

export function TilesWidget11({
  className,
  baseColor,
  iconColor = 'white',
  widgetHeight,
  title,
  count,
  svgUrl,
  linkUrl,
}) {
  return (
    <>
      <div
        className={`card card-custom bg-${baseColor} ${className}`}
        style={{ height: widgetHeight }}
      >
        <div className="p-5">
          <span className={`svg-icon svg-icon-3x svg-icon-${iconColor} ml-n2`}>
            {svgUrl && <SVG src={toAbsoluteUrl(svgUrl)} />}
          </span>
          <div
            className={`text-inverse-${baseColor} font-weight-bolder font-size-h2 mt-3`}
          >
            {count}
          </div>

          {linkUrl ?
            <Link
              to={toAbsoluteUrl(linkUrl)}
              className={`text-inverse-${baseColor} font-weight-bold font-size-h5 mt-1`}
            >
              {title}
            </Link>
            :
            <Link
              // to={toAbsoluteUrl(linkUrl)}
              className={`text-inverse-${baseColor} font-weight-bold font-size-h5 mt-1`}
            >
              {title}
            </Link>}
        </div>
      </div>
    </>
  );
}
