import axios from "axios";
const URL = process.env.REACT_APP_API_URL;
const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const getBranchHolidayList = (data) => {
  const req = {
    bookingStatus: data.bookingStatus,
    from: data.from,
    to: data.to,
  };
  return axios.post(`${URL}/userBranch/staff/holiday/list`, req, {
    headers });
};
