import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import filterFactory from "react-bootstrap-table2-filter";

import { sortCaret } from "../../_metronic/_helpers";

import paginationFactory from "react-bootstrap-table2-paginator";
import dayjs from "dayjs";
import { loadShiftList } from "../../store/business/shifts/shitftsSlice";
import { loadBranchShiftList } from "../../store/branch/branch_shifts/branchShitftsSlice";

const TimeFormatter = (cell, row) => {
  return dayjs(cell).format("hh:mm A");
};

export const ShiftsTable = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.shift.shifts);
  const branchShift = useSelector((state) => state.branchShifts.branchShifts);
  const { role, user } = useSelector((state) => state.authPersist);
  const [dataLoading, setDataLoading] = useState(true);
  const LinkFormatter = (cell, row) => {
    if (role == "business") {
      return <Link to={`shift/${row.uuid}`}>{cell}</Link>;
    } else {
      return cell;
    }
  };
  useEffect(() => {
    if (role === "business") {
      dispatch(loadShiftList()).then(() => {
        setDataLoading(false);
      });
    } else {
      dispatch(loadBranchShiftList()).then(() => {
        setDataLoading(false);
      });
    }
  }, []);

  const columns = [
    {
      dataField: "",
      text: "#",
      formatter: (row, cell, index) => {
        return index + 1;
      },
    },
    {
      dataField: "shift_name",
      text: "Shift Name",
      sort: true,
      formatter: LinkFormatter,
      sortCaret: sortCaret,
      // filter: textFilter(),
    },
    // {
    //   dataField: "branch[0].name",
    //   text: "Branch Name",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   // filter: textFilter(),
    // },
    {
      dataField: "start_time",
      text: "Start Time",
      sort: true,
      formatter: TimeFormatter,
    },
    {
      dataField: "end_time",
      text: "End Time",
      sort: true,
      formatter: TimeFormatter,
    },
    {
      dataField: "break1_start_time",
      text: "Break Start Time",
      sort: true,
      formatter: TimeFormatter,
    },
    {
      dataField: "break1_end_time",
      text: "Break End Time",
      sort: true,
      formatter: TimeFormatter,
    },
  ];

  const { SearchBar, ClearSearchButton } = Search;

  return (
    <ToolkitProvider
      search
      keyField="uuid"
      data={role == "business" ? data : branchShift}
      columns={columns}
    >
      {(props) => (
        <>
          <div className="container">
            <div class="row">
              <div className="col-sm-4">
                {/* <h4 className="mt-4" >Search</h4> */}
              </div>
              <div className="col-sm-8">
                <SearchBar {...props.searchProps} />
                <ClearSearchButton
                  {...props.searchProps}
                  className="ml-4 btn btn-secondary-light"
                />
              </div>
            </div>
          </div>

          <BootstrapTable
            {...props.baseProps}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            wrapperClasses="table-responsive"
            bordered={false}
            bootstrap4
            pagination={paginationFactory()}
            filter={filterFactory()}
            noDataIndication={
              <div className={"d-flex justify-content-center py-10"}>
                <h3>{dataLoading ? "Loading Data..." : "No records found"}</h3>
              </div>
            }
          />
        </>
      )}
    </ToolkitProvider>
  );
};
