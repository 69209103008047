import { createSlice } from "@reduxjs/toolkit";

import * as requestFromServer from "./topSalonsAPI";

const initialState = {
  loading:false,
  topSalons: [],
  salonView:{},
};

export const topSalonsSlice = createSlice({
  name: 'topSalonsSlice',
  initialState,
  reducers: {
   loadingTrue(state){
    state.loading = true
   },
    created(state, action) {
      state.topSalons.push(...action.payload);
      state.loading = false
    },
    emptyTopSalon(state){
      state.topSalons = []
    },
    viewSalon(state,action){
      state.salonView = action.payload;
      state.loading = false
    }
   
   
  }
});



export const topSalonsThunk = (data) => {
  // console.log("data",data)
  return async (dispatch) => {
  dispatch(topSalonsSlice.actions.loadingTrue());
    return requestFromServer
      .topSalonsAPI(data)
      .then((response) => {
        dispatch(topSalonsSlice.actions.created(response.data.data));
      })
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };
};


export const SalonViewThunk = (data) => {
  // console.log("data",data)
  return async (dispatch) => {
  dispatch(topSalonsSlice.actions.loadingTrue());
    return requestFromServer
      .salonView(data)
      .then((response) => {
        // console.log("response",response.data.data)
        dispatch(topSalonsSlice.actions.viewSalon(response.data.data));
      })
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };
};


export const {emptyTopSalon} = topSalonsSlice.actions;
